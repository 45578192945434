import React, { Component, Fragment } from 'react'
import Select from 'react-select';
import classNames from 'classnames';
import { api } from 'api/api';
import AsyncSelect from 'react-select/lib/Async';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';

 let cuentas = [];
 const getCuentas = (search)  =>{
    return api.get("cuenta_area",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    cuentas = [];
                    data.results.forEach(x=>{
                        cuentas.push(x)
                    })
                    return cuentas
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

let proveedores = [];
const getProveedores = (search) => {
    return api.get('proveedores', {search}).catch((error) => {})
        .then((data) => {
            if(data){
                proveedores = [];
                data.results.forEach(x=>{
                    proveedores.push(x)
                })
                return proveedores
            }else{
                return []
            }
        }).catch(e => {
            return []
        })
}
let categoria = [];
const getCategoria = (search) => {
    return api.get('categoria', {search}).catch((error) => {})
        .then((data) => {
            if(data){
                categoria = [];
                data.results.forEach(x=>{
                    categoria.push(x)
                })
                return categoria
            }else{
                return []
            }
        }).catch(e => {
            return []
        })
}

export default class Filtros extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
    render() {
        const { ver_gasto, ver_proveedor, ver_cuenta, monto_total, ver_rango} = this.props;
        return (
            <div className="col-md-12 row m-0 d-flex justify-content-center ">
                {
                    ver_rango && (
                        <Fragment>
                                    <div className="col-lg-8  m-0 form-group">
                                            <label className="m-0">Fecha inicio:</label>
                                            <SingleDatePicker
                                                placeholder={"Fecha Inicio"}
                                                date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                                                focused={this.state.focusedStart}
                                                isOutsideRange={() => false}
                                                onDateChange={(value) => {
                                                this.setState({dateStart:value})
                                                this.props.setDateStart(value)
                                                }}
                                                onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                numberOfMonths={1}
                                                id={"dateStart"}
                                            />
                                    </div>
                                    <div className="col-lg-8 row m-0  form-group">
                                            <label className="m-0">Fecha fin:</label>
                                            <SingleDatePicker
                                                placeholder={"Fecha Inicio"}
                                                date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                                                focused={this.state.focusedEnd}
                                                isOutsideRange={() => false}
                                                onDateChange={(value) => {
                                                this.setState({dateEnd:value})
                                                this.props.setDateEnd(value);
                                                }}
                                                onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                                                numberOfMonths={1}
                                                id={"dateEnd"}
                                            />
                                    </div>
                            </Fragment>
                    )
                }
                {
                    ver_gasto && (
                        <div className="col-lg-8 form-group">
                            <label className="m-0">Categoria de gasto</label>
                            <AsyncSelect
                                value={this.props.f_gasto}
                                onChange={this.props.setGasto}
                                placeholder="todos"
                                options={this.props.getGastos}
                                className={classNames('form-control p-0 ')}
                                    placeholder="todos"
                                    isClearable
                                    theme={(theme) => {
                                        return {
                                    ...theme,
                                    borderRadius: '1em',
                                            background: 'white',
                                    colors: {
                                    ...theme.colors,
                                        text: '#415362',
                                        primary25: '#ebebeb',
                                        primary: '#a8ba3a',
                                    },
                                    }
                                    }}
                                    classNamePrefix="react-select"
                                    cache={false} placeholder="Escriba para buscar"
                                    defaultOptions={ true }
                                    searchPromptText="Escriba para buscar"
                                    getOptionValue={(option) => (option["id"])}
                                    getOptionLabel={(option) => (option["nombre"])}
                                    loadOptions={getCategoria}
                            />
                    </div>
                    )
                }
                {
                    ver_proveedor && (
                        <div className="col-lg-8 form-group">
                            <label className="m-0">Proveedor:</label>
                            <AsyncSelect
                                value={this.props.f_proveedores}
                                onChange={this.props.setProveedor}
                                placeholder="todos"
                                options={this.props.getProveedores}
                                className={classNames('form-control p-0 ')}
                                placeholder="todos"
                                isClearable
                                theme={(theme) => {
                                    return {
                                ...theme,
                                borderRadius: '1em',
                                        background: 'white',
                                colors: {
                                ...theme.colors,
                                    text: '#415362',
                                    primary25: '#ebebeb',
                                    primary: '#a8ba3a',
                                },
                                }
                                }}
                                classNamePrefix="react-select"
                                cache={false}
                                placeholder="Escriba para buscar"
                                defaultOptions={ true }
                                searchPromptText="Escriba para buscar"
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["nombre"])}
                                loadOptions={getProveedores}
                            />
                    </div>
                    )
                }
                {
                    ver_cuenta && (
                        <div className="col-lg-8 form-group">
                                <label className="m-0">Cuenta</label>
                                <AsyncSelect
                                    value={this.props.f_cuenta}
                                    onChange={this.props.setCuenta}
                                    placeholder="todos"
                                    options={this.props.getCuentas}
                                    className={classNames('form-control p-0 ')}
                                        placeholder="todos"
                                        isClearable
                                        theme={(theme) => {
                                            return {
                                        ...theme,
                                        borderRadius: '1em',
                                                background: 'white',
                                        colors: {
                                        ...theme.colors,
                                            text: '#415362',
                                            primary25: '#ebebeb',
                                            primary: '#a8ba3a',
                                        },
                                        }
                                        }}
                                        classNamePrefix="react-select"
                                        cache={false} placeholder="Escriba para buscar"
                                        defaultOptions={ true }
                                        searchPromptText="Escriba para buscar"
                                        getOptionValue={(option) => (option["id"])}
                                        getOptionLabel={(option) => (option["nombre"])}
                                        loadOptions={getCuentas}
                                />
                        </div>
                    )
                }
                <div className="col-lg-8 row m-0 mb-4 borde-gris">
                    <div className="col-md-4 text-center p-0">
                        <span className="text-uppercase text-primary">Total:</span>
                    </div>
                    <div className="col-md-4 text-center p-0">
                        <RenderCurrency value={monto_total || 0}  className={'text-verde h3 font-weight-bold'}/>
                    </div>
                </div>
            </div>
        )
    }
}

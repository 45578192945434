import React, { Component } from 'react'
import 'react-dates/lib/css/_datepicker.css';
// import { Multi } from 'react-chartjs-2';
import NVD3ChartMask from "Utils/Graficas/NDV3ChartMask";
import { formatos, opcionesG1, sinTitulos, tooltips, colores, addHideTooltip, funciones } from 'Utils/Graficas/opciones';
import LoadMask from 'Utils/LoadMask/LoadMask';


export default class Grafica extends Component {
    render() {
        const { datum } = this.props;
        let options2 = _.cloneDeep(opcionesG1.optionsDiscreteBar2);
        return (
            <div>
                <div className="horizontal-scroll">
                    <NVD3ChartMask
                        id="gananciaGrafica"
                        {...options2}
                        datum={datum || []} height={350} />
                </div>
            </div>
        )
    }
}

import React, { Component, Fragment } from 'react';
import DetalleBilletes from './DetalleBilletes';
import InfoCierre from './InfoCierre';
import InfoApertura from './InfoApertura';
import TituloBodega from 'Utils/Header/TituloBodega';
import {iconsh} from 'icons';
import LoadMask from 'Utils/LoadMask/LoadMask';
import AutorizacionApertura from '../../../Utils/Autorizacion/Cajas/Apertura/AutorizacionApertura';
import AutorizacionCierre from '../../../Utils/Autorizacion/Cajas/Cierre/AutorizacionDoble';

export default class AperturaCierre extends Component {
    componentWillMount(){
        this.props.detail(this.props.match.params.id);
    }
    render() {
        const {updateData, cargando, modal_autorizacion, cargando_modal, configuracion} = this.props;
        return (
            <div className="col-md-12 row m-0 p-0 d-flex justify-content-between">
                {
                    modal_autorizacion && (
                        <Fragment>
                            {
                                updateData && updateData.cierre ? (
                                    <AutorizacionCierre
                                        cargando={cargando_modal}
                                        monto_inicial={configuracion ? configuracion.monto_inicial_caja : 0}
                                        caja_nombre={updateData ? updateData.nombre : ''}
                                        modal={modal_autorizacion}
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.cierreCaja} />
                                ) : (
                                    <AutorizacionApertura
                                        monto_inicial={configuracion ? configuracion.monto_inicial_caja : 0}
                                        cargando={cargando_modal}
                                        caja_nombre={updateData ? updateData.nombre : ''}
                                        modal={modal_autorizacion}
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.aperturarCaja} />
                                )
                            }
                        </Fragment>

                    )
                }
                {/* sección derecha */}
                <div className="col-lg-6 px-1">
                    <div className="col-md-12 p-0 container m-0 contenido-principal h85">
                        <div className="col-md-12 pt-3">
                            <TituloBodega
                            titulo={'Caja'}
                            subtitulo={updateData ? updateData.nombre : ''}
                            estilo={`col-md-6`}
                            icono={iconsh.crud_caja_venta}
                            />
                        </div>
                        <div className="col-md-12">
                            <LoadMask loading={cargando} blur_1>
                                {
                                    updateData && updateData.cierre ? (
                                        <InfoCierre {...this.props} />
                                        ) :(
                                        <InfoApertura {...this.props} />
                                    )
                                }
                            </LoadMask>
                        </div>
                    </div>
                </div>
                {/* sección derecha */}

                {/* sección izquierda */}
                <div className="col-lg-6 px-1 mt-lg-0 mt-2 ">
                    <div className="col-md-12 p-0 container m-0 contenido-principal h85">

                        <DetalleBilletes {...this.props}/>
                    </div>
                </div>
                {/* sección izquierda */}
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Redirect } from 'react-router'
import "./dashboardPrivadoEstilos.css";
import { productosPorVencerFarmacia } from '../../../redux/modules/login';

class Dashboard extends Component {
    
    componentWillMount = () => {
        const {
            productosPorVencerFarmacia, 
            productosPorVencerHospital,
            cuentasPorPagar,
            gastosIngresos,
            cuentasPorCobrar,
            ventasPorArea,
        } = this.props;
    
        productosPorVencerFarmacia();
        productosPorVencerHospital();
        cuentasPorPagar();
        gastosIngresos();
        cuentasPorCobrar();
        ventasPorArea();
    }
    
    render() {
        const {me} = this.props;
        console.log(me);
        if (me && me.is_superuser === true){
            return (
                <div>
                    <div className="row header-dashboard contenido-principal ml-1 mb-4">
                        <div className="col-6 d-flex align-items-end">
                            <h4>DASHBOARD</h4>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <img className="imagen-logo-dashboard" src={require("../../../../../src/assets/img/logo01.png")} alt="Eliminar" />
                        </div>
                    </div>

                    {/* Primera fila */}
                    <div className="d-flex justify-content-between">
                        {/* Cuentas de hospital por cobrar */}
                        <div className="card contenido-principal justify-content-between flex-1 mr-2 flex-wrap">
                            <div className="card-head-dashboard">
                                CUENTAS DE HOSPITAL POR COBRAR
                            </div>
                            <div className="card-body d-flex w-100">
                                <div className="d-flex flex-column align-items-center justify-content-center w-50">
                                    <div>
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <img className="imagen-card-dashboard" src={require("../../../../../src/assets/img/icons/dashboardCuentasPorCobrar.png")} alt="Eliminar" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-50 contenedor-card-texto">
                                    <div className='d-flex flex-column justify-content-center'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h2 className="text-cantidad-card-first">{this.props.cuentas_por_cobrar && this.props.cuentas_por_cobrar.cantidad}</h2>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h6 className="text-text-card-first">Pacientes</h6>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column card-textoCantidad'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h2 className="text-cantidad-card-first">Q. {this.props.cuentas_por_cobrar && this.props.cuentas_por_cobrar.total}</h2>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h6 className="text-text-card-first">Por Cobrar</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Cuentas por pagar */}
                        <div className="card contenido-principal justify-content-between flex-1 ml-2 mr-2 flex-wrap">
                            <div className="card-head-dashboard">
                            CUENTAS POR PAGAR
                            </div>
                            <div className="card-body d-flex w-100">
                                <div className="d-flex flex-column align-items-center justify-content-center w-50">
                                    <div>
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <img className="imagen-card-dashboard" src={require("../../../../../src/assets/img/icons/dashboardCuentasPorPagar.png")} alt="Eliminar" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-50 contenedor-card-texto">
                                    <div className='d-flex flex-column justify-content-center'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h2 className="text-cantidad-card-first">{this.props.cuentas_por_pagar && this.props.cuentas_por_pagar.cantidad}</h2>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h6 className="text-text-card-first">Ordenes de pago</h6>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column card-textoCantidad'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h2 className="text-cantidad-card-first">Q. {this.props.cuentas_por_pagar && this.props.cuentas_por_pagar.total}</h2>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h6 className="text-text-card-first">total</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Gastos e ingresos del mes */}
                        <div className="card contenido-principal justify-content-between flex-1 ml-2 flex-wrap">
                            <div className="card-head-dashboard">
                                GASTOS E INGRESOS DEL MES
                            </div>
                            <div className="card-body d-flex w-100">
                                <div className="d-flex flex-column align-items-center justify-content-center w-50">
                                    <div>
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <img className="imagen-card-dashboard" src={require("../../../../../src/assets/img/icons/dashboardGastosIngresos.png")} alt="Eliminar" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-50 contenedor-card-texto">
                                    <div className='d-flex flex-column justify-content-center'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h2 className="color-cantidad-por-vencer-2">Q. {this.props.gastos_ingresos && this.props.gastos_ingresos.gastos}</h2>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h6 className="text-text-card-first">Gastos</h6>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column card-textoCantidad'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h2 className="text-cantidad-card-first">Q. {this.props.gastos_ingresos && this.props.gastos_ingresos.ingresos}</h2>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <h6 className="text-text-card-first">Ingresos</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Segunda fila */}
                    <div className="d-flex justify-content-end mt-4 w-100">
                        {/* Productos por vencer */}
                        <div className="card contenido-principal justify-content-between col-8 mr-3 w-50">
                            <div className="card-head-dashboard">
                                PRODUCTOS POR VENCER Y VENCIDOS POR ÁREA
                            </div>
                            <div className="row h-100">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <img className="imagen-card-dashboard" src={require("../../../../../src/assets/img/icons/dashboardProductosPorVencer.png")} alt="Eliminar" />   
                                </div>
                                <div className="col-9">
                                    <div className="row card-body d-flex align-items-center justify-content-between">
                                        <div className="col d-flex flex-column align-items-center justify-content-center text-cantidad-card-first">POR VENCER 2 MESES</div>
                                        <div className="col d-flex flex-column align-items-center justify-content-center text-cantidad-card-first">POR VENCER 1 MES</div>
                                        <div className="col d-flex flex-column align-items-center justify-content-center text-cantidad-card-first">VENCIDOS</div>
                                    </div>
                                    <div className="row card-body d-flex align-items-center justify-content-between">
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="color-cantidad-por-vencer-2">{this.props.productos_por_vencer_farmacia && this.props.productos_por_vencer_farmacia.dos_meses}</h1>
                                            <h6 className="text-text-card-first">Farmacia</h6>
                                        </div>
                                        
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="color-cantidad-por-vencer-1">{this.props.productos_por_vencer_farmacia && this.props.productos_por_vencer_farmacia.un_mes}</h1>
                                            <h6 className="text-text-card-first">Farmacia</h6>
                                        </div>
                                        
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="color-cantidad-vencidos">{this.props.productos_por_vencer_farmacia && this.props.productos_por_vencer_farmacia.vencidos}</h1>
                                            <h6 className="text-text-card-first">Farmacia</h6>
                                        </div>
                                    
                                    </div>
                                    <div className="row card-body d-flex align-items-center justify-content-between">
                                    <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="color-cantidad-por-vencer-2">{this.props.productos_por_vencer_hospital && this.props.productos_por_vencer_hospital.dos_meses}</h1>
                                            <h6 className="text-text-card-first">Hospital</h6>
                                        </div>
                                        
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="color-cantidad-por-vencer-1">{this.props.productos_por_vencer_hospital && this.props.productos_por_vencer_hospital.un_mes}</h1>
                                            <h6 className="text-text-card-first">Hospital</h6>
                                        </div>
                                        
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="color-cantidad-vencidos">{this.props.productos_por_vencer_hospital && this.props.productos_por_vencer_hospital.vencidos}</h1>
                                            <h6 className="text-text-card-first">Hospital</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Ventas por area */}
                        <div className="card contenido-principal w-50">
                            <div className="card-head-dashboard">
                            TOTAL DE VENTAS POR ÁREA
                            </div>
                            <div className="row h-100">
                                <div className="col">
                                    <div className="row card-body d-flex align-items-center justify-content-between">
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <img className="imagen-card-ventas-area-dashboard" src={require("../../../../../src/assets/img/icons/dashboardFarmacia.png")} alt="Eliminar" />   
                                        </div>

                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="text-cantidad-card-first">Q. {this.props.ventas_por_area && this.props.ventas_por_area.total_farmacia}</h1>
                                            <h6 className="text-text-card-first">Farmacia</h6>
                                        </div>
                                    
                                    </div>
                                    <div className="row card-body d-flex align-items-center justify-content-between">
                                        
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <img className="imagen-card-ventas-area-dashboard" src={require("../../../../../src/assets/img/icons/dashboardHospital.png")} alt="Eliminar" />   
                                        </div>
                                        
                                        <div className="col d-flex flex-column align-items-center justify-content-center">
                                            <h1 className="text-cantidad-card-first">Q. {this.props.ventas_por_area && this.props.ventas_por_area.total_hospital}</h1>
                                            <h6 className="text-text-card-first">Hospital</h6>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (me && me.is_superuser === false){
            return (
                <Redirect to='/page'/>
            )
        } else {
            return null
        }
    }
}

export default Dashboard;
import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack, go } from 'react-router-redux'
import { initialize as initializeForm, reset, destroy as destroyForm } from 'redux-form'
import { push } from 'react-router-redux';
import { actions as actionsLogin} from '../login';
import { actions as actionsMov } from './movimientosbodega';
import _ from 'lodash';
import swal from 'sweetalert2';
import uuid1 from 'uuid/v1';

const url = 'bodega';

const LOADER_EB = 'LOADER_EB';
const SET_DATA_EB = 'SET_DATA_EB';
const SET_PAGE_EB = 'SET_PAGE_EB';
const SET_UPDATE_DATA_EB ='SET_UPDATE_DATA_EB';
const SET_BUSCADOR_EB = 'SET_BUSCADOR_EB';
const SET_FILTRO_EB = 'SET_FILTRO_EB';
const SET_MODALAUTORIZACION_EB = 'SET_MODALAUTORIZACION_EB';
const SET_CARGANDO_EB = 'SET_CARGANDO_EB';
const SET_NOTIFICACION_EB = 'SET_NOTIFICACION_EB';
const SET_MODAL_EB = 'SET_MODAL_EB';
const SET_VERIFICACION = 'SET_VERIFICACION';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_EB, cargando: true});
    const store = getStore();
    const updateData = store.estadobodega.updateData
    const search = store.estadobodega.buscador;
    const filtro = store.estadobodega.filtro_eb;
    let params = {page, search};

    if(filtro){
        params['filtro'] = filtro.value
    }
    // if(updateData){
    //     params['bodega__id'] = updateData.id
    // }
    if(updateData.id) {
        api.get(`inventario/getInventario/${updateData.id}`, params).catch((error) => {
            dispatch({type: LOADER_EB, cargando: false});
            Swal(
                'Error',
                (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).then((data) => {
            if(data){
                dispatch({type: SET_DATA_EB, data});
                dispatch({type: SET_PAGE_EB, page});
            }
            dispatch(getNotificacion());
            dispatch({type: LOADER_EB, cargando: false});
        })
    }
};
const detail = (id, recargar=false) => (dispatch, getState) =>{
    dispatch({type: LOADER_EB, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_EB, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/estadobodega'))
        }
    }).then((data) => {
        dispatch({type: LOADER_EB, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_EB, updateData: data});
           dispatch(listar())
           if(recargar){
                dispatch(actionsMov.listar_despachos_pendientes())
                dispatch(actionsMov.listar_ingreso_pendiente())
           }
           dispatch(getNotificacion());
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.bodega.values;
    dispatch({type: LOADER_EB, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_EB, cargando: false})
        Swal('Éxito', 'Se ha creado la bodega.', 'success')
        .then(() => {
            dispatch(push('/estadobodega'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_EB, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
        dispatch(getNotificacion());
    }).finally(() => {
        dispatch({type: LOADER_BODEGA, cargando: false})
    });
};

const crearDespacho = () => (dispatch, getStore) => {
    const store = getStore();
    const updateData = store.estadobodega.updateData;
    const formData = getStore().form.Despacho.values;
    dispatch({type: LOADER_EB, cargando: true})
    api.post(`movbodega/despacho/${updateData.id}`, formData).then((data) => {
        dispatch({type: LOADER_EB, cargando: false})
        Swal('Éxito', 'Se ha creado el despacho.', 'success')
        .then(() => {
            dispatch(go(-1))
        })
    }).catch((error) => {
        dispatch({type: LOADER_EB, cargando: false});
        dispatch(getNotificacion());
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_BODEGA, cargando: false})
    });
};

const ingresoBeneficio = () => (dispatch, getStore) => {
    const store = getStore();
    const formData = _.cloneDeep(getStore().form.Ingreso.values);
    const updateData = store.estadobodega.updateData
    if(!formData.productos || !formData.productos.length){
        swal('Error', 'Debe de ingresar productos.', 'error');
    }else{
        dispatch({type: LOADER_EB, cargando: true})
        api.post(`${url}/IngresoBeneficio/${updateData.id}`, formData).then((data) => {
            dispatch({type: LOADER_EB, cargando: false})
            dispatch(getNotificacion());
            Swal('Éxito', 'Se ha realizado el ingreso.', 'success')
            .then(() => {
                dispatch(go(-1))
            })
        }).catch((error) => {
            dispatch({type: LOADER_EB, cargando: false})
            dispatch(getNotificacion());
            Swal(
                'Error',
                (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).finally(() => {
            dispatch({type: LOADER_EB, cargando: false})
        });
    }

};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_EB, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_EB, cargando: false});
        dispatch(getNotificacion());
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/estadobodega'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_EB, cargando: false});
        dispatch(getNotificacion());
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/estadobodega'));
        }
    })
}
const reintegroBaja = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.reajuste.values);
    if(formData.reajuste == 'true'){
        formData.reajuste = true;
    }else{
        formData.reajuste = false;
    }
    dispatch({type: SET_CARGANDO_EB, cargando_modal: true});
    api.put(`inventario/reajusteBaja/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: SET_CARGANDO_EB, cargando_modal: false});
        if(data){
            dispatch(getNotificacion());
            Swal(
                'Éxito',
                'Se ha realizado el movimiento.',
                'success'
            ).then(() => {
                dispatch(closeModalReajuste())
                dispatch(listar(getStore().estadobodega.page));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: SET_CARGANDO_EB, cargando_modal: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/estadobodega'));
        }
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_EB, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (!filtro){
        filtro = null
    }
    dispatch({type: SET_FILTRO_EB, filtro_eb: filtro});
    dispatch(listar(1));
};

const verificarUsuario = () => (dispatch, getStore) => {
    const store = getStore();
    const updateData = store.estadobodega.updateData;
    const formData = getStore().form.autorizacion.values;
    formData.bodega= updateData.id
    api.post(`${url}/verificarUsuario`, formData).then((data) => {
        dispatch({type: SET_VERIFICACION, verificacion_usuario: true});
    }).catch((error) => {
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
        dispatch({type: SET_VERIFICACION, verificacion_usuario: false});
    }).finally(() => {
    });

};
const cambiarTurno = () => (dispatch, getStore) => {
    const store = getStore();
    const me = store.login.me;
    const updateData = store.estadobodega.updateData;
    const formData = getStore().form.autorizacion.values;
    formData.usuario1 = me.username
    formData.bodega= updateData.id
    dispatch({type: SET_CARGANDO_EB, cargando_modal: true})

    api.post(`${url}/cambioTurno`, formData).then((data) => {
        dispatch({type: SET_CARGANDO_EB, cargando_modal: false})
        Swal('Éxito', 'Se ha realizado el cambio de turno.', 'success')
        .then(() => {
            dispatch(actionsLogin.cambioTurno(data));
            dispatch(detail(updateData.id));
            dispatch(closeModal());

        })
    }).catch((error) => {
        dispatch({type: SET_CARGANDO_EB, cargando_modal: false});
        dispatch(initializeForm('autorizacion'));
        dispatch(getNotificacion());
        let mensaje = 'Ha ocurrido un error, por favor vuelva a intentar.';
        if(error){
            mensaje= error.detail;
        }
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: SET_CARGANDO_EB, cargando_modal: false})
        dispatch({type: SET_VERIFICACION, verificacion_usuario: false});
    });

}

const openModal = () => (dispatch, getStore) =>  {
    dispatch({type: SET_MODALAUTORIZACION_EB, modal_autorizacion: true})
    dispatch(initializeForm('autorizacion'));
    dispatch({type: SET_VERIFICACION, verificacion_usuario: false});
}
const closeModal = () => (dispatch, getStore)  => {
    dispatch({type: SET_MODALAUTORIZACION_EB, modal_autorizacion: false})
    dispatch(destroyForm('autorizacion'))
}
const regresar = () => (dispatch, getStore) => {
    dispatch(go(-1))
}
export const getNotificacion = () => (dispatch, getState) =>{
    api.get(`${url}/notificacionMovBodega`).catch((error) => {
        if(error.statusCode  === 404){
        }
    }).then((data) => {
        if(data){
            dispatch({type: SET_NOTIFICACION_EB, notificacion: data.total});
        }
    })
}
const openModalReajuste = () => (dispatch, getStore) => {
    dispatch({
        type: SET_MODAL_EB,
        modal: true
    });
}
const closeModalReajuste = () => (dispatch, getStore) => {
    dispatch({
        type: SET_MODAL_EB,
        modal: false
    });
}
const initCantidad  = (lote, lotes) => (dispatch, getStore) => {
    const formProducto = getStore().form.productoform;
    const loteID = lote.target.value;
    let loteResult = _.find(lotes, (ob => ob.lote.id == loteID));
    if(loteResult) {
        if(loteResult.existencias >= 1) {
            dispatch(initializeForm('productoform', {
                ...formProducto.values,
                cantidad: 1,
                existencias: loteResult.existencias,
                vencimiento: loteResult.lote.fecha_vencimiento,
            }));
        }else {
            dispatch(initializeForm('productoform',
                {...formProducto.values,
                    cantidad: '',
                    existencias: loteResult.existencias,
                    vencimiento: loteResult.lote.fecha_vencimiento,
                }));
        }
    } else {
        dispatch(initializeForm('productoform', {
            ...formProducto.values,
            cantidad: '',
            existencias: 0,
        }));
    }

}

const uuidInitial = () => (dispatch) => {
    dispatch(initializeForm('Despacho', {
        uuid: uuid1()
    }));
}
export const actions = {
    listar,
    detail,
    create,
    update,
    search,
    filtro,
    regresar,
    openModal,
    closeModal,
    verificarUsuario,
    cambiarTurno,
    crearDespacho,
    reintegroBaja,
    getNotificacion,
    ingresoBeneficio,
    openModalReajuste,
    closeModalReajuste,
    initCantidad,
    uuidInitial
};
export const reducer = {
    [LOADER_EB]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_EB]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_EB]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_EB]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_EB]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_EB]: (state, { filtro_eb }) => {
        return {...state, filtro_eb }
    },
    [SET_MODALAUTORIZACION_EB]: (state, { modal_autorizacion }) => {
        return {...state, modal_autorizacion }
    },
    [SET_CARGANDO_EB]: (state, { cargando_modal }) => {
        return {...state, cargando_modal }
    },
    [SET_NOTIFICACION_EB]: (state, { notificacion }) => {
        return {...state, notificacion }
    },
    [SET_MODAL_EB]: (state, { modal }) => {
        return {...state, modal }
    },
    [SET_VERIFICACION]: (state, {verificacion_usuario}) => {
        return {...state, verificacion_usuario}
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_eb: null,
    updateData: {},
    modal_autorizacion: false,
    modal: false,
    cargando_modal: false,
    notificacion: 0,
    verificacion_usuario: false
};
export default handleActions(reducer, initialState)

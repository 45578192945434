import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray, FormSection } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import {
    renderField,
    renderSwitch,
    renderCurrency,
    renderPercentage,
    renderFieldCheck,
    renderSearchSelect,
} from 'Utils/renderField/renderField';
import { RenderCurrency, dateFormatter, checkFormatter } from 'Utils/renderField/renderReadField';

import Fracciones from '../Fracciones';
import { api } from 'api/api';
import Modal from 'react-responsive-modal';
import { iconsh, EXTRAS } from  "icons";
import PaqueteForm from './formulariomodal/paqueteform';
import { stat } from 'fs';

let cuentas = [];

const getCuentas = (search)  =>{
    return api.get("cuenta_area",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    cuentas = [];
                    data.results.forEach(x=>{
                        cuentas.push(x);
                    })
                    return cuentas
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}
class RenderProductosPaquetes extends Component {
    state = {
        openModal: false,
        initialValues: {},
        editar: false
    }
    constructor(props){
        super(props);
    }
    openModal = () => {
        this.setState({openModal: true});
    }
    closeModal = () => {
        this.setState({openModal: false, initialValues: {}, editar: false});
    }
    agregarProducto = (data) => {
        const {  fields } = this.props;
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombre_completo,
            precio_unitario: data.producto.costo,
            precio_etiqueta: data.producto.precio,
            cantidad: 1,
            nuevo: true,
            aplicar_enfermeria: true
        })
        this.closeModal();
    }
    actualizarProducto = (data) => {
        const {   fields } = this.props;
        const prod = fields.get(data.index);
        prod.producto_id = data.producto.id,
        prod.nombre_producto= data.producto.nombre_completo,
        prod.precio_unitario= data.producto.costo,
        prod.aplicar_enfermeria= data.aplicar_enfermeria
        this.closeModal();
    }
    editarProducto = (valores) => {
        this.setState({initialValues: {
            aplicar_enfermeria: valores.aplicar_enfermeria,
            index: valores.index,
            producto: {
                id: valores.producto_id,
                nombre_completo: valores.nombre_producto
            }
        },editar: true
        });
        this.openModal();
    }
    render(){
        const { meta: { error, submitFailed } } = this.props
        const {   fields } = this.props;
        return (
            <div className="col-12 px-0">
                {
                    this.state.openModal && (
                        <Modal open={this.state.openModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Agregar producto</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <PaqueteForm
                                        editarProducto={this.actualizarProducto}
                                        editar={this.state.editar}
                                        initialValues={this.state.initialValues}
                                        addProducto={this.agregarProducto}
                                        closeModal={this.closeModal}/>
                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="row col-sm-12 p-0 m-0">
                        <div className="col-sm-12 form-group np-r  no-striped mt-2 sin-borde p-0  over-h">
                            <table className="table table-sm table-responsive-sm m-0">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
                                    <th className="text-center" style={{width: "40%", minWidth: '90px'}} >Aplicado en enfermería</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Cantidad</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-center">Precio unitario</th>
                                    <th style={{width: "15%", minWidth: '160px'}} className="text-center"  >Sub total</th>
                                    <th style={{width: "10%", minWidth: '160px'}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio_etiqueta);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top font-italic">
                                            {fields.get(index).nombre_producto}
                                        </td>
                                        <td className="sin-borde-top text-center font-italic">
                                            { checkFormatter(fields.get(index).aplicar_enfermeria)}
                                        </td>
                                        <td className="sin-borde-top text-center text-rosado">
                                            <Field
                                                name={`${producto}.cantidad`}
                                                type="number"
                                                addClass={"text-right"}
                                                component={renderField}
                                                placeholder="Representante"
                                            />
                                        </td>
                                        <td className="sin-borde-top text-right ">
                                            <RenderCurrency value={fields.get(index).precio_etiqueta || 0} />
                                        </td>
                                        <td className="sin-borde-top text-right">
                                            <RenderCurrency value={subtotal || 0} />
                                        </td>
                                        <td className="d-flex align-items-center justify-content-center sin-borde-top" >
                                             <img
                                                onClick={()=>{
                                                    this.editarProducto(prod);
                                                }}
                                                className="btn-tabla mr-2"
                                                title="Editar"
                                                src={EXTRAS.editar} alt="Edit" />
                                            <button
                                                width={28}
                                                type="button"
                                                className=" btn-danger btn-tabla "
                                                onClick={() =>{
                                                    fields.remove(index)
                                                }}
                                            >X</button>
                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-center mt-2 ">
                                <button type="button" className="btn btn-celeste" onClick={this.openModal}>
                                        Agregar Producto
                                </button>
                            </div>
                        </div>
                        </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
            </div>
        )
    }
}

 class ServicioForm extends Component {
  render() {
    return (
      <div className='mt-5'>
        <div className="col-md-12 row">
            <div className="col-md-4">
                <div className="col-md-12 col-sm-12">
                    <label htmlFor="nombre" className="m-0">Nombre*:</label>
                </div>
                <div className="col-md-12  form-group">
                    <Field name="nombre" component={renderField} type="text" className="form-control"/>
                </div>
            </div>
            <div className="col-md-4">
                <div className="col-md-12 col-sm-12">
                    <label htmlFor="precio" className="m-0">Precio*:</label>
                </div>
                <div className="col-md-12  form-group">
                    <Field
                            name={`precio`}
                            type="number"
                            addClass={"text-right"}
                            component={renderCurrency}
                            placeholder="Precio"
                        />
                </div>
            </div>
            <div className="col-md-4">
                <div className="col-md-12 col-sm-12">
                    <label htmlFor="modificable_venta" className="m-0">Precio final en caja modificable*:</label>
                </div>
                <div className="col-md-12  form-group">
                    <Field name="modificable_venta" component={renderSwitch} type="text" className="form-control" />
                </div>
            </div>
        </div>
        <div className="col-md-12 row ">
            <div className="col-md-6">
                <div className="col-md-12 col-sm-12">
                    <label htmlFor="cuenta" className="m-0">Cuenta:</label>
                </div>
                <div className="col-md-12  form-group">
                    <Field
                                    name={`cuenta`}
                                    valueKey="id"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={renderSearchSelect}
                                    loadOptions={getCuentas}
                                    />
                </div>
            </div>
        </div>
        <FieldArray name="kit"
                                deshabilitado={true}
                                component={RenderProductosPaquetes}/>
      </div>
    )
  }
}
class ProductoContainer extends Component{

    render(){
        const { fraccionable, vendible, alerta, porcentaje, cambioDescuentoUnitario } = this.props
        return(
            <div >
                <div className="d-flex justify-content-center row">
                    <div className="col-md-12 col-lg-8">
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Nombre*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="nombre" component={renderField} type="text" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="casa_medica" className="m-0">Casa médica:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name={`casa_medica`}
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="Casamedica"
                                        component={renderSearchSelect}
                                        opciones={this.props.proveedores}
                                        loadOptions={this.props.getProveedores}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="ingrediente_activo" className="m-0">Ingrediente activo*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="ingrediente_activo" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="cuenta" className="m-0">Cuenta:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name={`cuenta`}
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={renderSearchSelect}
                                        loadOptions={getCuentas}
                                        />
                                </div>
                        </div>
                        <div className="col-md-12 p-0">
                            <div className=" row col-md-10 p-0">
                                <div className="row col-md-6">
                                    <div className="col-auto text-gris">
                                        Fraccionable:
                                    </div>
                                    <div className="col-md-4">
                                        <Field name="fraccionable" component={renderSwitch} type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 ">
                            {
                                !fraccionable && (
                                    <Fragment>
                                        <div className="col-md-6 p-0">
                                            <div className="col-md-12 col-sm-12 p-0">
                                                <label htmlFor="presentacion" className="m-0">Presentación de producto*:</label>
                                            </div>
                                            <div className="col-md-12  form-group p-0">
                                                <Field name="presentacion" component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 p-0">
                                            <div className="col-md-12 col-sm-12 p-0 px-md-2">
                                                <label htmlFor="costo" className="m-0">Costo*:</label>
                                            </div>
                                            <div className="col-md-12  form-group p-0 px-md-2">
                                                <Field
                                                        name={`costo`}
                                                        type="number"
                                                        addClass={"text-right"}
                                                        component={renderCurrency}
                                                        placeholder="costo"
                                                    />
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }

                        </div>
                        {
                            !fraccionable && (
                                <div className="row">
                                    <div className="row col-md-3 m-0 p-0 pl-2">
                                        <div className="col-auto m-0 ml-3 form-group label ">
                                            <Field name="vendible" component={renderFieldCheck}
                                                type="checkbox"
                                                className="form-control"
                                                placeholder="vendible"/>
                                        </div>
                                        <div className="p-0 ml-3  ml-sm-0  col   pt-1">
                                            <span className="text-primary font-weight-bold text-uppercase">Vendible</span>
                                        </div>
                                    </div>
                                {
                                    vendible && (
                                        <Fragment>
                                            <div className="col-md-3">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="precioetiqueta" className="m-0">Precio etiqueta*:</label>
                                                </div>
                                                <div className="col-md-12  form-group">
                                                    <Field
                                                            name={`precio`}
                                                            type="number"
                                                            addClass={"text-right"}
                                                            component={renderCurrency}
                                                            placeholder="precio descuento"
                                                            _onChange={(e) => cambioDescuentoUnitario(e, 'precio')}
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="porcentaje" className="m-0">(%) de descuento :</label>
                                                </div>
                                                <div className="col-md-12  form-group">
                                                    <Field
                                                            name={`porcentaje`}
                                                            type="number"
                                                            addClass={"text-right"}
                                                            component={renderPercentage}
                                                            placeholder="precio descuento"
                                                            _onChange={(e) => cambioDescuentoUnitario(e, 'porcentaje')}
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="preciodescuento" className="m-0">Precio descuento*:</label>
                                                </div>
                                                <div className="col-md-12  form-group">
                                                    <Field
                                                            name={`precio_descuento`}
                                                            type="number"
                                                            addClass={"text-right"}
                                                            component={renderCurrency}
                                                            placeholder="Precio decuento"
                                                            _onChange={(e) => cambioDescuentoUnitario(e, 'precio_descuento')}
                                                        />
                                                </div>
                                            </div>

                                        </Fragment>
                                    )
                                }

                            </div>
                            )
                        }
                        {
                            !fraccionable && (
                                <div className="row mt-2 pt-2">
                                    <div className="row col-md-4 m-0 p-0">
                                        <div className="col-md-8 text-gris">
                                            Alerta de existencias mínimas:
                                        </div>
                                        <div className="col-md-4">
                                            <Field name="alerta" component={renderSwitch} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    {
                                        alerta && (
                                            <Fragment>
                                                <div className="col-md-3">
                                                    <div className="col-md-12 col-sm-12">
                                                        <label htmlFor="minimo" className="m-0">Mínimo en bodega*:</label>
                                                    </div>
                                                    <div className="col-md-12  form-group">
                                                        <Field name="minimo_existencias" component={renderField} type="number" className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="col-md-12 col-sm-12">
                                                        <label htmlFor="minimo_farmacia" className="m-0">Mínimo en farmacia*:</label>
                                                    </div>
                                                    <div className="col-md-12  form-group">
                                                        <Field name="minimo_existencias_farmacia" component={renderField} type="number" className="form-control"/>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                </div>
                            )
                        }




                    </div>

                    {
                        fraccionable && (
                            <div className="mt-4 col-md-12">
                                <h5 className="text-left font-weight-bold pl-3">FRACCIONES</h5>
                                <Fracciones />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}


let ProductoForm = props => {
    const { handleSubmit } = props;
    const { ver_servicio, editando, valores_formulario } = props;
    return(
        <form >
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">

                    {/* radiobuttons para servicio o producto */}
                    {
                        !editando && (
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-8 row justify-content-center">
                                    <div className="row col-md-10">
                                        <div className="row col-md-6">
                                            <div className="col-md-2 col-2">
                                                <Field name="inventariable"  disabled={false}
                                                component="input" type="radio" value={'true'}/>
                                            </div>
                                            <div className="col-8 text-gris">
                                                PRODUCTO
                                            </div>
                                        </div>
                                        <div className="row col-md-6">
                                            <div className="col-md-2 col-2">
                                                <Field name="inventariable"  disabled={false}
                                                component="input" type="radio" value={'false'}/>
                                            </div>
                                            <div className="col-8 text-gris">
                                                SERVICIO
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {/* fin radiobuttons producto o servicio */}
                    {
                        ver_servicio ? (
                            <ServicioForm {...props} />
                         ) : (
                            <ProductoContainer {...props} />
                        )
                    }

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/admin_productos">Cancelar</Link>
                                <button type="submit" onClick={e => {
                                    e.preventDefault()
                                    props.crear()
                                }} className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
ProductoForm = reduxForm({
    form: 'producto',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()("Campo requerido."),
            'casa_medica': validators.exists()("Campo requerido."),
            'precio': validators.exists()("Campo requerido."),
            'modificable_venta': validators.exists()("Campo requerido."),
            'presentacion': validators.exists()("Campo requerido."),

        })
    },
    asyncBlurFields:[],
    initialValues: {
        inventariable: 'true'
    }
})(ProductoForm);

const selector = formValueSelector('producto');
ProductoForm = connect((state, props) => {
    const inventariable = selector(state, "inventariable");
    const fraccionable = selector(state, "fraccionable");
    const vendible = selector(state, "vendible");
    const alerta = selector(state, "alerta");
    let valores_formulario;
    let ver_servicio = false;
    if(inventariable !== 'true'){
        ver_servicio = true
    }

    const precio = selector(state, "precio")
    const precio_descuento = selector(state, "precio_descuento")

    let porcentaje = 0;
    // if( precio && precio_descuento){
    //     porcentaje = Number((100 * Number(precio_descuento) )/ Number(precio));
    //     porcentaje = 100 - porcentaje;
    //     porcentaje = porcentaje.toFixed(1);
    // }
    let initialValues = {inventariable:'true'}
    if(state.form.producto){
        if(state.form.producto.values){
            valores_formulario = state.form.producto.values
        }
    }
    return {
        ver_servicio,
        fraccionable,
        initialValues,
        vendible,
        alerta,
        porcentaje,
        valores_formulario
    };
})(ProductoForm);

export const ProductoUpdateForm = reduxForm({
    form: 'producto',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()("Campo requerido."),
            'casa_medica': validators.exists()("Campo requerido."),
            'precio': validators.exists()("Campo requerido."),
            'modificable_venta': validators.exists()("Campo requerido."),
            'presentacion': validators.exists()("Campo requerido."),
        })
    },
    asyncBlurFields: []
})(ProductoForm);
export default ProductoForm


import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'configuracion';

const LOADER_CONF = 'LOADER_CONF';
const SET_DATA_CONF = 'SET_DATA_CONF';
const SET_PAGE_CONF = 'SET_PAGE_CONF';
const SET_UPDATE_DATA_CONF ='SET_UPDATE_DATA_CONF';
const SET_BUSCADOR_CONF = 'SET_BUSCADOR_CONF';
const SET_FILTRO_CONF = 'SET_FILTRO_CONF';


const detail = () => (dispatch, getState) =>{
    dispatch({type: LOADER_CONF, cargando: true});
    api.get(`${url}/getConfiguracion`).catch((error) => {
        dispatch({type: LOADER_CONF, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/admin_configuracion'))
        }
    }).then((data) => {
        dispatch({type: LOADER_CONF, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_CONF, updateData: data});
            dispatch(initializeForm('configuracion', data))
        }
    })
}

const cancelar = () => (dispatch, getStore) => {
    const store = getStore();
    const updateData = store.configuracion.updateData;
    dispatch(initializeForm('configuracion', updateData))
}
const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.configuracion.values);
    dispatch({type: LOADER_CONF, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_CONF, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_CONF, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/admin_configuracion'));
        }
    })
}

export const actions = {
    detail,
    update,
    cancelar
};
export const reducer = {
    [LOADER_CONF]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_CONF]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_CONF]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_CONF]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_CONF]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_CONF]: (state, { filtro_conf }) => {
        return {...state, filtro_conf }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_conf: null,
    updateData: {},
};
export default handleActions(reducer, initialState)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Bitacora/bitacora';
import BitacoraGrid from './BitacoraGrid';

const mstp = state => {
    return {...state.bitacora}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(BitacoraGrid)

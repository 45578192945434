import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Cuenta/cuenta';
import CuentaCrear from './CuentaCrear';

const mstp = state => {
    return {...state.cuenta}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CuentaCrear)

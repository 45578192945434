import React, {Fragment} from 'react'
import { iconsh } from 'icons';
import { Link } from 'react-router-dom';
import {MOVIMIENTO_BODEGA} from '../../../../utility/constants';
import { formatAllDate } from '../../../Utils/renderField/renderReadField';
export default function CajaCard(props) {
    let ingreso = props.ingreso;
    const { caja, usuario_logueado } = props;
  return (
    <div className="card-ingreso m-2 col-md-3 p-0">
      <div className={`header-verde`} ></div>
      <div className="col-md-12  m-0 p-3">
        <div className="col-md-12">
            <div className="text-center">
                <img className="mr-2 pb-1" src={iconsh.crud_caja_venta} height={30} />
            </div>
            <div className="text-center text-md-center">
                <span className="text-uppercase font-weight-bold text-primary">
                {caja.nombre}
                </span>
            </div>
            {
                caja.encargado_actual ? (
                    <div className="text-gris text-center text-md-center">
                        <h6 className="text-gris text-center m-0 font-weight-bold">Encargado</h6>
                        <span>{ caja.encargado_actual.nombreCompleto  }</span>
                    </div>
                ) : (
                    <div className="text-gris text-center text-md-center">
                        <h6 className="text-gris text-center m-0 font-weight-bold">Último cierre</h6>
                        <span>{ formatAllDate(props.caja.ultimo_cierre) }</span>
                    </div>
                )
            }

        </div>
        <div className="col-md-12 mt-2 d-flex align-items-center justify-content-center">
            <Fragment>
                {
                    caja.bodega_encargado && caja.bodega_encargado === usuario_logueado.id ? (
                        <Fragment>
                            {
                                caja.encargado_actual ? (
                                    <Link className={`btn btn-primary`}
                                        to={`/caja/punto_venta`} >
                                        Abrir
                                    </Link>
                                ):(
                                    <Link className={`btn btn-primary`}
                                        to={`/caja/apertura_cierre/${caja.id}`} >
                                        Abrir
                                    </Link>
                                )
                            }
                        </Fragment>

                    ):(
                        <button
                                    disabled={true}
                                    type="button"
                                    className="btn btn-primary">
                                    Abrir
                                </button>
                    )
                }
            </Fragment>

        </div>
      </div>
    </div>
  )
}


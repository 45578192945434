import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderNoAsyncSelectField, renderSelectField } from 'Utils/renderField/renderField';
import { api } from 'api/api';
import { dateFormatter } from '../../../../Utils/renderField/renderReadField';

let productos = []

class EstructuraProductosForm extends Component {
    state = {
        lotes: []
    }
    componentWillMount(){
        this.setState({cargado: true});
        console.log("row", this.props.row)
        this.props.row.cantidad_agregar=1
        api.get('inventario/get_lotes_producto/', {id: this.props.row.id, bodega: this.props.row.inventario[0] ? this.props.row.inventario[0].bodega : 0}).then((data)=>{
            this.setState({lotes: data});
            console.log("lotes", data)
            if (this.props.valores) {
                const lote = _.find(data, o => o.lote.id == this.props.valores.lote);
                if (lote) {
                    this.props.initCantidad({target: {value: lote.lote.id}}, data);
                }
            }
        })
        
    }
    getProductos = (search)  =>{
        return api.get(`inventario/getProductosExistencia/${this.props.bodega.id ? this.props.bodega.id : 0}`,{search}).catch((error) => {})
                .then((data) => {
                    productos = []
                    if (data){
                        productos = data.results
                        return data.results
                    }
                    else{
                        return []
                    }
                })
                .catch(e => {
                    return []
                })
    }
    render() {
        const { handleSubmit, valores, vencimiento, existencias, lote, error_existencias, cantidad, me } = this.props;
        const producto = new Promise((resolve, reject) => {
            setTimeout(()=>{
                resolve([this.props.row])
            },20)
        })
    return(
        <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6">
                            
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="Producto" className="m-0">Producto y presentación*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name={`producto`}
                                        valueKey="id"
                                        labelKey="nombre_completo"
                                        label="Producto"
                                        component={renderSearchSelect}
                                        _defaultValue={this.props.row}
                                        loadOptions={(search) => {return producto.then((data)=>{return data})}}
                                        disabled
                                        />
                                </div>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="lote_producto" className="m-0">Lote*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="lote" component={renderSelectField}
                                            key
                                            type="select" labelKey="lote_nombre" valueKey="lote_id"
                                            options={this.state.lotes}
                                            onChange={e => {
                                                this.props.row.lote_id=e.target.value
                                                this.props.initCantidad(e, this.state.lotes)}}
                                        />
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="col-md-12 col-sm-12 text-center">
                                <label htmlFor="vencimiento" className="m-0">Vencimiento*:</label>
                            </div>
                            <div className="col-md-12  form-group text-center">
                                <span className="text-gris font-weight-light">{ dateFormatter(vencimiento)}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12 text-right">
                                <label htmlFor="existencias" className="m-0">Existencias*:</label>
                            </div>
                            <div className="col-md-12  form-group text-right">
                                <span className="text-primary">{existencias}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cantidad" className="m-0">Cantidad a agregar*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="cantidad" component={renderField} type="number" className="form-control"
                                parse={value => Number(value)}
                                onChange={e => {
                                    this.props.row.cantidad_agregar=e.target.value
                                }}/>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" className="btn btn-secondary m-1" onClick={this.props.closeModal}>Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1" disabled={error_existencias} onClick={ e =>{
                                    this.props.agregar_productos_carrito(this.props.row)
                                    //this.props.closeModal()
                                    }
                                }>Agregar</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
    )
  }
}

let ProductoForm = props => {
    const { handleSubmit, valores, lotes, vencimiento, existencias } = props;
    return(
        <form onSubmit={handleSubmit}>
            <EstructuraProductosForm {...props} />
            <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" className="btn btn-secondary m-1" onClick={props.closeModal}>Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1" disabled={props.error_existencias}>Agregar</button>
                            </div>
                        </div>
                    </div>
        </form>
    )
};


 ProductoForm = reduxForm({
    form: 'productoform2',
    validate: (data, props) => {
        const errors = {}
        const {  vencimiento, existencias } = props;
        console.log("data",data)
        if(!data.lote){
            errors.lote = 'Campo requerido'
        }
        if(!data.cantidad){
            errors.cantidad = 'Campo requerido';
        }else{
            if(!Number.isInteger(Number(data.cantidad))){
                errors.cantidad = "Debe de ser un número entero."
            }
            if(Number(data.cantidad) < 0){
                errors.cantidad = "Debe ser un valor igual o mayor a 0"
            }
            if(Number(existencias) < Number(data.cantidad)){
                errors.cantidad = true;
                errors.cantidad = "No debe de ser mayor a las existencias";
            }
        }
        console.log("errors", errors)
        return errors;
    }
})(ProductoForm);
const selector = formValueSelector('productoform2');
ProductoForm = connect((state, props) => {
    let producto = props.row
    let lote = selector(state, 'lote');
    let existencias = selector(state, 'existencias');
    let vencimiento = selector(state, 'vencimiento');
    const formProducto = state.form.productoform2;
    let valores = null;
    let error_existencias = false;

    if(lote){
        let id_lote = Number(lote);
        lote = producto.inventario.find(x => {
            return x.lote.id === id_lote
        })
    }

    if(formProducto) {
        if(formProducto.values) {
            if(formProducto.values.cantidad) {
                (Number(existencias) < Number(formProducto.values.cantidad)) && (error_existencias = true);
                if(Number(formProducto.values.cantidad)===0){
                    error_existencias = true;
                }
            }
        }
    }

    try {
        valores = state.form.productoform2.values;
    } catch (error) {
        valores = null;
    }
    return {
        valores,
        vencimiento,
        existencias,
        lote,
        error_existencias
    };
})(ProductoForm);
export default ProductoForm

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Gastos/gastos';
import GastosGrid from './GastosGrid';

const mstp = state => {
    return {...state.gastos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(GastosGrid)

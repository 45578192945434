import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Cuenta_area/cuenta_area';
import CuentaAreaCrear from './CuentaAreaCrear.js';

const mstp = state => {
    return {...state.cuenta_area}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CuentaAreaCrear)

import React, { Component } from 'react'
import Titulo from './Titulo';
import { iconsh } from  "icons";
import { RenderCurrency, dateFormatter, formatoMoneda } from 'Utils/renderField/renderReadField';
import { BreakLine } from 'Utils/tableOptions';
import { Link } from 'react-router-dom';

import Table from 'Utils/Grid';

export default class OrdenCompra extends Component {
  render() {
      const { detailOc, url } = this.props;
      const {oc, page, cargando, dataActualizada } = this.props;
    const data = {
        count: 0,
        results: oc.productos
    }

      return (
      <div>
          <div className="col-md-12 mt-4 mb-3">
            <Titulo icono={iconsh.crudOc} titulo={'Orden de compra'} />
            <div className="col-md-12 row m-0 p-0">
                <div className="col-md-4 d-flex align-items-end p-0">
                    <span className="text-primary h5 mr-3">Total Compra:</span>
                    <RenderCurrency value={dataActualizada.monto || 0} className={'text-verde h4'}/>
                </div>
                <div className="col d-flex justify-content-end">
                    {
                        dataActualizada.entregado ? (
                            <h3>Ingreso completo</h3>
                        ) : (
                            <Link className="btn btn-primary m-1" to={`${url.includes("/solicitudes") ? '/solicitudes': '/ordenes'}/aceptado/pago/${dataActualizada.id}`}>Crear orden de pago</Link>
                        )
                    }
                </div>
            </div>

          </div>
          <div className="col-md-12">
            <Table
                    data={data}
                    loading={cargando}
                    pagination={false}
                    page={page}>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="nombre_producto" dataSort>Producto</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatoMoneda}
                        dataField="precio_costo" dataSort>Precio Unitario</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatoMoneda}
                        dataField="subtotal" dataSort>Subtotal</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        hidden={true}
                        dataAlign="center">Acciones</TableHeaderColumn>
                </Table>
          </div>

      </div>
    )
  }
}

import React, { Component } from 'react'
import HeaderReporte from '../../../Utils/Header/HeaderReporte';
import { iconsh } from "icons";
import Grafica from './Grafica';
import Filtro from './Filtros';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import LoadMask from 'Utils/LoadMask/LoadMask';
import DetalleCompra from './DetalleCompra';
import DetalleVenta from './DetalleVenta';
export default class RCompraVenta extends Component {
    state = {
        activeTab:'compras'
    }
    componentWillMount() {
        this.props.getGraficaCompra();
    }
    toggle = (tab) => {
        if (this.props.tab !== tab) {
            this.setState({
                activeTab: tab
            })
            this.props.setTab(tab);
            if (tab === 'compras') {
                // this.props.listar(1);
                this.props.reset_filtros_compra();
            } else {
                // this.props.listar_cerrada(1);
                this.props.reset_filtros_venta();
            }
        }
    }
    render() {
        const { data_grafica, cargando, monto_total } = this.props;
        return (
            <div>

                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderReporte
                        icono={iconsh.crud_reportes}
                        titulo={'Compras y Ventas'}
                        {...this.props}
                    />
                </div>
                <div className="container m-0 contenido-principal mt-3 pt-0 col-md-12">
                    <Nav tabs className="col-12  px-0 ">
                        <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center',{ active: this.props.tab === 'compras' })}
                                onClick={() => { this.toggle('compras'); }}>
                                <h5>Compras</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center',{ active: this.props.tab === 'ventas' })}
                                onClick={() => { this.toggle('ventas'); }}>
                                <h5>Ventas</h5>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <div className="fondo-inverso-navbar mb-5" style={{height:40}}></div>
                    <TabContent activeTab={this.props.tab} >
                        <TabPane  tabId={"compras"} className="col-md-12">
                            <LoadMask loading={cargando} dark blur>
                                <div className="col-md-12 row">
                                    <div className="col-md-4 d-flex align-items-center">
                                        <Filtro {...this.props}
                                            monto_total={monto_total}
                                            ver_gasto ver_proveedor ver_cuenta/>
                                    </div>
                                    <div className="col-md-8">
                                        <Grafica datum={data_grafica} />
                                    </div>
                                </div>
                                <div className="col-md-12 p-0">
                                    <DetalleCompra {...this.props} placeholder={"Buscar por: Proveedor ó Categoría"} />
                                </div>
                            </LoadMask>
                        </TabPane>
                    <TabPane  tabId={"ventas"}>
                            <LoadMask loading={cargando} dark blur>
                                <div className="col-md-12 row">
                                    <div className="col-md-4 d-flex align-items-center">
                                        <Filtro {...this.props}
                                            monto_total={monto_total}
                                            ver_proveedor
                                            ver_cuenta
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <Grafica datum={this.props.data_grafica_venta} />
                                    </div>
                                </div>
                                <div className="col-md-12 p-0">
                                    <DetalleVenta {...this.props} placeholder={"Buscar por: Cuenta"} />
                                </div>
                            </LoadMask>
                        </TabPane>
                    </TabContent>

                </div>
            </div>
        )
    }
}

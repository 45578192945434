import React, { Component, Fragment } from 'react'
import TituloBodega from '../../../Utils/Header/TituloBodega';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { formatAllDate, dateFormatter } from '../../../Utils/renderField/renderReadField';
import classNames from 'classnames';


function formatFraccion(cell){
    if(cell){
        return cell.nombre_completo;
    }
    return ''
}
function formatLote(cell){
    if(cell){
        return cell.lote;
    }
    return ''
}
function formatVencimiento(cell){
    if(cell){
        return dateFormatter(cell.fecha_vencimiento);
    }
    return '';
}

export default class VerMovimiento extends Component {
    componentWillMount(){
        this.props.detail_movimiento(this.props.match.params.id)
    }
  render() {
      const {cargando, page, update_movimiento} = this.props;
        let data = {
            results: update_movimiento.detalle_movimiento,
            count: 0
        }
      return (
      <div className="container m-0 contenido-principal row d-flex justify-content-center">

        <div className="row col-md-12">
            <TituloBodega estilo="col-md-3" titulo={update_movimiento.bodega ? update_movimiento.bodega.nombre:''} subtitulo={`${update_movimiento.tipo}`} />
            {
                update_movimiento.destino ? (
                    <div className="col-md-3 d-flex align-items-end">
                        <label htmlFor="emitido" className="m-0 mr-2">Envio hacia:</label>
                        <span className="text-primary">{update_movimiento.destino ? update_movimiento.destino.nombre:''}</span>
                    </div>
                ):(
                    <Fragment>
                        {
                            update_movimiento.proveedor  ? (
                                <div className="col-md-3 d-flex align-items-end">
                                    <label htmlFor="emitido" className="m-0 mr-2">Envio hacia proveedor:</label>
                                    <span className="text-primary">{update_movimiento.proveedor ? update_movimiento.proveedor.nombre:''}</span>
                                </div>
                            ):(
                                <Fragment>
                                    {
                                        update_movimiento.viene_de && (
                                            <div className="col-md-3 d-flex align-items-end">
                                                <label htmlFor="emitido" className="m-0 mr-2">Envio por:</label>
                                                <span className="text-primary">{update_movimiento.viene_de ? update_movimiento.viene_de.nombre:''}</span>
                                            </div>
                                        )
                                    }
                                </Fragment>


                            )
                        }
                        <Fragment>
                            {
                                update_movimiento.origen && (
                                    <div className="col-md-3 d-flex align-items-end">
                                        <label htmlFor="emitido" className="m-0 mr-2">Envio por:</label>
                                        <span className="text-primary">{update_movimiento.origen ? update_movimiento.origen:''}</span>
                                    </div>
                                )
                            }
                        </Fragment>
                    </Fragment>
                )
            }

            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                <span className="text-primary">{update_movimiento.usuario ? update_movimiento.usuario.nombreCompleto:''}</span>
            </div>
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                <span className="text-primary">{update_movimiento.fecha ? formatAllDate(update_movimiento.fecha) : '' }</span>
            </div>
            {
                update_movimiento.nota && (
                    <div className="col-md-6 mt-3">
                        <label htmlFor="emitido" className="m-0 mr-2">Nota: </label>
                        <p className="text-gris font-weight-light mb-0">{update_movimiento.nota}</p>
                    </div>
                )
            }
            <div className="col-md-6 mt-3">
                <label className="m-0 mr-2">Orden: </label>
                <p className="text-gris font-weight-light mb-0">{update_movimiento.no_movimiento}</p>
            </div>

        </div>

        <div className="col-md-12 mt-5">
            <Table
                            data={data}
                            loading={cargando}
                            pagination={false}
                            page={1}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatFraccion}
                                dataField="fraccion" dataSort>Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatLote}
                                dataField="lote" dataSort>lote</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatVencimiento}
                                dataField="lote" dataSort>Vencimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                                <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="nombre_s" dataSort></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"></TableHeaderColumn>
                        </Table>
        </div>
        {
            update_movimiento.anulado && (
                <div className="col-md-12 mt-5">
                    <div className="col-md-10">
                        <h5 className="text-left text-uppercase">Observaciones de despacho</h5>
                        <textarea
                            disabled={true}
                            value={update_movimiento.nota_anulacion}
                            style={{ resize: "none" }} rows={3}
                            className={classNames('form-control')} />
                    </div>
                </div>
            )
        }


      </div>
    )
  }
}

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSearchSelectOC } from 'Utils/renderField/renderField';
import { api } from 'api/api';

let productos = []
const getProductos = (search)  =>{
    return api.get("producto/getFracciones",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    productos = data.results
                    return data.results
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}


class Productos extends Component {

    getProductos = (search) => {
        const {cuenta_id} = this.props;
        return api.get("producto/getFraccionesPadres",{search, cuenta__id:cuenta_id}).catch((error) => {})
            .then((data) => {
                if (data){
                    productos = data.results
                    return data.results
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
    }
  render() {

    return (
      <Fragment>
        <div className="row">
            <div className="col-md-12">
                <div className="col-md-12 col-sm-12">
                    <label htmlFor="Producto" className="m-0">Producto y presentación*:</label>
                </div>
                <div className="col-md-12  form-group">
                    <Field
                        name={`producto`}
                        valueKey="id"
                        labelKey="nombre_completo"
                        label="Producto"
                        component={renderSearchSelectOC}
                        loadOptions={this.getProductos}
                        />
                </div>
            </div>
        </div>
      </Fragment>
    )
  }
}


let ProductoForm = props => {
    const { handleSubmit, valores, cuenta_id } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <Productos cuenta_id={cuenta_id} />
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" className="btn btn-secondary m-1" onClick={props.closeModal}>Cancelar</button>
                                <button type="button" id="btn-guardar-add-product" className="btn btn-primary m-1" onClick={ e =>{
                                    props.addProducto(valores)
                                    }
                                }>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
ProductoForm = reduxForm({
    form: 'producto',
    validate: data => {
        return validate(data, {
        })
    }
})(ProductoForm);
const selector = formValueSelector('producto');
ProductoForm = connect(state => {
    let valores = null;
    try {
        valores = state.form.producto.values;
    } catch (error) {
        valores = null;
    }
    return {
        valores
    };
})(ProductoForm);
export default ProductoForm

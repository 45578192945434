import React, { Component } from 'react'
import Titulo from './Titulo';
import { iconsh } from  "icons";
import { RenderCurrency, dateFormatter, formatoMoneda } from 'Utils/renderField/renderReadField';
import { BreakLine } from 'Utils/tableOptions';
import Modal from 'react-responsive-modal';
import Table from 'Utils/Grid';
import PagosForm from './Formularios/formPagos';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import moment from 'moment';

import PagadoForm from './Formularios/formPagado'


function entregadaFormater(cell) {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times"/>
}
function formatoPagado(cell, row){
    if(row.monto_vale){
        let total = row.monto - row.monto_vale
        return formatoMoneda(total)
    }
    return formatoMoneda(cell)
}
export default class Pagos extends Component {
    state = {
        row: {}
    }
  pagosTotal = () => {
      const { pagos } = this.props;
      let movimientos = pagos;
      let total = 0;
      movimientos.forEach(x => {
          if (x.pago_completo){
            total += x.monto
          }
      });
      return total
  }
  getTotal = () => {
    const { pagos } = this.props;
    let movimientos = pagos;
    let total = 0;
    movimientos.forEach(x => {
          total += x.monto
    });
    return total
  }
  getPagado = () => {
      const { pagos } = this.props;
      let movimientos = pagos;
      let pagado = true;
      movimientos.forEach(x => {
        if (!x.pago_completo){
          pagado = false;
          return
        }
    });
    return pagado
  }
  cancelar = () => {
    const { dataActualizada } = this.props;
    let data = {
        pago_completo: dataActualizada.pago_completo
    }
    this.props.setFormularioPagado(data);
  }
  botonModal = (id, row) => {
    let fecha = new Date()
    fecha = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
    if (!row.pago_completo){
        return (


            <button
                        className="btn btn-success-outline"
                         onClick={(e)=>{
                             e.preventDefault();
                             let detalle = {
                                id: id,
                                monto: row.monto,
                                tipo_pago:40,
                                fecha: fecha
                             }
                             this.setState({row:detalle})
                             this.props.openModal();
                         }}
                          alt="Ver fracciones" >Registrar pago </button>)
    }
    return (<span></span>)

    }
  render() {
    const { cargando, dataActualizada, pagos } = this.props;
    let data = {
        count: 0,
        results: pagos
    }
    let totalPagos = 0;
    let total = 0;
    let pagado = false
    totalPagos = this.pagosTotal()
    total = this.getTotal()
    pagado = dataActualizada.pago_completo;
    return (
      <div>
           {
                this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                        <div  style={{ Width: '100%' }}>
                            <div className="modal-header">
                                <div className="panel-body col-md-12">
                                    <div className="borde-abajo pb-2">
                                        <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                        <span className="ml-2 text-uppercase text-titulo"><strong>Registrar pago</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                {this.state.row.monto == 0?
                                    <div>
                                        <div className="center my-4 ">
                                            <h5 className="text-dark">
                                                El monto de este pago es de Q 0.00, quiere registrar este pago como completado?
                                            </h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <button
                                                        type="button"
                                                        onClick={this.props.closeModal}
                                                        className="btn btn-secondary m-1"
                                                    >
                                                        Cancelar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary m-1"
                                                        onClick={()=>this.props.maracarPagoComoPagado(this.state.row)}
                                                    >
                                                        Guardar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <PagosForm
                                        data={dataActualizada}
                                        initialValues={this.state.row}
                                        pago={this.state.row}
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.agregarPago}
                                        />
                                }
                            </div>
                        </div>
                    </Modal>
                )
            }
        <div className="col-md-12 mt-4 mb-3">
            <Titulo icono={iconsh.crudOc} titulo={'Ordenes de pago'} />

          </div>
          <div className="col-md-12">
            <Table
                    data={data}
                    loading={cargando}
                    pagination={false}>
                    <TableHeaderColumn
                        tdStyle={{width: '155px', padding: '5px'}}
                        thStyle={{width: '155px', padding: '5px'}}
                        dataField="id"
                        isKey={true}
                        dataAlign="center"
                        dataFormat={activeFormatter({ adicional:this.botonModal })}>Acciones</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={dateFormatter}
                        dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="tipo_movimiento" dataSort>Tipo de pago</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="documento_pago" dataSort>No. Cheque</TableHeaderColumn>
                <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="factura" dataSort>No. Factura</TableHeaderColumn>
                     <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="banco" dataSort>Banco</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatoPagado}
                        dataField="total" dataSort>Monto pagado</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatoMoneda}
                        dataField="monto_vale" dataSort>Monto crédito</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatoMoneda}
                        dataField="monto" dataSort>Total</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={entregadaFormater}
                        dataAlign="center"
                        dataField="pago_completo" dataSort>Pagado</TableHeaderColumn>


                </Table>
          </div>

          <div className="col-md-12 row d-flex justify-content-md-between justify-content-center mt-3">
            <div className="col-md-4">
                <PagadoForm initialValues={{pago_completo: pagado}} />
            </div>
            <div className="col-md-4">
                <div className="col-md-12 row">
                    <div className="col text-right">
                        <span className="text-primary h5 mr-3">Total:</span>
                    </div>
                    <div className="col text-right">
                        <RenderCurrency value={total || 0} className={'text-verde h4'}/>
                    </div>
                </div>
                <div className="col-md-12 row">
                    <div className="col text-right">
                        <span className="text-primary h5 mr-3">Pagado:</span>
                    </div>
                    <div className="col text-right">
                        <RenderCurrency value={totalPagos || 0} className={'text-primary h4'}/>
                    </div>
                </div>
            </div>
          </div>
          <div className="col-md-12 mt-5">
              {/* <FormPagado cancelar={this.cancelar} onSubmit={this.props.pagoCompleto}/> */}
          </div>
      </div>
    )
  }
}



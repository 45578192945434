import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
//IMPORATCIONES EXTRA
import moment from 'moment';
import { dateFormatter } from '../../../common/components/Utils/renderField/renderReadField'


const url = 'bitacora';

const LOADER_BITACORA = 'LOADER_BITACORA';
const SET_DATA_BITACORA = 'SET_DATA_BITACORA';
const SET_PAGE_BITACORA = 'SET_PAGE_BITACORA';
const SET_UPDATE_DATA_BITACORA ='SET_UPDATE_DATA_BITACORA';
const SET_BUSCADOR_BITACORA = 'SET_BUSCADOR_BITACORA';
const SET_FILTRO_BITACORA = 'SET_FILTRO_BITACORA';

const SET_STARTDATE_BITACORA = 'SET_STARTDATE_BITACORA';
const SET_ENDDATE_BITACORA = 'SET_ENDDATE_BITACORA';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_BITACORA, cargando: true});
    const store = getStore();
    const search = store.bitacora.buscador;
    const filtro = store.bitacora.filtro_bitacora;
    let dateStart = store.bitacora.dateStart;
    let dateEnd = store.bitacora.dateEnd

    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    if(dateStart){
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if(dateEnd) {
        try{
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch(error){
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_BITACORA, data});
            dispatch({type: SET_PAGE_BITACORA, page});
        }
        dispatch({type: LOADER_BITACORA, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_BITACORA, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/admin_bitacora'))
        }
    }).then((data) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_BITACORA, updateData: data});
            dispatch(initializeForm('bodega', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.bodega.values;
    dispatch({type: LOADER_BITACORA, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_BITACORA, cargando: false})
        Swal('Éxito', 'Se ha creado la bodega.', 'success')
        .then(() => {
            dispatch(push('/admin_bitacora'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_BITACORA, cargando: false})
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_BITACORA, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_BITACORA, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/admin_bitacora'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_BITACORA, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/admin_bitacora'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_BITACORA, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_BITACORA, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_BITACORA, filtro_bitacora: filtro});
    dispatch(listar(1));
};

// acciones no comunes
const setDateStart = (date) => (dispatch, getStore) => {
    dispatch({type: SET_STARTDATE_BITACORA, dateStart: date});
    dispatch(listar(1));
}
const setDateEnd = (date) => (dispatch, getStore) => {
    dispatch({type: SET_ENDDATE_BITACORA, dateEnd: date});
    dispatch(listar(1));
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    setDateStart,
    setDateEnd
};
export const reducer = {
    [LOADER_BITACORA]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_BITACORA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_BITACORA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_BITACORA]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_BITACORA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_BITACORA]: (state, { filtro_bitacora }) => {
        return {...state, filtro_bitacora }
    },
    [SET_STARTDATE_BITACORA]: (state, { dateStart }) => {
        return {...state, dateStart }
    },
    [SET_ENDDATE_BITACORA]: (state, { dateEnd }) => {
        return {...state, dateEnd }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_bitacora: null,
    updateData: {
        usuario:{
            first_name: ''
        }
    },
};
export default handleActions(reducer, initialState)

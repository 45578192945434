import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class HeaderSimple extends Component {
  render() {
    return (
      <div className="col-md-12  m-0 mb-3">
         <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
            {
                (typeof this.props.ruta) === "string" ? (
                    <Link className="btn btn-primary " to={this.props.ruta}>
                        {this.props.texto}
                    </Link>
                )   : (
                    <button type='button'
                        onClick={e => {
                            e.preventDefault();
                            this.props.ruta();
                        }}
                        className="btn btn-primary">
                        {this.props.texto}
                    </button>
                )
            }

        </div>
        <div className="col-md-6 " >
        {
          this.props.titulo  && (
            <h4 className="text-md-left text-uppercase text-center">
              <img className="mr-2 pb-1" src={this.props.icono} height={25} />
              {this.props.titulo}
            </h4>
          )
        }
        </div>
       
      </div>
    )
  }
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../redux/modules/Punto_venta/punto_venta';
import Venta from './Venta';
import { initialize as initializeForm } from 'redux-form'
import {reset, destroy} from 'redux-form';

const mstp = state => {
    return {...state.puntoventa}
};

const mdtp = {
    ...actions,
    resetFormulario: () =>  dispatch => dispatch(initializeForm('venta', {productos: []})),

};

export default connect(mstp, mdtp)(Venta)

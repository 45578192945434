import React, {Component} from 'react'
import Card from 'Utils/Cards/cardGrande';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {iconsh} from "icons";
import {formatAllDate, formatoMoneda, RenderCurrency} from '../../../Utils/renderField/renderReadField';
import Table from 'Utils/Grid';
import {BreakLine, cellTachado} from 'Utils/tableOptions';

function formatMedicamento(cell, row) {
    if (cell) {
        return <span>{cell.nombre_completo}</span>
    }
    return <span></span>
}
function styleTD(cell, row) {
    if(!row.activo){
        return cellTachado
    }
    return BreakLine
}

export default class DetallePaciente extends Component {
    state = {
        activeTab: 'medicamentos'
    };

    componentDidMount() {
        this.props.detail(this.props.match.params.id);
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            if (tab === 'activas') {
                // this.props.listar(1);
            } else {
                // this.props.listar_cerrada(1);
            }
        }
    };

    render() {
        const {cargando, updateData, listar, page, detalle} = this.props;
        let data = {
            results: detalle,
            count: 0
        };

        //add ricky
        let subtotal = 0;
        detalle.forEach(_detalle => {
            if (_detalle.activo) {
                subtotal += parseFloat(parseFloat(_detalle.subtotal).toFixed(2));
            }
        });

        return (
            <div className="container m-0 contenido-principal row ">
                <div className="col-md-12">
                    <Card
                        icono={iconsh.crud_paciente}
                        titulo={"Paciente"}>
                        <LoadMask loading={cargando} blur_1>
                            <div className="col-md-12 d-flex justify-content-center mb-4">
                                <div className="col-md-12 row m-0">
                                    <div className="col-md-3">
                                        <span className="text-gris">Paciente: </span>
                                        <span
                                            className="text-primary text-uppercase">{updateData.paciente ? updateData.paciente.nombre : ''}</span>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="text-gris">Ingreso: </span>
                                        <span
                                            className="text-primary text-uppercase">{updateData.fecha_ingreso ? formatAllDate(updateData.fecha_ingreso) : ''}</span>
                                    </div>
                                    {
                                        (updateData.pagado) &&
                                        <div className="col-md-3">
                                            <span className="text-gris">Egreso: </span>
                                            <span
                                                className="text-primary text-uppercase">{updateData.fecha_pagado ? formatAllDate(updateData.fecha_pagado) : ''}</span>
                                        </div>
                                    }

                                    <div className="col-md-3">
                                        <span className="text-gris">Total: </span>
                                        <span className="text-primary text-uppercase"><RenderCurrency value={subtotal}/></span>
                                    </div>

                                </div>
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                pagination
                                loading={cargando}
                                page={page}>
                                <TableHeaderColumn
                                    tdStyle={styleTD}
                                    thStyle={BreakLine}
                                    dataFormat={formatMedicamento}
                                    dataField="stock" dataSort>Producto</TableHeaderColumn>

                                <TableHeaderColumn
                                  tdStyle={styleTD}
                                  thStyle={BreakLine}
                                  dataField="usuario_que_agrego" dataSort>Agregado por</TableHeaderColumn>

                                <TableHeaderColumn
                                  tdStyle={styleTD}
                                  thStyle={BreakLine}
                                  dataField="bodega" dataSort>Origen</TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={styleTD}
                                    thStyle={BreakLine}
                                    dataAlign={'right'}
                                    dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={styleTD}
                                    thStyle={BreakLine}
                                    dataFormat={formatoMoneda}
                                    dataAlign={'right'}
                                    dataField="subtotal" dataSort>Subtotal</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={styleTD}
                                    thStyle={BreakLine}
                                    isKey={true}
                                    hidden={true}
                                    dataField="stock" dataSort>Fecha Ingreso</TableHeaderColumn>
                            </Table>
                        </LoadMask>
                    </Card>
                </div>
            </div>
        )
    }
}

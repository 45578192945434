export const ROLES = {ADMINISTRADOR: 100, COLABORADOR:80}
export const TIPOSPAGO = {
    // TARJETA: 20,
    // TRANSACCION: 30,
    CHEQUE: 40,
    EFECTIVO: 50
}
export const PROCESO = {
    ACEPTADO: 100,
    RECHAZADO:80,
    ENVIADO: 60,
    BORRADOR: 10
}
export const MOVIMIENTO_BODEGA = {
    INGRESO: 10,
    DESPACHO: 20,
    REAJUSTE: 30,
    BAJAS: 40,
    DEVOLUCION: 50
}
export const nivelesOptions = [
    {value: 100, label: "Administrador"},
    {value: 80, label: "Colaborador"}
]

export const Monedas = [
    {value:'USD', label:'USD', simbolo: '$'},
    {value:'GTQ', label:'GTQ', simbolo: 'Q'}
]

export const opcionesBanco = [
    // { value: 20, label: 'Tarjeta', documento: 'No comprobante' },
    // { value: 30, label: 'Transacción', documento:'No transacción' },
    { value: 40, label: 'Cheque', documento: 'No. cheque'},
    { value: 50, label: 'Efectivo', documento: ''},
]

export const ACCIONES_BITACORA = [
	//ACCIONES DE ACTA
    {descripcion: "Usuario editado", label: "Editar usuario"},
];

export const BANCOS = [
    {value: 'Banco industrial', label:'Banco industrial'},
    {value: 'InterBanco', label:'InterBanco'},
    {value: 'BAC', label:'BAC'}
]

export const MESES = [
    { value: "01", label: 'Enero'},
    { value: "02", label: 'Febrero' },
    { value: "03", label: 'Marzo' },
    { value: "04", label: 'Abril'},
    { value: "05", label: 'Mayo'},
    { value: "06", label: 'Junio'},
    { value: "07", label: 'Julio'},
    { value: "08", label: 'Agosto'},
    { value: "09", label: 'Septiembre'},
    { value: "10", label: 'Octubre'},
    { value: "11", label: 'Noviembre'},
    { value: "12", label: 'Diciembre'},
];

import React, { Component } from 'react'
import { RenderCurrency } from 'Utils/renderField/renderReadField'

export default class TarjetaVentas extends Component {
  render() {
    const { cuenta } = this.props;
    return (
        <div key={cuenta.id} className="card-ingreso m-2 col-md-3 p-0">
            <div className={`header-verde`} ></div>
            <div className="col-md-12  m-0 p-3 text-center">
                <h3 className="text-primary">{cuenta.nombre}</h3>
                <h6 className="text-gris mb-0 font-weight-bold">GANANCIA</h6>
                <RenderCurrency value={cuenta.ganancia || 0}  className={'text-verde h4 font-weight-bold'}/>
                <div className="col-md-12 row  m-0 p-0 d-flex justify-content-center mt-3">
                    <div className="col-md-8 row m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-4 p-0 text-md-right text-center">
                            <h6 className="text-gris mb-0 font-weight-bold">VENTA</h6>
                        </div>
                        <div className="col-md-6 p-0 text-md-right text-center">
                            <RenderCurrency value={cuenta.ventas || 0}  className={'text-azul h6 font-weight-bold'}/>
                        </div>
                    </div>
                    <div className="col-md-8 row m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-4 p-0 text-md-right text-center">
                            <h6 className="text-gris mb-0 font-weight-bold">GASTADO</h6>
                        </div>
                        <div className="col-md-6 p-0 text-md-right text-center">
                            <RenderCurrency value={cuenta.gastos || 0}  className={'text-gris h6 font-weight-bold'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

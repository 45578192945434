import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect,renderSearchCreateSelect, renderTextArea, renderCurrency } from 'Utils/renderField/renderField';
import renderDatePicker  from 'Utils/renderField/renderDatePicker';
import { api } from 'api/api';
import { RenderCurrency, dateFormatter } from 'Utils/renderField/renderReadField';
import Modal from 'react-responsive-modal';
import { iconsh } from  "icons";
import ProductoForm  from './formulariosmodal/productos';
import { PROCESO } from '../../../../utility/constants';

let proveedores = []
const getProveedores = (search)  =>{
    return api.get("proveedores",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    proveedores = [];
                    data.results.forEach(x=>{
                        proveedores.push({value: x.id, label: x.nombre})
                    })
                    return proveedores
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

let cuentas = [];
const getCuentas = (search)  =>{
    return api.get("cuenta_area",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    cuentas = [];
                    data.results.forEach(x=>{
                        cuentas.push(x)
                    })
                    return cuentas
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

const renderAnulaciones = ({fields, meta: {error, submitFailed }, deshabilitado}) => (
    <div className="row col-sm-12 p-0 m-0 mt-5 ">
            {
                fields.length > 0 && (
                    <div className="col-md-12">
                        <h5 className="font-weight-bold text-uppercase">Observaciones de orden de compra</h5>
                    </div>
                )
            }

                <div className="col-sm-12 form-group np-r tabla-gris no-striped mt-2 sin-borde p-0 over-h">

                        {fields.map((anulacion, index) => (
                            <div key={index} className="col-md-12 row my-3">
                                 <div className="col-md-5 ver-movil p-0">
                                    <div className="col-md-12 p-0">
                                        <div className="col-md-12 col-sm-12 p-0">
                                            <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                            <span className="text-primary">{dateFormatter(fields.get(index).creado)}</span>
                                        </div>
                                        <div className="col-md-12 col-sm-12 p-0">
                                            <label htmlFor="emitido" className="m-0 mr-2">Rechazada por:</label>
                                            <span className="text-primary">{fields.get(index).usuario.first_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 p-0">
                                    <Field
                                        name={`${anulacion}.justificacion`}
                                        disabled={deshabilitado}
                                        component={renderTextArea} type="text" className="form-control"/>
                                </div>
                                <div className="col-md-5 ver-escritorio">
                                    <div className="col-md-12 p-0">
                                        <div className="col-md-12 col-sm-12 p-0">
                                            <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                            <span className="text-primary">{dateFormatter(fields.get(index).creado)}</span>
                                        </div>
                                        <div className="col-md-12 col-sm-12 p-0">
                                            <label htmlFor="emitido" className="m-0 mr-2">Rechazada por:</label>
                                            <span className="text-primary">{fields.get(index).usuario.first_name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
            </div>
    </div>
)

class RenderProductos extends Component{
    state = {
        openModal: false
    }
    constructor(props){
        super(props)
    }
    openModal = () => {
        this.setState({openModal: true});
    }
    closeModal = () => {
        this.setState({openModal: false});
    }
    agregarProducto = (data) => {
        const {  fields } = this.props;
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombre_completo,
            precio_unitario: data.producto.costo,
            cantidad: 1,
            nuevo: true
        })
        this.closeModal();

    }
    render() {
        const { meta: { error, submitFailed } } = this.props
        const {  ciclos, fields, deshabilitado, cuenta_id } = this.props;

        return (
            <div className="col-12 px-0">
                {
                    this.state.openModal && (
                        <Modal open={this.state.openModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Agregar producto</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <ProductoForm
                                        cuenta_id={cuenta_id}
                                        closeModal={this.closeModal}
                                        addProducto={this.agregarProducto}
                                        />
                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="row col-sm-12 p-0 m-0">
                        <div className="col-sm-12 form-group np-r  no-striped mt-2 sin-borde p-0  over-h">
                            <table className="table table-sm table-responsive-sm m-0">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Cantidad</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-center">Precio unitario</th>
                                    <th style={{width: "15%", minWidth: '160px'}} className="text-center"  >Sub total</th>
                                    <th style={{width: "10%", minWidth: '160px'}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio_unitario);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top font-italic">
                                            {fields.get(index).nombre_producto}
                                        </td>
                                        <td className="sin-borde-top text-center text-rosado">
                                            <Field
                                                disabled={deshabilitado}
                                                name={`${producto}.cantidad`}
                                                type="number"
                                                addClass={"text-right"}
                                                component={renderField}
                                                placeholder="Representante"
                                            />
                                        </td>
                                        <td className="sin-borde-top text-center text-rosado">
                                            <Field
                                                disabled={deshabilitado}
                                                name={`${producto}.precio_unitario`}
                                                type="number"
                                                addClass={"text-right"}
                                                component={renderCurrency}
                                                placeholder="Representante"
                                            />
                                        </td>
                                        <td className="sin-borde-top text-right">
                                            <RenderCurrency value={subtotal || 0} />
                                        </td>
                                        <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                            {
                                                !deshabilitado && (
                                                    <button
                                                        type="button"
                                                        className=" btn-danger btn-tabla "
                                                        onClick={() =>{
                                                            fields.remove(index)
                                                        }}
                                                    >X</button>
                                                )
                                            }

                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            {
                                !deshabilitado && (
                                    <div className="d-flex justify-content-center mt-2 ">
                                        <button type="button" className="btn btn-celeste" onClick={this.openModal}>
                                                Agregar Producto
                                        </button>
                                    </div>
                                )
                            }

                        </div>
                        </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}

            </div>
      )
    }

}

/// formulario general para la orden de compra
let OrdenesForm = props => {
    const { handleSubmit, valores, total, guardarBorrador, me, cuenta_id } = props;
    let deshabilitado = false;
    if(props.updateData && (props.updateData.no_proceso === PROCESO.ENVIADO || props.updateData.no_proceso === PROCESO.ACEPTADO)){
        deshabilitado = true;
    }
    return(
        <form onSubmit={handleSubmit}>
        <div className="form-group grid-container">
            {
                props.updateData && props.updateData.no_proceso === PROCESO.RECHAZADO && (
                    <h6 className="text-danger font-weight-bold text-uppercase ver-movil text-center">Orden de compra Rechazada</h6>
                )
            }

            <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                <div className="row">
                    {
                        props.updateData && (
                            <div className="col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="emitido" className="m-0 mr-2">Orden No:</label>
                                    <span className="text-primary">{props.updateData.no_orden}</span>
                                </div>
                            </div>
                        )
                    }

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                            {
                                me && (
                                    <span className="text-primary">{me? me : ''}</span>
                                )
                            }
                            {
                                props.updateData && (
                                    <span className="text-primary">{props.updateData.usuario ? props.updateData.usuario.first_name : ''}</span>
                                )
                            }
                        </div>
                    </div>
                    {
                        props.updateData && props.updateData.no_proceso === PROCESO.RECHAZADO &&(
                            <div className="col-md-4">
                                <h6 className="text-danger font-weight-bold text-uppercase ver-escritorio">Orden de compra Rechazada</h6>
                            </div>
                        )
                    }
                </div>
                <div className="row">
                    <div className="col-md-8 p-0 px-md-2 row">
                        <div className="col-md-8 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="proveedor" className="m-0">Proveedor*:</label>
                            </div>
                            <Field
                                name={`uuid`}
                                component={renderField}
                                type={'hidden'}
                            />
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`proveedor`}
                                    valueKey="id"
                                    labelKey="nombre"
                                    label="proveedor"
                                    isDisabled={deshabilitado}
                                    component={renderSearchCreateSelect}
                                    loadOptions={getProveedores}
                                    />
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha" className="m-0">Fecha*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha"
                                    component={renderDatePicker}
                                    placeholder="fecha"
                                    type="fecha"
                                    className=""
                                    disabled={deshabilitado}
                                    numberOfMonths={1}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 px-md-2">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 p-0 col-sm-12">
                                <label htmlFor="cuenta" className="m-0">Cuentas*:</label>
                            </div>
                            <div className="col-md-12 p-0 form-group">
                                <Field
                                    name={`cuenta`}
                                    valueKey="id"
                                    labelKey="nombre"
                                    label="nombre"
                                    disabled={deshabilitado}
                                    component={renderSearchSelect}
                                    loadOptions={getCuentas}
                                    />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-8 p-0 px-md-2 row">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="descripcion" className="m-0">Descripción*:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="descripcion"
                                disabled={deshabilitado}
                                component={renderTextArea} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-md-4  d-flex align-items-end justify-content-end p-0 p-0">
                        <span className="text-primary h5 mr-3">Total Compra:</span>
                        <RenderCurrency value={total || 0} className={'text-verde h4'}/>

                    </div>
                </div>
                <FieldArray name="productos"
                    deshabilitado={deshabilitado}
                    cuenta_id={cuenta_id}
                    component={RenderProductos} />

                <FieldArray name="anulaciones"
                                deshabilitado={true}
                                component={renderAnulaciones}/>




                <div className="row">
                    <div className="col-12">
                        {
                            deshabilitado ? (
                                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link className="btn btn-primary m-1" to="/ordenes">Regresar</Link>
                                </div>
                            ):(
                                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link className="btn btn-secondary m-1" to="/ordenes">Cancelar</Link>
                                    <button type="button" onClick={ e=>{
                                        e.preventDefault();
                                        guardarBorrador()
                                    } } className="btn btn-success m-1">Borrador</button>
                                    <button type="submit" className="btn btn-primary m-1">Enviar</button>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    </form>
    )
};


OrdenesForm = reduxForm({
    form: 'orden',
    validate: values => {
        const errors = {};
        if(!values.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(!values.fecha){
            errors.fecha = 'Campo requerido';
        }
        if(!values.cuenta){
            errors.cuenta = 'Campo requerido';
        }
        if(!values.productos || !values.productos.length){
            errors.productos = {_error: 'Debe de agregar al menos un producto'}
        }
        else{
            //Validación de cada línea de detalle
            const productosArrayDetalle = []
            values.productos.forEach( function(linea, index) {
                let  DetalleErrors = {}
                if(!linea.precio_unitario){
                    DetalleErrors.precio_unitario = "Campo Requerido";
                } else {
                    if(parseFloat(linea.precio_unitario) == 0){
                        DetalleErrors.precio_unitario = "El precio debe ser mayor a 0";
                    }
                }
                if(!linea.cantidad){
                    DetalleErrors.cantidad = "Campo Requerido";
                } else {
                    if(parseFloat(linea.cantidad) == 0) {
                        DetalleErrors.cantidad = "La cantidad debe ser mayor a 0";
                    }
                }

                 if(DetalleErrors){
                    productosArrayDetalle[index] = DetalleErrors
                 }
            });
            if(productosArrayDetalle.length){
                errors.productos = productosArrayDetalle;
            }
        }
        return errors;
    },
    asyncBlurFields: [],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(OrdenesForm);

const selector = formValueSelector('orden');
OrdenesForm = connect(state => {
    let todos = [];
    const productos = selector(state, "productos");
    const cuenta = selector(state, "cuenta");
    let cuenta_id = null;
    if(cuenta){
        cuenta_id = cuenta.id;
    }
    if(productos){
        todos=productos;
    }
    let total = 0;
    todos.forEach(x => {
        if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
            total += Number(x.cantidad) * Number(x.precio_unitario);
        }
    });
    return {
        total,
        cuenta_id
    };
})(OrdenesForm);

export const OrdenUpdateForm = reduxForm({
    form: 'orden',
    validate: values => {
        const errors = {};
        if(!values.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(!values.fecha){
            errors.fecha = 'Campo requerido';
        }
        if(!values.cuenta){
            errors.cuenta = 'Campo requerido';
        }
        if(!values.productos || !values.productos.length){
            errors.productos = {_error: 'Debe de agregar al menos un producto'}
        }
        else{
            //Validación de cada línea de detalle
            const productosArrayDetalle = []
            values.productos.forEach( function(linea, index) {
                let  DetalleErrors = {}
                if(!linea.precio_unitario){
                    DetalleErrors.precio_unitario = "Campo Requerido";
                } else {
                    if(parseFloat(linea.precio_unitario) == 0){
                        DetalleErrors.precio_unitario = "El precio debe ser mayor a 0";
                    }
                }
                if(!linea.cantidad){
                    DetalleErrors.cantidad = "Campo Requerido";
                } else {
                    if(parseFloat(linea.cantidad) == 0) {
                        DetalleErrors.cantidad = "La cantidad debe ser mayor a 0";
                    }
                }

                 if(DetalleErrors){
                    productosArrayDetalle[index] = DetalleErrors
                 }
            });
            if(productosArrayDetalle.length){
                errors.productos = productosArrayDetalle;
            }
        }
        return errors;
    },
    asyncBlurFields: []
})(OrdenesForm);

export default OrdenesForm;

import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReporte from '../../../Utils/Header/HeaderReporte';
import { iconsh, EXTRAS } from "icons";
import Grafica from './Grafica';
import Filtro from '../RCompraVenta/Filtros';
import Table from 'Utils/Grid';
import { BreakLine } from '../../../Utils/tableOptions';
import { formatoMoneda, formatSelect, dateFormatter, formatNombre } from '../../../Utils/renderField/renderReadField';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import ToolbarSearch from 'Utils/Toolbar/ToolbarSearch';

function formatNoorden(cell, row){
    if(cell){
        return <div>
            <p className="m-0">
                <b>No de pago: </b><span className="font-weight-light">{row.no_orden}</span>
            </p>
            <p className="m-0">
                <b>No de compra: </b><span className="font-weight-light">{row.compra}</span>
            </p>
        </div>
    }
    return <span>-- --</span>
}
export default class Rporpagar extends Component {
    componentWillMount(){
       this.props.getGraficaProveedores();
       this.props.listar(this.props.page)
    }
  render() {
      const { data_grafica, total_deuda, cargando, page, data } = this.props;

    return (
      <div>
          <div className="container m-0 contenido-principal row d-flex justify-content-center">
                <HeaderReporte
                    icono={iconsh.crud_reportes}
                    titulo={'Cuentas por pagar'}
                    es_detalle
                />
            </div>
            <div className="container m-0 contenido-principal mt-3 pt-3 col-md-12">
                <div className="fondo-inverso-navbar mb-5" style={{height:40}}></div>
                <div className="col-md-12 row m-0 mt-3">
                    <div className="col-md-4 d-flex align-items-center">
                        {/* <Filtro {...this.props} /> */}
                        <Filtro {...this.props}
                                ver_rango
                                            monto_total={total_deuda || 0}
                                            ver_proveedor />
                    </div>
                    <div className="col-md-8">
                        <Grafica datum={data_grafica} />
                    </div>
                </div>
                <div className="col-md-12 ">
                    <ToolbarSearch 
                        titulo={" "} 
                        buscar={this.props.search} 
                        buscador={this.props.buscador}
                        placeholder={"Buscar por: Proveedor, Cuenta"}
                    />
                    <div className="mb-3"></div>
                    <Table
                            onPageChange={this.props.listar}
                            data={data}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={dateFormatter}
                                dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNoorden}
                                dataField="no_orden" dataSort>Orden</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre_proveedor" dataSort>Proveedor</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre_cuenta" dataSort>Cuenta</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="monto" dataSort>Monto</TableHeaderColumn>

                        </Table>
                </div>
            </div>
      </div>
    )
  }
}

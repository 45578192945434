import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';
import { formatoMoneda } from '../renderField/renderReadField';

export default class ToolbarFiltroFecha extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
  render() {
    return (
      <div className="col-12 row d-flex justify-content-end">

            <div className="col row ">
                <div className="d-flex align-items-center"><h5 className="text-primary text-uppercase mr-3">Total créditos </h5></div>
                <div className="d-flex align-items-center"><h4 className="text-verde">{formatoMoneda(this.props.total)}</h4></div>
            </div>
            <div className="col-md-3 form-group">
                <label className="m-0">Proveedor:</label>
                <Select
                            value={this.props.valor_filtro}
                            onChange={this.props.cambiar_filtro}
                            placeholder="todos"
                            className={'form-control p-0'}
                            classNamePrefix="react-select"
                            options={this.props.valores_filtro}
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['nombre'])}
                            isClearable
                            theme={(theme) => {
                                return {
                              ...theme,
                              borderRadius: '1em',
                                    background: 'white',
                              colors: {
                              ...theme.colors,
                                text: '#415362',
                                primary25: '#ebebeb',
                                primary: '#a8ba3a',
                              },
                            }
                            }}
                        />
            </div>
            <div className="col-lg-4 col-md-4 p-0 search pt-15">
              {(this.props.tituloBuscador &&
                <p className="ml-2 text-primary mt-5 mt-md-0 mt-lg-0"><strong>{this.props.tituloBuscador}</strong></p>
              )}
                {(this.props.buscar !== undefined) && (
                <Search buscar={this.props.buscar} buscador={this.props.buscador} placeholder={this.props.placeholder}/>
                )}
            </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import Titulo from '../Aceptado/Titulo';
import { iconsh } from 'icons';
import { formatSelect, dateFormatter } from 'Utils/renderField/renderReadField';
import Modal from 'react-responsive-modal';
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import FormIngreso from '../Aceptado/Formularios/FormIngreso';
import FormIngresoCompleto from './formularios/FormIngresocompleto';

const isExpandableRow = () => {
    return true;
}
function formatLotes(cell) {
    return <div>
        {
            cell.map((x, index) => {
                return (
                    <span>{x.lote} &ensp; &ensp;</span>)
            })
        }
    </div>
}
export default class Ingreso extends Component {
    state = {
        modal: false,
        idDetalle: 0,
        detalle: null,
        expandidos: []
    }
    expandComponent = (row) => {
        let data = row && row.lotes ? row.lotes : [];
        let movimientos = _.cloneDeep(data);
        // const movimientos = []
        // const { me } = this.props
        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    stripped={false}
                    headerStyle={{ backgroundColor: '#aacfda' }}
                    data={movimientos}>
                    <TableHeaderColumn
                        dataField="cantidadActual"
                        dataAlign="center"
                        width={'90px'}>A Ingresar</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vencimiento"
                        dataFormat={dateFormatter}
                        dataAlign="center"
                    >Vencimiento</TableHeaderColumn>
                    <TableHeaderColumn
                        isKey={true}
                        dataAlign="center"
                        dataField="lote">Lote</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
    botonModal = (id, row) => {
        // verificar si la cantidad que se esta resiviendo (0) es string o numeric
        let incompleto = row.cantidadActual == '0';
        if(row.cantidadActual == 0){
            incompleto = true
        }
        return (<button
            className="btn btn-success-outline"
            onClick={(e) => {
                e.preventDefault();
                this.setState({ idDetalle: id, detalle: row })
                this.props.openModal();
            }}
            alt="Ver fracciones"
            disabled={incompleto}
        >
            Ingresar producto
        </button>)
    }
    openModal = () => {
        this.setState({ modal: true });
    }
    closeModal = () => {
        this.setState({ modal: false });
    }
    handleExpand = (rowKey, isExpand) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        if (isExpand) {
            expandidos.push(rowKey)
        } else {
            let index = expandidos.findIndex(x => x === rowKey)
            expandidos.pop(index)
        }
        this.setState({expandidos: expandidos})
    }
    rowClassNameFormat = (row, rowIdx) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        let registro = expandidos.find(x => x === row.id)
        return registro === undefined ? '' : 'background-row-selected';
    }
    render() {
        const { ingresoData, cargando } = this.props;
        let data = {
            count: 0,
            results: ingresoData.productos
        }
        const options = {
            onExpand: this.handleExpand
        };
        return (
            <div className="con-hijos">
                {
                    this.props.modal && (
                        <Modal open={this.props.modal} onClose={this.props.closeModal} >
                            <div style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Registrar Ingreso</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormIngreso
                                        initialValues={this.state.detalle}
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.addLote}
                                        row={this.state.detalle}
                                        updateData={ingresoData} />
                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="col-md-12 row mt-3 mb-3">
                    <div className="col-md-4">
                        <Titulo icono={iconsh.crudOc} titulo={'Ingreso de orden de pago'} />
                    </div>
                    <div className="col-md-4 row">
                        <div className="col-auto d-flex justify-content-md-end justify-content-center align-items-center">
                            <label htmlFor="op" className="m-0">Fecha de op:</label>
                        </div>
                        <div className="col  form-group m-0 d-flex align-items-center justify-content-center justify-content-md-start">
                            <span className="text-primary text-uppercase ">{dateFormatter(ingresoData.fecha)}</span>
                        </div>
                    </div>
                    <div className="col-md-4 row">
                        <div className="col-auto d-flex justify-content-md-end justify-content-center align-items-center">
                            <label htmlFor="op" className="m-0">Proveedor:</label>
                        </div>
                        <div className="col  form-group m-0 d-flex align-items-center justify-content-center justify-content-md-start">
                            <span className="text-primary text-uppercase ">{formatSelect(ingresoData.proveedor)}</span>
                        </div>
                    </div>
                </div>

                <Table
                    data={data}
                    loading={cargando}
                    expandableRow={isExpandableRow}
                    expandComponent={this.expandComponent}
                    onExpand={this.handleExpand}
                    trClassName= {this.rowClassNameFormat}
                    page={1}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        width={180}
                        dataAlign="right"
                        dataFormat={activeFormatter({ adicional: this.botonModal })}>Acciones</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        columnClassName={ 'text-uppercase' }
                        dataField="nombre_producto" dataSort>Producto</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="cantidadActual" dataSort>A ingresar</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatLotes}
                        dataField="lotes" dataSort>Lotes</TableHeaderColumn>

                </Table>

                <div className="col-md-12">
                    <FormIngresoCompleto atras={this.props.setTab} onSubmit={this.props.saveIngreso} />
                </div>
            </div>
        )
    }
}


import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';

import moment from 'moment';
import { dateFormatter } from '../../../common/components/Utils/renderField/renderReadField'

const url = 'reporte';

const LOADER_RINVENTARIO = 'LOADER_RINVENTARIO';
const SET_DATA_RINVENTARIO = 'SET_DATA_RINVENTARIO';
const SET_GRAFICAMOVIMIENTOS_RINVENTARIO = 'SET_GRAFICAMOVIMIENTOS_RINVENTARIO';
const SET_GRAFICAALERTA_RINVENTARIO = 'SET_GRAFICAALERTA_RINVENTARIO';

const SET_DATAMOVIMIENTOS_RINVENTARIO = 'SET_DATAMOVIMIENTOS_RINVENTARIO';

const SET_PAGE_RINVENTARIO = 'SET_PAGE_RINVENTARIO';
const SET_UPDATE_DATA_RINVENTARIO ='SET_UPDATE_DATA_RINVENTARIO';
const SET_BUSCADOR_RINVENTARIO = 'SET_BUSCADOR_RINVENTARIO';
const SET_FILTRO_RINVENTARIO = 'SET_FILTRO_RINVENTARIO';

const SET_FAREA_RINV = 'SET_FAREA_RINV';
const SET_FMOVI_RINV = 'SET_FMOVI_RINV';

const SET_TAB_RINV = 'SET_TAB_RINV';

const SET_STARTDATE_RINVENTARIO = 'SET_STARTDATE_RINVENTARIO';
const SET_ENDDATE_RINVENTARIO = 'SET_ENDDATE_RINVENTARIO';

function obtenerFechas(dateStart, dateEnd){
    let params = {};
    if (dateStart) {
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    return params;
}

const listar_movimientos = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RINVENTARIO, cargando: true });
    const store = getStore();
    const search = store.rinventario.buscador;
    const filtro_area = store.rinventario.filtro_area;
    const filtro_movimiento = store.rinventario.filtro_movimiento;

    let dateStart = store.rinventario.dateStart;
    let dateEnd = store.rinventario.dateEnd

    let params = { page, search };

    if(filtro_area){
        params['filtro_area'] = filtro_area.id;
    }
    if(filtro_movimiento){
        params['filtro_movimiento'] = filtro_movimiento.value;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/detalle_reajustes_bajas`, params).catch((error) => {
        dispatch({ type: LOADER_RINVENTARIO, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATAMOVIMIENTOS_RINVENTARIO, data_movimiento: data });
            dispatch({type: SET_PAGE_RINVENTARIO,page: page});
        }
        dispatch({ type: LOADER_RINVENTARIO, cargando: false });
    })
};


const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_RINVENTARIO, buscador: search});
    dispatch(listar_movimientos());
};
const setFiltroArea = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FAREA_RINV, filtro_area: filtro});
    dispatch(listar_movimientos());
};
const setFiltroMovimientos = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FMOVI_RINV, filtro_movimiento: filtro});
    dispatch(listar_movimientos());
}

const setDateStart = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_STARTDATE_RINVENTARIO, dateStart: date });
    dispatch(listar_movimientos());
}
const setDateEnd = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_ENDDATE_RINVENTARIO, dateEnd: date });
    dispatch(listar_movimientos());
}
const setTab = (tab) => (dispatch, getStore) => {
    dispatch({
        type: SET_TAB_RINV,
        toggleTab: tab
    })
}
export const actions = {
    listar_movimientos,
    search,
    setFiltroMovimientos,
    setFiltroArea,
    setDateEnd,
    setDateStart,
    setTab
};
export const reducer = {
    [LOADER_RINVENTARIO]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_RINVENTARIO]: (state, { data }) => {
        return {...state, data }
    },
    [SET_DATAMOVIMIENTOS_RINVENTARIO]: (state, { data_movimiento }) => {
        return {...state, data_movimiento }
    },
    [SET_PAGE_RINVENTARIO]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_RINVENTARIO]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_RINVENTARIO]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_DATAMOVIMIENTOS_RINVENTARIO]: (state, { data_movimiento }) => {
        return {...state, data_movimiento }
    },
    [SET_FAREA_RINV]: (state, { filtro_area }) => {
        return {...state, filtro_area }
    },
    [SET_FMOVI_RINV]: (state, { filtro_movimiento }) => {
        return {...state, filtro_movimiento }
    },
    [SET_STARTDATE_RINVENTARIO]: (state, { dateStart }) => {
        return {...state, dateStart }
    },
    [SET_ENDDATE_RINVENTARIO]: (state, { dateEnd }) => {
        return {...state, dateEnd }
    },
    [SET_TAB_RINV]: (state, { toggleTab }) => {
        return {...state, toggleTab }
    },
    [SET_GRAFICAMOVIMIENTOS_RINVENTARIO]: (state, { grafica_movimientos }) => {
        return {...state, grafica_movimientos }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_movimiento: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_: null,
    filtro_area: null,
    filtro_movimiento: null,
    updateData: {},
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
    toggleTab: 'alertas',
    grafica_movimientos: []
};
export default handleActions(reducer, initialState)

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Layout } from './layout';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';

class Login extends PureComponent {
    static propTypes = {
        nameError: PropTypes.bool.isRequired,
        passError: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        hasNameError: PropTypes.func.isRequired,
        hasPassError: PropTypes.func.isRequired,
    };
    state = {
        expanded:false
    }
    componentDidMount(props) {
        this.state = { prueba: true };
        setTimeout(() => {
            this.setState({expanded: !this.state.expanded});
        }, 900);
    }
    toogleExpanded = () => {
        setTimeout(() => {
            this.setState({expanded: !this.state.expanded});
        }, 300);

    }
    render() {
        const { onSubmit, submitError } = this.props;
        const { mensajeLogin, loader } = this.props;
        if (sessionStorage.getItem('token')) {
            return (<Redirect to="/page" />);
        }
        return (
            <Layout toogleExpanded={this.toogleExpanded} expanded={this.state.expanded}>
                 <div>


                {
                    loader ? (
                        <div className="d-flex justify-content-center text-white">
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <LoginForm
                            onSubmit={onSubmit}
                            submitError={submitError}
                            mensajeLogin={mensajeLogin}/>
                    )

                }


                </div>
            </Layout>

        );
    }
}

export default Login;

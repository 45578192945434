import React, { Component } from 'react'
import { iconsh } from 'icons';
import Table from 'Utils/Grid';
import Titulo from '../Aceptado/Titulo';
import Modal from 'react-responsive-modal';
import { BreakLine } from 'Utils/tableOptions';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { formatSelect, dateFormatter, formatoMoneda, RenderCurrency } from 'Utils/renderField/renderReadField';
import FormPrecio from './formularios/FormPrecio';
import NumberFormat from 'react-number-format';

const   isExpandableRow=()=> {
    return true;
}
function formatPorcentaje(cell, row){
    let porcentaje = 0
    try {
        porcentaje = Number((100 * Number(row.precio_descuento) )/ Number(row.precio));
        porcentaje = 100 - porcentaje;
        porcentaje = porcentaje.toFixed(1);
    } catch (error) {
        porcentaje = 0
    }
    if(isNaN(porcentaje)){
        porcentaje = 0
    }
    return <span>{porcentaje ? porcentaje : 0} %</span>
}
function formatPrecioDescuento(cell, row){
    if(cell){
        return <span>-- --</span>
    }else{
        return(
            <NumberFormat
                          decimalScale={2} fixedDecimalScale={true}
                          value={row.precio_descuento} thousandSeparator={true} prefix={'Q'} displayType={"text"}
            />
        )
    }
}
function formatPrecio(cell, row){
    if(cell){
        return <span>-- --</span>
    }else{
        return (
            <NumberFormat
                          decimalScale={2} fixedDecimalScale={true}
                          value={row.precio} thousandSeparator={true} prefix={'Q'} displayType={"text"}
            />
        )
    }
}
function formatPorcentajeRow(cell, row){
    if(cell){
        return <span>-- --</span>
    }else{
        let porcentaje = 0
        try {
            porcentaje = Number((100 * Number(row.precio_descuento) )/ Number(row.precio));
            porcentaje = 100 - porcentaje;
            porcentaje = porcentaje.toFixed(1);
        } catch (error) {
            porcentaje = 0
        }
        if(isNaN(porcentaje)){
            porcentaje = 0
        }
        return <span>{porcentaje ? porcentaje : 0} %</span>
    }
}
function formatUnico(cell){
    if(cell){
        return <span>Fracción</span>
    }else{
        return <span>Único</span>
    }
}
export default class Precios extends Component {
    state = {
        producto: {},
        expanding: [ 0 ],
        expandidos: []

    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.tab !== prevProps.tab){
        }

    }

    expandComponent = (row) => {
        let data = row && row.fracciones ? row.fracciones : [];
        let fracciones = _.cloneDeep(data);
        // const movimientos = []
        // const { me } = this.props

        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    headerStyle={{ backgroundColor: '#aacfda' }}
                    data={fracciones}>
                    <TableHeaderColumn
                        dataField="nombre"
                        isKey={true}
                        dataAlign="center"
                        width={'90px'}>Fracción</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio"
                        dataAlign="right"
                        dataFormat={formatoMoneda}
                    >Precio Etiqueta</TableHeaderColumn>
                    <TableHeaderColumn

                        dataAlign="right"
                        dataFormat={formatoMoneda}
                        dataField="precio_descuento">Precio descuento</TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataFormat={formatPorcentaje}
                        dataField="precio">Porcentaje descuento</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
    botonModal = (id, row) => {
        return (<button
                    className="btn btn-success-outline"
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({producto: row})
                         this.props.setFormularioPrecios(row);
                         this.props.openModalPagos();
                     }}
                      alt="Ver fracciones" >Registrar precio </button>)
    }
    openModal = () => {
        this.setState({modal: true});
    }
    closeModal = () => {
        this.setState({modal: false});
    }
    handleExpand = (rowKey, isExpand) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        let index = expandidos.findIndex(x => x === rowKey)
        if(index >0){
            expandidos.pop(index)
        }else{
            expandidos.push(rowKey)
        }
        this.setState({expandidos: expandidos})
    }
    rowClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        let registro = expandidos.find(x => x === row.id)
        return registro === undefined ? '' : 'background-row-selected';
    }
    render() {
        const { precios, ingresoData, cargando } = this.props;
        let data ={
            count:0,
            results: precios
        }
        let index = []
        precios.forEach( x =>{
            if(x.fraccionable){
                index.push(x.id)
            }
        })
        const options = {
            expanding: index ,
            noDataText: 'No hay datos'
        };
        return (
            <div className="con-hijos">
                <Modal open={this.props.modalPagos} onClose={this.props.closeModalPagos} >
                    <div  style={{ Width: '100%' }}>
                        <div className="modal-header">
                            <div className="panel-body col-md-12">
                                <div className="borde-abajo pb-2">
                                    <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                    <span className="ml-2 text-uppercase text-titulo"><strong>Registrar precios</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <FormPrecio
                                cambioDescuento={this.props.cambioDescuento}
                                cerrarModal={this.props.closeModalPagos}
                                onSubmit={this.props.cambiarPrecios}
                                producto={this.state.producto} />
                        </div>
                    </div>
                </Modal>
                <div className="col-md-12 row mt-3 mb-3">
                    <div className="col-md-4">
                        <Titulo icono={iconsh.crudOc} titulo={'Ingreso de orden de pago'} />
                    </div>
                    <div className="col-md-4 row">
                        <div className="col-auto d-flex justify-content-md-end justify-content-center align-items-center">
                            <label htmlFor="op" className="m-0">Fecha de op:</label>
                        </div>
                        <div className="col  form-group m-0 d-flex align-items-center justify-content-center justify-content-md-start">
                            <span className="text-primary text-uppercase ">{ dateFormatter(ingresoData.fecha) }</span>
                        </div>
                    </div>
                    <div className="col-md-4 row">
                        <div className="col-auto d-flex justify-content-md-end justify-content-center align-items-center">
                            <label htmlFor="op" className="m-0">Proveedor:</label>
                        </div>
                        <div className="col  form-group m-0 d-flex align-items-center justify-content-center justify-content-md-start">
                            <span className="text-primary text-uppercase ">{formatSelect(ingresoData.proveedor)}</span>
                        </div>
                    </div>
                </div>
                <BootstrapTable
                    expandableRow={isExpandableRow}
                    expandComponent={this.expandComponent}
                    options={ options }
                    trClassName= {this.rowClassNameFormat}
                    data={data.results}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        width={180}
                        dataAlign="right"
                        columnClassName={ (fieldValue, row, rowIdx, colIdx) => {
                            let expandidos = _.cloneDeep(index);
                            let registro = expandidos.find(x => x === row.id)
                            return registro === undefined ? '' : 'background-row-selected';
                        } }
                        dataFormat={activeFormatter({ adicional:this.botonModal })}>Acciones</TableHeaderColumn>
                     <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        columnClassName={ (fieldValue, row, rowIdx, colIdx) => {
                            let expandidos = _.cloneDeep(index);
                            let registro = expandidos.find(x => x === row.id)
                            return registro === undefined ? 'text-uppercase' : 'background-row-selected text-uppercase';
                        } }
                        dataField="nombre" dataSort>Producto</TableHeaderColumn>
                     <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatUnico}
                        columnClassName={ (fieldValue, row, rowIdx, colIdx) => {
                            let expandidos = _.cloneDeep(index);
                            let registro = expandidos.find(x => x === row.id)
                            return registro === undefined ? '' : 'background-row-selected';
                        } }
                        dataField="fraccionable" dataSort>Fracción</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatPrecio}
                        dataAlign={'right'}
                        columnClassName={ (fieldValue, row, rowIdx, colIdx) => {
                            let expandidos = _.cloneDeep(index);
                            let registro = expandidos.find(x => x === row.id)
                            return registro === undefined ? '' : 'background-row-selected';
                        } }
                        dataField="fraccionable" dataSort>Precio etiqueta</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatPrecioDescuento}
                        dataAlign={'right'}
                        columnClassName={ (fieldValue, row, rowIdx, colIdx) => {
                            let expandidos = _.cloneDeep(index);
                            let registro = expandidos.find(x => x === row.id)
                            return registro === undefined ? '' : 'background-row-selected';
                        } }
                        dataField="fraccionable" dataSort>Precio Descuento</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatPorcentajeRow}
                        dataAlign={'center'}
                        columnClassName={ (fieldValue, row, rowIdx, colIdx) => {
                            let expandidos = _.cloneDeep(index);
                            let registro = expandidos.find(x => x === row.id)
                            return registro === undefined ? '' : 'background-row-selected';
                        } }
                        dataField="fraccionable" dataSort>Porcentaje descuento</TableHeaderColumn>


                </BootstrapTable>


                <div className="col-md-12">
                </div>
                <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={e => {
                                        e.preventDefault()
                                        this.props.cancelar();
                                    }}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="button" onClick={e => {
                                    e.preventDefault();
                                    this.props.actualizarPrecios()
                                }} className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Producto/producto';
import ProductoCreate from './ProductoCreate';
import { change } from 'redux-form'

const mstp = state => {
    return {...state.producto}
};

const mdtp = {
    ...actions,
    setPorcentajeProducto: (value) => dispatch => dispatch(change('producto', 'porcentaje', value)),
    setPrecio_descuentoProducto: (value) => dispatch => dispatch(change('producto', 'precio_descuento', value)),

};

export default connect(mstp, mdtp)(ProductoCreate)

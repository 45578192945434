import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reportes/rganancia';
import RGanancia from './RGanancia';

const mstp = state => {
    return {...state.rganancia}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(RGanancia)

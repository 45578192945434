import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Compras/aceptado';
import AceptadoContainer from './AceptadoContainer';
import { initialize as initializeForm } from 'redux-form'

const mstp = state => {
    const url = window.location.href;
    return { url, ...state.aceptado}
};

const mdtp = {
    ...actions,
    setFormularioIngreso:(data) => dispatch => dispatch(initializeForm('ordenpago', data)),
    setFormularioPagado:(data) => dispatch => dispatch(initializeForm('pagado', data)),
};

export default connect(mstp, mdtp)(AceptadoContainer)

import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSearchSelect } from "Utils/renderField/renderField";
import { RenderDateTime, formatAllDate } from '../../../Utils/renderField/renderReadField';

import { api } from "api/api";

let productos = [];
const getProductos = search => {
    return api
        .get("inventario/getInventarioDisponiblePV", { search })
        .catch(error => {})
        .then(data => {
            if (data) {
                productos = data.results;
                return data.results;
            } else {
                return [];
            }
        })
        .catch(e => {
            return [];
        });
};



let MedicamentosForm = props => {
    const { handleSubmit, existencia, es_producto, error_existencias, setExistencia } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0  pb-sm-1">
                    {/* <FieldArray name="productos" component={renderProductos} /> */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="medicamento" className="m-0">Medicamento/Servicio*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                            name={`producto`}
                                            valueKey="id"
                                            labelKey="nombre_completo"
                                            label="Producto"
                                            component={renderSearchSelect}
                                            onChange = { async e => {
                                                if(e){
                                                    let lotes = await api.get('inventario/get_lotes_producto/', {id: e.id});
                                                    let existencias = 0;
                                                    lotes.forEach(lote => existencias += lote.existencias );
                                                    setExistencia(existencias);
                                                }
                                            }}
                                            loadOptions={getProductos}
                                        />
                            </div>
                        </div>
                        {
                            es_producto ? (
                                <Fragment>
                                    <div className="col-md-3">
                                        <div className="col-md-12 col-sm-12 text-center">
                                            <label htmlFor="ex" className="m-0">Existencias*:</label>
                                        </div>
                                        <div className="col-md-12  form-group text-center">
                                            <span className="text-primary">{existencia}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="cantidad" className="m-0">Cantidad*:</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                        name={'cantidad'}
                                                        type="number"
                                                        addClass={"text-right"}
                                                        component={renderField}
                                                        placeholder="Representante"
                                                    />
                                            {error_existencias && (<span className="custom-error">No debe de ser mayor a las existencias</span>)}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className="col-md-3">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="cantidad" className="m-0">Cantidad*:</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                        disabled={true}
                                                        name={`cantidad`}
                                                        type="number"
                                                        addClass={"text-right"}
                                                        component={renderField}
                                                        placeholder="Representante"
                                                    />
                                        </div>
                                    </div>
                            )
                        }


                     </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                <button type='button'
                                    onClick={e=> {
                                        e.preventDefault();
                                        props.cerrarModal();
                                    }}
                                    className="btn btn-secondary m-1">
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                    disabled={error_existencias}
                                >
                                    Aplicar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
MedicamentosForm = reduxForm({
    form: "medicamentos",
    validate: data => {
        return validate(data, {});
    },
    initialValues:{
        cantidad: 1
    }
})(MedicamentosForm);

const selector = formValueSelector('medicamentos');
MedicamentosForm = connect(state => {
    const producto = selector(state, "producto");
    const cantidad = selector(state, "cantidad");
    const existencia = selector(state, "existencia");
    let es_producto = true;
    let error_existencias = false;
    if(producto){
        (Number(existencia) < Number(cantidad)) && (error_existencias = true);
        if(!producto.es_producto){
            es_producto = false;
            error_existencias = false;
        }
    }

    return {
        existencia,
        es_producto,
        error_existencias
    };
})(MedicamentosForm);
export default MedicamentosForm;

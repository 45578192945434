import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'gasto';

const LOADER_GASTOS = 'LOADER_GASTOS';
const SET_DATA_GASTOS = 'SET_DATA_GASTOS';
const SET_PAGE_GASTOS = 'SET_PAGE_GASTOS';
const SET_UPDATE_DATA_GASTOS ='SET_UPDATE_DATA_GASTOS';
const SET_BUSCADOR_GASTOS = 'SET_BUSCADOR_GASTOS';
const SET_FILTRO_GASTOS = 'SET_FILTRO_GASTOS';
const SET_MODAL_GASTOS = 'SET_MODAL_GASTOS';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_GASTOS, cargando: true});
    const store = getStore();
    const search = store.gastos.buscador;
    const filtro = store.gastos.filtro_gastos;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_GASTOS, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_GASTOS, data});
            dispatch({type: SET_PAGE_GASTOS, page});
        }
        dispatch({type: LOADER_GASTOS, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_GASTOS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_GASTOS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/gastos'))
        }
    }).then((data) => {
        dispatch({type: LOADER_GASTOS, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_GASTOS, updateData: data});
            dispatch(initializeForm('bodega', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.gasto.values);
    formData.categoria_gasto = formData.categoria_gasto.id;
    formData.cuenta = formData.cuenta.id
    if(formData.proveedor){
        formData.proveedor = formData.proveedor.id;
    }
    if(formData.cuenta_ingreso){
        formData.cuenta_ingreso = formData.cuenta_ingreso.id;
    }
    dispatch({type: LOADER_GASTOS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_GASTOS, cargando: false})
        Swal('Éxito', 'Se ha creado el gasto.', 'success')
        .then(() => {
            dispatch(push('/gastos'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_GASTOS, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_GASTOS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.gasto.values);
    formData.categoria_gasto = formData.categoria_gasto.id;
    formData.cuenta = formData.cuenta.id
    if(formData.proveedor){
        formData.proveedor = formData.proveedor.id;
    }
    if(formData.cuenta_ingreso){
        formData.cuenta_ingreso = formData.cuenta_ingreso.id;
    }
    dispatch({type: LOADER_GASTOS, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_GASTOS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/gastos'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_GASTOS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/gastos'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.anulacion.values);
    const store = getStore();
    const updateData = store.gastos.updateData;

    dispatch({type: LOADER_GASTOS, cargando: true});
    api.put(`${url}/anular/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_GASTOS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Gasto anulado correctamente.',
                'success'
            ).then(() => {
                dispatch(closeModal());
                dispatch(listar(1));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_GASTOS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(closeModal());
            dispatch(listar(1));
        }
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_GASTOS, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_GASTOS, filtro_gastos: filtro});
    dispatch(listar(1));
};
const openModal = () => (dispatch, getStore) => {
    dispatch({
        type: SET_MODAL_GASTOS,
        toggleModal: true
    });
}
const closeModal = () => (dispatch, getStore) => {
    dispatch({
        type: SET_MODAL_GASTOS,
        toggleModal: false
    })
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    openModal,
    closeModal
};
export const reducer = {
    [LOADER_GASTOS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_GASTOS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_GASTOS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_GASTOS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_GASTOS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_GASTOS]: (state, { filtro_gastos }) => {
        return {...state, filtro_gastos }
    },
    [SET_MODAL_GASTOS]: (state, { toggleModal }) => {
        return {...state, toggleModal }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_gastos: null,
    updateData: {},
    toggleModal: false
};
export default handleActions(reducer, initialState)

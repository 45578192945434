import React, { Component } from 'react'
import HeaderIngreso from 'Utils/Header/HeaderIngreso';
import FormIngreso from './FormIngreso';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class IngresoCrear extends Component {
    componentWillMount(){
        // this.props.listar(1);
        this.props.detail(this.props.match.params.id)
    }
  render() {
      const { cargando, updateData } = this.props;
    return (
      <div className="container m-0 contenido-principal row d-flex justify-content-center mt-3">
        <HeaderIngreso
                    titulo={`Bodega principal`}
                    subtitulo={'Ingreso'}/>
        <div className="col-md-12">
            <LoadMask loading={cargando} blur_1>
                <FormIngreso onSubmit={this.props.ingresoBeneficio} />
            </LoadMask>
        </div>
      </div>
    )
  }
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../../redux/modules/Venta/venta';
import Detalle from './DetalleVenta';

const mstp = state => {
    return {...state.venta}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Detalle)

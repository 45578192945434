import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Clientes/clientes';
import Crear from './ClienteCrear';

const mstp = state => {
    return {...state.clientes}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Crear)

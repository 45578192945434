import React, {Fragment} from 'react';
import { AsyncCreatable, Async } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from "react-switch";
import Select, { components } from 'react-select';
import _ from "lodash";
import TimePicker from 'react-time-picker';


export const renderField = ({ input, label, disabled, addClass, type, min=0, meta: { touched, error } }) => {
    const invalid = touched && error;

    return (
        <div>
            <input {...input} placeholder={label} type={type}
                autoComplete={'false'}
                disabled={disabled?'disabled':''}
                min={min}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)} />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};


export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        // <Fragment>
        //     <input {...input} placeholder={label} type={type}
        //         disabled={disabled?'disabled':''}
        //         className={classNames('', { 'is-invalid': invalid })} />

        // </Fragment>
        <div className="checkbox c-checkbox">
            <label className="needsclick">
                <input {...input} placeholder={label} type={type}
                disabled={disabled?'disabled':''}
                autoComplete={'false'}
                className={classNames('', { 'is-invalid': invalid })}/>
                <span className="fa fa-check" />
            </label>
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};


export const renderTextArea = ({ input, label, disabled, rows, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                autoComplete={'false'}
                disabled={disabled?'disabled':''}
                {...input} placeholder={label} style={{ resize: "none" }} rows={rows ? rows : 3}
                className={classNames('form-control', { 'is-invalid': invalid })} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const renderSearchSelect = ({ input, clearable, _defaultValue, disabled, loadOptions, valueKey, labelKey, defaultOptions, opciones =[], meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <AsyncSelect clearable={clearable} isDisabled={disabled}
                    value={_defaultValue ? _defaultValue:input.value} className={classNames('form-control p-0 ', { 'is-invalid': invalid })}
                    classNamePrefix="react-select"
                   onChange={(e) => {
                        input.onChange(e ? e : null);
                    }}

                    theme={(theme) => {
                        return {
                      ...theme,
                      borderRadius: '1em',
                            background: 'white',
                      colors: {
                      ...theme.colors,
                        text: '#415362',
                        primary25: '#ebebeb',
                        primary: '#a8ba3a',
                      },
                    }
                    }}

                    onSelect={(e)=>{
                    }}

                    classNamePrefix="react-select"
                    cache={false} placeholder="Escriba para buscar"
                    defaultOptions={ true }
                    searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}
                    loadOptions={loadOptions} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSearchSelectOC = ({ input, clearable, disabled, loadOptions, valueKey, labelKey, defaultOptions, opciones =[], meta: { touched, error } }) => {
    const invalid = touched && error;

    return (
        <div>
            <AsyncSelect clearable={clearable} isDisabled={disabled}
                    value={ input.value} className={classNames('form-control p-0 ', { 'is-invalid': invalid })}
                   classNamePrefix="react-select"
                   onChange={(e) => {
                        input.onChange(e ? e : null);
                        document.getElementById("btn-guardar-add-product").focus();
                    }}

                    theme={(theme) => {
                        return {
                      ...theme,
                      borderRadius: '1em',
                            background: 'white',
                      colors: {
                      ...theme.colors,
                        text: '#415362',
                        primary25: '#ebebeb',
                        primary: '#a8ba3a',
                      },
                    }
                    }}

                    onSelect={(e)=>{
                    }}

                    classNamePrefix="react-select"
                    cache={false} placeholder="Escriba para buscar"
                    defaultOptions={ true }
                    searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}
                    loadOptions={loadOptions} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSearchCreateSelect = ({ input, isDisabled, loadOptions, valueKey, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <AsyncCreatable
                isDisabled={isDisabled}
                value={input.value} className={classNames('form-control  p-0 ', { 'is-invalid': invalid })}
                onChange={(e) => { input.onChange(e ? e : null); }}
                classNamePrefix="react-select"
                defaultOptions
                searchPromptText="Escriba para buscar"
                placeholder="Escriba para buscar"
                theme={(theme) => {
                    return {
                  ...theme,
                  borderRadius: '1em',
                        background: 'white',
                  colors: {
                  ...theme.colors,
                    text: '#415362',
                    primary25: '#ebebeb',
                    primary: '#a8ba3a',
                  },
                }
                }}
                // getOptionValue={(option) => (option[valueKey])}
                // getOptionLabel={(option) => (option[labelKey])}
                searchPromptText="Escriba para buscar" valueKey={valueKey} labelKey={labelKey}
                loadOptions={loadOptions}
                promptTextCreator={(label) => {
                    return `Crear opción ${label}` }} />
            {invalid && <div className="invalid-feedback">
                {error}
                {input.value}
            </div>}
        </div>
    )
};

export const renderSelectField = ({ input, disabled, onChange, options,prerequisitos_list, key=0, valueKey='value', labelKey='label', meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <select {...input}
                disabled={disabled}
                key={ key }
                    onChange={(e) => {
                        if(onChange) {
                            onChange(e.target.value);
                        }
                        input.onChange(e);
                    }}
                className={classNames('form-control', { 'is-invalid': invalid })}>
                <option  value="" className="select-text-default" style={{color: '#918080'}}>
                    Seleccione

                </option>);
                {options.map((opcion, index) => {
                    return (<option
                        key={typeof (opcion) === "string"+ key? opcion : String(opcion[valueKey])+String(key)}
                        value={typeof (opcion) === "string" ? opcion : opcion[valueKey]}>
                        {typeof (opcion) === "string" ? opcion : opcion[labelKey]}

                    </option>);
                })}
            </select>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderNumber = ({ input, label, type, decimalScale,numberFormat, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat className={classNames('form-control', { 'is-invalid': invalid })}
                          format={numberFormat}
                decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} prefix={''}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const renderNoAsyncSelectField = ({ input, prerequisitos_list,  clearable, disabled, options, valueKey,isMulti, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Select clearable={clearable} isDisabled={disabled}
                // value={input.value}
                value={ options.find(o => o[valueKey]  === input.value)}
                className={classNames('form-control p-0 ', { 'is-invalid': invalid })}
                   isMulti={ isMulti }
                   onChange={(e) => {
                        input.onChange(e ? e.value : null);
                    }}

                    theme={(theme) => {
                        return {
                      ...theme,
                      borderRadius: '1em',
                            background: 'white',
                      colors: {
                      ...theme.colors,
                        text: '#415362',
                        primary25: '#ebebeb',
                        primary: '#a8ba3a',
                      },
                    }
                    }}
                    classNamePrefix="react-select"
                   cache={false} placeholder="Escriba para buscar"
                   key={valueKey}
                   defaultOptions
                   options={ options }
                   defaultOptions
                   searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const render = ({ input, prerequisitos_list,  clearable, disabled, options, valueKey,isMulti, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Select
            clearable={clearable} isDisabled={disabled} value={input.value}
            className={classNames('form-control p-0 select-reset', { 'is-invalid': invalid }, addClass,
            {'disabled': disabled})}
                   isMulti={ isMulti }
                   onChange={(e) => {
                        input.onChange(e ? e : null);
                    }}
                    theme={(theme) => {
                        return {
                      ...theme,
                      colors: {
                      ...theme.colors,
                        'neutral0': "#FFFFFF",
                        text: '#415362',
                        primary25: '#ebebeb',
                        primary: '#a8ba3a',
                      },
                    }
                    }}

                    classNamePrefix="react-select"
                   cache={false} placeholder="Escriba para buscar"
                   key={valueKey}
                   defaultOptions
                   options={ options }
                   defaultOptions
                   searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const renderCurrency = ({ input, _onChange, label, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
          <div className={classNames({ 'is-invalid': invalid })}>
            <NumberFormat
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                decimalScale={2} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} prefix={'Q '}
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if(!!_onChange){
                        _onChange(values.floatValue)
                    }
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const renderPercentage = ({ input, _onChange, type, disabled, addClass, meta: { touched, error }, simbolo=' %' }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                          decimalScale={2} fixedDecimalScale={true}
                          disabled={disabled}
                          value={input.value} thousandSeparator={true} suffix={simbolo}
                          onValueChange={(values) => {
                              input.onChange(values.value);
                              if (!!_onChange) {
                                  _onChange(values.floatValue);
                              }
                          }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSwitch = ({ input, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Switch
                onColor="#a8ba3a"
                offColor="#aacfda"
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                id="normal-switch"
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
""
export const renderFileUpload = ({className, placeholder, input, label, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                className={classNames('form-control', { 'is-invalid': invalid })}
                placeholder={placeholder}
                type="file"
                accept=".csv"
                onChange={(e, file) => {
                file = file || e.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    input.onChange(reader.result);
                };
                reader.readAsDataURL(file);
            }}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};



export const renderTimeField = ({ required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <TimePicker
                maxDetail="minute"
                locale="en-US"
                disableClock={ true }
                clearIcon={ null }
                name={ name }
                value={ input.value }
                onChange={(value) => {
                    input.onChange(value);
                }}/>
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};




export const RenderField = {
    renderField,
    renderFieldCheck,
    renderTextArea,
    renderSearchSelect,
    renderSearchCreateSelect,
    renderSearchSelectOC,
    renderSelectField,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderNoAsyncSelectField
};


/*temp1.filter(e=>{return e.value != 3})*/

import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { iconsh } from  "icons";
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSearchCreateSelect } from 'Utils/renderField/renderField';
import { api } from 'api/api';
import { formatAllDate, dateFormatter } from '../../../Utils/renderField/renderReadField';
import FormProducto from './Modal/FormProducto';

let proveedores = []
const getProveedores = (search)  =>{
    return api.get("proveedores",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    proveedores = [];
                    data.results.forEach(x=>{
                        proveedores.push({value: x.id, label: x.nombre})
                    })
                    return proveedores
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}


class RenderProductos extends Component {
    state = {
        openModal: false
    }
    openModal = () => {
        this.setState({openModal : true})
    }
    closeModal = () => {
        this.setState({openModal: false})
    }
    agregarProducto = (data) => {
        const {  fields } = this.props;
        let fecha = new Date(data.vencimiento)
        fecha = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombre_completo,
            vencimiento: fecha,
            lote: data.lote,
            cantidad: data.cantidad,
            nuevo: true
        })
        this.closeModal();

    }
  render() {
    const { meta: { error, submitFailed } } = this.props
    const {  ciclos, fields, deshabilitado } = this.props;
    return (
      <div className="col-12 px-0">
            {
                    this.state.openModal && (
                        <Modal open={this.state.openModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crud_bodegas} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Agregar producto</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormProducto
                                        closeModal={this.closeModal}
                                        addProducto={this.agregarProducto}
                                        onSubmit={this.agregarProducto}
                                        />
                                </div>
                            </div>
                        </Modal>
                    )
                }
            <div className="row col-sm-12 p-0 m-0">
                        <div className="col-sm-12 form-group np-r  no-striped mt-2 sin-borde p-0  over-h">
                            <table className="table table-sm table-striped table-responsive-sm m-0">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Cantidad</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-center">Vencimiento</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-center">Lote</th>
                                    <th style={{width: "10%", minWidth: '160px'}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio_unitario);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top text-gris">
                                            {fields.get(index).nombre_producto}
                                        </td>
                                        <td className="sin-borde-top text-center text-gris">
                                            {fields.get(index).cantidad}
                                        </td>
                                        <td className="sin-borde-top text-center text-gris">
                                            {dateFormatter(fields.get(index).vencimiento)}
                                        </td>
                                        <td className="sin-borde-top text-center text-gris">
                                            {fields.get(index).lote}
                                        </td>
                                        <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                            {
                                                !deshabilitado && (
                                                    <button
                                                        type="button"
                                                        className=" btn-danger btn-tabla "
                                                        onClick={() =>{
                                                            fields.remove(index)
                                                        }}
                                                    >X</button>
                                                )
                                            }
                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            {
                                !deshabilitado && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <button type="button" className="btn btn-celeste" onClick={this.openModal}>
                                                Registrar Producto
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                        </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                </div>}
      </div>
    )
  }
}


let IngresoForm = props => {
    const { handleSubmit, error } = props;
    let fecha_actual = new Date();
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="row col-md-8">
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="proveedor" className="m-0">Proveedor*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name={`proveedor`}
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="proveedor"
                                        component={renderSearchCreateSelect}
                                        loadOptions={getProveedores}
                                        />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha" className="m-0">Fecha y hora*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <span className="text-gris">{formatAllDate(fecha_actual)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FieldArray name="productos"
                        component={RenderProductos} />
                        {error && <li className="error">{error}</li>}

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/">Cancelar</Link>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
IngresoForm = reduxForm({
    form: 'Ingreso',
    validate: data => {
        const errors = {};
        if(!data.proveedor){
            errors.proveedor = 'Campo requerido'
        }
        if(!data.productos || !data.productos.length){
            errors.productos = 'Campo requerido'
        }else{

        }
        return errors
    }
})(IngresoForm);
export default IngresoForm

import React, { Component, Fragment } from 'react'
import Select from 'react-select';
import classNames from 'classnames';
import { api } from 'api/api';
import AsyncSelect from 'react-select/lib/Async';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { SingleDatePicker } from 'react-dates'
import { MESES } from '../../../../utility/constants';
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';

 let cajas = [];
 const getCajas = (search)  =>{
    return api.get("caja",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    cajas = [];
                    data.results.forEach(x=>{
                        cajas.push(x)
                    })
                    return cajas;
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}


export default class Filtros extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
    render() {
        const { ver_gasto, ver_proveedor, ver_cuenta, total_recaudado,
            ver_rango, year_list
        } = this.props;
        return (
            <div className="col-md-12 row m-0 d-flex justify-content-center ">
                {
                    ver_rango && (
                        <Fragment>
                                    <div className="col-lg-8  m-0 form-group">
                                            <div className="col-12">
                                                <label className="m-0">Año:</label>
                                            </div>
                                            <div className="col-12">
                                                <Select
                                                    onChange={this.props.setDateStart}
                                                    value={this.props.dateStart}
                                                    placeholder="Niguno"
                                                    options={year_list} />
                                            </div>
                                    </div>
                                    <div className="col-lg-8 row m-0  form-group">
                                            <div className="col-12">
                                                <label className="m-0">Mes:</label>
                                            </div>
                                            <div className="col-12">
                                                <Select
                                                    onChange={this.props.setDateEnd}
                                                    value={this.props.dateEnd}
                                                    placeholder="Niguno"
                                                    options={MESES} 
                                                />
                                            </div>
                                    </div>
                            </Fragment>
                    )
                }
                <div className="col-lg-8 form-group">
                    <div className="col-12">
                        <label className="m-0">Cajas:</label>
                    </div>
                    <div className="col-12">
                        <AsyncSelect
                            value={this.props.filtro_rcaja}
                            onChange={this.props.setFiltro}
                            placeholder="todos"
                            className={classNames('form-control p-0 ')}
                                placeholder="todos"
                                isClearable
                                theme={(theme) => {
                                    return {
                                ...theme,
                                borderRadius: '1em',
                                        background: 'white',
                                colors: {
                                ...theme.colors,
                                    text: '#415362',
                                    primary25: '#ebebeb',
                                    primary: '#a8ba3a',
                                },
                                }
                                }}
                                classNamePrefix="react-select"
                                cache={false} placeholder="Escriba para buscar"
                                defaultOptions={ true }
                                searchPromptText="Escriba para buscar"
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["nombre"])}
                                loadOptions={getCajas}
                        />
                    </div>
                </div>
                <div className="col-lg-10 row m-0 mb-2 borde-gris pt-1 pb-1">
                    <div className="col-md-4 text-center p-0">
                        <span className="text-uppercase text-primary ">Total recaudado:</span>
                    </div>
                    <div className="col-md-8 text-md-right text-center p-0">
                        <RenderCurrency value={total_recaudado || 0}  className={'text-verde h3 font-weight-bold'}/>
                    </div>
                </div>
            </div>
        )
    }
}

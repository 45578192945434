import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReporte from '../../../Utils/Header/HeaderReporte';
import { iconsh } from "icons";

import Table from 'Utils/Grid';
import { BreakLine } from '../../../Utils/tableOptions';
import { dateFormatter, formatoMoneda, dateTimeFormatter, formatAllDate } from '../../../Utils/renderField/renderReadField';

import { activeFormatter, activeFormatter2 } from 'Utils/Acciones/Acciones';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { actions as printActions, PrintContainer } from "../../../Utils/Print";

function cellTachado(cell, row) {
    if(!row.activo){
        return {...BreakLine,padding: 5, textDecoration: "line-through"}
    }
    return {...BreakLine, padding: 5}
}
const isExpandableRow = () => {
    return true;
}
export default class DetalleCaja extends Component {
    componentWillMount() {
        this.props.setIdApertura(this.props.match.params.id);
        this.props.detailApertura(this.props.match.params.id);
        this.props.listarMovimientos();
    }
    accionesFormat = (cell, row) => {
        if(row.tipo_orden !== 'Pago de cuenta'){
            if(!row.activo){
                return activeFormatter2({  popover:true, cell, row, ver: "/cierre_caja/detalle_venta"})
            }else {
                return activeFormatter2({ cell, row, ver: "/cierre_caja/detalle_venta"})
            }
        }

    }
    nombreCliente = (cell, row) => {
        if(row.nombre_cliente) {
            return row.nombre_cliente;
        } else {
            if(row.paciente) {
                return row.paciente.nombre;
            }
        }
    }
  render() {
      const { cargando, data_movimientos, page, listarMovimientos, apertura } = this.props;
      let data = {
          count: 0,
          results: []
      }
      let total = 0;
        if(apertura.total_apertura){
            total = apertura.total_apertura + apertura.total_efectivo;
        }
    return (
      <div>
            <div className="container m-0 contenido-principal row d-flex justify-content-center no-print">
                <HeaderReporte
                    icono={iconsh.crud_reportes}
                    titulo={'Cajas'}
                    es_detalle
                    {...this.props}
                />
            </div>
            <div className="container m-0 contenido-principal mt-3 pt-3 col-md-12">
                <div className="fondo-inverso-navbar " style={{height:40}}></div>
                    <div className="col-md-12 row">
                    <LoadMask loading={cargando} dark blur className="w-100">
                        <PrintContainer name='detalle_caja'>
                        <div className="row px-md-5 mt-5">
                            <div className="col-12 row m-0 text-md-left text-center">
                                    <span className="text-primary text-uppercase h4">{ apertura && apertura.caja ? apertura.caja.nombre :''}</span>
                            </div>
                        </div>
                        <br/>
                        <div className="row  px-md-5 mt-5">
                            <div className="col-12">
                                <span className="text-uppercase text-gris ">Usuario quien aperturó la caja:</span>
                                &nbsp;&nbsp;{(apertura.usuario && apertura.usuario.nombreCompleto) ? apertura.usuario.nombreCompleto : ''} &nbsp;({(apertura.usuario)? apertura.usuario.username : ''})
                            </div>
                        </div>
                        <div className="row px-md-5 mt-5">
                            <div className="col-4 col-md-4">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-uppercase text-gris ">Apertura:</span>
                                        &nbsp;&nbsp;{dateTimeFormatter(apertura.creado)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <RenderCurrency value={apertura.total_apertura || 0}  className={'text-gris h2 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-md-4">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-uppercase text-gris ">Ingreso tarjeta:</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <RenderCurrency value={apertura.total_tarjeta || 0}  className={'text-gris h6 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-md-4">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-uppercase text-gris ">Ingreso cheque:</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <RenderCurrency value={apertura.total_cheque || 0}  className={'text-gris h6 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  px-md-5 mt-5">
                            <div className="col-12">
                                <span className="text-uppercase text-gris ">Usuario quien autorizó el cierre de caja:</span>
                                &nbsp;&nbsp;{(apertura.quien_cerro && apertura.quien_cerro.nombreCompleto) ? apertura.quien_cerro.nombreCompleto : ''} &nbsp;({(apertura.quien_cerro)? apertura.quien_cerro.username : ''})
                            </div>
                        </div>
                        <div className="row px-md-5 mt-5">
                            <div className="col-4 col-md-4">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-uppercase text-gris ">Cierre:</span>
                                        &nbsp;&nbsp;{dateTimeFormatter(apertura.fecha_cierre)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <RenderCurrency value={apertura.total_cierre || 0}  className={'text-gris h2 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-md-4">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-uppercase text-azul">Total ingresado:</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <RenderCurrency value={apertura.total_efectivo || 0}  className={'text-azul h4 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 col-md-2 borde-gris d-flex align-items-center justify-content-center flex-column">
                            <div className="row">
                                    <div className="col-12">
                                        <span className="text-uppercase text-primary ">Total recolectado:</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <RenderCurrency value={total || 0}  className={'text-verde h3 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </PrintContainer>
                        <div className="col-md-12 d-flex justify-content-center mt-5">
                            <div className="col-md-4 text-center p-0">
                                <button
                                    className="btn btn-primary"
                                    onClick={()=>{
                                        window.print()
                                    }}
                                >Imprimir</button>
                            </div>
                        </div>
                        </LoadMask>
                    </div>

                <h5 className="mt-4 mb-2 no-print">DETALLE DE CAJA</h5>
                <div className="no-print">
                    <Table
                        onPageChange={listarMovimientos}
                        data={data_movimientos}
                        loading={cargando}
                        page={page}>
                        <TableHeaderColumn
                            tdStyle={{...BreakLine, padding: 5}}
                            thStyle={{...BreakLine, padding: 5}}
                            dataField="id"
                            isKey={true}
                            dataAlign="center"
                            dataFormat={this.accionesFormat}>Acciones </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={{...BreakLine, padding: 5}}
                            width={'90px'}
                            dataAlign={"center"}
                            dataField="no_orden" dataSort>No</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={{...BreakLine}}
                            width={'80px'}
                            dataAlign={"center"}
                            dataFormat={formatAllDate}
                            dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={{...BreakLine, padding: 5}}
                            dataAlign={"center"}
                            dataFormat={this.nombreCliente} dataSort>Cliente</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={{...BreakLine, padding: 5}}
                            dataFormat={formatoMoneda}
                            dataAlign={"right"}
                            dataField="monto" dataSort>Total</TableHeaderColumn>

                    </Table>
                </div>
            </div>
      </div>
    )
  }
}

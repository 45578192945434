import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarFiltroFecha from 'Utils/Toolbar/toolbarFiltroFecha';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { dateFormatter, formatAllDate } from 'Utils/renderField/renderReadField'


function formatoUsuario(cell, row){
    return <span>{cell.first_name}</span>
}
export default class BitacoraGrid extends Component {
componentWillMount(){
    this.props.listar(this.props.page);
}
render() {
    const {
        listar,
        detail,
        create,
        update,
        destroy,
    } = this.props;
    const {
        cargando,
        page,
        data
    } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">

        <ToolbarFiltroFecha
            titulo=""
            icono={iconsh.crud_bitacora}
            buscar={this.props.search}
            buscador={this.props.buscador}
            {...this.props}
            />
        <div className="mt-3 col-md-12">
            <Table
                onPageChange={listar}
                data={data}
                loading={cargando}
                page={page}>
                <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="center"
                    dataFormat={activeFormatter({ ver: '/reporte_bitacora_detalle'})}>Acciones</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatAllDate}
                    dataField="creado" dataSort>Fecha</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatoUsuario}
                    dataField="usuario" dataSort>Usuario</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="accion" dataSort>Acción</TableHeaderColumn>

            </Table>
        </div>
    </div>
    )
    }
}

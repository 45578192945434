import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack, go } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { getNotificacion } from './estadobodega';
import { push } from 'react-router-redux';
import swal from 'sweetalert2';
const url = 'movbodega';

const LOADER_MB = 'LOADER_MB';
const SET_DATA_MB = 'SET_DATA_MB';
const SET_PAGE_MB = 'SET_PAGE_MB';
const SET_DATAHISTORIA_MB = 'SET_DATAHISTORIA_MB';
const SET_PAGEHISTORIA_MB = 'SET_PAGEHISTORIA_MB';
const SET_UPDATE_DATA_MB ='SET_UPDATE_DATA_MB';
const SET_BUSCADOR_MB = 'SET_BUSCADOR_MB';
const SET_FILTRO_MB = 'SET_FILTRO_MB';
const SET_BUSCADORHISTO_MB = 'SET_BUSCADORHISTO_MB';
const SET_FILTROHISTO_MB = 'SET_FILTROHISTO_MB';

const SET_INGRESOSPENDIENTES_MB = 'SET_INGRESOSPENDIENTES_MB';
const SET_TOGGLE_MB = 'SET_TOGGLE_MB';
const SET_ACTIVETAB_MB = 'SET_ACTIVETAB_MB';

const listar_ingreso_pendiente = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_MB, cargando: true});
    const store = getStore();
    const search = store.movimientobodega.buscador;
    const filtro = store.movimientobodega.filtro_mb;
    const bodega = store.estadobodega.updateData
    let params = {};

    params['destino__id'] = bodega.id
    params['ingresos_pendientes'] = true

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}/getIngresosPendientes`, params).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_INGRESOSPENDIENTES_MB, ingresos_pendientes:data});
        }
        dispatch({type: LOADER_MB, cargando: false});
    })
};
const listar_despachos_pendientes = (page=1) => (dispatch, getStore) => {
    dispatch({type: LOADER_MB, cargando: true});
    const store = getStore();
    const search = store.movimientobodega.buscador;
    const filtro = store.movimientobodega.filtro_mb;
    const bodega = store.estadobodega.updateData
    let params = {page, search};
    console.log('buscador', search)
    params['bodega__id'] = bodega.id
    params['despachos_pendientes'] = true
    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_MB, data_despacho: data});
            dispatch({
                type: SET_PAGE_MB,
                page: page
            })
        }
        dispatch({type: LOADER_MB, cargando: false});
    })
}

const listar_historia = (page=1) => (dispatch, getStore) => {
    dispatch({type: LOADER_MB, cargando: true});
    const store = getStore();
    const search = store.movimientobodega.buscador_historia;
    const bodega = store.estadobodega.updateData
    let params = {page, search};

    params['bodega__id'] = bodega.id
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        dispatch(getNotificacion());
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        dispatch(getNotificacion());
        if(data){
            dispatch({type: SET_DATAHISTORIA_MB, data_historia: data});
            dispatch({
                type: SET_PAGEHISTORIA_MB,
                page_historia: page
            });
        }
        dispatch({type: LOADER_MB, cargando: false});
    })
}
const detail_movimiento = id => (dispatch, getState) =>{
    dispatch({type: LOADER_MB, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_MB, update_movimiento: data});
            if(data.anulado){
                dispatch(marcarLeido(id))
            }
        }
    })
}
const detail_despacho = id => (dispatch, getState) =>{
    dispatch({type: LOADER_MB, cargando: true});
    api.get(`devolucion/getDevolucion/${id}`).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(data){
            data.detalle_movimiento.forEach(x => {
                x.lotes = [{cantidadActual: x.cantidad}]
            })
            dispatch({type: SET_UPDATE_DATA_MB, update_movimiento: data});
            if(data.anulado){
                dispatch(marcarLeido(id))
            }
        }
    })
}
const marcarLeido = id => (dipatch, getState) => {
    api.put(`${url}/marcarLeido/${id}`,{}).catch((error) => {

    }).then((data) => {

    })
}

const create = () => (dispatch, getStore) => {
    const store = getStore();
    const updateData = store.movimientobodega.update_movimiento;
    let error_lotes = false;
    updateData.detalle_movimiento.forEach(x => {
        x.lotes.forEach(item => {
            if (!item.vencimiento || !item.lote){
                error_lotes = true;
            }
        });
    })
    if(!error_lotes){
        dispatch({type: LOADER_MB, cargando: true})
        api.post(`devolucion/ingreso/${updateData.id}`, updateData).then((data) => {
            dispatch({type: LOADER_MB, cargando: false})
            Swal('Éxito', 'Se ha realizado el reintegro de productos.', 'success')
            .then(() => {
                dispatch(go(-1))
            })
        }).catch((error) => {
            dispatch({type: LOADER_MB, cargando: false})
            Swal(
                'Error',
                (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).finally(() => {
            dispatch({type: LOADER_MB, cargando: false})
        });
    }else{
        swal('Error', 'Debe de ingresar los lotes para ingresar a bodega.', 'error')
    }


};
const createRechazar = (id) => (dispatch, getStore) => {
    const formData = getStore().form.rechazo.values;
    const store = getStore()
    const updateData  = store.movimientobodega.update_movimiento
    dispatch({type: LOADER_MB, cargando: true})
    api.post(`${url}/rechazar/${updateData.id}`, formData).then((data) => {
        dispatch({type: LOADER_MB, cargando: false})
        Swal('Éxito', 'Se ha rechazado el ingreso.', 'success')
        .then(() => {
            dispatch(closeModal())
            dispatch(go(-1));
        })
    }).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_MB, cargando: false})
    });
};
const aceptar = id => (dispatch, getState) =>{
    const store = getStore()
    const updateData  = store.movimientobodega.update_movimiento
    dispatch({type: LOADER_MB, cargando: true});
    api.get(`${url}/aceptaringreso/${updateData.id}`).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(error.statusCode  === 404){
            dispatch(go(-1));
        }
    }).then((data) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_MB, update_movimiento: data});
            dispatch(initializeForm('bodega', data))
        }
    })
}

const ingresar_bodega = id => (dispatch, getStore) => {
    const store = getStore();
    const updateData = store.movimientobodega.update_movimiento;
    let formData = {};
    dispatch({type: LOADER_MB, cargando: true})
    api.post(`${url}/ingreso/${updateData.id}`, formData).then((data) => {
        dispatch({type: LOADER_MB, cargando: false})
        Swal('Éxito', 'Se ha aceptado el ingreso.', 'success')
        .then(() => {
            dispatch(go(-1));
        })
    }).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_MB, cargando: false})
    });
}
const addLote = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.ingreso.values);
    const dataOp = _.cloneDeep(getStore().movimientobodega.update_movimiento)
    let productos = dataOp.detalle_movimiento
    let producto = productos.find(x => x.fraccion === formData.fraccion)
    if (producto){
        producto.lotes = formData.lotes;
        producto.lotes.forEach(x => {
            try {
                x.vencimiento = x.vencimiento.format("YYYY-MM-D")
            } catch (e) {
                let fecha = new Date(x.vencimiento)
                x.vencimiento = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
            }
        });
    }
    dispatch({type: SET_UPDATE_DATA_MB, update_movimiento: dataOp});
    dispatch(closeModal());
}
const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_MB, cargando: true});
    api.put(`url/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_MB, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_MB, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_MB, cargando: true});
    api.eliminar(`url/id`).catch((error) => {
        dispatch({type: LOADER_MB, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_MB, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    console.log(search);
    dispatch({type: SET_BUSCADOR_MB, buscador: search});
    dispatch(listar_despachos_pendientes(1));
};

const setBuscadorHistoria = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADORHISTO_MB, buscador_historia: search});
    dispatch(listar_historia(1));
}

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_MB, filtro_mb: filtro});
    dispatch(listar_despachos_pendientes(1));
};
const closeModal =() => (dipatch, getStore) => {
    dipatch({
        type: SET_TOGGLE_MB ,
        toggleModal: false
    })
}
const openModal =() => (dipatch, getStore) => {
    dipatch({
        type: SET_TOGGLE_MB ,
        toggleModal: true
    })
}
const regresar = () => (dispatch, getStore) => {
    dispatch(go(-1))
}
const setActiveTab = (tab) => (dispatch, getStore) => {
    dispatch({
        type: SET_ACTIVETAB_MB,
        activeTab: tab
    });
}
export const actions = {
    listar_despachos_pendientes,
    listar_ingreso_pendiente,
    listar_historia,
    detail_movimiento,
    ingresar_bodega,
    create,
    update,
    destroy,
    search,
    addLote,
    filtro,
    closeModal,
    openModal,
    createRechazar,
    detail_despacho,
    regresar,
    aceptar,
    setBuscadorHistoria,
    setActiveTab
};
export const reducer = {
    [LOADER_MB]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_MB]: (state, { data_despacho }) => {
        return {...state, data_despacho }
    },
    [SET_PAGE_MB]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_MB]: (state, { update_movimiento }) => {
        return {...state, update_movimiento }
    },
    [SET_BUSCADOR_MB]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_MB]: (state, { filtro_mb }) => {
        return {...state, filtro_mb }
    },
    [SET_INGRESOSPENDIENTES_MB]: (state, { ingresos_pendientes }) => {
        return {...state, ingresos_pendientes }
    },
    [SET_TOGGLE_MB]: (state, { toggleModal }) => {
        return {...state, toggleModal }
    },
    [SET_PAGEHISTORIA_MB]: (state, { page_historia }) => {
        return {...state, page_historia }
    },
    [SET_BUSCADORHISTO_MB]: (state, { buscador_historia }) => {
        return {...state, buscador_historia }
    },
    [SET_DATAHISTORIA_MB]: (state, { data_historia }) => {
        return {...state, data_historia }
    },
    [SET_ACTIVETAB_MB]: (state, { activeTab }) => {
        return {...state, activeTab }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    page_historia:1,
    data_despacho: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_historia: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    buscador_historia:'',
    filtro_mb: null,
    update_movimiento: {},
    ingresos_pendientes: [],
    toggleModal: false,
    activeTab: 'bodega'
};
export default handleActions(reducer, initialState)

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency } from 'Utils/renderField/renderField';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import renderDatePicker from 'Utils/renderField/renderDatePicker';
import Titulo from '../Aceptado/Titulo';
import { iconsh } from 'icons';
import { formatSelect } from 'Utils/renderField/renderReadField';


const productosRender = ({fields, meta: {error, submitFailed}}) => (
  <div className="col-md-12 p-0">
      <table className="table table-sm table-responsive-sm m-0 table-striped">
        <tbody>
            <tr className="header-tabla">
              <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
              <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Solicitado en OC</th>
              <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Ya ingresado</th>
              <th style={{width: "10%", minWidth: '90px'}} className="text-center" >A ingresar</th>
              <th style={{width: "10%" , minWidth: '90px'}} className="text-center">Precio unitario</th>
              <th style={{width: "10%", minWidth: '90px'}} className="text-center"  >Sub total</th>
            </tr>
            {
              fields.map((producto, index) => {
                const todos = fields.getAll(); //Obtiene todos los productos
                todos[index]['index'] = index
                let subtotal = 0;
                const prod = fields.get(index); // Producto actual
                const ingresados = Number(prod.cantidad) - Number(prod.cantidadActual)
                try {
                    subtotal = Number(prod.cantidadActual) * Number(prod.precio_unitario);
                } catch (error) {
                    subtotal = 0;
                }
                return (
                  <tr key={index}>
                    <td className="sin-borde-top">
                      {fields.get(index).nombre_producto}
                    </td>
                    <td className="sin-borde-top text-right pr-3">
                        {fields.get(index).cantidad}
                    </td>
                    <td className="sin-borde-top text-right pr-3">
                        {fields.get(index).cantidadIngresada}
                    </td>
                    <td className="sin-borde-top">
                      <Field
                            name={`${producto}.cantidadActual`}
                            type="number"
                            addClass={"text-right"}
                            component={renderField}
                            placeholder="Representante"
                        />
                    </td>
                    <td className="text-right sin-borde-top">
                      <Field
                            name={`${producto}.precio_unitario`}
                            type="number"
                            addClass={"text-right"}
                            component={renderCurrency}
                            placeholder="Representante"
                        />
                    </td>
                    <td className="text-right sin-borde-top">
                      <RenderCurrency value={subtotal || 0} />
                    </td>
                  </tr>
                )
              })
            }
        </tbody>
      </table>
  </div>
)

let OrdenPagoForm = props => {
  const { handleSubmit, total, updateData } = props;
  return(
    <form onSubmit={handleSubmit}>
      <div className="form-group grid-container">
        <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
          <div className="col-md-12 mb-4">
            <div className="col-md-12 mt-2 row">
              <div className="col-md-6 row">
                <Titulo icono={iconsh.crudOc} titulo={'Orden de Pago'} />
              </div>

              <div className="col-md-6 row">
                <div className="col-md-auto d-flex justify-content-md-end justify-content-center align-items-center">
                    <label htmlFor="op" className="m-0">Proveedor:</label>
                </div>
                <div className="col-md-4  form-group m-0 d-flex align-items-center justify-content-md-start justify-content-center">
                  <span className="text-primary text-uppercase">{ formatSelect(updateData.proveedor) }</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2 row">
              <div className="col-md-6 row">
                <div className="col-12 col-md-auto text-center">
                  <span className="text-primary h5 mr-3">Total orden de compra:</span>
                </div>
                <div className="col-12 col-md-auto text-center">
                <RenderCurrency value={updateData.monto || 0} className={'text-verde h4'}/>
                </div>
              </div>
              <div className="col-md-6 row">
                <div className="col-md-auto d-flex justify-content-md-end justify-content-center align-items-center">
                    <label htmlFor="op" className="m-0">Fecha orden de Pago*:</label>
                </div>
                <div className="col-md-4  form-group m-0">
                  <Field
                        name={'fecha'}
                        className=""
                        component={renderDatePicker}
                        placeholder="Fecha"
                        numberOfMonths={1}
                    />
                </div>
              </div>
            </div>



            </div>
          <FieldArray name="productos" component={productosRender} />
          <div className="col-md-12 mt-3 d-flex justify-content-end">
                <span className="text-primary h5 mr-3">Total orden de Pago:</span>
                <RenderCurrency value={total || 0} className={'text-verde h4'}/>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <button type="button"
                    onClick={ e=>{
                        e.preventDefault();
                        props.cancelar()
                    }}
                    className="btn btn-secondary m-1">Cancelar</button>
                <button type="submit" className="btn btn-primary m-1">Siguiente</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
OrdenPagoForm = reduxForm({
  form: 'OrdenPago',
  validate: data => {
    return validate(data, {
        'fecha': validators.exists()('Campo requerido')
    })
  },
  initialValues:{
      fecha: moment.now()
  }
})(OrdenPagoForm);

const selector = formValueSelector('OrdenPago');
OrdenPagoForm = connect(state => {
    let todos = [];
    const productos = selector(state, "productos");
    if(productos){
        todos=productos;
    }
    let total = 0;
    todos.forEach(x => {
        if(!isNaN(x.cantidadActual) && !isNaN(x.precio_unitario)){
            total += Number(x.cantidadActual) * Number(x.precio_unitario);
        }
    });
    return {
        total
    };
})(OrdenPagoForm);

export default OrdenPagoForm

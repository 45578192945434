import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';

let CuentaAreaForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">Nombre*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nombre" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/admin_cuenta_areas">Cancelar</Link>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
CuentaAreaForm = reduxForm({
    form: 'cuentaarea',
    validate: data => {
        return validate(data, {
        })
    }
})(CuentaAreaForm);
export default CuentaAreaForm

import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"
import { formatAllDate } from '../renderField/renderReadField';
import Select from 'react-select';
import classNames from 'classnames';

export default class ToolbarBodega extends Component {
    render() {
        const { bodega } = this.props;
        let encargado= null;
        if(bodega){
            encargado = bodega.encargado
        }
        return (
            <div className="col-12 row d-flex justify-content-md-end justify-content-center">
                <div className="col-md-4 row ">
                    <div className="col-md-6  ">
                        <div className="col-md-12 col-sm-12 text-center text-md-left">
                            <label htmlFor="usuario" className="m-0">Usuario activo:</label>
                        </div>
                        <div className="col-md-12 ">
                            <h5 className="text-uppercase m-0 text-center text-md-left">{encargado ? encargado.nombreCompleto : ''}</h5>
                        </div>

                    </div>
                    <div className="col-md-6 ">
                        <span className="text-gris">Fecha y hora de ingreso:</span>
                        <span className="text-primary"> {bodega ? formatAllDate(bodega.fecha_cambio) :''}</span>
                    </div>
                </div>
                <div className="col-md-4 mb-md-0 mb-2 d-flex justify-content-center justify-content-md-end  ">
                    <div className="col-auto ">
                        <label className="m-0 mt-2">Filtro:</label>
                    </div>
                    <div className="col-md-6 form-group text-left m-0">
                            <Select
                                value={this.props.filtro_bodega}
                                onChange={this.props.cambiarFiltro}
                                className={classNames('form-control p-0 ')}
                                placeholder="todos"
                                isClearable
                                theme={(theme) => {
                                    return {
                                  ...theme,
                                  borderRadius: '1em',
                                        background: 'white',
                                  colors: {
                                  ...theme.colors,
                                    text: '#415362',
                                    primary25: '#ebebeb',
                                    primary: '#a8ba3a',
                                  },
                                }
                                }}
                                classNamePrefix="react-select"
                                options={[
                                    {value: 10, label: 'Con alerta'},
                                ]}
                            />
                        </div>
                </div>

                <div className="col-lg-4 col-md-4 p-0 mb-md-0 mb-2 text-right search">
                    {(this.props.buscar !== undefined) && (
                        <Search buscar={this.props.buscar} buscador={this.props.buscador} placeholder={this.props.placeholder} />
                    )}
                    {(this.props.tituloBuscador &&
                        <p className="ml-2 text-primary mt-5 mt-md-0 mt-lg-0"><strong>{this.props.tituloBuscador}</strong></p>
                    )}
                        <div className="col-md-12 d-flex justify-content-center justify-content-md-end mt-3 ">
                            <div className="col-md-12 p-0 mt-2 d-flex align-items-center justify-content-md-end justify-content-center">
                                <span className="text-gris mr-3">Existencias Mínimas</span> <div className="dot dot-naranja"></div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderCurrency, renderTextArea, renderNoAsyncSelectField, renderSwitch } from 'Utils/renderField/renderField';
import {api} from 'api/api';
import { opcionesBanco, TIPOSPAGO } from '../../../../utility/constants';
import renderDatePicker  from 'Utils/renderField/renderDatePicker';

let cuentas = [];
let proveedores = [];
let categoria = [];

const getProveedores = (search) => {
    return api.get("proveedores", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                proveedores = [];
                data.results.forEach(x => {
                    proveedores.push(x)

                })
                return proveedores
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}
const getCuentas = (search) => {
    return api.get("cuenta_area", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                cuentas = [];
                data.results.forEach(x => {
                    cuentas.push(x)

                })
                return cuentas
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}
const getCategoria = (search) => {
    return api.get("categoria", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                categoria = [];
                data.results.forEach(x => {
                    categoria.push(x)

                })
                return categoria
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}


let GastoForm = props => {
    const { handleSubmit, mostrar_cuenta, documento } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="categoria" className="m-0">Categoria*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`categoria_gasto`}
                                    valueKey="id"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={renderSearchSelect}
                                    loadOptions={getCategoria}
                                    />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="monto" className="m-0">Monto*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                        name={`monto`}
                                        type="number"
                                        addClass={"text-right'"}
                                        component={renderCurrency}
                                        placeholder="monto"
                                    />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="factura" className="m-0">Factura/recibo*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="factura" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Cuenta" className="m-0">Cuenta saliente*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`cuenta`}
                                    valueKey="id"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={renderSearchSelect}
                                    loadOptions={getCuentas}
                                    />
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                            <div className="row ">
                                <div className="col-md-8 text-gris p-0 text-right">
                                   Ingresar a otra cuenta
                                </div>
                                <div className="col-md-4">
                                    <Field name="mostrar_cuenta" component={renderSwitch} type="text" className="form-control" />
                                </div>
                            </div>
                        </div>

                        {
                            mostrar_cuenta && (
                                <div className="col-md-4">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="cuenta_ingreso" className="m-0">Cuenta a ingresar*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name={`cuenta_ingreso`}
                                            valueKey="id"
                                            labelKey="nombre"
                                            label="nombre"
                                            component={renderSearchSelect}
                                            loadOptions={getCuentas}
                                            />
                                    </div>
                                </div>
                            )
                        }
                        {
                            !mostrar_cuenta && (
                                <div className="col-md-4">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="proveedor" className="m-0">Proveedor*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name={`proveedor`}
                                            valueKey="id"
                                            labelKey="nombre"
                                            label="nombre"
                                            component={renderSearchSelect}
                                            loadOptions={getProveedores}
                                            />
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="tipo_movimiento" className="m-0">Forma de pago*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`tipo_movimiento`}
                                    valueKey="value"
                                    labelKey="label"
                                    label="label"
                                    component={renderNoAsyncSelectField}
                                    options={opcionesBanco}
                                    />
                            </div>
                        </div>
                        {
                           documento && documento.length > 0 && (
                                <div className="col-md-4">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="documento_pago" className="m-0">{documento}*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="documento_pago" component={renderField} type="text" className="form-control"/>
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="documento_pago" className="m-0">Fecha de pago*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="fecha_pagado"
                                    component={renderDatePicker}
                                    placeholder="Fecha de pago"
                                    type="fecha"
                                    className=""
                                    numberOfMonths={1}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="descripcion" className="m-0">Descripción*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                        name={`descripcion`}
                                        component={renderTextArea} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/gastos">Cancelar</Link>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
GastoForm = reduxForm({
    form: 'gasto',
    validate: data => {
        let error = {};
        if(!data.categoria_gasto){
            error.categoria_gasto = 'Campo requerido'
        }
        if(!data.cuenta){
            error.cuenta = 'Campo requerido';
        }
        if(!data.monto){
            error.monto = 'Campo requerido'
        }else{
            if(data.monto <= 0){
                error.monto = 'El monto debe de ser mayor a 0.'
            }
        }
        if(data.mostrar_cuenta){
            //Se verifica la cuenta
            if(!data.cuenta_ingreso){
                error.cuenta_ingreso = 'Campo requerido';
            }else{
                if(data.cuenta && data.cuenta_ingreso){
                    if(data.cuenta.id == data.cuenta_ingreso.id){
                        error.cuenta_ingreso = 'La cuenta debe de ser diferente a la cuenta de salida'
                    }
                }
            }
        }else{
            // se verifica el proveedor
            if(!data.proveedor){
                error.proveedor = 'Campo requerido'
            }
        }
        if(!data.descripcion){
            error.descripcion = 'Campo requerido';
        }
        if(!data.tipo_movimiento){
            error.tipo_movimiento = 'Campo requerido';
        }else{
            if(data.tipo_movimiento == TIPOSPAGO.CHEQUE){
                if(!data.documento_pago){
                    error.documento_pago = 'Campo requerido'
                }
            }
        }
        if(!data.factura){
            error.factura = 'Campo requerido';
        }
        return error;
    },
    initialValues: {
        tipo_movimiento: 40
    }
})(GastoForm);
const selector = formValueSelector('gasto');
GastoForm = connect(state => {
    const mostrar_cuenta = selector(state, "mostrar_cuenta");
    const tipo_movimiento = selector(state, "tipo_movimiento");
    let documento = 'No. Cheque';
    if(tipo_movimiento){
        let _formaPago = opcionesBanco.find(x => x.value == tipo_movimiento)
        documento = _formaPago.documento
    }
    return {
        mostrar_cuenta,
        documento
    };
})(GastoForm);
export default GastoForm

import React, { Component } from 'react'
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { activeFormatter } from 'Utils/Acciones/Acciones';
import ToolbarSearch from 'Utils/Toolbar/ToolbarSearch';
import { formatoMoneda } from '../../../Utils/renderField/renderReadField';
import { EXTRAS } from 'icons';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import ProductoForm  from './Formularios/ProductoForm';
import { api } from 'api/api';

function formatCantidad(cell, row){
    if(row.es_producto){
        return <span>{cell}</span>
    }
    return <span>1</span>
}
function formatNombre(cell, row){
    if(row.es_producto){
        return <span>{cell}</span>
    }
    return <span>{row.nombre}</span>
}

export default class ProductosVenta extends Component {
    state = {
        openModal: false,
        row: []
    }
    openModal = () => {
        this.setState({ openModal: true });
    }
    closeModal = () => {
        this.setState({ openModal: false });
    }
    agregarProducto = (data, lote, existencias) => {
        const { fields } = this.props;
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombre_completo,
            existencia: existencias,
            cantidad: data.cantidad,
            lote: lote.lote.lote,
            lote_id: lote.lote.id,
            vencimiento: lote.lote.fecha_vencimiento,
            nuevo: true
        })
        this.closeModal();

    }
    botonModal = (id, row) => {
            return (
                <img onClick={(e) => {
                    e.preventDefault();
                    if(row.es_producto){
                        console.log("row boton modal", row)
                        this.setState({ row: row });
                        this.openModal()
                    }
                    else{
                        this.props.agregar_productos_carrito(row);
                    }
                }}
                className="action-img"
                title="Agregar producto" src={EXTRAS.agregar1} alt="Agregar producto" />

                );
    }
    render() {
        const { getInventario, cargando, page, destroy,inventario, cargando_productos } = this.props;
        return (
            <div>
                {
                    this.state.openModal && (
                        <Modal open={this.state.openModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Agregar producto</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <ProductoForm
                                        ref={(instance) => {this.form = instance}}
                                        row={this.state.row}
                                        initCantidad={this.props.initCantidad}
                                        closeModal={this.closeModal} addProducto={this.agregarProducto}
                                        agregar_productos_carrito={this.props.agregar_productos_carrito}
                                        onSubmit={()=>{
                                            this.props.agregar_productos_carrito(this.state.row)
                                            console.log("row padre", this.state.row)
                                            this.closeModal()}
                                        }
                                        />
                                        
                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="mb-2">
                    <ToolbarSearch
                        titulo={' '}
                        half
                        buscar={this.props.search}
                        buscador={this.props.buscador}
                        placeholder={"Buscar por: Producto"}
                    />
                </div>

                <Table
                    onPageChange={getInventario}
                    data={inventario}
                    loading={cargando_productos}
                    page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        dataAlign="center"
                        dataFormat={activeFormatter({ adicional: this.botonModal})}>Agregar compra</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatNombre}
                        dataField="nombre_completo" dataSort>Producto</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatCantidad}
                        dataField="cantidad" dataSort>Existencias</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign={'right'}
                        dataFormat={formatoMoneda}
                        dataField="precio" dataSort>Precio</TableHeaderColumn>

                </Table>
            </div>
        )
    }
}

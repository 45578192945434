import React, { Component } from 'react'
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './UsuarioForm';
import { UsuarioUpdateForm } from './UsuarioForm';
import { iconsh } from "icons";

export default class UsuarioCrear extends Component {
  state = {
      editar: false,
      mensaje: 'Agregar usuario',
      password: false,
  }
  componentWillMount(){
      if(this.props.match.params.id){
          this.props.detail(this.props.match.params.id);
          this.setState({
                editar: true,
                mensaje: 'Editar usuario'
            });
      }
  }
  verPassword = () =>{
      this.setState({
          password: !this.state.password
      })
  }
  render() {
    const { create, update } = this.props;
    const { updateData, cargando } = this.props;
    return (
      <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12">
            <Card
                icono={iconsh.usuario}
                titulo={this.state.mensaje}>
                <LoadMask loading={cargando} blur_1>
                    {
                        this.state.editar ?
                            <UsuarioUpdateForm
                                verPassword={this.verPassword}
                                mostrar_pass={this.state.password}
                                onSubmit={update}
                                updateData={updateData}
                                editando={true} />
                        :
                            <Form onSubmit={create}  mostrar_pass/>
                    }
                </LoadMask>
            </Card>
        </div>
      </div>
    )
  }
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Compras/op';
import OrdenPagoContainer from './OrdenPagoContainer';
import { initialize as initializeForm } from 'redux-form'


const mstp = state => {
    return {...state.op}
};

const mdtp = {
    ...actions,
    setFormularioPrecios:(data) => dispatch => dispatch(initializeForm('precio', data)),
};

export default connect(mstp, mdtp)(OrdenPagoContainer)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Devoluciones/devoluciones';
import DevolucionesCrear from './DevolucionesCrear';

const mstp = state => {
    let me;
    if(state.login.me) {
        me = state.login.me;
    }
    return {...state.devoluciones,
        me
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DevolucionesCrear)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Producto/producto';
import Fracciones from './Fracciones';
import { initialize as initializeForm, change } from 'redux-form'

const mstp = state => {
    return {...state.producto}
};

const mdtp = {
    ...actions,
    setFormFraccion:(data) => dispatch => dispatch(initializeForm('fraccionEditar', data)),
    setPorcentaje:(formulario, value) => dispatch => dispatch(change(formulario, 'porcentaje', value)),
    setPrecio_descuento:(formulario, value) => dispatch => dispatch(change(formulario, 'precio_descuento', value)),
};

export default connect(mstp, mdtp)(Fracciones)

import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { slide as Slide } from 'react-burger-menu';
import { Menu } from '../Menu';
import DropdownMenu from 'react-dd-menu';
import Fcm from '../Fcm/FcmContainer'

import './navbar.css';
import './burger-sidebar.css';
import './dd-menu.css';
class Navbar extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { verMenu: false, opened: false, expand:false };
  }
  componentWillMount() {
    this.logOut = this.logOut.bind(this);
    this.props.getNotificacionA()
    this.props.getNotificacionP()
    this.props.getNotificacionR()
    //REcupera la cantidad de despachos e ingresos pendientes
    this.props.getNotificacion()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ verMenu: false });
    }
  }

  toggleMenu = (e) => {

        this.setState({verMenu: !this.state.verMenu})
        return (<Redirect to="/page" />);
  }
  logOut(event) {
    this.props.logOut();
  }
  handleStateChange = (e) =>{
    this.setState({verMenu: e.isOpen})
  }
  close = () => {
      this.setState({opened: !this.state.opened})
  }
  toggle = () => {
      this.setState({opened: !this.state.opened})
  }

  toggleExpand = () =>{
    let sidebar = document.getElementById('sidebar');
    let content = document.getElementById('content-wrapper');
    if(sidebar && content){
      if(this.state.expand){
        sidebar.classList.remove('sidebar-small');
        content.classList.remove('menu-small-content-wrapper');
        this.setState({expand:false})
      }else{

        sidebar.classList.add('sidebar-small');
        content.classList.add('menu-small-content-wrapper');
        this.setState({expand: true})
      }
    }

  }

  render() {
    const { me } = this.props;
    const username = me ? `${me.nombreCompleto} (${me.username})`: 'Perfil';
    const proyecto = me.proyecto ? me.proyecto : null;
    const menuOptions = {
        isOpen: this.state.opened,
        close: this.close,
        closeOnInsideClick: false,
        toggle: <li><a onClick={this.toggle}>{username}<em className="fa fa-angle-right" /></a></li>,
        align: 'right',
      };
    return (
      <div>
          <Fcm />
        <header className="topnavbar-wrapper">

          <nav className="navbar topnavbar">
            <div className="ver-escritorio" onClick={this.toggleExpand}  style={{zIndex:50, position: "absolute",left: 25, cursor: "pointer"}}>
              {/* <img src={require('../../../../assets/img/login/menu.png')}/> */}
            </div>
            <div className="nav-wrapper">
              <ul className="nav navbar-nav">
                <li>
                    <a href="#/" style={{ padding: 0 }}>
                        <img className="nav-logo" src={require('../../../../assets/img/logo02.png')} alt="Logo" />
                    </a>
                </li>

              </ul>

            </div>


            <div className="ver-escritorio">
                <ul className="nav navbar-nav perfil">
                    <DropdownMenu {...menuOptions}>
                        <li><a href="/#/login" onClick={ this.logOut } className="menu-item"><em className="fa fa-sign-out" />Cerrar Sesión</a></li>
                    </DropdownMenu>
                </ul>
            </div>
          </nav>
        </header>

        <Slide id="bubble"
            isOpen={this.state.verMenu}
            pageWrapId={"page-wrap"}
            onStateChange={(state) => this.handleStateChange(state)}
            outerContainerId={"outer-container"} >
                    <Menu {...this.props} toggleMenu={this.toggleMenu}/>

        </Slide>
      </div>
    );
  }
}
Navbar.propTypes = {
};

export default Navbar;

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push, go } from 'react-router-redux';
const url = 'cuenta';

const LOADER_ENF = 'LOADER_ENF';
const SET_DATA_ENF = 'SET_DATA_ENF';
const SET_DETALLE_ENF = 'SET_DETALLE_ENF';
const SET_PAGE_ENF = 'SET_PAGE_ENF';
const SET_UPDATE_DATA_ENF ='SET_UPDATE_DATA_ENF';
const SET_BUSCADOR_ENF = 'SET_BUSCADOR_ENF';
const SET_FILTRO_ENF = 'SET_FILTRO_ENF';

const SET_BUSCADORDETALLE_ENF = 'SET_BUSCADORDETALLE_ENF';
const SET_MODAL_ENF = 'SET_MODAL_ENF';

const eliminarCuentaUsuario = (id_cuenta) => (dispatch) => {
    Swal({
        title: '¿Desea eliminar la cuenta?',
        text: 'No puedes deshacer esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/eliminarCuenta/${id_cuenta}`).then((data) => {
                dispatch({type: LOADER_ENF, cargando: false})
                Swal('Éxito', 'Cuenta eliminada correctamente.', 'success')
                .then(() => {
                    dispatch(listar(1));
                })
            }).catch((error) => {
                dispatch({type: LOADER_ENF, cargando: false})
                Swal(
                    'Error',
                     (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).finally(() => {
                dispatch({type: LOADER_ENF, cargando: false})
            });
        }
    });
}

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ENF, cargando: true});
    const store = getStore();
    const search = store.enfermeria.buscador;
    const filtro = store.enfermeria.filtro_enfermeria;
    let params = {page, search, pagado: false};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_ENF, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ENF, data});
            dispatch({type: SET_PAGE_ENF, page});
        }
        dispatch({type: LOADER_ENF, cargando: false});
    })
};
const listar_detalle = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ENF, cargando: true});
    const store = getStore();
    const search = store.enfermeria.buscador_detalle;
    const updateData = store.enfermeria.updateData;
    let params = {page, search};
    params['orden__id'] = updateData.id
    params['ordering']='-creado'
    api.get(`detalle_movimiento`, params).catch((error) => {
        dispatch({type: LOADER_ENF, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DETALLE_ENF, detalle: data});
        }
        dispatch({type: LOADER_ENF, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_ENF, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ENF, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/enfermeria_cuentas'))
        }
    }).then((data) => {
        dispatch({type: LOADER_ENF, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_ENF, updateData: data});
            dispatch(listar_detalle(1));
        }
    })
}

const quitarMedicamento = (id) => (dispatch, getStore) => {
    Swal({
        title: '¿Desea eliminar este elemento?',
        text: 'No puedes deshacer esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            const data_send = {id};
            const store = getStore();
            const updateData = store.enfermeria.updateData;
            api.post(`${url}/quitar/${updateData.id}`, data_send).then((data) => {
                dispatch({type: LOADER_ENF, cargando: false})
                Swal('Éxito', 'Cargo eliminado correctamente.', 'success')
                .then(() => {
                    dispatch(listar_detalle());
                    dispatch(detail(updateData.id))
                })
            }).catch((error) => {
                dispatch({type: LOADER_ENF, cargando: false})
                Swal(
                    'Error',
                     (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).finally(() => {
                dispatch({type: LOADER_ENF, cargando: false})
            });
        }
    });
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.medicamentos.values;
    const store = getStore();
    const updateData = store.enfermeria.updateData;
    formData.producto = formData.producto.id;
    dispatch({type: LOADER_ENF, cargando: true})
    api.post(`${url}/agregar/${updateData.id}`, formData).then((data) => {
        dispatch({type: LOADER_ENF, cargando: false});
        let texto = '';
        if (data.servicio)
            texto += '<strong style="font-weight: bold">SERVICIO AGREGADO</strong>';
        else
            texto += '<strong style="font-weight: bold">PRODUCTO AGREGADO</strong>';
        texto += '<ul style="font-size: 15px; text-align: left">';
        for (let i = 0; i < data.productos.length; i++) {
            texto += '<li>' + data.productos[i] + '.';
            texto += '</li>';
        }
        texto += '<ul>';
        texto += '</ul>';
        Swal('Éxito', texto, 'success')
            .then(() => {
                dispatch(detail(updateData.id));
                dispatch(listar_detalle());
                dispatch(closeModal());
            });
    }).catch((error) => {
        dispatch({type: LOADER_ENF, cargando: false})
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_ENF, cargando: false})
    });
};
const aplicarMedicamento = () => (dispatch, getStore) => {
    const formData = getStore().form.aplicarmedicamento.values;
    const store = getStore();
    const updateData = store.enfermeria.updateData;
    dispatch({type: LOADER_ENF, cargando: true})
    api.post(`${url}/aplicarMedicamento/${formData.id}`, formData).then((data) => {
        dispatch({type: LOADER_ENF, cargando: false})
        Swal('Éxito', 'Se han aplicado el medicamento.', 'success')
        .then(() => {
            dispatch(listar_detalle());
            dispatch(closeModal());
        })
    }).catch((error) => {
        dispatch({type: LOADER_ENF, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_ENF, cargando: false})
    });
}
const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_ENF, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ENF, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/enfermeria_cuentas'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ENF, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/enfermeria_cuentas'));
        }
    })
}
const anular_cargo = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.anulacion.values);
    dispatch({type: LOADER_ENF, cargando: true});
    api.put(`${url}/anular_cargo/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ENF, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Cargo anulado correctamente.',
                'success'
            ).then(() => {
                dispatch(listar_detalle(1));
                dispatch(closeModal());
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ENF, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/enfermeria_cuentas'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_ENF, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ENF, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_ENF, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const setExistencia = existencia => (dispatch, getStore) => {
    const form = getStore().form.medicamentos;
    dispatch(initializeForm('medicamentos', {...form.values, existencia}));
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_ENF, buscador: search});
    dispatch(listar(1));
};
const set_search_detalle = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADORDETALLE_ENF, buscador_detalle: search});
    dispatch(listar_detalle(1));
}
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_ENF, filtro_enfermeria: filtro});
    dispatch(listar(1));
};

const openModal = () => (dispatch, getStore) => {
    dispatch({
        type:SET_MODAL_ENF,
        toggleModal: true
    })
}
const closeModal = () => (dispatch, getStore) => {
    dispatch({
        type:SET_MODAL_ENF,
        toggleModal: false
    })
}
const regresar = () => (dispatch, getStore) => {
    dispatch(go(-1));
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    listar_detalle,
    set_search_detalle,
    setExistencia,
    openModal,
    closeModal,
    aplicarMedicamento,
    anular_cargo,
    regresar,
    quitarMedicamento,
    eliminarCuentaUsuario,
};
export const reducer = {
    [LOADER_ENF]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_ENF]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_ENF]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_ENF]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_ENF]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_ENF]: (state, { filtro_enfermeria }) => {
        return {...state, filtro_enfermeria }
    },
    [SET_DETALLE_ENF]: (state, { detalle }) => {
        return {...state, detalle }
    },
    [SET_BUSCADORDETALLE_ENF]: (state, { buscador_detalle }) => {
        return {...state,  buscador_detalle}
    },
    [SET_MODAL_ENF]: (state, { toggleModal }) => {
        return {...state, toggleModal }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    buscador_detalle: '',
    filtro_enfermeria: null,
    updateData: {},
    detalle: [],
    toggleModal: false
};
export default handleActions(reducer, initialState)

import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Table from 'Utils/Grid';
import { BreakLine } from '../../../Utils/tableOptions';
import { formatoMoneda, formatSelect, dateFormatter, formatNombre } from '../../../Utils/renderField/renderReadField';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import ToolbarSearch from 'Utils/Toolbar/ToolbarSearch';

function formatCategoria(cell){
    if(cell){
        return <span>{cell}</span>
    }
    return <span>Sin categoria</span>
}

export default class DetalleCompra extends Component {
    state = {
        activeTab: 'detalle'
    }
    componentWillMount() {
        this.props.getDetalleCompra(this.props.page);
    }
    toggle = (tab) => {
        if (this.state.tab !== tab) {
            this.setState({
                activeTab: tab
            })
            if (tab === 'detalle') {
                this.props.getDetalleCompra(this.props.page);
            } else {
                this.props.getCategoriaCompra();
            }
        }
    }
    render() {
        const { cargando, data_detalle, data_categoria, page } = this.props;
        return (
            <div className="mt-5">
                <ToolbarSearch 
                    titulo={" "} 
                    buscar={this.props.search} 
                    buscador={this.props.buscador} 
                    placeholder={this.props.placeholder}
                />
                <Nav tabs className="col-12  px-0 ">
                    <NavItem className="col-3 col-md-2 pl-0 pr-0">
                        <NavLink
                            className={classNames('py-2 text-center', { active: this.state.activeTab === 'detalle' })}
                            onClick={() => { this.toggle('detalle'); }}>
                            <h5>Detalle</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem className="col-3 col-md-2 pl-0 pr-0">
                        <NavLink
                            className={classNames('py-2 text-center', { active: this.state.activeTab === 'categoria' })}
                            onClick={() => { this.toggle('categoria'); }}>
                            <h5>Categoría</h5>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="mt-0">
                    <TabPane tabId={"detalle"}>
                        <Table
                            onPageChange={this.props.getDetalleCompra}
                            data={data_detalle}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={dateFormatter}
                                dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatSelect}
                                dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="cuenta" dataSort>Cuenta</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="categoria_gasto" dataSort>Categoría</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'center'}
                                dataFormat={formatoMoneda}
                                dataField="monto" dataSort>Monto</TableHeaderColumn>

                        </Table>
                    </TabPane>
                    <TabPane tabId={"categoria"}>
                        <Table
                            onPageChange={this.props.getCategoriaCompra}
                            data={data_categoria}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="categoria_gasto"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatCategoria}
                                dataField="nombre_categoria" dataSort>Categoría</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'center'}
                                dataFormat={formatoMoneda}
                                dataField="monto" dataSort>Monto</TableHeaderColumn>
                        </Table>
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

import React from 'react';
import classNames from 'classnames'
import moment from 'moment';
import DatePicker from 'react-date-picker';
export default class renderDate extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        date: '',
      }
  }

  onFocusChange({ focused }) {
      this.setState({ dpFocused: focused })
  }

  render() {
      const { input, name,  className, numberOfMonths, placeholder, id, meta: { touched, error } } = this.props;
      const invalid = touched && error;

      return (
          <div className={classNames(``,`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={(value) => {
                  input.onChange( value );
                  /*this.setState({ date: value })*/
                }}
                name={ name }
                locale='es-GT'
                value={typeof (input.value) === "string" && input.value != '' ? new Date(input.value): input.value}/>
                  {invalid && <div className="invalid-feedback">
                  {error}
              </div>}
          </div>
      )
  }
}

import moment from 'moment';
import React, { Component } from 'react'
import Titulo from './Titulo';
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import Modal from 'react-responsive-modal';
import { BreakLine } from 'Utils/tableOptions';
import FormIngreso from './Formularios/FormIngreso';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { dateFormatter, formatoMoneda } from 'Utils/renderField/renderReadField';


const   isExpandableRow=()=> {
    return true;
}
function entregadaFormater(cell) {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times"/>
}
export default class Ingreso extends Component {
    expandComponent = (row) => {
        let data = row && row.productos ? row.productos : [];
        let movimientos = _.cloneDeep(data);
        // const movimientos = []
        return(
            <div className=" tabla-adentro">
                <BootstrapTable
                    headerStyle={ { backgroundColor: '#aacfda' } }
                    data={ movimientos }>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                hidden={true}
                                dataAlign="center"
                                width={'90px'}>Ingresadas a bodega</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre_producto"
                                dataAlign="center"
                                width={'90px'}>Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cantidad"
                                >Cantidad</TableHeaderColumn>

                </BootstrapTable>
            </div>

        )
    }
    botonModal = (id, row) => {
        return (<button
                    className="btn btn-success-outline"
                     onClick={(e)=>{
                         e.preventDefault();
                         this.props.openModalIngreso();
                     }}
                      alt="Ver fracciones" >Ingresar producto </button>)
    }
  render() {
      const { cargando, pagos } = this.props;
    let data = {
        count: 0,
        results: pagos
    }
    return (
      <div>
        {
                this.props.modalIngreso && (
                    <Modal open={this.props.modalIngreso} onClose={this.props.closeModalIngreso} >
                        <div  style={{ Width: '100%' }}>
                            <div className="modal-header">
                                <div className="panel-body col-md-12">
                                    <div className="borde-abajo pb-2">
                                        <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                        <span className="ml-2 text-uppercase text-titulo"><strong>Registrar Ingreso</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <FormIngreso

                                    closeModal={this.props.closeModalIngreso} />
                            </div>
                        </div>
                    </Modal>
                )
            }
          <div className="col-md-12 mt-4 mb-3">
            <Titulo icono={iconsh.crudOc} titulo={'Ingreso Orden de compra'} />
          </div>
          <Table
                data={data}
                loading={cargando}
                expandableRow={isExpandableRow}
                expandComponent={this.expandComponent}
                page={1}>
                <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    hidden={true}
                    dataAlign="right"
                    dataFormat={activeFormatter({ adicional:this.botonModal })}>Acciones</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="no_orden" dataSort>Orden de pago</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={dateFormatter}
                    dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatoMoneda}
                    dataAlign={'right'}
                    dataField="monto" dataSort>Pago</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={entregadaFormater}
                    dataAlign={'center'}
                    dataField="pago_completo" dataSort>Pagada</TableHeaderColumn>

            </Table>

      </div>
    )
  }
}

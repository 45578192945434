import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';

import moment from 'moment';
import { dateFormatter } from '../../../common/components/Utils/renderField/renderReadField'

const url = 'reporte';

const LOADER_RGAN = 'LOADER_RGAN';
const SET_DATA_RGAN = 'SET_DATA_RGAN';
const SET_DATAGRAFICA_RGAN = 'SET_DATAGRAFICA_RGAN';
const SET_TOTALVENTA_RGAN = 'SET_TOTALVENTA_RGAN';
const SET_TOTALGASTO_RGAN = 'SET_TOTALGASTO_RGAN';
const SET_TOTALGANANCIA_RGA = 'SET_TOTALGANANCIA_RGA';


const SET_PAGE_RGAN = 'SET_PAGE_RGAN';

const SET_FILTROCUENTA_RGAN = 'SET_FILTROCUENTA_RGAN';


const SET_STARTDATE_RGAN = 'SET_STARTDATE_RGAN';
const SET_ENDDATE_RGAN = 'SET_ENDDATE_RGAN';

function obtenerFechas(dateStart, dateEnd){
    let params = {};
    if (dateStart) {
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    return params;
}
const getGraficaGanancia = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RGAN, cargando: true });
    const store = getStore();
    const search = store.rganancia.buscador;
    const f_cuenta = store.rganancia.filtro_cuentas;

    let dateStart = store.rganancia.dateStart;
    let dateEnd = store.rganancia.dateEnd

    let params = { page, search };

    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/grafica_ganancia`, params).catch((error) => {
        dispatch({ type: LOADER_RGAN, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATAGRAFICA_RGAN, data_grafica: data.cuenta });
            dispatch({type: SET_TOTALGANANCIA_RGA, total_ganancia: data.total_ganancias})
            dispatch({type: SET_TOTALGASTO_RGAN, total_gasto: data.total_gastos})
            dispatch({type: SET_TOTALVENTA_RGAN, total_venta: data.total_ventas})
            dispatch({type: SET_DATA_RGAN, data: data.detalle});
        }
        dispatch({ type: LOADER_RGAN, cargando: false });
    })
};
const setCuenta = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTROCUENTA_RGAN, filtro_cuentas: filtro});
    dispatch(getGraficaGanancia());
}
const setDateStart = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_STARTDATE_RGAN, dateStart: date });
    dispatch(getGraficaGanancia());

}
const setDateEnd = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_ENDDATE_RGAN, dateEnd: date });
    dispatch(getGraficaGanancia());
}
export const actions = {
    setCuenta,
    setDateEnd,
    setDateStart,
    getGraficaGanancia
};
export const reducer = {
    [LOADER_RGAN]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_RGAN]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_RGAN]: (state, { page }) => {
        return {...state, page }
    },
    [SET_STARTDATE_RGAN]: (state, { dateStart }) => {
        return {...state, dateStart }
    },
    [SET_ENDDATE_RGAN]: (state, { dateEnd }) => {
        return {...state, dateEnd }
    },
    [SET_FILTROCUENTA_RGAN]: (state, { filtro_cuentas }) => {
        return {...state, filtro_cuentas }
    },
    [SET_TOTALVENTA_RGAN]: (state, { total_venta }) => {
        return {...state, total_venta }
    },
    [SET_TOTALGANANCIA_RGA]: (state, { total_ganancia }) => {
        return {...state, total_ganancia }
    },
    [SET_TOTALGASTO_RGAN]: (state, { total_gasto }) => {
        return {...state, total_gasto }
    },
    [SET_DATAGRAFICA_RGAN]: (state, { data_grafica }) => {
        return {...state, data_grafica }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: [],
    data_grafica: [],
    buscador: '',
    filtro_cuentas: null,
    updateData: {},
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
    total_venta: 0,
    total_ganancia:0,
    total_gasto: 0
};
export default handleActions(reducer, initialState)

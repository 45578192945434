import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/login';
import { actions as actionsSolicitudes } from '../../../redux/modules/Compras/compras';
import { actions as actionsEB } from '../../../redux/modules/Bodega/estadobodega';
import Navbar from './Navbar';


const ms2p = (state) => {
  return {
    ...state.login,
  };
};

const md2p = { ...actions, ...actionsSolicitudes, ...actionsEB };

export default connect(ms2p, md2p)(Navbar);

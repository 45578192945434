import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';

export default class ToolbarFiltroFecha extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
  render() {
    return (
      <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-2 row">
                <div className="titulo d-flex align-items-center">
                    <img width={35} className="" src={this.props.icono} alt="Usuario" />
                    <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                </div>
            </div>
            <div className="col-md-3 form-group">
                <label className="m-0">Fecha Inicial</label>
                <SingleDatePicker
                    placeholder={"Fecha Inicio"}
                    date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                    focused={this.state.focusedStart}
                    isOutsideRange={() => false}
                    onDateChange={(value) => {
                       this.setState({dateStart:value})
                       this.props.setDateStart(value)
                    }}
                    onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                    numberOfMonths={1}
                    id={"dateStart"}
                />
            </div>
            <div className="col-md-3 form-group">
                <label className="m-0">Fecha Final</label>
                <SingleDatePicker
                        placeholder={"Fecha Inicio"}
                        date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                        focused={this.state.focusedEnd}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                           this.setState({dateEnd:value})
                           this.props.setDateEnd(value);
                        }}
                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                        numberOfMonths={1}
                        id={"dateStart"}
                    />
            </div>
            <div className="col-lg-4 col-md-4 text-right search d-flex align-items-center">
                {(this.props.buscar !== undefined) && (
                <Search buscar={this.props.buscar} buscador={this.props.buscador}/>
                )}
            </div>
      </div>
    )
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import moment from 'moment';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderTimeField } from 'Utils/renderField/renderField';
import renderDatePicker  from 'Utils/renderField/renderDatePicker';
import { api } from 'api/api';
 let pacientes = [];
 const getPacientes = (search)  =>{
    return api.get("paciente",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    pacientes = [];
                    data.results.forEach(x=>{
                        pacientes.push({value: x.id, label: x.nombre})
                    })
                    return pacientes
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

let CuentaForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Paciente" className="m-0">Paciente*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`paciente`}
                                    valueKey="value"
                                    labelKey="label"
                                    label="label"
                                    component={renderSearchSelect}
                                    loadOptions={getPacientes}
                                    />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha" className="m-0">Fecha ingreso*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha_ingreso"
                                    component={renderDatePicker}
                                    placeholder="fecha"
                                    type="fecha"
                                    className=""
                                    numberOfMonths={1}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="hora" className="m-0">Hora*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`hora`}
                                    component={renderTimeField}
                                    required={ true }/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" className="btn btn-secondary m-1"
                                    onClick={e =>{
                                        e.preventDefault();
                                        props.regresar();
                                    }}
                                    >Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
CuentaForm = reduxForm({
    form: 'cuenta',
    validate: data => {
        return validate(data, {
            'paciente': validators.exists()('Campo requerido.'),
            'fecha_ingreso': validators.exists()('Campo requerido.'),
            'hora': validators.exists()('Campo requerido.'),
        })
    },
    initialValues:{
        fecha_ingreso: new Date(),
        hora: new Date()
    }
})(CuentaForm);
export default CuentaForm

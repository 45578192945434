import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';
import { iconsh } from  "icons";
import {Animated} from "react-animated-css";



let Paso2Form = props => {
    const { handleSubmit, bodega, caja_nombre } = props;
    return(
        <Animated animationIn="fadeInUp"  >
            <form onSubmit={handleSubmit} className="fondo-autorizacion pt-5 pb-3">
                <div className="form-group grid-container m-0">
                    <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                        <div className="col-md-12 row m-0">
                            <div className="col-md-12 d-flex justify-content-center">
                                <img src={iconsh.crud_bodegas}  height={25}/>
                            </div>
                            <div className="col-md-12">
                                <h3 className="text-center">{caja_nombre}</h3>
                            </div>
                        </div>
                        <h2 className="text-uppercase text-azul">Autorizar cierre</h2>
                        <div className="col-md-12 row d-flex justify-content-center m-0">
                            <div className="col-md-6">
                                <div className="col-12">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="usuario" className="m-0 text-center">Usuario*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="usuario" component={renderField} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="password" className="m-0 text-center">Contraseña*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="password" component={renderField} type="password" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <button type="submit" className="btn btn-success-outline  m-1">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Animated>
    )
};
Paso2Form = reduxForm({
    form: 'autorizacion',// <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'password': validators.exists()('Campo requerido.'),
            'usuario': validators.exists()('Campo requerido.'),
        })
    }
})(Paso2Form);
export default Paso2Form

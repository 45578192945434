import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector,FieldArray, FormSection } from 'redux-form';
import { renderField, renderSwitch, renderFieldCheck, renderNoAsyncSelectField, renderNumber} from '../../../Utils/renderField/renderField';
import { nivelesOptions } from '../../../../utility/constants';
import { api } from 'api/api';

let proyectos = []
const getUsuarios = (search)  =>{
    return api.get("users",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    proyectos = data.results
                    return data.results
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}


let UsuarioForm = props => {
    const { handleSubmit } = props;
    const { ver_permisos,
        ver_area,
        ver_enfermeria,
        ver_reportes,
        ver_admin,
    } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="first_name" className="m-0">Nombre*:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="first_name" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">Usuario*:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="username" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Correo:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono*:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" numberFormat="#### ####" component={renderNumber}
                                   type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className={`col-md-6 p-0 `}>
                        {
                            props.mostrar_pass && (
                                <div className="col-md-12 p-0 m-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="password" className="m-0">Contraseña*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="password" component={renderField} type="text" className="form-control" />
                                    </div>
                                </div>
                            )
                        }
                        {
                            props.editando && (
                                <div className="col-md-12">
                                    <button type="button" className="btn-s  btn-success pt-0" onClick={props.verPassword}>
                                    {
                                    props.mostrar_pass ? <span>Ocultar Campo.</span> : <span>Cambiar contraseña.</span>
                                    }
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="puestos" className="m-0">Permisos*:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name={`puestos`}
                                valueKey="value"
                                labelKey="label"
                                label="acceso"
                                component={renderNoAsyncSelectField}
                                options={nivelesOptions}
                                />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 row m-0">
                         <div className="col-auto text-gris">
                            Autoriza cajas:
                        </div>
                        <div className="col-md-4">
                            <Field name="permiso_cajas" component={renderSwitch} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                {
                    ver_permisos && (
                        <div className="row">
                            <div className=" col-md-12 p-0 form-group custom-control custom-checkbox">
                                <FormSection name="permisos">
                                    <div className="col-md-12 row m-0">
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="ordenes_compra" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Ordenes de compra</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="solicitudes" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Solicitudes</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="devoluciones" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Devoluciones</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="caja" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Caja</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="area" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Área</label>
                                                </div>
                                            </div>
                                            {
                                                ver_area && (
                                                    <Fragment>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="mi_area" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Mi área</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="areas" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Áreas</label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="enfermeria" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 col form-group ">
                                                    <label htmlFor="class">Enfermeria</label>
                                                </div>
                                            </div>
                                            {
                                                ver_enfermeria && (
                                                    <Fragment>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="pacientes" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Pacientes</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="cuentas_pacientes" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Cuentas Pacientes</label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="gastos" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Gastos</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="reportes" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Reportes</label>
                                                </div>
                                            </div>
                                            {
                                                ver_reportes && (
                                                    <Fragment>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rcuentas" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Cuentas</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rcomprasventas" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Compras y ventas</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rganancias" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Ganancias</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rcaja" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Caja</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rmovbodega" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Mov bodega</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rvencimientos" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Vencimientos</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rporpagar" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Cta por pagar</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="rbitacora" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Bitácora</label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                        <div className="col-md-6 col-xl-4 p-0">
                                            <div className="row col-12 m-0">
                                                <div className="col-auto m-0 form-group label">
                                                    <Field name="ver_admin" component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="Correo"/>
                                                </div>
                                                <div className="ml-3 ml-sm-0 col form-group ">
                                                    <label htmlFor="class">Admin</label>
                                                </div>
                                            </div>
                                            {
                                                ver_admin && (
                                                    <Fragment>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="productos" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Productos</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="usuarios" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Usuarios</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="proveedores" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Proveedores</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="clientes" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Clientes</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="categoria" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Categoria</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="cuentas" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Cuentas</label>
                                                            </div>
                                                        </div>
                                                        <div className="row col-12 m-0 ml-3">
                                                            <div className="col-auto m-0 form-group label">
                                                                <Field name="configuracion" component={renderFieldCheck}
                                                                    type="checkbox"
                                                                    className="form-control"
                                                                    placeholder="Correo"/>
                                                            </div>
                                                            <div className="ml-3 ml-sm-0 col form-group ">
                                                                <label htmlFor="class">Configuración</label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                    </div>

                                </FormSection>

                            </div>

                    </div>
                    )
                }
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/admin_usuarios">Cancelar</Link>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
UsuarioForm = reduxForm({
    form: 'usuario',
    validate: data => {
        return validate(data, {
            'first_name': validators.exists()('Campo requerido.'),
            'puestos': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'username': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
        })
    }
})(UsuarioForm);
const selector = formValueSelector('usuario');
UsuarioForm = connect(state => {
    const acceso = selector(state, "puestos");
    const permisos = selector(state, "permisos");
    let ver_permisos = false;
    let ver_area = false;
    let ver_enfermeria = false;
    let ver_reportes = false;
    let ver_admin = false;


    if (acceso == 80){
        ver_permisos = true;
    }
    if(permisos){
        if(permisos.area){
            ver_area=true;
        }
        if(permisos.enfermeria){
            ver_enfermeria = true;
        }
        if(permisos.reportes){
            ver_reportes = true;
        }
        if(permisos.ver_admin){
            ver_admin = true;
        }
    }
    return {
        ver_permisos,
        ver_area,
        ver_enfermeria,
        ver_reportes,
        ver_admin,
    };
})(UsuarioForm);
export const UsuarioUpdateForm = reduxForm({
    form: 'usuario',
    validate: data => {
        return validate(data, {
            'first_name': validators.exists()('Campo requerido.'),
            'puestos': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'username': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(UsuarioForm);

export default UsuarioForm

import Table from 'Utils/Grid';
import React, { Component, Fragment } from 'react'
import { iconsh, EXTRAS } from "icons";
import Modal from 'react-responsive-modal';
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderBodega from 'Utils/Header/HeaderBodega';
import ReajusteForm from './formularios/ReajusteForm';
import { BreakLine } from '../../../Utils/tableOptions';
import ToolbarBodega from 'Utils/Toolbar/ToolbarBodega';
import { activeFormatter } from 'Utils/Acciones/Acciones';
import AutorizacionDoble from '../../../Utils/Autorizacion/AutorizacionDoble';
import { formatoMoneda, dateFormatter } from '../../../Utils/renderField/renderReadField';


function formatNombre(cell, row){
    return <span className={`${row.ya_vencidos > 0 ? 'text-danger' :''}`}>{cell}</span>
}
function formatAlertaVencimiento(cell, row){
    if(Number(row.alerta_vencimiento)  > 0){
        return <div className="col-md-12 row m-0 p-0">
            <div className="col-md-6 p-0">
                <img src={EXTRAS.vencimiento1} height={'30px'} alt="Ya vencido" />
            </div>
            <div className={`${row.alerta_minima ? 'background-danger': ''} col-md-6 p-0 pr-2 pt-2 text-primary{`}  >
                <span className="font-weight-bold text-primary">{cell}</span>
            </div>
        </div>
    }else{
        return <div className="col-md-12 row m-0 p-0">
            <div className="col-md-6"></div>
            <div className={`${row.alerta_minima ? 'background-danger': ''} col-md-6 p-0 pr-2 pt-2 text-primary{`} >
                <span className="font-weight-bold text-primary">{cell}</span>
            </div>
        </div>
    }

}
function formatLote(cell, row) {
    if (cell) {
        return <span className={`${Number(row.meses_vencimiento) == 1 ? 'text-danger': ''}`}>{cell.lote}</span>
    }
}
function formatExistencias(cell, row){
    return <span className={`${Number(row.meses_vencimiento) == 1 ? 'text-danger' : ''}`}>{cell}</span>
}
function formatVencimiento(cell, row) {
    if (cell) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                {
                    row.meses_vencimiento && Number(row.meses_vencimiento) <5 &&(
                        <Fragment>
                            {
                                Number(row.meses_vencimiento)  == 1 && (
                                    <img src={EXTRAS.vencimiento5} height={'30px'} alt="Ya vencido" />
                                )
                            }
                            {
                                row.meses_vencimiento === 2 && (
                                    <img src={EXTRAS.vencimiento4} height={'30px'} alt="vencimiento un mes" />
                                )
                            }
                            {
                                row.meses_vencimiento === 3 && (
                                    <img src={EXTRAS.vencimiento3} height={'30px'} alt="vencimiento dos meses" />
                                    )
                                }
                            {
                                row.meses_vencimiento  === 4 && (
                                    <img src={EXTRAS.vencimiento2} height={'30px'} alt="vencimiento tres meses" />
                                )
                            }


                        </Fragment>
                    )
                }
                <span className={`pl-2 ${Number(row.meses_vencimiento) == 1 ? 'text-danger': ''}`}>{dateFormatter(cell.fecha_vencimiento)}</span>
            </div>
        )
    }
}
const isExpandableRow = () => {
    return true;
}
export default class EstadoGrid extends Component {
    state = {
        expandidos: [],
        inventario: null
    }
    componentWillMount() {
        const {listar, detail, match, page } = this.props;
        listar(page);
        detail(match.params.id)
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id != this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
        }
    }
    cambiarTurno = () => {
        this.props.openModal()
    }
    handleExpand = (rowKey, isExpand) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        if (isExpand) {
            expandidos.push(rowKey)
        } else {
            let index = expandidos.findIndex(x => x === rowKey)
            expandidos.pop(index)
        }
        this.setState({ expandidos: expandidos })
    }
    rowClassNameFormat = (row, rowIdx) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        let registro = expandidos.find(x => x === row.id)
        return registro === undefined ? '' : 'background-row-selected';
    }
    botonModal = (id, row) => {
        return (
            <img onClick={e => {
                e.preventDefault();
                this.setState({
                    inventario: row
                })
                this.props.openModalReajuste();
            }}
                className="action-img"
                title="Editar"
                src={EXTRAS.reajustes} alt="reajuste o baja" />

        )
    }
    realizarReajuste = () => {

    }
    expandComponent = (row) => {
        let data = row && row.inventario ? row.inventario : [];
        let inventario = _.cloneDeep(data);
        // const movimientos = []
        // const { me } = this.props
        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    stripped={false}
                    data={inventario}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        dataAlign="center"
                        dataFormat={activeFormatter({ adicional: this.botonModal })}>Acciones</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="lote"
                        dataAlign="center"
                        dataFormat={formatLote}
                        width={'90px'}>Lote</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="lote"
                        dataFormat={formatVencimiento}
                        dataAlign="center"
                    >Vencimiento</TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataFormat={formatExistencias}
                        dataField="existencias">Existencias</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
            cambiarTurno,
        } = this.props;
        const {
            cargando,
            cargando_modal,
            page,
            data,
            modal_autorizacion,
            nombreBodega,
            updateData,
            encargado,
            notificacion,
            modal
        } = this.props;
        return (
            <div className="con-hijos">
                {
                    modal_autorizacion && (
                        <AutorizacionDoble
                            cargando={cargando_modal}
                            bodega={nombreBodega}
                            encargado={encargado}
                            modal={modal_autorizacion}
                            closeModal={this.props.closeModal}
                            verificarUsuario={this.props.verificarUsuario}
                            verificacion_usuario={this.props.verificacion_usuario}
                            onSubmit={cambiarTurno} />
                    )
                }
                <Modal open={this.props.modal} onClose={this.props.closeModalReajuste} >
                    <div style={{ Width: '100%' }}>
                        <div className="modal-header">
                            <div className="panel-body col-md-12">
                                <div className="borde-abajo pb-2">
                                    <img width={35} className="" src={iconsh.crud_bodegas} alt="Usuario" />
                                    <span className="ml-2 text-uppercase text-titulo"><strong>Reajuste o baja</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <LoadMask loading={cargando_modal} blur_1>
                                <ReajusteForm
                                    initialValues={{
                                        id: this.state.inventario ? this.state.inventario.id : 0,
                                        reajuste: 'true'
                                    }}
                                    inventario={this.state.inventario}
                                    cancelar={this.props.closeModalReajuste}
                                    onSubmit={this.props.reintegroBaja} />
                            </LoadMask>
                        </div>
                    </div>

                </Modal>
                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderBodega
                        bodega={`${nombreBodega}`}
                        updateData={updateData}
                        texto3={`Cambiar turno`}
                        funcion1={this.cambiarTurno}
                        ruta={`/area_estado/ingreso/${this.props.match.params.id}`}
                        me={this.props.me}
                        texto={`Ingresos y despachos`}
                        notificacion={notificacion}
                    />
                </div>
                <div className="container m-0 contenido-principal row d-flex justify-content-center mt-3">
                    <ToolbarBodega
                        cambiarFiltro={this.props.filtro}
                        filtro_bodega={this.props.filtro_eb}
                        bodega={updateData}
                        buscar={this.props.search}
                        buscador={this.props.buscador}
                        // tituloBuscador={"Buscar por: Producto"}
                        placeholder={"Buscar por: Producto"}
                    />
                    <div className="mt-3 col-md-12">
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            expandableRow={isExpandableRow}
                            expandComponent={this.expandComponent}
                            onExpand={this.handleExpand}
                            trClassName={this.rowClassNameFormat}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                hidden={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ editar: '/', eliminar: destroy })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="nombre_completo" dataSort>Producto</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal', width:90 }}
                                thStyle={{ whiteSpace: 'normal', width:90 }}
                                width={'90px'}
                                dataAlign={'right'}
                                className='tamanio-cuadrado'
                                dataFormat={formatAlertaVencimiento}

                                dataField="cantidad" dataSort>Existencias</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'center'}
                                dataFormat={formatoMoneda}
                                dataField="precio" dataSort>Precio</TableHeaderColumn>

                        </Table>
                    </div>
                </div>
            </div>

        )
    }
}


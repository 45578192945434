import React, { Component } from 'react'
import Titulo from './Titulo';
import { iconsh } from  "icons";
import { RenderCurrency, dateFormatter, formatoMoneda } from 'Utils/renderField/renderReadField';
import OrdenPagoForm from './Formularios/FormOrden';

export default class OrdenPago extends Component {

    cancelar = () =>{
        this.props.detail(this.props.match.params.id)
    }
  render() {
      const {dataActualizada} = this.props;
    return (
      <div>
          <div className="col-md-12 mt-4 mb-3">
            <OrdenPagoForm onSubmit={this.props.actualizarOrden} cancelar={this.cancelar} />
          </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter, activeFormatter2 }  from 'Utils/Acciones/Acciones'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { dateFormatter, formatSelect } from '../../../Utils/renderField/renderReadField';
import { PROCESO } from '../../../../utility/constants';
import { EXTRAS } from '../../../../../utility/icons';

function formatUsuario(cell){
    let nombre = '';
    if(cell){
        nombre = cell.first_name
    }
    return ( <span>{nombre}</span>)
}
function entregadaFormater(cell) {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times"/>
}
function pago_completoFormater(cell) {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times"/>
}
function rowClassNameFormat(row, rowIdx){
    return !row.ya_visto && row.no_proceso === PROCESO.RECHAZADO ? 'background-noleido' : '';
}
function rowClassNameVistos(row, rowIdx){
    return !row.ya_visto  ? 'background-noleido' : '';
}
function formatEstadoProceso(cell, row){
    return (
        <div>
            <span className={classNames(
                { 'text-primary': row.no_proceso === PROCESO.ENVIADO },
                { 'text-verde': row.no_proceso === PROCESO.BORRADOR },
                { 'text-danger': row.no_proceso === PROCESO.RECHAZADO }
                )}>
                {cell}
            </span>
            {
                row.no_proceso === PROCESO.RECHAZADO && (
                    <span className="pl-2">
                        <img src={EXTRAS.advertencia}   height={15}  width={15}/>
                    </span>
                )
            }

        </div>

    );
}
export default class OrdenesGrid extends Component {
    state = {
        activeTab: 'creadas'
    }
    componentWillMount(){
        this.props.listarTodo(this.props.page);
    }
    toggle = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab:tab
            });
            if(tab === 'creadas'){
                this.props.listarTodo(this.props.page);
            }else{
                this.props.listarAceptados(this.props.pageAceptadas);
            }
        }
    }
    accionesFormat = (cell, row) => {
        if(row.no_proceso == PROCESO.ENVIADO){
            return activeFormatter2({ ver: '/orden', cell, row})
        }else{
            return activeFormatter2({ editar: '/orden', eliminar: this.props.destroy, cell, row})

        }
    }
    accionesAceptadosFormat = (cell, row) => {
        if(row.pago_completo){
            return activeFormatter2({ ver: '/ordenes/aceptado', cell, row})
        }else{
            return activeFormatter2({  ver: '/ordenes/aceptado',  cell, row})

        }
    }
    botonModal = (id, row) => {
        return (<img className="action-img" title="Abonar"
                    src={require("../../../../../../assets/img/icons/pagos1.png")}
                     onClick={(e)=>{

                     }}
                      alt="Ver pagos" />)
    }
    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
            listarTodo,
            listarAceptados
        } = this.props;
        const {
            cargando,
            page,
            pageAceptadas,
            data,
            dataTodo,
            dataAceptados
        } = this.props;
        return (
        <div className="container m-0 contenido-principal row d-flex justify-content-center">
            <HeaderSimple
                texto={"Agregar Ordenes de compra"}
                ruta={"/orden"}
            />
            <ToolbarSimple
                titulo="Ordenes de compra"
                icono={iconsh.crudOc}
                buscar={this.props.search}
                buscador={this.props.buscador}
                // tituloBuscador="Buscar por: No. Orden, Proveedor, Emitida por."
                placeholder="Buscar por: No. Orden, Proveedor, Emitida por."
                />
            <div className="mt-3 col-md-12">
            <Nav tabs className="col-12  px-0 ">
                    <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center',{ active: this.state.activeTab === 'creadas' })}
                                onClick={() => { this.toggle('creadas'); }}>
                                <h5>Creadas</h5>
                            </NavLink>
                            {
                                this.props.notificacionR > 0 && (
                                    <span className={"bubble-alert-danger animated flash"}></span>
                                )
                            }
                        </NavItem>
                        <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center',{ active: this.state.activeTab === 'aceptadas' })}
                                onClick={() => { this.toggle('aceptadas'); }}>
                                <h5>Aceptadas</h5>
                            </NavLink>
                            {
                                this.props.notificacionA > 0 && (
                                    <span className={"bubble-alert-success animated flash"}></span>
                                )
                            }
                        </NavItem>
			        </Nav>
			        <TabContent activeTab={this.state.activeTab} className="mt-0">
                        <TabPane  tabId={"creadas"}>
                            <LoadMask loading={cargando} dark blur>
                                <Table
                                    onPageChange={listarTodo}
                                    data={dataTodo}
                                    loading={cargando}
                                    trClassName={rowClassNameFormat}
                                    page={page}>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={this.accionesFormat}>Acciones</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="no_orden" dataSort>No. orden</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatSelect}
                                        dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={dateFormatter}
                                        dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatUsuario}
                                        dataField="usuario" dataSort>Emitida por</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatEstadoProceso}
                                        dataField="proceso" dataSort>Estado</TableHeaderColumn>

                                </Table>
                            </LoadMask>
                        </TabPane>

                       <TabPane  tabId={"aceptadas"}>
                            <LoadMask loading={cargando} dark blur>
                                <Table
                                    onPageChange={listarAceptados}
                                    data={dataAceptados}
                                    loading={cargando}
                                    trClassName={rowClassNameVistos}
                                    page={pageAceptadas}>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={this.accionesAceptadosFormat}>Acciones</TableHeaderColumn>
                                     <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="no_orden" dataSort>No. orden</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={dateFormatter}
                                        dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatSelect}
                                        dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatUsuario}
                                        dataField="usuario" dataSort>Emitida por</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatUsuario}
                                        dataField="aceptado_por" dataSort>Aprobada por</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'center'}
                                        dataFormat={pago_completoFormater}
                                        dataField="pago_completo" dataSort>Pagada</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'center'}
                                        dataFormat={entregadaFormater}
                                        dataField="entregado" dataSort>Entregada</TableHeaderColumn>

                                </Table>
                            </LoadMask>
                        </TabPane>

			        </TabContent>


            </div>
        </div>
        )
    }
}

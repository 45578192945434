import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { formatoMoneda, checkFormatter, formatNombre } from '../../../Utils/renderField/renderReadField';

function formatCasa(cell, row){
    return cell ? <span>{cell.nombre}</span> : <span>---</span>
}
function presentacion(cell, row) {
    if(row.fraccionable) {
        return '<strong class="text-primary">FRACCIONADO</strong>'
    } else {
        return cell;
    }
}
const   isExpandableRow=()=> {
    return true;
}
export default class ProductoGrid extends Component {
    state = {
        activeTab: 'servicios'
    }
componentWillMount(){
    if(this.props.tab === 'servicios'){
        this.props.listar(this.props.page, false);
    }else{
        this.props.listar(this.props.page, true);
    }
}
toggle = (tab) => {
    if(this.props.tab !== tab){
        this.props.setTab(tab)
        if(tab === 'servicios'){
            this.props.listar(1, false);
        }else{
            this.props.listar(1, true);
        }
    }
}
expandComponentServicio = (row) => {
    let data = row && row.kit ? row.kit: [];
    let _kit = _.cloneDeep(data);
    return (
        <div className=" tabla-adentro ">
            <br/>
            <BootstrapTable
                headerStyle={ { backgroundColor: '#aacfda' } }
                data={ _kit }>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    isKey={true}
                    dataField="nombre_producto" dataSort>Producto</TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

expandComponent = (row) => {
    let data = row && row.fracciones ? row.fracciones : [];
    let fracciones = _.cloneDeep(data)

    const { me } = this.props
    return(
        <div className=" tabla-adentro ">
            <br/>
            <BootstrapTable
                headerStyle={ { backgroundColor: '#aacfda' } }
                data={ fracciones }>

                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={this.formatGallina}
                    isKey={true}
                    dataField="presentacion" dataSort>{(row.fraccionable) ? 'Fracción': 'Producto'}</TableHeaderColumn>

                <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataFormat={formatoMoneda}
                            dataField="precio_descuento" dataSort>Precio descuento</TableHeaderColumn>
                <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataFormat={formatoMoneda}
                            dataField="precio" dataSort>Precio etiqueta</TableHeaderColumn>

            </BootstrapTable>
        </div>

    )
}
render() {
    const {
        listar,
        detail,
        create,
        update,
        destroy,
    } = this.props;
    const {
        cargando,
        page,
        data
    } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <HeaderSimple
            texto={"Agregar Producto"}
            ruta={"/admin_producto"}
        />
        <ToolbarSimple
            titulo="Productos y servicios"
            icono={iconsh.productos}
            buscar={this.props.search}
            buscador={this.props.buscador}
            placeholder={"Buscar..."}
            />
        <div className="mt-3 col-md-12">
            <Nav tabs className="col-12  px-0 ">
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        className={classNames('py-2 text-center',{ active: this.props.tab === 'servicios' })}
                        onClick={() => { this.toggle('servicios'); }}>
                        <h5>Servicios</h5>
                    </NavLink>
                </NavItem>
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        className={classNames('py-2 text-center',{ active: this.props.tab === 'productos' })}
                        onClick={() => { this.toggle('productos'); }}>
                        <h5>Productos</h5>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.props.tab} className="mt-0">
                <TabPane  tabId={"servicios"}>
                    <LoadMask loading={cargando} dark blur>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            expandableRow={isExpandableRow}
                            expandComponent={this.expandComponentServicio}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ editar: '/admin_producto', eliminar: destroy })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre" dataSort>Servicio</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatoMoneda}
                                dataAlign={'right'}
                                dataField="precio" dataSort>Costo</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'center'}
                                dataFormat={checkFormatter}
                                dataField="modificable_venta" dataSort>Precio modificable</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="cuenta" dataSort>Cuenta</TableHeaderColumn>
                        </Table>
                    </LoadMask>
                </TabPane>
            <TabPane  tabId={"productos"} className="con-hijos">
                    <LoadMask loading={cargando} dark blur>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            expandableRow={isExpandableRow}
                            expandComponent={this.expandComponent}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ editar: '/admin_producto', eliminar: destroy })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={presentacion}
                                dataField="presentacion" dataSort>Presentación</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="ingrediente_activo" dataSort>Ingrediente activo</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatCasa}
                                dataField="casa_medica" dataSort>Casa Médica</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="cuenta" dataSort>Cuenta</TableHeaderColumn>
                        </Table>
                    </LoadMask>
                </TabPane>
            </TabContent>

        </div>
    </div>
    )
    }
}

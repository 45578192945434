import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';

import moment from 'moment';
import { dateFormatter } from '../../../common/components/Utils/renderField/renderReadField'

const url = 'reporte';

const LOADER_RCOM = 'LOADER_RCOM';
const SET_DATA_RCOM = 'SET_DATA_RCOM';
const SET_PAGE_RCOM = 'SET_PAGE_RCOM';
const SET_UPDATE_DATA_RCOM = 'SET_UPDATE_DATA_RCOM';
const SET_BUSCADOR_RCOM = 'SET_BUSCADOR_RCOM';
const SET_FILTRO_RCOM = 'SET_FILTRO_RCOM';

// data necesaria para el reporte de compra
const SET_DATAGRAFICA_RCOM = 'SET_DATAGRAFICA_RCOM';
const SET_DATADETALLECOMPRA_RCOM = 'SET_DATADETALLECOMPRA_RCOM';
const SET_DATACATEGORIACOMPRA_RCOM = 'SET_DATACATEGORIACOMPRA_RCOM';

// data necesaria para el reporte de ventas
const SET_DATAGRAFICAVENTA_RCOM = 'SET_DATAGRAFICAVENTA_RCOM';
const SET_DATADETALLEVENTA_RCOM = 'SET_DATADETALLEVENTA_RCOM';
const SET_DATACUENTAVENTA_RCOM = 'SET_DATACUENTAVENTA_RCOM';

// data necesaria para el reporte de ventas

const SET_TAB_RCOM = 'SET_TAB_RCOM';
const SET_MONTOTOTAL_RCOM = 'SET_MONTOTOTAL_RCOM';

// acctions para filtros
const SET_FILTROGASTO_RCOM = 'SET_FILTROGASTO_RCOM';
const SET_FILTROPROVEEDOR_RCOM = 'SET_FILTROPROVEEDOR_RCOM';
const SET_FILTROCUENTA_RCOM = 'SET_FILTROCUENTA_RCOM';


const SET_STARTDATE_RCOM = 'SET_STARTDATE_BITACORA';
const SET_ENDDATE_RCOM = 'SET_ENDDATE_BITACORA';

function obtenerFechas(dateStart, dateEnd){
    let params = {};
    if (dateStart) {
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    return params;
}

//////////////////////////////////////////////////////////////////////////
/// Acciones para la compra
//////////////////////////////////////////////////////////////////////////

const getGraficaCompra = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCOM, cargando: true });
    const store = getStore();
    const search = store.rcompra.buscador;
    const filtro = store.rcompra.filtro_rcom;
    const f_gasto = store.rcompra.f_gasto;
    const f_cuenta = store.rcompra.f_cuenta;
    const f_proveedores = store.rcompra.f_proveedores;

    let dateStart = store.rcompra.dateStart;
    let dateEnd = store.rcompra.dateEnd

    let params = { page, search };

    if(f_gasto){
        params['categoria_gasto__id'] = f_gasto.id;
    }
    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }
    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/grafica_compra`, params).catch((error) => {
        dispatch({ type: LOADER_RCOM, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATAGRAFICA_RCOM, data_grafica: data.cuenta });
            dispatch({type: SET_MONTOTOTAL_RCOM, monto_total: data.monto_total})
        }
        dispatch({ type: LOADER_RCOM, cargando: false });
    })
};
const getDetalleCompra = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCOM, cargando: true });
    const store = getStore();
    const search = store.rcompra.buscador;
    const filtro = store.rcompra.filtro_rcom;
    const f_gasto = store.rcompra.f_gasto;
    const f_cuenta = store.rcompra.f_cuenta;
    const f_proveedores = store.rcompra.f_proveedores;


    let dateStart = store.rcompra.dateStart;
    let dateEnd = store.rcompra.dateEnd

    let params = { page, search, compras: true };

    if (filtro) {
        params[''] = filtro
    }
    if(f_gasto){
        params['categoria_gasto__id'] = f_gasto.id;
    }
    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }
    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }



    if (dateStart) {
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({ type: LOADER_RCOM, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATADETALLECOMPRA_RCOM, data_detalle: data });
            dispatch({ type: SET_PAGE_RCOM, page: page })
        }
        dispatch({ type: LOADER_RCOM, cargando: false });
    })
};
const getCategoriaCompra = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCOM, cargando: true });
    const store = getStore();
    const search = store.rcompra.buscador;
    const filtro = store.rcompra.filtro_rcom;
    const f_gasto = store.rcompra.f_gasto;
    const f_cuenta = store.rcompra.f_cuenta;
    const f_proveedores = store.rcompra.f_proveedores;

    let dateStart = store.rcompra.dateStart;
    let dateEnd = store.rcompra.dateEnd

    let params = { page, search, cat_compras: true };

    if(f_gasto){
        params['categoria_gasto__id'] = f_gasto.id;
    }
    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }
    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }
    if (dateStart) {
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    api.get(`${url}/categoria_compra`, params).catch((error) => {
        dispatch({ type: LOADER_RCOM, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATACATEGORIACOMPRA_RCOM, data_categoria: data });
            dispatch({ type: SET_PAGE_RCOM, page: page })
        }
        dispatch({ type: LOADER_RCOM, cargando: false });
    })
};

//////////////////////////////////////////////////////////////////////////
/// Acciones para la venta
//////////////////////////////////////////////////////////////////////////
const getGraficaVenta = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCOM, cargando: true });
    const store = getStore();
    const search = store.rcompra.buscador;
    const f_cuenta = store.rcompra.f_cuenta;
    const f_proveedores = store.rcompra.f_proveedores;

    let dateStart = store.rcompra.dateStart;
    let dateEnd = store.rcompra.dateEnd

    let params = { page, search };

    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }
    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/grafica_venta`, params).catch((error) => {
        dispatch({ type: LOADER_RCOM, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATAGRAFICAVENTA_RCOM, data_grafica_venta: data.cuenta });
            dispatch({type: SET_MONTOTOTAL_RCOM, monto_total: data.monto_total})
        }
        dispatch({ type: LOADER_RCOM, cargando: false });
    })
};

const getDetalleVenta = (page=1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCOM, cargando: true });
    const store = getStore();
    const search = store.rcompra.buscador;
    const f_cuenta = store.rcompra.f_cuenta;
    const f_proveedores = store.rcompra.f_proveedores;

    let dateStart = store.rcompra.dateStart;
    let dateEnd = store.rcompra.dateEnd

    let params = { page, search };

    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }
    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/detalle_venta`, params).catch((error) => {
        dispatch({ type: LOADER_RCOM, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATADETALLEVENTA_RCOM, data_detalle_venta: data });
            dispatch({ type: SET_PAGE_RCOM, page: page })
        }
        dispatch({ type: LOADER_RCOM, cargando: false });
    })
}
const getCuentaVenta = (page=1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCOM, cargando: true });
    const store = getStore();
    const search = store.rcompra.buscador;
    const f_cuenta = store.rcompra.f_cuenta;
    const f_proveedores = store.rcompra.f_proveedores;

    let dateStart = store.rcompra.dateStart;
    let dateEnd = store.rcompra.dateEnd

    let params = { page, search };

    if(f_cuenta){
        params['cuenta__id'] = f_cuenta.id;
    }
    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/cuentas_venta`, params).catch((error) => {
        dispatch({ type: LOADER_RCOM, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATACUENTAVENTA_RCOM, data_cuenta_venta: data });
            dispatch({ type: SET_PAGE_RCOM, page: page })
        }
        dispatch({ type: LOADER_RCOM, cargando: false });
    })
}

const search = (search) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_BUSCADOR_RCOM, buscador: search });

    if(store.rcompra.tab == 'compras'){
        dispatch(getDetalleCompra())
        dispatch(getCategoriaCompra())
    }else{
        dispatch(getDetalleVenta(1));
        dispatch(getCuentaVenta(1));
    }
};

//filtros
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0) {
        filtro = null
    }
    dispatch({ type: SET_FILTRO_RCOM, filtro_rcom: filtro });
    dispatch(listar(1));
};
const setGasto = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTROGASTO_RCOM, f_gasto: filtro});
    dispatch(disparar_compra())
}
const setProveedor = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    const store = getStore();
    const {tab} = store.rcompra;
    dispatch({type: SET_FILTROPROVEEDOR_RCOM, f_proveedores: filtro});
    if (tab === "ventas"){
        dispatch(disparar_venta())
    } else{
        dispatch(disparar_compra())
    }
}

const setCuenta = (filtro) => (dispatch, getStore) => {
    const store = getStore();
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTROCUENTA_RCOM, f_cuenta: filtro});
    if(store.rcompra.tab == 'compras'){
        dispatch(disparar_compra());
    }else{
        dispatch(disparar_venta(1))
    }

}

const disparar_compra = () => (dispatch, getStore) => {
        dispatch(getDetalleCompra())
        dispatch(getCategoriaCompra())
        dispatch(getGraficaCompra(1));
}
const disparar_venta = () => (dispatch, getStore) => {
    dispatch(getGraficaVenta(1))
    dispatch(getDetalleVenta(1));
    dispatch(getCuentaVenta(1));
}

const reset_filtros_compra = (filtro) => (dispatch, getStore) => {
    dispatch({type: SET_FILTROCUENTA_RCOM, f_cuenta: null});
    dispatch({type: SET_FILTROGASTO_RCOM, f_gasto: null});
    dispatch({type: SET_FILTROPROVEEDOR_RCOM, f_proveedores: null});
    dispatch(disparar_compra());
}
const reset_filtros_venta = () => (dispatch, getStore) => {
    dispatch({type: SET_FILTROCUENTA_RCOM, f_cuenta: null});
    dispatch({type: SET_FILTROGASTO_RCOM, f_gasto: null});
    dispatch({type: SET_FILTROPROVEEDOR_RCOM, f_proveedores: null});
    dispatch(disparar_venta(1))
}

// acciones no comunes
const setDateStart = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_STARTDATE_RCOM, dateStart: date });
    if(store.rcompra.tab == 'compras'){
        dispatch(disparar_compra());
    }else{
        dispatch(disparar_venta(1))
    }
}
const setDateEnd = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_ENDDATE_RCOM, dateEnd: date });

    if(store.rcompra.tab == 'compras'){
        dispatch(disparar_compra());
    }else{
        dispatch(disparar_venta(1))
    }
}

const setTab = (tab) => (dispatch, getStore) =>  {
    dispatch({
        type: SET_TAB_RCOM,
        tab: tab
    });
}
export const actions = {
    search,
    filtro,
    setGasto,
    setCuenta,
    setProveedor,
    setDateEnd,
    setTab,
    setDateStart,
    getGraficaCompra,
    getDetalleCompra,
    reset_filtros_venta,
    reset_filtros_compra,
    getCategoriaCompra,
    getGraficaVenta,
    getCuentaVenta,
    getDetalleVenta
};
export const reducer = {
    [LOADER_RCOM]: (state, { cargando }) => {
        return { ...state, cargando }
    },
    [SET_DATA_RCOM]: (state, { data }) => {
        return { ...state, data }
    },
    [SET_PAGE_RCOM]: (state, { page }) => {
        return { ...state, page }
    },
    [SET_UPDATE_DATA_RCOM]: (state, { updateData }) => {
        return { ...state, updateData }
    },
    [SET_BUSCADOR_RCOM]: (state, { buscador }) => {
        return { ...state, buscador }
    },
    [SET_FILTRO_RCOM]: (state, { filtro_rcom }) => {
        return { ...state, filtro_rcom }
    },
    [SET_STARTDATE_RCOM]: (state, { dateStart }) => {
        return { ...state, dateStart }
    },
    [SET_ENDDATE_RCOM]: (state, { dateEnd }) => {
        return { ...state, dateEnd }
    },
    [SET_DATAGRAFICA_RCOM]: (state, { data_grafica }) => {
        return { ...state, data_grafica }
    },
    [SET_DATADETALLECOMPRA_RCOM]: (state, { data_detalle }) => {
        return { ...state, data_detalle }
    },
    [SET_DATACATEGORIACOMPRA_RCOM]: (state, { data_categoria }) => {
        return { ...state, data_categoria }
    },
    [SET_FILTROGASTO_RCOM]: (state, { f_gasto }) => {
        return { ...state, f_gasto }
    },
    [SET_FILTROPROVEEDOR_RCOM]: (state, { f_proveedores }) => {
        return { ...state, f_proveedores }
    },
    [SET_FILTROCUENTA_RCOM]: (state, { f_cuenta }) => {
        return { ...state, f_cuenta }
    },
    [SET_TAB_RCOM]: (state, { tab }) => {
        return {...state, tab }
    },
    [SET_DATAGRAFICAVENTA_RCOM]: (state, { data_grafica_venta }) => {
        return {...state, data_grafica_venta }
    },
    [SET_DATADETALLEVENTA_RCOM]: (state, { data_detalle_venta }) => {
        return {...state, data_detalle_venta }
    },
    [SET_DATACUENTAVENTA_RCOM]: (state, { data_cuenta_venta }) => {
        return {...state, data_cuenta_venta }
    },
    [SET_MONTOTOTAL_RCOM]: (state, { monto_total }) => {
        return {...state, monto_total }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_rcom: null,
    f_gasto: null,
    f_proveedores: null,
    f_cuenta: null,
    updateData: {},
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
    data_grafica: [],
    data_grafica_venta: [],
    monto_total:0,
    data_detalle_venta:{
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_cuenta_venta:{
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_detalle: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_categoria: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    tab: 'compras'
};
export default handleActions(reducer, initialState)

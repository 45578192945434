import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import LoadMask from 'Utils/LoadMask/LoadMask';
import classNames from 'classnames';
import OrdenPagoForm from './OrdenPago';
import Ingreso from './Ingreso';
import Precios from './precios';
export default class OrdenPagoContainer extends Component {
  state = {
    activeTab: 'op',
    dataIngreso: {
        productos:[]
    }
}
componentWillMount(){
    this.props.detail(this.props.match.params.id);
    this.props.setTab('op')
}
  toggle = (tab) => {
    if(this.props.tab !== tab){
        this.props.setTab(tab)
        if(tab === 'op'){
            // this.props.listarTodo(1);
        }
        else if(tab === 'ingreso'){
            // this.props.setIngresoData();
        }
    }
  }
  siguiente = () => {
    this.setState({activeTab: 'ingreso'});
    this.props.setTab('ingreso')
    this.props.setIngresoData();
  }
  anterior = () => {
    this.setState({activeTab:'op'});
  }
  render() {
    const { dataActualizada, cargando, tab } = this.props;
    return (
      <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12">
          <div className="col-md-12 borde-extremo-y">
          </div>
          {/* inicio de tabs */}
         <div className="col-md-12 mt-4 p-0">
            <Nav tabs className="col-12  px-0 ">
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        className={classNames('py-2 text-center',{ active: tab === 'op' })}
                        onClick={() => { this.toggle('op'); }}>
                        <h5>Orden de Pago</h5>
                    </NavLink>
                </NavItem>
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        className={classNames('py-2 text-center',{ active: tab === 'ingreso' })}
                        onClick={() => { this.toggle('ingreso'); }}>
                        <h5>Ingresos</h5>
                    </NavLink>
                </NavItem>
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        className={classNames('py-2 text-center',{ active: tab === 'precio' })}
                        onClick={() => { this.toggle('precio'); }}>
                        <h5>Precios</h5>
                    </NavLink>
                </NavItem>
			    </Nav>
			    <TabContent activeTab={tab} className="mt-0">
                    <TabPane  tabId={"op"}>
                        <LoadMask loading={cargando} dark blur>
                            {/* <OrdenCompra {...this.props} /> */}
                            <OrdenPagoForm {...this.props} onSubmit={this.siguiente} />
                        </LoadMask>
                    </TabPane>
                    <TabPane  tabId={"ingreso"}>
                        <LoadMask loading={cargando} dark blur>
                            <Ingreso {...this.props} />
                        </LoadMask>
                    </TabPane>
                    <TabPane  tabId={"precio"}>
                        <LoadMask loading={cargando} dark blur>
                            <Precios {...this.props} />
                        </LoadMask>
                    </TabPane>
			    </TabContent>
            </div>
          {/* fin de tabs */}
      </div>
    </div>
    )
  }
}

import { iconsh } from 'icons';
import Table from 'Utils/Grid';
import React, { Component } from 'react'
import { BreakLine } from 'Utils/tableOptions';
import { activeFormatter } from 'Utils/Acciones/Acciones';
import VentaForm from './VentaForm';
import Modal from 'react-responsive-modal';
import LoadMask from 'Utils/LoadMask/LoadMask';
import CuentasForm from './CuentasForm';
export default class Venta extends Component {
    state = {
        modal: false
    }
    setModal = () =>{
        this.setState({
            modal: !this.state.modal
        })
    }
    closeModal = () => {
        this.setState({
            modal: false
        })
    }
    openModal = () => {
        this.setState({
            modal: true
        })
    }
    render() {
        const { listar, cargando, page, destroy, carrito, cambioPrecioServicio } = this.props;
        let data = {
            results: [],
            count: 0
        }
        return (
            <div className="col-md-12 p-0">
                 <Modal open={this.state.modal} onClose={this.closeModal} >
                    <div style={{ Width: '100%' }}>
                        <div className="modal-header">
                            <div className="panel-body col-md-12">
                                <div className="borde-abajo pb-2">
                                    <img width={35} className="" src={iconsh.crud_caja_venta} alt="Usuario" />
                                    <span className="ml-2 text-uppercase text-titulo"><strong>Buscar cuenta</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <LoadMask loading={cargando} blur_1>
                                <CuentasForm cerrar={this.closeModal} onSubmit={this.props.abrir_cuenta}/>
                            </LoadMask>
                        </div>
                    </div>

                </Modal>
                <div className="col-md-12 p-2 fondo-inverso-navbar">
                    <h5 className="text-uppercase m-0 pr-3 text-right text-white">
                        <img className="mr-2 pb-1" src={iconsh.crud_caja_venta2} height={35} />
                        Pago
                    </h5>
                </div>
                <VentaForm
                    reset={this.props.borrar_carrito}
                    openModal={this.openModal}
                    cambioPrecioServicio={cambioPrecioServicio}
                    initialValues={{productos: carrito}} onSubmit={this.props.ir_pagos} />


            </div>
        )
    }
}

import React, {Component, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';
import { RenderCurrency } from 'Utils/renderField/renderReadField'



const renderProductos = ({fields, meta: {error, submitFailed }, deshabilitado, subtotal, descuento, precio_final, openModal, cambioPrecioServicio}) => (
    <div className="row col-sm-12 p-0 m-0">
                    <div className="row mt-3 col-md-12">
                        <div className="col-12">
                            <div className="row d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button
                                    className="btn btn-fondo m-1"
                                    onClick={e =>{
                                        e.preventDefault()
                                        openModal()
                                    }}
                                    type="button">
                                    Pagar cuenta
                                </button>
                                <button type="button"
                                    onClick={e => {
                                        e.preventDefault();
                                        fields.removeAll()
                                    }}
                                    className="btn btn-secondary m-1">
                                    Cancelar
                                </button>
                                <button type="submit" className="btn btn-primary m-1">Pagar</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 mt-3">
                        <div className="col-md-6 p-0 d-flex align-items-end">
                            <h3 className="text-left">COMPRA</h3>
                        </div>
                        <div className="col-md-6  p-0">
                            <div className="col-md-12 row p-0 m-0">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-primary">Subtotal</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    <RenderCurrency value={subtotal}  className={'text-gris  font-weight-bold'}/>
                                </div>
                            </div>
                            <div className="col-md-12 row p-0 m-0">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-gris">Descuento</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    -<RenderCurrency value={descuento}  className={'text-gris  font-weight-bold'}/>
                                </div>
                            </div>
                            <div className="col-md-12 row p-0 m-0">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-primary h5">TOTAL</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    <RenderCurrency value={precio_final}  className={'text-verde h4 font-weight-bold'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="col-sm-12 form-group np-r   mt-2 sin-borde p-0  over-h" style={{overflowX: 'auto'}}>
                            <table className="table table-sm table-responsive-sm m-0 table-striped">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} className="text-uppercase">Producto/Servicio</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center text-uppercase" >Cantidad</th>
                                    {/*<th style={{width: "15%", minWidth: '100px'}} className="text-center text-uppercase" >Precio</th>*/}
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-center text-uppercase">Sub Total</th>
                                    <th style={{width: "10%", minWidth: '160px'}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top text-gris">
                                            { fields.get(index).es_producto ? fields.get(index).nombre_completo : fields.get(index).nombre}
                                        </td>
                                        <td className="sin-borde-top text-center text-rosado">
                                            <Field
                                                disabled={deshabilitado}
                                                name={`${producto}.cantidad`}
                                                type="number"
                                                parse={value => Number(value)}
                                                addClass={"text-right"}
                                                component={renderField}
                                                placeholder="Representante"
                                            />
                                        </td>
                                        {/*
                                        <td className="sin-borde-top text-right">
                                        {prod.modificable_venta ?
                                                <RenderCurrency
                                                    value={prod.precio || 0}
                                                    display_type={prod.modificable_venta ? 'input' : 'text'}
                                                    className={"w-75 text-right"}
                                                    change={cambioPrecioServicio}
                                                    dato={prod}
                                                />
                                            :
                                            <RenderCurrency value={prod.precio || 0} className={""} />
                                            }
                                        </td>
                                        */}
                                        <td className="sin-borde-top text-right">
                                            <RenderCurrency value={subtotal || 0}/>
                                        </td>
                                        <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                            {
                                                !deshabilitado && (
                                                    <button
                                                        type="button"
                                                        className=" btn-danger btn-tabla "
                                                        onClick={() =>{
                                                            fields.remove(index)
                                                        }}
                                                    >X</button>
                                                )
                                            }

                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                        </div>
    </div>
)

let VentaForm = props => {
    const { handleSubmit,cambioPrecioServicio, subtotal, precio_final, openModal } = props;
    let descuento = 0;
    if(subtotal > 0 && precio_final > 0){
        descuento = subtotal - precio_final;
    }
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">


                    <FieldArray name="productos"
                                openModal={openModal}
                                subtotal={subtotal}
                                precio_final={precio_final}
                                descuento={descuento}
                                component={renderProductos}
                                cambioPrecioServicio={cambioPrecioServicio}
                                />
                </div>
            </div>
        </form>
    )
};
VentaForm = reduxForm({
    form: 'venta',
    validate: data => {
        const errors ={};
        const ProductosArrayErrors = [];
        if(!data.productos || data.productos.length == 0){
            errors.productos = 'Debe de seleccionar productos'
        }else{
            data.productos.forEach( (producto, index) => {
                const productoErrors = {};
                if(!producto.cantidad){
                    productoErrors.cantidad = "Campo requerido";
                        ProductosArrayErrors[index] = productoErrors
                }else{
                    if(Number(producto.cantidad) <= 0){
                        productoErrors.cantidad = "Debe ser una cantidad mayor a 0.";
                        ProductosArrayErrors[index] = productoErrors
                    }
                    if(Number(producto.cantidad) > Number(producto.existencias)) {
                        productoErrors.cantidad = `No debe de ser mayor a las existencias (${producto.existencias})`;
                        ProductosArrayErrors[index] = productoErrors
                    }
                }

            })
        }
        if(ProductosArrayErrors.length){
            errors.productos = ProductosArrayErrors;
        }
        return errors;
    },
    enableReinitialize: true

})(VentaForm);
const selector = formValueSelector('venta');
VentaForm = connect(state => {
    const productos = selector(state, "productos");
    let subtotal = 0;
    let precio_final = 0;
    let productos_copia = [];

    if(productos){
        productos_copia = _.cloneDeep(productos);
    }
    productos_copia.forEach(prod => {
        try {
            subtotal += Number(prod.cantidad) * Number(prod.precio);
            precio_final += Number(prod.cantidad) * Number(prod.precio_descuento);
        } catch (error) {
            subtotal = subtotal;
        }
    });

    return {
        subtotal,
        precio_final
        };
})(VentaForm);
export default VentaForm

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Punto_venta/punto_venta';
import Pago from './Pago';
import { change, initialize as initializeForm } from 'redux-form';
const mstp = state => {
    return {...state.puntoventa}
};

const mdtp = {
    ...actions,
    setPagoForm:(data) => dispatch => dispatch(initializeForm('pago', data))
};

export default connect(mstp, mdtp)(Pago)

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { api } from "api/api";

let productos = [];
const getProductos = search => {
    return api
        .get("producto/getFracciones", { search })
        .catch(error => {})
        .then(data => {
            if (data) {
                productos = data.results;
                return data.results;
            } else {
                return [];
            }
        })
        .catch(e => {
            return [];
        });
};
let CargarForm = props => {
    const { handleSubmit, subtotal } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <h5>CARGAR A CUENTA </h5>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="medicamento" className="m-0">Medicamento/servicio*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                            <Field
                                            name={`producto`}
                                            valueKey="id"
                                            labelKey="nombre_completo"
                                            label="Producto"
                                            component={renderSearchSelect}
                                            loadOptions={getProductos}
                                        />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Cantidad" className="m-0">cantidad*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="cantidad" component={renderField} type="number" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="precio" className="m-0">Subtotal*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <RenderCurrency value={subtotal}  className={'text-primary  font-weight-bold'}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="submit" className="btn btn-success-outline m-1">Agregar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
CargarForm = reduxForm({
    form: 'cargar',
    validate: data => {
        return validate(data, {
        })
    },
    initialValues: {
        cantidad: 1
    }
})(CargarForm);
const selector = formValueSelector('cargar');
CargarForm = connect(state => {
    const producto = selector(state, "producto");
    const cantidad = selector(state, "cantidad");
    let subtotal =  0
    if(producto && cantidad > 0){
        subtotal = producto.precio * cantidad;
    }
    return {
        subtotal
    };
})(CargarForm);
export default CargarForm

import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './acciones.css';
import Swal from 'sweetalert2';
import { iconsh, EXTRAS } from "icons";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';


class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = { redirect: false, url: '', popoverOpen: false};
        this.redirect = this.redirect.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }
    redirect(url) {
        return () => {
            this.setState({ url });
            this.setState({ redirect: true });
        };
    }
    togglePop = () => {
        this.setState({popoverOpen: !this.state.popoverOpen})
    }
    eliminar(id) {
        return () => {
            Swal({
                title: '¿Desea eliminar este elemento?',
                text: 'No puedes deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    }
    editar(id) {
        return () => {
            this.props.editar(id);
        }
    }
    editarCampos(id, row){
        return () =>{
            this.props.editarCampos(id, row);
        }
    }

    render() {
        const { id, ver, editar, eliminar, quitar, adicional, obj, row,
            adicional2,
            editarCampos,
            index,
            otro,
            popover,
            ver_icono} = this.props;
        if (this.state.redirect) {
            return (<Redirect to={`${this.state.url}/${id}`} />);
        }
        let offset = 0;
        if(adicional !== undefined){
            offset += 1;
        }
        if (ver !== undefined) {
            offset += 1;
        }
        if (editar !== undefined) {
            offset += 1;
        }
        if (editarCampos !== undefined) {
            offset += 1;
        }
        if (eliminar !== undefined) {
            offset += 1;
        }
        if (ver_icono !== undefined) {
            offset += 1;
        }
        return (
            <div className="row">
                <div className="col-lg-12  col-md-12 col-sm-12 btn-tablet">
                    {(adicional !== undefined ) && adicional(id,row)}
                    {(adicional2 !== undefined) && adicional2(id,row, index, otro)}
                    {(ver_icono !== undefined) && (
                         <Link to={`${ver_icono}/${id}/`} ><img className="action-img" title="Ver" src={require("../../../../../assets/img/icons/editar1.png")} alt="Ver" /></Link>
                    )}

                    {(editar !== undefined) && ((typeof editar) === "string") && (
                        <Link to={`${editar}/${id}/`} ><img className="action-img" title="Editar" src={require("../../../../../assets/img/icons/editar1.png")} alt="Edit" /></Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) !== "string") && (
                        <img onClick={this.editar(id)} className="action-img" title="Editar" src={require("../../../../../assets/img/icons/editar1.png")} alt="Edit" />
                    )}
                     {(editarCampos !== undefined) && ((typeof editarCampos) !== "string") && (
                        <img onClick={this.editarCampos(id, row)} className="action-img" title="Editar" src={require("../../../../../assets/img/icons/editar1.png")} alt="Edit" />
                    )}
                    {(ver !== undefined) && ((typeof ver) === "string") && (
                        <Link to={`${ver}/${id}/`} ><img className="action-img" title="Ver" src={require("../../../../../assets/img/icons/detalle1.png")} alt="Edit" /></Link>
                    )}
                    {(ver !== undefined) && ((typeof ver) !== "string") && (
                        <img onClick={this.ver(id)} className="action-img" title="Ver" src={require("../../../../../assets/img/icons/detalle1.png")} alt="Edit" />
                    )}
                    {(eliminar !== undefined && !row.activo) && (
                        <img onClick={this.eliminar(id)} className="action-img" title="Eliminar" src={require("../../../../../assets/img/icons/eliminar1.png")} alt="Delete" />
                    )}
                    {(quitar !== undefined) && (
                        <React.Fragment>
                        {
                            row.activo ?
                            <img onClick={()=>quitar(id)} className="action-img" title="Eliminar" src={require("../../../../../assets/img/icons/eliminar1.png")} alt="Delete" />
                            :
                            null
                        }
                        </React.Fragment>
                    )}
                    {(popover !== undefined && !row.activo) && (
                        <Fragment>
                        <img id={`popover${id}`} className="action-img"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    popoverOpen: !this.state.popoverOpen
                                })
                            }}
                            title="Ver" src={EXTRAS.anulacion1} alt="Delete" />

                        <Popover
                            isOpen={this.state.popoverOpen}
                            placement="bottom"  target={`popover${id}`} toggle={this.togglePop}>
                        <PopoverHeader>Justificación de Anulación</PopoverHeader>
                        <PopoverBody>
                            <p className="m-0"><span className="font-weight-bold">Usuario: </span> {row.usuario_anulacion? row.usuario_anulacion.first_name: ''}</p>
                            {row.justificacion_anulacion}.
                        </PopoverBody>
                        </Popover>
                    </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function activeFormatter(acciones) {
    return (cell, row, index, otro) => {
        return (<Acciones id={cell} {...acciones} row={row} contenido={row} index={index} otro={otro}/>)
    };
}
//

export function activeFormatter2(acciones) {
        return (<Acciones id={acciones.cell} {...acciones} row={acciones.row} contenido={acciones.row} />)
}

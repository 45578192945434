import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import  renderDate from '../../../../Utils/renderField/renderDate';
import { api } from 'api/api';

let productos = []
const getProductos = (search)  =>{
    return api.get("producto/getFracciones",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    productos = data.results
                    return data.results
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}



let ProductoForm = props => {
    const { handleSubmit, valores } = props;
    return(
        <form onSubmit={handleSubmit(val => {
        })} >
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Producto" className="m-0">Producto y presentación*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`producto`}
                                    valueKey="id"
                                    labelKey="nombre_completo"
                                    label="Producto"
                                    component={renderSearchSelect}
                                    loadOptions={getProductos}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12  col-sm-12">
                                <label htmlFor="cantidad" className="m-0">Cantidad*:</label>
                            </div>
                            <div className="col-md-12 p-0 form-group">
                                <Field name="cantidad" component={renderField} type="number" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 p-0 col-sm-12">
                                <label htmlFor="vencimiento" className="m-0">Vencimiento*:</label>
                            </div>
                            <div className="col-md-12 p-0 form-group">
                                <Field
                                        name={`vencimiento`}
                                        className=""
                                        component={renderDate}
                                        placeholder="Fecha"
                                        numberOfMonths={1}
                                    />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Lote" className="m-0">Lote*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="lote" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" onClick={props.closeModal}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="button" form='formProductos' onClick={e => {
                                    e.preventDefault()
                                    props.addProducto(valores)
                                }}
                                     className="btn btn-primary m-1">Agregar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
ProductoForm = reduxForm({
    form: 'producto',
    validate: (data, props) => {
        const errors = {};
        if(!data.producto){
            errors.producto = 'campo requerido'
        }
        if(!data.cantidad){
            errors.cantidad = 'Campo requerido'
        }else{
            if(!Number.isInteger(Number(data.cantidad))){
                errors.cantidad = "Debe de ser un número entero."
            }
            if(Number(data.cantidad) < 0){
                errors.cantidad = "Debe ser un valor igual o mayor a 0"
            }
        }
        if(!data.vencimiento){
            errors.vencimiento = 'Campo requerido'
        }
        if(!data.lote){
            errors.lote = 'Campo requerido'
        }

        return errors
    }
})(ProductoForm);
const selector = formValueSelector('producto');
ProductoForm = connect(state => {
    let valores = null;
    try {
        valores = state.form.producto.values;
    } catch (error) {
        valores = null;
    }
    return {
        valores
    };
})(ProductoForm);
export default ProductoForm

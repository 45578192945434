import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency, renderPercentage } from 'Utils/renderField/renderField';


class ItemArray extends React.Component {

    render(){
        const { index, precio, eliminar, nombre, cambioDescuento} = this.props;
        return(
            <tr key={index}>
                <td className="sin-borde-top text-center text-gris">
                    {nombre}
                </td>
                <td className="sin-borde-top">
                    <Field
                            name={`${precio}.precio`}
                            type="number"
                            addClass={"text-right"}
                            component={renderCurrency}
                            placeholder="Precio"
                            _onChange={(e) => cambioDescuento(e, 'precio', index)}
                        />
                </td>
                <td className="sin-borde-top text-center text-gris font-weight-light">
                    {/* {porcentaje}% */}
                    <Field
                            name={`${precio}.porcentaje`}
                            type="number"
                            addClass={"text-right"}
                            component={renderPercentage}
                            placeholder="Precio"
                            _onChange={(e) => cambioDescuento(e, 'porcentaje', index)}
                        />
                </td>
                <td className="sin-borde-top">
                    <Field
                            name={`${precio}.precio_descuento`}
                            type="number"
                            addClass={"text-right"}
                            component={renderCurrency}
                            placeholder="Precio"
                            _onChange={(e) => cambioDescuento(e, 'precio_descuento', index)}
                        />
                </td>
            </tr>
        )
    }
}

const renderPrecios = ({fields, meta: {error, submitFailed }, cambioDescuento}) => (
    <div className=" col-sm-12 p-0">

                <div className="col-sm-12 p-0 form-group np-r">
                    <table className="table table-sm  m-0">
                        <tbody>
                            <tr>
                                <th className="text-gris sin-borde-top text-center">Fracción</th>
                                <th className="text-gris sin-borde-top text-center">Precio etiqueta</th>
                                <th className="text-gris sin-borde-top text-center">Porcentaje descuento(%)</th>
                                <th className="text-gris sin-borde-top text-center">Precio descuento</th>
                            </tr>
                        {fields.map((precio, index) => {
                            let porcentaje = 0;
                            let precio_descuento = 0;
                            const fraccion = fields.get(index); // Producto actual
                            const todos = fields.getAll(); //Obtiene todos los productos
                            // if(Number(fraccion.porcentaje) > 0 ){
                            //     precio_descuento = Number(fraccion.precio) * (100 - Number(fraccion.porcentaje));
                            // }
                            // if(Number(fraccion.precio_descuento) > 0){
                            //     porcentaje = (100 * Number(fraccion.precio_descuento) )/ Number(fraccion.precio);
                            //     porcentaje = 100- porcentaje;
                            //     porcentaje = porcentaje.toFixed(1);

                            // }
                            // todos[index]['porcentaje'] = porcentaje
                            // try {
                            //     if(Number(fraccion.precio_descuento) > 0){
                            //         porcentaje = (100 * Number(fraccion.precio_descuento) )/ Number(fraccion.precio);
                            //         porcentaje = 100- porcentaje;
                            //         porcentaje = porcentaje.toFixed(1);
                            //         todos[index]['procentaje'] = procentaje
                            //     }
                            //     else if(Number(fraccion.procentaje) > 0 ){
                            //         let precio_descuento = Number(fraccion.precio) * (100 - Number(fraccion.procentaje))
                            //         todos[index]['precio_descuento'] = precio_descuento
                            //     }
                            // } catch (error) {
                            //     porcentaje = 0;
                            // }

                            todos[index]['index'] = index

                            return(
                                <ItemArray
                                    index={index}
                                    cambioDescuento={cambioDescuento}
                                    precio={precio}
                                    nombre={fields.get(index).nombre}
                                    todos={todos}
                                    fraccion={fraccion}
                                    />
                            )
                        })}
                        </tbody>
                    </table>
            </div>
    </div>
)



let PrecioForm = props => {
    const { handleSubmit, producto } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <h5 className="font-weight-bold text-left">{producto ? producto.nombre:''}</h5>
                    <FieldArray name="fracciones"
                        cambioDescuento={props.cambioDescuento}
                        component={renderPrecios} />
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={e => {
                                        e.preventDefault()
                                        props.cerrarModal()
                                    }}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
PrecioForm = reduxForm({
    form: 'precio',
    validate: data => {
        return validate(data, {
        })
    }
})(PrecioForm);
const selector = formValueSelector('precio');
PrecioForm = connect(state => {
    const fraccion = selector(state, "fracciones");

    return {
        fraccion
    };
})(PrecioForm);
export default PrecioForm

import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { iconsh } from "icons";


export default class HeaderBodega extends Component {
    render() {
        const { updateData, me}= this.props;
        let habilitado = false
        if(updateData.encargado && me){
            if(updateData.encargado.id === me.id){
                habilitado=true
            }
        }
        return (
            <div className="col-md-12  m-0">

                <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    {/* <button className="btn btn-primary btn-estadocuenta">
                    Agregar usuario
                </button> */}
                { habilitado && (
                    <button className="btn btn-fondo mr-2 mt-2" onClick={e => {
                        e.preventDefault()
                        this.props.funcion1()
                    }}>
                        {this.props.texto3}
                    </button>
                )}
                    {
                        habilitado && (
                            <Fragment>
                                {
                                    this.props.ruta2 && (
                                        <Link className="btn btn-secondary mr-2 mt-2" to={this.props.ruta2}>
                                            {this.props.texto2}
                                        </Link>
                                    )
                                }
                                {
                                    this.props.ruta && (
                                            <Link className="btn btn-primary mt-2 position-relative" to={this.props.ruta}>
                                                {this.props.texto}
                                                {
                                                    this.props.notificacion > 0 && (
                                                        <span className={"bubble-alert-danger animated flash"}></span>
                                                    )
                                                }
                                            </Link>
                                    )
                                }
                            </Fragment>
                        )
                    }

                </div>
                <div className="col-md-6 mt-3" >
                    <h5 className="text-primary text-md-left text-center text-uppercase m-0 pl-md-4 ml-md-3">Bodega</h5>
                    <h3 className="text-md-left text-uppercase text-center">
                        <img className="mr-2 pb-1" src={iconsh.crud_bodegas} height={25} />
                        {this.props.bodega}
                    </h3>
                </div>
            </div>

        )
    }
}

import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './FormConf';
import { iconsh } from "icons";

export default class Configuracion extends Component {

componentWillMount(){
        this.props.detail();
}
render() {
    const { create, update } = this.props;
    const { updateData, cargando } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12">
            <Card
                icono={iconsh.crud_conf}
                titulo={"Configuración"}>
                <LoadMask loading={cargando} blur_1>
                            <Form onSubmit={update}
                            cancelar={this.props.cancelar}
                            updateData={updateData}  />
                </LoadMask>
            </Card>
        </div>
    </div>
    )
    }
}

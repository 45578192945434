import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import FormUno from './FormUno';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class AutorizacionApertura extends Component {
    state = {
        page: 1
    }
    componentDidMount() {

    }
    nextPage = () =>{
        this.setState({page: this.state.page + 1 })
    }
    previousPage = () => {
        this.setState({page: this.state.page - 1 })
    }
    render() {
        const { onSubmit, cargando, bodega, caja_nombre, monto_inicial } = this.props
        const { page } = this.state
        return (
            <div >
                <Modal open={this.props.modal} onClose={this.props.closeModal} >
                    <LoadMask loading={cargando} blur_1>
                         <FormUno
                            bodega={bodega}
                            initialValues={{}}
                            monto_inicial={monto_inicial}
                            caja_nombre={caja_nombre}
                            onSubmit={onSubmit} />
                    </LoadMask>
                </Modal>
            </div>
        )
    }
}

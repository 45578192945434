import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderTextArea } from 'Utils/renderField/renderField';

let RechazoForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="motivo" className="m-0">Motivo de rechazo*:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="rechazo" component={renderTextArea} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={e => {
                                        e.preventDefault()
                                        props.closeModal()
                                    }}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
RechazoForm = reduxForm({
    form: 'rechazo',
    validate: data => {
        return validate(data, {
            'rechazo': validators.exists()('Campo requerido.'),
        })
    }
})(RechazoForm);
export default RechazoForm

import React, { Component, Fragment } from 'react'
import { iconsh } from "icons";
import Table from 'Utils/Grid';
import ToolbarSearch from 'Utils/Toolbar/ToolbarSearch';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderIngresoBodega from 'Utils/Header/HeaderIngreso';
import { activeFormatter } from 'Utils/Acciones/Acciones';
import AutorizacionDoble from '../../../Utils/Autorizacion/AutorizacionDoble';
import { formatAllDate, formatUsuario, formatNota, formatNombre } from  '../../../Utils/renderField/renderReadField';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';

import LoadMask from 'Utils/LoadMask/LoadMask';
import IngresoCard from './IngresoCard';
import { EXTRAS } from 'icons';


function formatAnulado(cell){
    if(cell){
        return(
            <div>
                <span className="text-danger">Rechazado</span>
                <span className="pl-2">
                        <img src={EXTRAS.advertencia}   height={15}  width={15}/>
                    </span>
            </div>
            )
    }
    return <span></span>
}
export default class IngresoDespachoGrid extends Component {
    state = {
        activeTab: 'bodega'
    }
    componentWillMount() {
        // this.props.listar(1);
        this.props.detail(this.props.match.params.id, true)


    }
    cambiarTurno = () => {
        this.props.openModal()
    }
    toggle = (tab) => {
        if(this.props.activeTab !== tab){
            this.props.setActiveTab(tab)
            if(tab === 'bodega'){
                // this.props.listarTodo(1);
                this.props.detail(this.props.match.params.id, true)
            }else{
                // this.props.listarAceptados(1);
                this.props.listar_historia(1)
            }
        }
    }
    mensaje = (id, row) => {
        if(row.anulado){
            return(
                <a>
                    <span className="text-danger">Rechazado</span>
                    <span className="pl-2">
                            <img src={EXTRAS.advertencia}   height={15}  width={15}/>
                        </span>
                </a>
                )
        }
        return <span></span>

    }
    render() {
        const {
            listar,
            detail,
            create,
            update,
            listar_despachos_pendientes,
            destroy,
            cambiarTurno,
        } = this.props;
        const {
            cargando,
            cargando_modal,
            page,
            modal_autorizacion,
            nombreBodega,
            updateData,
            data_despacho,
            update_movimiento,
            ingresos_pendientes
        } = this.props;
        let data = {
            count: 0,
            results: []
        }
        return (
            <div>

                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderIngresoBodega
                        principal={updateData.principal ? updateData.principal : false}
                        titulo={`${nombreBodega}`}
                        subtitulo={`Ingresos y Despachos`}
                        funcion2={`/area_estado/crearingreso/${this.props.match.params.id}`}
                        texto2="Ingreso fuera de OC"
                        funcion1={`/area_estado/creardespacho/${this.props.match.params.id}`}
                        texto1={'Crear despacho'}
                    />
                </div>
                <div className="container m-0 pt-0 contenido-principal row d-flex justify-content-center mt-3">
                    <Nav tabs className="col-12  px-4 ">
                        <NavItem className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center', { active: this.props.activeTab === 'bodega' })}
                                onClick={() => { this.toggle('bodega'); }}>
                                <h5>Bodega</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center', { active: this.props.activeTab === 'historial' })}
                                onClick={() => { this.toggle('historial'); }}>
                                <h5>Historial</h5>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.props.activeTab} className="mt-md-0 mt-3 pt-3 col-md-12">
                        <TabPane tabId={"bodega"}>

                            {
                                ingresos_pendientes && ingresos_pendientes.length > 0 && (
                                    <Fragment>
                                        <div className="col-md-12 text-center text-md-left">
                                            <h5 className="text-uppercase text-center text-md-left m-0">Ingresos pendientes </h5>
                                        </div>
                                        <div className="col-md-12 row m-0 my-3 d-flex justify-content-center">
                                            {
                                                ingresos_pendientes.map( x => (
                                                    <IngresoCard ingreso={x}/>
                                                ))
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }


                            <ToolbarSearch
                                titulo={'Despachos pendientes a otras bodegas'}
                                buscar={this.props.search}
                                buscador={this.props.buscador}
                                placeholder={"Buscar por: No. de Movimiento "}
                            />
                            <LoadMask loading={cargando} dark blur>
                                <div className="mt-1 col-md-12">
                                    <Table
                                        onPageChange={listar_despachos_pendientes}
                                        data={data_despacho}
                                        loading={cargando}
                                        page={page}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey={true}
                                            dataAlign="center"
                                            dataFormat={activeFormatter({ adicional: this.mensaje, ver: 'detalledespacho' })}>Acciones</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="no_movimiento" dataSort>No. movimiento</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={formatNombre}
                                            dataField="destino" dataSort>Enviado a</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={formatAllDate}
                                            dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={formatUsuario}
                                            dataField="usuario" dataSort>Emitida por</TableHeaderColumn>
                                    </Table>
                                </div>
                            </LoadMask>
                        </TabPane>
                        <TabPane tabId={"historial"}>

                            <ToolbarSearch
                                titulo={' '}
                                buscar={this.props.setBuscadorHistoria}
                                buscador={this.props.buscador_historia}
                                // tituloBuscador="Ingresar No. de orden"
                                placeholder = "Buscar No. de orden"
                            />
                            <LoadMask loading={cargando} dark blur>
                                <div className="mt-1 col-md-12">
                                    <Table
                                        onPageChange={this.props.listar_historia}
                                        data={this.props.data_historia}
                                        loading={cargando}
                                        page={this.props.page_historia}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey={true}
                                            dataAlign="center"
                                            dataFormat={activeFormatter({ adicional: this.mensaje, ver: 'vermovimiento' })}>Acciones</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="no_movimiento" dataSort>Orden</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={formatAllDate}
                                            dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="tipo" dataSort>Movimiento</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={formatUsuario}
                                            dataField="usuario" dataSort>Emitida por</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={formatNota}
                                            dataField="nota" dataSort>Nota</TableHeaderColumn>

                                    </Table>
                                </div>
                            </LoadMask>
                        </TabPane>
                    </TabContent>


                </div>
            </div>

        )
    }
}

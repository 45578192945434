import React, {Fragment} from 'react';
import {api} from 'api/api';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { formatAllDate } from '../../../Utils/renderField/renderReadField';
import { Field, reduxForm, formValueSelector,FieldArray, change } from 'redux-form';
import { renderField, renderSearchCreateSelect, renderCurrency, renderFieldCheck, renderNumber } from 'Utils/renderField/renderField';


let clientes = [];
const getClientes = (search) => {
    return api.get("clientes", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                clientes = [{value: 'CF', label: 'CF', cliente: 'Consumidor final'}];
                data.results.forEach(x => {
                    if('CF' !== x.nit){
                        clientes.push({value: x.id, label: x.nit, cliente: x.nombre, telefono: x.telefono})
                    }
                })
                return clientes
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let PagoForm = props => {
    const {
        handleSubmit,
        cancelar,
        ver_efectivo,
        ver_tarjeta,
        ver_cheque,
        totales_compra,
        numero_cliente,
        vuelto,
        nombre_cliente } = props;
    let fecha = new Date();
    let descuento = 0;
    if(totales_compra.subtotal > 0 && totales_compra.precio_final){
        descuento = totales_compra.subtotal - totales_compra.precio_final;
    }
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nit" className="m-0">NIT*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                        name={`nit`}
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={renderSearchCreateSelect}
                                        loadOptions={getClientes}
                                        onChange={e => {
                                            props.setPagoForm({
                                                nombre: e.cliente,
                                                telefono: e.telefono,
                                                efectivo: true
                                            })
                                        }}
                                        />
                            </div>
                        </div>
                                <div className="col-md-7">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="" className="m-0">NOMBRE:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="nombre"

                                            component={renderField} type="text" className="form-control"/>
                                    </div>
                                </div>
                        <div className="col-md-7">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="" className="m-0">TELÉFONO:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="telefono"
                                       numberFormat="#### ####" component={renderNumber}
                                       type="number" className="form-control"/>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="col-md-6">
                                <span className="text-gris">Fecha y hora: </span><span className="font-weight-light text-gris">{formatAllDate(fecha)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 row m-0 mt-3">
                            <div className="col-md-6 p-0 d-flex align-items-end">
                            </div>
                            <div className="col-md-6  p-0">
                                <div className="col-md-12 row p-0 m-0">
                                    <div className="col-md-6 p-0 text-right">
                                        <span className="text-primary">Subtotal</span>
                                    </div>
                                    <div className="col-md-6 p-0 text-right">
                                        <RenderCurrency value={totales_compra.subtotal}  className={'text-gris  font-weight-bold'}/>
                                    </div>
                                </div>
                                <div className="col-md-12 row p-0 m-0">
                                    <div className="col-md-6 p-0 text-right">
                                        <span className="text-gris">Descuento</span>
                                    </div>
                                    <div className="col-md-6 p-0 text-right">
                                        -<RenderCurrency value={descuento}  className={'text-gris  font-weight-bold'}/>
                                    </div>
                                </div>
                                <div className="col-md-12 row p-0 m-0">
                                    <div className="col-md-6 p-0 text-right">
                                        <span className="text-primary h5">TOTAL</span>
                                    </div>
                                    <div className="col-md-6 p-0 text-right">
                                        <RenderCurrency value={totales_compra.precio_final}  className={'text-verde h4 font-weight-bold'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* inicio de pago de formularios */}
                    <div className="row col-md-12 m-0 ">
                        <div className="col-md-12 borde-gris py-4">

                            <div className=" col-md-12">
                                <div className="col-md-12">
                                    <h5>CARGAR PAGO</h5>
                                </div>
                                <div className="col-md-12 row m-0 d-flex justify-content-between">

                                    <div className="row col-md-4">
                                        <div className="col-8 text-gris">
                                            TARJETA
                                        </div>
                                        <div className="col-2">
                                        <Field name="tarjeta"
                                                component={renderFieldCheck}
                                                type="checkbox"
                                                className="form-control"
                                                placeholder="efectivo"/>
                                        </div>
                                    </div>
                                    <div className="row col-md-4">
                                        <div className="col-8 text-gris">
                                            CHEQUE
                                        </div>
                                        <div className="col-2">
                                        <Field name="cheque"
                                                component={renderFieldCheck}
                                                type="checkbox"
                                                className="form-control"
                                                placeholder="efectivo"/>
                                        </div>
                                    </div>
                                    <div className="row col-md-4">
                                        <div className="col-8 text-gris">
                                            EFECTIVO
                                        </div>
                                        <div className=" col-2">
                                        <Field name="efectivo"
                                                component={renderFieldCheck}
                                                type="checkbox"
                                                className="form-control"
                                                placeholder="efectivo"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mt-3 d-flex justify-content-center">
                                    <div className="col-md-11">
                                        {
                                            ver_tarjeta && (
                                                <div className="col-md-12 p-0">
                                                    <div className="col-md-6 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="pago_tarjeta" className="m-0">TARJETA</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <Field
                                                                    name={`pago_tarjeta`}
                                                                    type="number"
                                                                    addClass={"text-right"}
                                                                    component={renderCurrency}
                                                                    placeholder=""
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            ver_cheque && (
                                                <div className="col-md-12 p-0 row m-0">
                                                    <div className="col-md-4 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="pago_cheque" className="m-0">CHEQUE:</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <Field
                                                                    name={`pago_cheque`}
                                                                    type="number"
                                                                    addClass={"text-right"}
                                                                    component={renderCurrency}
                                                                    placeholder=""
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="nocheque" className="m-0">NO. CHEQUE:</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <Field name="no_cheque" component={renderField} type="number" className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="banco" className="m-0">Banco:</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <Field name="banco" component={renderField} type="text" className="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            ver_efectivo && (
                                                <div className="col-md-12 row m-0 p-0">
                                                    <div className="col-6 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="pago_efectivo" className="m-0">EFECTIVO</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <Field
                                                                    name={`pago_efectivo`}
                                                                    type="number"
                                                                    addClass={"text-right"}
                                                                    component={renderCurrency}
                                                                    placeholder="pago en efectivo"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="vuelto" className="m-0">VUELTO:</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <RenderCurrency value={vuelto || 0}  className={'text-primary font-weight-light'}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* fin del fomulario de pagos */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button className="btn btn-secondary m-1"
                                    onClick={ e => {
                                        e.preventDefault();
                                        cancelar();
                                    }}
                                    type="button" >
                                    Cancelar
                                </button>
                                <button type="submit" className="btn btn-primary m-1">Pagar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
PagoForm = reduxForm({
    form: 'pago',
    validate: (data, props) => {
        const { totales_compra } = props;
        const errors = {};
        if(!data.nit){
            errors.nit = 'Campo requerido';
        }
        if(!data.nombre){
            errors.nombre = 'Campo requerido';
        } else if (data.nombre.trim() === '') {
            errors.nombre = 'Debe de escribir un nombre';
        }
        if(!data.efectivo && !data.tarjeta && !data.cheque){
            errors.efectivo = 'Debe de seleccionar una opción'
        }else{
            //VAlidación de pago solo en efectivo
            let valor_efectivo = Number(data.pago_efectivo || 0);
            let valor_cheque = Number(data.pago_cheque || 0);
            let valor_tarjeta = Number(data.pago_tarjeta || 0);
            let total = totales_compra.precio_final;
            valor_efectivo = parseFloat(valor_efectivo.toFixed(2));
            valor_cheque = parseFloat(valor_cheque.toFixed(2));
            valor_tarjeta = parseFloat(valor_tarjeta.toFixed(2));
            total = parseFloat(total.toFixed(2));
            // if(!data.pago_cheque){
            //     errors.pago_cheque = 'Campo requerido';
            // }
            // if(!data.pago_tarjeta){
            //     errors.pago_tarjeta = 'Campo requerido';
            // }
            if(!data.pago_efectivo && !data.pago_tarjeta && !data.pago_cheque){
                errors.pago_efectivo = 'Campo requerido';
                errors.pago_tarjeta = 'Campo requerido';
                errors.pago_cheque = 'Campo requerido';
            }

            if(!data.banco){
                errors.banco = 'Campo requerido';
            }
            if(!data.no_cheque){
                errors.no_cheque = 'Campo requerido';
            }

            // Si solo es tarjeta entonces se verifica
                // que la cantidad del monto de pago por tarjeta
                // debe de ser igual al monto a pagar
            if(valor_efectivo <= 0 && valor_tarjeta > 0 && valor_cheque <= 0 ){
                if(valor_tarjeta != total){
                    errors.pago_tarjeta = 'El monto de tarjeta debe de coincidir con el total';
                }
            }
            else if(valor_efectivo <= 0 && valor_tarjeta > 0 && valor_cheque > 0){
                if((valor_tarjeta + valor_cheque) != total){
                    errors.pago_tarjeta = 'La suma del monto de cheque y tarjeta debe de ser igual al total.'
                    errors.pago_cheque = 'La suma del monto de cheque y tarjeta debe de ser igual al total.'
                }
            }
            else if(valor_efectivo <= 0 && valor_tarjeta <= 0 && valor_cheque > 0){
                if(valor_cheque != total){
                    errors.pago_cheque = 'El monto de cheque debe de coincidir el total del pago.'
                }
            }
            else if(valor_efectivo > 0 && valor_tarjeta <= 0 && valor_cheque <= 0){
                if(valor_efectivo < total){
                    errors.pago_efectivo = 'El monto de efectivo debe de ser igual o mayor al total';
                }
            }
            else if(valor_efectivo > 0 && (valor_tarjeta > 0 || valor_cheque > 0)){
                if((valor_cheque + valor_tarjeta) >= total){
                    errors.pago_cheque = 'La suma de cheque y tarjeta debe de ser menor al total.';
                }
                else if((valor_cheque + valor_tarjeta + valor_efectivo) < total){
                    errors.pago_cheque = 'La suma debe ser igual o mayor al total'
                    errors.pago_tarjeta = 'La suma debe ser igual o mayor al total'
                }
            }
        }

        return errors;
    },
    initialValues:{
        efectivo: true,
        nit: {value: 'CF', label: 'CF', cliente: 'Consumidor final', cliente_seleccionado: 'Consumidor final'},
        nombre: 'Consumidor Final'
    }
})(PagoForm);
const selector = formValueSelector('pago');
PagoForm = connect((state, props) => {
    const efectivo = selector(state, "efectivo");
    const tarjeta = selector(state, "tarjeta");
    const cheque = selector(state, "cheque");
    const pago_efectivo = selector(state, "pago_efectivo");
    const pago_tarjeta = selector(state, "pago_tarjeta");
    const pago_cheque = selector(state, "pago_cheque");
    const nit = selector(state, 'nit');



    let nombre_cliente = null;
    if(nit && nit.cliente){
        if (nit.value === "CF"){
            nombre_cliente = nit.cliente;
        }
    }
    const { totales_compra } = props;
    let ver_efectivo = false;
    let ver_tarjeta = false;
    let ver_cheque = false;
    let vuelto = 0;

    if(efectivo == true){
        ver_efectivo = true;
        let pagar_en_efectivo = totales_compra.precio_final - Number( pago_tarjeta ||0) - Number(pago_cheque || 0);
        if(pago_efectivo >= pagar_en_efectivo){
            vuelto = pago_efectivo - pagar_en_efectivo ;
        }
    }
    tarjeta ? ver_tarjeta=true : pago_tarjeta > 0 ? props.setPagoForm('pago_tarjeta', 0) : ver_tarjeta=false ;
    cheque ? ver_cheque= true: pago_cheque > 0 ? props.setPagoForm('pago_cheque', 0): ver_cheque=false;
    return {
        ver_efectivo,
        ver_tarjeta,
        ver_cheque,
        vuelto,
        nombre_cliente
    };
})(PagoForm);
export default PagoForm

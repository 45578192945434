import React, { Component } from "react";
import Table from "Utils/Grid";
import HeaderSimple from "Utils/Header/HeaderSimple";
import { BreakLine } from "../../../Utils/tableOptions";
import { activeFormatter, activeFormatter2 } from "Utils/Acciones/Acciones";
import ToolbarSearch from "Utils/Toolbar/ToolbarSearch";
import {
    dateFormatter,
    formatoMoneda,
    formatSelect,
    formatAllDate, nombreCliente
} from "../../../Utils/renderField/renderReadField";

function cellTachado(cell, row) {
    if (!row.activo) {
        return { ...BreakLine, padding: 5, textDecoration: "line-through" };
    }
    return { ...BreakLine, padding: 5 };
}
export default class HistoriaVentas extends Component {
    componentWillMount() {
        this.props.listarMovimientos(this.props.page);
    }
    accionesFormat = (cell, row) => {
        if(cell){
            if(row.no_orden && !row.no_orden.includes('PC')){
                return activeFormatter2({ver: "/caja/punto_venta/detalle", cell, row});
            }
        }

    }
    render() {
        const {
            listarMovimientos,
            cargando,
            page,
            data_movimientos
        } = this.props;
        let data = {
            results: [],
            count: 0
        };
        return (
            <div className="col-md-12">
                <div className="mb-2">
                    <ToolbarSearch titulo={"Ventas"} half />
                </div>
                <Table
                    onPageChange={listarMovimientos}
                    data={data_movimientos}
                    loading={cargando}
                    page={page}
                >
                    <TableHeaderColumn
                        tdStyle={{ ...BreakLine, padding: 5 }}
                        thStyle={{ ...BreakLine, padding: 5 }}
                        dataField="id"
                        isKey={true}
                        dataAlign="center"
                        dataFormat={this.accionesFormat}
                    >
                        Acciones
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={cellTachado}
                        thStyle={{ ...BreakLine, padding: 5 }}
                        width={"90px"}
                        dataAlign={"center"}
                        dataField="no_orden"
                        dataSort
                    >
                        No
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={cellTachado}
                        thStyle={{ ...BreakLine }}
                        width={"80px"}
                        dataAlign={"center"}
                        dataFormat={formatAllDate}
                        dataField="fecha"
                        dataSort
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={cellTachado}
                        thStyle={{ ...BreakLine, padding: 5 }}
                        dataAlign={"center"}
                        dataField="nombre_cliente"
                        dataFormat={nombreCliente}
                        dataSort
                    >
                        Cliente
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={cellTachado}
                        thStyle={{ ...BreakLine, padding: 5 }}
                        dataFormat={formatoMoneda}
                        dataAlign={"right"}
                        dataField="monto"
                        dataSort
                    >
                        Total
                    </TableHeaderColumn>
                </Table>
            </div>
        );
    }
}

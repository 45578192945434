import React, { Component } from 'react'
import TituloBodega from '../../../Utils/Header/TituloBodega';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { formatAllDate, dateFormatter } from '../../../Utils/renderField/renderReadField';
import {MOVIMIENTO_BODEGA} from '../../../../utility/constants';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import FormIngreso from '../../Solicitudes/Aceptado/Formularios/FormIngreso';

function formatFraccion(cell){
    if(cell){
        return cell.nombre_completo;
    }
    return ''
}
function formatLote(cell){
    if(cell){
        return cell.lote;
    }
    return ''
}
function formatVencimiento(cell){
    if(cell){
        return dateFormatter(cell.fecha_vencimiento);
    }
    return ''
}
const isExpandableRow = () => {
    return true;
}
function formatLotes(cell) {
    return <div>
        {
            cell.map((x, index) => {
                return (
                    <span>{x.lote} &ensp; &ensp;</span>)
            })
        }
    </div>
}
function formatNombreCompleto(cell, row){
    if(cell){
        return row.producto + ' - ' + row.presentacion;
    }
    return ''
}
export default class IngresarDevolucion extends Component {
    state = {
        modal: false,
        idDetalle: 0,
        detalle: null,
        expandidos: []
    }
    componentWillMount(){
        this.props.detail_despacho(this.props.match.params.id);
    }
    botonModal = (id, row) => {
        return (<button
            className="btn btn-success-outline"
            onClick={(e) => {
                e.preventDefault();
                this.setState({ idDetalle: id, detalle: row })
                this.props.openModal();
            }}
            alt="Ver fracciones" >Ingresar producto </button>)
    }
    openModal = () => {
        this.setState({ modal: true });
    }
    closeModal = () => {
        this.setState({ modal: false });
    }
    handleExpand = (rowKey, isExpand) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        if (isExpand) {
            expandidos.push(rowKey)
        } else {
            let index = expandidos.findIndex(x => x === rowKey)
            expandidos.pop(index)
        }
        this.setState({expandidos: expandidos})
    }
    rowClassNameFormat = (row, rowIdx) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        let registro = expandidos.find(x => x === row.fraccion)
        return registro === undefined ? '' : 'background-row-selected';
    }
    expandComponent = (row) => {
        let data = row && row.lotes ? row.lotes : [];
        let movimientos = _.cloneDeep(data);
        // const movimientos = []
        // const { me } = this.props
        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    stripped={false}
                    headerStyle={{ backgroundColor: '#aacfda' }}
                    data={movimientos}>
                    <TableHeaderColumn
                        dataField="cantidadActual"
                        dataAlign="center"
                        width={'90px'}>A Ingresar</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vencimiento"
                        dataFormat={dateFormatter}
                        dataAlign="center"
                    >Vencimiento</TableHeaderColumn>
                    <TableHeaderColumn
                        isKey={true}
                        dataAlign="center"
                        dataField="lote">Lote</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
  render() {
        const {cargando, page, update_movimiento} = this.props;
        let data = {
            results: update_movimiento.detalle_movimiento,
            count: 0
        }
        const options = {
            onExpand: this.handleExpand
        };
      return (
      <div className="container m-0 contenido-principal row d-flex justify-content-center">
        {
                    this.props.toggleModal && (
                        <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                            <div style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Registrar Ingreso</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormIngreso
                                        devolucion
                                        initialValues={this.state.detalle}
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.addLote}
                                        row={this.state.detalle}
                                        updateData={update_movimiento} />
                                </div>
                            </div>
                        </Modal>
                    )
                }
        <div className="row col-md-12">
            <TituloBodega estilo="col-md-3"
                titulo={update_movimiento.bodega ? update_movimiento.bodega.nombre:''}
                subtitulo={`Devolución`} />
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Envio de:</label>
                        <span className="text-primary">{update_movimiento.proveedor ? update_movimiento.proveedor.nombre:''}</span>
            </div>
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                <span className="text-primary">{update_movimiento.usuario ? update_movimiento.usuario.nombreCompleto:''}</span>
            </div>
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                <span className="text-primary">{update_movimiento.fecha ? formatAllDate(update_movimiento.fecha) : '' }</span>
            </div>
        </div>
        <div className="col-md-12 mt-5 con-hijos">
            <Table
                    data={data}
                    loading={cargando}
                    expandableRow={isExpandableRow}
                    expandComponent={this.expandComponent}
                    onExpand={this.handleExpand}
                    trClassName= {this.rowClassNameFormat}
                    page={1}>
                    <TableHeaderColumn
                        dataField="fraccion"
                        isKey={true}
                        dataAlign="right"
                        dataFormat={activeFormatter({ adicional: this.botonModal })}>Acciones</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        columnClassName={ 'text-uppercase' }
                        dataFormat={formatNombreCompleto}
                        dataField="producto" dataSort>Producto</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="cantidad" dataSort>A ingresar</TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataFormat={formatLotes}
                        dataField="lotes" dataSort>Lotes</TableHeaderColumn>

                </Table>
        </div>

        <div className="col-md-12 row mt-3">
             <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {
                    update_movimiento.tipo_id === MOVIMIENTO_BODEGA.DESPACHO && (
                        <button type="button" onClick={e => {
                            e.preventDefault()
                            this.props.openModal()
                            }} className="btn btn-danger m-1">Rechazar orden</button>
                    )
                }
             </div>
             <div className="col-md-6 d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {/* <Link className="btn btn-secondary m-1" to="/solicitudes">Cancelar</Link> */}
                <button type="button" onClick={ e=>{
                    e.preventDefault();
                    this.props.regresar()
                }} className="btn btn-secondary m-1">Cancelar</button>
                <button type="button" onClick={ e=>{
                    e.preventDefault();
                    this.props.create()
                }} className="btn btn-primary m-1">Confirmar</button>
             </div>
        </div>
      </div>
    )
  }
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Caja/caja';
import AperturaCierre from './AperturaCierre';

const mstp = state => {
    let apertura = state.caja.apertura
    let data = state.form.monedas ? state.form.monedas.values : {};
    data = data ? data : {};
    let total = 0;
    total = data.b200 ? total + (parseInt(data.b200) * 200) : total;
    total = data.b100 ? total + (parseInt(data.b100) * 100) : total;
    total = data.b50 ? total + (parseInt(data.b50) * 50) : total;
    total = data.b20 ? total + (parseInt(data.b20) * 20) : total;
    total = data.b10 ? total + (parseInt(data.b10) * 10) : total;
    total = data.b5 ? total + (parseInt(data.b5) * 5) : total;
    total = data.b1 ? total + (parseInt(data.b1)) : total;
    total = data.m2000 ? total + (parseInt(data.m2000) * 20) : total;
    total = data.m1000 ? total + (parseInt(data.m1000) * 10) : total;
    total = data.m500 ? total + (parseInt(data.m500) * 5) : total;
    total = data.m200 ? total + (parseInt(data.m200) * 2) : total;
    total = data.m100 ? total + (parseInt(data.m100)) : total;
    total = data.m50 ? total + (parseInt(data.m50) * 0.50) : total;
    total = data.m25 ? total + (parseInt(data.m25) * 0.25) : total;
    total = data.m20 ? total + (parseInt(data.m20) * 0.20) : total;
    total = data.m10 ? total + (parseInt(data.m10) * 0.10) : total;
    total = data.m5 ? total + (parseInt(data.m5) * 0.05) : total;
    total = data.m1 ? total + (parseInt(data.m1)) : total;
    total = data.m01 ? total + (parseInt(data.m01)* 0.01) : total;
    total = parseFloat(total).toFixed(2);
    return {...state.caja, total, apertura}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AperturaCierre)

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField } from '../Utils/renderField'

const LoginForm = props => {
    const { handleSubmit, pristine, reset, submitting } = props
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <div className="col-12">
                    <label htmlFor="first_name" className="text-white">Usuario</label>
                </div>
                <div className="col-12">
                    <Field name="username" component={renderField} type="text" className="form-control" />
                </div>

            </div>
            <div className="form-group has-feedback">
                <div className="col-12">
                    <label htmlFor="first_name" className="text-white">Contraseña</label>
                </div>
                <div className="col-12">
                    <Field name="password" component={renderField} type="password" className="form-control" />
                </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12- text-center text-white mt-0 pt-0 font-weight-bold">
                { props.submitError && (
                      <span>
                        { props.mensajeLogin }
                      </span>)
                }
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="submit" className="btn btn-verde m-1">Iniciar Sesión</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'login' // a unique identifier for this form
})(LoginForm)

import React, { Component } from 'react'
import Table from 'Utils/Grid';
import { BreakLine } from '../../../Utils/tableOptions';
import { formatoMoneda, formatSelect, dateFormatter, formatAllDate, formatNombre, formatUsuario } from '../../../Utils/renderField/renderReadField';
import { activeFormatter } from 'Utils/Acciones/Acciones'
export default class DetalleCaja extends Component {
  render() {
      const {listar, data, page, cargando} = this.props;
    return (
      <div>
        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/reporte_caja' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatAllDate}
                                dataField="creado" dataSort>Apertura</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatAllDate}
                                dataField="fecha_cierre" dataSort>Cierre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatUsuario}
                                dataField="usuario" dataSort>Usuario</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="caja" dataSort>Caja</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="total_apertura" dataSort>Monto Apertura</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="total_recaudado" dataSort>Monto Recolectado</TableHeaderColumn>

                        </Table>
      </div>
    )
  }
}

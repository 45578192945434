import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm, SubmissionError } from 'redux-form';
import { push } from 'react-router-redux';
import swal from 'sweetalert2';

const url = 'producto';

let fracciones_id = 0;

const LOADER_PRODUCTO = 'LOADER_PRODUCTO';
const SET_DATA_PRODUCTO = 'SET_DATA_PRODUCTO';
const SET_PAGE_PRODUCTO = 'SET_PAGE_PRODUCTO';
const SET_UPDATE_DATA_PRODUCTO ='SET_UPDATE_DATA_PRODUCTO';
const SET_BUSCADOR_PRODUCTO = 'SET_BUSCADOR_PRODUCTO';
const SET_FILTRO_PRODUCTO = 'SET_FILTRO_PRODUCTO';
const SET_PROVEEDORES_PRODUCTO = 'SET_PROVEEDORES_PRODUCTO';
const SET_MODAL_PRODUCTO = 'SET_MODAL_PRODUCTO';
const SET_FORMPROD_PRODUCTO = 'SET_FORMPROD_PRODUCTO';
const SET_FRACCIONES_PRODUCTO = 'SET_FRACCIONES_PRODUCTO';
const SET_EDITCREATE_PRODUCTO = 'SET_EDITCREATE_PRODUCTO';
const SET_TAB_PRODUCTO = 'SET_TAB_PRODUCTO';

const listar = (page = 1, servicio=false) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PRODUCTO, cargando: true});
    const store = getStore();
    const search = store.producto.buscador;
    const filtro = store.producto.filtro_producto;
    const tab = store.producto.tab;

    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    let inventariable = false;
    if(tab !=='servicios'){
        inventariable = true;
    }
    params['inventariable'] = inventariable;
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PRODUCTO, data});
            dispatch({type: SET_PAGE_PRODUCTO, page});
        }
        dispatch({type: LOADER_PRODUCTO, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_PRODUCTO, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/admin_productos'))
        }
    }).then((data) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_PRODUCTO, updateData: data});
            let fracciones = formatearData(data.fracciones)
            dispatch({
                type: SET_FRACCIONES_PRODUCTO,
                fracciones: fracciones
            })
            dispatch(initializeForm('producto', data))
        }
    })
}
function validacionProductos(producto){
    // 'nombre': validators.exists()("Campo requerido."),
    //         'casa_medica': validators.exists()("Campo requerido."),
    //         'precio': validators.exists()("Campo requerido."),
    //         'modificable_venta': validators.exists()("Campo requerido."),
    //         'presentacion': validators.exists()("Campo requerido."),
    if(!producto.nombre){
        swal('Error', 'Debe de ingresar un nombre.', 'error')
        throw new SubmissionError({
            nombre: 'error',
            _error: 'Ingrese un nombre'
        })
    }
    if(producto.inventariable == 'true'){
        if(!producto.casa_medica){
            swal('Error', 'Debe de ingresar una casa médica.', 'error')
                throw new SubmissionError({
                    nombre: 'error',
                    _error: 'Debe de ingresar la casa médica.'
                })
        }
    }
    if(!producto.cuenta){
        swal('Error', 'Debe de ingresar una cuenta.', 'error')
        throw new SubmissionError({
            nombre: 'error',
            _error: 'Ingrese una cuenta.'
        })
    }
}
const create = () => (dispatch, getStore) => {
    const store = getStore()
    const fracciones = store.producto.fracciones;

    let formData = _.cloneDeep(getStore().form.producto.values);
    validacionProductos(formData)
    if(formData.casa_medica){
        try {
            formData.casa_medica = formData.casa_medica.id
        } catch (error) {
            formData.casa_medica = formData.casa_medica
        }
    }
    if(formData.cuenta){
        try {
            formData.cuenta = formData.cuenta.id
        } catch (error) {
            formData.cuenta = formData.cuenta
        }
    }
    let inventariable = formData.inventariable === 'true' ? true : false;
    formData.inventariable = inventariable;
    if(inventariable){
        let nuevas_fracciones = []
        fracciones.forEach((obj) => {
            let new_obj = _.cloneDeep(obj)
            if (new_obj.nuevo) {
              new_obj.id = null
              new_obj.index = null
            }
            delete new_obj.nuevo
            nuevas_fracciones.push(new_obj)
        })
        console.log("Creación de productos | fraciones", fracciones)
        console.log("nuevas_fraciones", nuevas_fracciones)
        formData.fracciones = nuevas_fracciones;
    }

    dispatch({type: LOADER_PRODUCTO, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false})
        let mensaje = 'Se ha creado el producto.';
        inventariable ? mensaje = 'Se ha creado el producto.' : mensaje = 'Se ha creado el servicio.';
        Swal('Éxito', mensaje, 'success')
        .then(() => {
            dispatch(push('/admin_productos'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_PRODUCTO, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    let formData = _.cloneDeep(getStore().form.producto.values);
    const store = getStore();
    let fracciones = store.producto.fracciones
    if(formData.casa_medica){
        try {
            formData.casa_medica = formData.casa_medica.id
        } catch (error) {
            formData.casa_medica = formData.casa_medica
        }
    }
    if(formData.cuenta){
        try {
            formData.cuenta = formData.cuenta.id
        } catch (error) {
            formData.cuenta = formData.cuenta
        }
    }
    let inventariable = formData.inventariable === 'true' ? true : false;
    formData.inventariable = inventariable;
    if(inventariable && formData.fraccionable){
        let nuevas_fracciones = []
        fracciones.forEach((obj) => {
            let new_obj = _.cloneDeep(obj)
            if (new_obj.nuevo) {
              new_obj.id = null
              new_obj.index = null
            }
            delete new_obj.nuevo
            nuevas_fracciones.push(new_obj)
        })
        console.log("Actualización de productos | fraciones", fracciones)
        console.log("nuevas_fraciones", nuevas_fracciones)
        formData.fracciones = nuevas_fracciones;
    }
    if(!inventariable){
        const producto_update = store.producto.updateData;
        let kit_borrados = producto_update.kit.filter((el) => {
            if(formData.kit.find(x => x.id === el.id)){
                return;
            }
            return el;
        })
        formData.kit_borrados = kit_borrados;
    }
    dispatch({type: LOADER_PRODUCTO, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/admin_productos'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/admin_productos'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    const store = getStore();
    const tab = store.producto.tab
    dispatch({type: LOADER_PRODUCTO, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                let servicio = false;
                if(tab != 'servicios'){
                    servicio=true
                }
                dispatch(listar(1, servicio));
        })
    }).then((data) => {
        dispatch({type: LOADER_PRODUCTO, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                let servicio = false;
                if(tab != 'servicios'){
                    servicio=true
                }
                dispatch(listar(1, servicio));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PRODUCTO, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PRODUCTO, filtro_producto: filtro});
    dispatch(listar(1));
};
// funciones extras
const getProveedores = (search) =>  (dispatch, getStore) => {
    // dispatch({type: LOADER_PRODUCTO, cargando: true});
    return api.get("proveedores",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    dispatch({type: SET_PROVEEDORES_PRODUCTO, proveedores:data.results});
                    dispatch({type: LOADER_PRODUCTO, cargando: false})
                    return data.results
                }
                else{
                    dispatch({type: LOADER_PRODUCTO, cargando: false})
                    return []
                }
            })
            .catch(e => {
                dispatch({type: LOADER_PRODUCTO, cargando: false});
                return []
            })
};



//////////////////////////////////////////////////////////////////////////
/// fracciones
//////////////////////////////////////////////////////////////////////////
export const setEditCreateFraccion = (data) => (dispatch, getStore) => {
    const store = getStore()
    dispatch({
        type: SET_EDITCREATE_PRODUCTO,
        editCreateFraccion: data
    })
}
function formatearData(fracciones=[]){
    let data = [];

    fracciones.forEach((x, index) => {
        let estructuraFraccion = {}
        if(x.id){
            if(x.nuevo){
                estructuraFraccion = {
                    id: x.id,
                    presentacion: x.presentacion,
                    contenido: x.contenido,
                    contiene: '',
                    vendible: x.vendible ? true : false,
                    capacidad_maxima: x.capacidad_maxima,
                    index: x.id,
                    alerta: x.alerta ? true : false,
                    precio: x.precio,
                    precio_descuento: x.precio_descuento,
                    porcentaje: x.porcentaje,
                    minimo_existencias: x.minimo_existencias,
                    minimo_existencias_farmacia: x.minimo_existencias_farmacia,
                    costo: x.costo,
                    nuevo: x.nuevo
                }
            } else {
              estructuraFraccion = {
                  id: x.id,
                  presentacion: x.presentacion,
                  contenido: x.contenido,
                  contiene: '',
                  vendible: x.vendible ? true : false,
                  capacidad_maxima: x.capacidad_maxima,
                  index: x.id,
                  alerta: x.alerta ? true : false,
                  precio: x.precio,
                  precio_descuento: x.precio_descuento,
                  porcentaje: x.porcentaje,
                  minimo_existencias: x.minimo_existencias,
                  minimo_existencias_farmacia: x.minimo_existencias_farmacia,
                  costo: x.costo,
                  nuevo: false
              }
            }
        } else {
            estructuraFraccion = {
                id: 'nuevo' + fracciones_id,
                presentacion: x.presentacion,
                contenido: x.contenido,
                contiene: '',
                vendible: x.vendible ? true : false,
                capacidad_maxima: x.capacidad_maxima,
                index: x.id,
                alerta: x.alerta ? true : false,
                precio: x.precio,
                precio_descuento: x.precio_descuento,
                porcentaje: x.porcentaje,
                minimo_existencias: x.minimo_existencias,
                minimo_existencias_farmacia: x.minimo_existencias_farmacia,
                costo: x.costo,
                nuevo: true
            }
            fracciones_id += 1;
        }
        estructuraFraccion.contenido = fracciones[index].presentacion
        if (index < fracciones.length){
            if(fracciones[index+1]){
                estructuraFraccion.contenido = fracciones[index+1].presentacion
            }
        }
        data.push(estructuraFraccion)
    })
    return data;
}
export const agregarNuevaFraccion = () => (dispatch, getStore) => {
    const store = getStore()
    const fraccion = store.form.fraccion.values;
    const fracciones = store.producto.fracciones
    let dataFracciones = _.cloneDeep(fracciones)
    const nombreFraccion = fraccion.presentacion.trim();

    const nombreseleccionado = store.producto.editCreateFraccion.presentacion

    let editCreateFraccion = {
        data: null,
        arriba: null,
        abajo: null,
        tipo: 'create',
        posicion: null
    };

    let estructuraFraccion = {
        id: null,
        presentacion: fraccion.presentacion,
        contenido: null,
        contiene: fraccion.contiene,
        alerta: fraccion.alerta ? true : false,
        precio: fraccion.precio,
        precio_descuento: fraccion.precio_descuento,
        porcentaje: fraccion.porcentaje,
        vendible: fraccion.vendible ? true : false,
        index: new Date().getTime(),
        minimo_existencias: fraccion.minimo_existencias,
        minimo_existencias_farmacia: fraccion.minimo_existencias_farmacia,
        costo: fraccion.costo,
    }
    //Validación de que no se ingrese fracción repetida
    const fraccion_encontrada = _.find(fracciones, (fra)=> {
        return fra.presentacion.toLowerCase() == nombreFraccion.toLowerCase()
    });
    // generando estructura arriba abajo
    if(fraccion.posicion){
        if(fraccion.posicion === 'arriba'){
            fracciones.forEach(function (item, index) {
                if (item.presentacion === nombreseleccionado) {
                    editCreateFraccion.abajo = _.cloneDeep(item);
                    editCreateFraccion.arriba = _.cloneDeep(item[index - 1]);
                    editCreateFraccion.posicion = 'arriba';
                    editCreateFraccion.data = estructuraFraccion;

                }
            })
        }else{
            fracciones.forEach(function (item, index) {
                if (item.presentacion === nombreseleccionado) {
                    editCreateFraccion.abajo = _.cloneDeep(item[index + 1]);
                    editCreateFraccion.arriba = _.cloneDeep(item);
                    editCreateFraccion.posicion = 'abajo';
                    editCreateFraccion.data = estructuraFraccion;
                }
            })
        }
    }
    if(!fraccion_encontrada){
        if(fracciones.length != 0){
            // Se valida si no es la primera fracción
            if(editCreateFraccion.posicion === 'arriba'){
                if (editCreateFraccion.arriba) {
                    estructuraFraccion.capacidad_maxima = fraccion.capacidad_maxima_abajo;
                    estructuraFraccion.contenido = editCreateFraccion.abajo ? editCreateFraccion.abajo.presentacion : null
                    fracciones.forEach(function (item, index) {
                        if (item.presentacion === editCreateFraccion.arriba.presentacion) {
                            dataFracciones[index].capacidad_maxima = fraccion.capacidad_maxima_abajo;
                            dataFracciones[index].contenido = fraccion.nombre;
                            dataFracciones.splice(index + 1, 0, estructuraFraccion);
                        }
                    });
                } else {
                    fracciones.forEach(function (item, index) {
                        if (item.presentacion === editCreateFraccion.abajo.presentacion) {
                            estructuraFraccion.capacidad_maxima = fraccion.capacidad_maxima_arriba;
                            estructuraFraccion.contenido = editCreateFraccion.abajo ? editCreateFraccion.abajo.presentacion : null
                            dataFracciones.splice(index, 0, estructuraFraccion);
                        }
                    })
                }
            }else{
                // Si tiene alguna posicion abajo se asimila que se esta ingresando en medio de 2 fracciones
                if (editCreateFraccion.abajo) {
                    estructuraFraccion.capacidad_maxima = fraccion.capacidad_maxima_abajo;
                    estructuraFraccion.contenido = editCreateFraccion.abajo ? editCreateFraccion.abajo.presentacion : null;
                } else {
                    estructuraFraccion.capacidad_maxima = 1;
                    estructuraFraccion.contenido = null;
                }
                fracciones.forEach(function (item, index) {
                    if (item.presentacion === editCreateFraccion.arriba.presentacion) {
                        dataFracciones[index].capacidad_maxima = fraccion.capacidad_maxima_abajo;
                        dataFracciones[index].contenido = fraccion.presentacion;
                        dataFracciones.splice(index + 1, 0, estructuraFraccion);
                    }
                })
            }

        }else{
            // Es la primera fracción
            dataFracciones.push(estructuraFraccion)
        }
        dispatch({
            type: SET_FRACCIONES_PRODUCTO,
            fracciones: formatearData(dataFracciones)
        })
        dispatch(closeModal())
    }else{
        swal(
            'Error',
            'No fue posible ingresar la fraccCajaión ya tiene una fracción con el mismo nombre.',
            'error'
        )
    }
}
export const editarFraccion = () => (dispatch, getStore) => {
    const store = getStore();
    let fraccion  = _.cloneDeep(getStore().form.fraccionEditar.values);
    let fracciones = _.cloneDeep(store.producto.fracciones);

    let fracciones_nuevo = fracciones.map(x => {
        if(x.index == fraccion.index){
            return fraccion
        }
        return x
    })
    dispatch(closeModal())
    let fraccion_fomateado = formatearData(fracciones_nuevo)
    dispatch({type: SET_FRACCIONES_PRODUCTO, fracciones: fraccion_fomateado});
}
export const eliminarFraccion = (nombre) => (dispatch, getStore) => {
    const store = getStore();
    let fracciones = _.cloneDeep(store.producto.fracciones);
    Swal({
        title: '¿ Desea eliminar la fracción?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {

            fracciones = _.remove(fracciones, function (fraccion) {
                return fraccion.presentacion != nombre;
            })
            const cantidadFin = fracciones.length;

            const fracionUnidad = _.find(fracciones, function (fra) {
                return parseInt(fra.capacidad_maxima) == 1;
            })
            if (!fracionUnidad) {
                if (cantidadFin > 0){
                    fracciones[cantidadFin - 1].capacidad_maxima = 1
                    fracciones[cantidadFin - 1].contenido = null;
                }
            }
            let nuevo_fracciones = formatearData(fracciones)
            dispatch({type: SET_FRACCIONES_PRODUCTO, fracciones: nuevo_fracciones});
        }
    });
}

//////////////////////////////////////////////////////////////////////////
/// Modal
//////////////////////////////////////////////////////////////////////////
export const openModal = () => (dispatch, getStore) => {
    let formData = _.cloneDeep(getStore().form.producto.values);
    dispatch({
        type: SET_FORMPROD_PRODUCTO,
        formProducto: formData
    })
    dispatch({
        type: SET_MODAL_PRODUCTO,
        toggleModal: true
    })
}
export const closeModal = () => (dispatch, getStore) => {
    const store = getStore()
    const data = store.producto.formProducto
    dispatch(initializeForm('producto', data))
    dispatch({
        type: SET_MODAL_PRODUCTO,
        toggleModal: false
    })
}

export const setTab = (tab) => (dispatch, getStore) => {
    dispatch({
        type: SET_TAB_PRODUCTO,
        tab: tab
    })
}
export const resetFracciones = () => (dispatch, getStore) => {
    dispatch({
        type: SET_FRACCIONES_PRODUCTO,
        fracciones: []
    })
}
export const cambioDescuentoUnitario = (valor, campo) => (dispatch, getStore) => {
    const {  values } = _.cloneDeep(getStore().form.producto);
    if(campo === 'precio_descuento'){
        if (valor){
            values.porcentaje = ((valor / parseFloat(values.precio)) * 100).toFixed(2);
            values.porcentaje = 100 - values.porcentaje;
        }
        else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }
    }
    else if(campo === 'precio'){
        if(valor){
            if(values.porcentaje){
                values.precio_descuento = ((values.porcentaje / 100) * parseFloat(values.precio)).toFixed(2);
                values.precio_descuento = values.precio - values.precio_descuento
            }else{
                values.porcentaje = 0;
                values.precio_descuento = values.precio
            }
        }else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }
    }
    else{
        if (valor){
            values.precio_descuento = ((valor / 100) * parseFloat(values.precio)).toFixed(2);
            values.precio_descuento = values.precio - values.precio_descuento
        }
        else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }

    }
    dispatch(initializeForm('producto', {...values}))
}
export const cambioDescuentoFraccion = (valor, campo) => (dispatch, getStore) => {
    const {  values } = _.cloneDeep(getStore().form.fraccion);
    if(campo === 'precio_descuento'){
        if (valor){
            values.porcentaje = ((valor / parseFloat(values.precio)) * 100).toFixed(2);
            values.porcentaje = 100 - values.porcentaje;
        }
        else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }
    }
    else if(campo === 'precio'){
        if(valor){
            if(values.porcentaje){
                values.precio_descuento = ((values.porcentaje / 100) * parseFloat(values.precio)).toFixed(2);
                values.precio_descuento = values.precio - values.precio_descuento
            }else{
                values.porcentaje = 0;
                values.precio_descuento = values.precio
            }
        }else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }
    }
    else{
        if (valor){
            values.precio_descuento = ((valor / 100) * parseFloat(values.precio)).toFixed(2);
            values.precio_descuento = values.precio - values.precio_descuento
        }
        else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }

    }
    dispatch(initializeForm('fraccion', {...values}))
}
export const cambioDescuentoFraccionEditar = (valor, campo) => (dispatch, getStore) => {
    const {  values } = _.cloneDeep(getStore().form.fraccionEditar);
    if(campo === 'precio_descuento'){
        if (valor){
            values.porcentaje = ((valor / parseFloat(values.precio)) * 100).toFixed(2);
            values.porcentaje = 100 - values.porcentaje;
        }
        else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }
    }
    else if(campo === 'precio'){
        if(valor){
            if(values.porcentaje){
                values.precio_descuento = ((values.porcentaje / 100) * parseFloat(values.precio)).toFixed(2);
                values.precio_descuento = values.precio - values.precio_descuento
            }else{
                values.porcentaje = 0;
                values.precio_descuento = values.precio
            }
        }else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }
    }
    else{
        if (valor){
            values.precio_descuento = ((valor / 100) * parseFloat(values.precio)).toFixed(2);
            values.precio_descuento = values.precio - values.precio_descuento
        }
        else{
            values.porcentaje = 0;
            values.precio_descuento = values.precio
        }

    }
    dispatch(initializeForm('fraccionEditar', {...values}))
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    getProveedores,
    agregarNuevaFraccion,
    eliminarFraccion,
    setEditCreateFraccion,
    openModal,
    closeModal,
    setTab,
    editarFraccion,
    resetFracciones,
    cambioDescuentoUnitario,
    cambioDescuentoFraccion,
    cambioDescuentoFraccionEditar
};
export const reducer = {
    [LOADER_PRODUCTO]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PRODUCTO]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PRODUCTO]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PRODUCTO]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PRODUCTO]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PRODUCTO]: (state, { filtro_producto }) => {
        return {...state, filtro_producto }
    },
    [SET_PROVEEDORES_PRODUCTO]: (state, { proveedores }) => {
        return {...state, proveedores }
    },
    [SET_MODAL_PRODUCTO]: (state, { toggleModal }) => {
        return {...state, toggleModal }
    },
    [SET_FORMPROD_PRODUCTO]: (state, { formProducto }) => {
        return {...state, formProducto }
    },
    [SET_FRACCIONES_PRODUCTO]: (state, { fracciones }) => {
        return {...state, fracciones }
    },
    [SET_EDITCREATE_PRODUCTO]: (state, { editCreateFraccion }) => {
        return {...state, editCreateFraccion }
    },
    [SET_TAB_PRODUCTO]: (state, { tab }) => {
        return {...state, tab }
    }
}
export const initialState = {
    cargando: false,
    proveedores: [],
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_producto: null,
    updateData: {},
    fracciones: [],
    toggleModal: false,
    formProducto: null,
    editCreateFraccion: {},
    tab: 'servicios'
};
export default handleActions(reducer, initialState)

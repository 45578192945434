import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Caja/caja';
import Caja from './Caja';

const mstp = state => {
    let me = state.login.me;
    return {...state.caja, me}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Caja)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Enfermeria/enfermeria';
import DetallePaciente from './DetallePaciente';

const mstp = state => {
    return {...state.enfermeria}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetallePaciente)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Bodega/estadobodega';
import DespachoCrear  from './DespachoCrear';

const mstp = state => {
    let nombreBodega = '';
    if(state.estadobodega.updateData){
        let bodega = state.estadobodega.updateData
        nombreBodega = bodega.nombre ? bodega.nombre : '';
    }
    return {
        ...state.estadobodega,
        nombreBodega
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DespachoCrear)

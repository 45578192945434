import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"

export default class ToolbarSimple extends Component {
  render() {
    return (
      <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-6 row">
                <div className="titulo d-flex align-items-center">
                    <img width={35} className="" src={this.props.icono} alt="Usuario" />
                    <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                </div>
            </div>
            <div className={`col-lg-4 col-md-4 p-0 search ${this.props.margen}`}>
              {(this.props.tituloBuscador &&
                <p className="ml-2 text-primary mt-5 mt-md-0 mt-lg-0"><strong>{this.props.tituloBuscador}</strong></p>
              )}
                {(this.props.buscar !== undefined) && (
                <Search buscar={this.props.buscar} buscador={this.props.buscador} placeholder={this.props.placeholder} />
                )}
            </div>
      </div>
    )
  }
}

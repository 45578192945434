import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderFieldCheck, renderCurrency, renderTextArea } from 'Utils/renderField/renderField';
import { formatAllDate, dateFormatter } from '../../../Utils/renderField/renderReadField';
import {api} from 'api/api';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import ProductoForm  from '../../EstadoBodega/Despacho/Formularios/ProductoForm';

let proveedores = [];
const getProveedores = (search) => {
    return api.get("proveedores", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                proveedores = [];
                data.results.forEach(x => {
                    proveedores.push(x)

                })
                return proveedores
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}

class RenderProductos extends Component {
    state = {
        openModal: false
    }
    constructor(props) {
        super(props)
    }
    openModal = () => {
        this.setState({ openModal: true });
    }
    closeModal = () => {
        this.setState({ openModal: false });
    }
    getProductos = (search)  =>{
        return api.get(`inventario/getProductosExistencia/${0}`,{search})
            .then((data) => {
                if (data){
                    return data.results
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
    }
    agregarProducto = (data, inventario, existencias) => {
        const { fields } = this.props;
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombre_completo,
            existencia: existencias,
            cantidad: data.cantidad,
            lote: inventario.lote.lote,
            lote_id: inventario.lote.id,
            vencimiento: inventario.lote.fecha_vencimiento,
            nuevo: true
        })
        this.closeModal();

    }
    render() {
        const { meta: { error, submitFailed } } = this.props
        const {  ciclos, fields, deshabilitado, editando, me } = this.props;

        return (
            <div className="col-12 px-0">
                {
                    this.state.openModal && (
                        <Modal open={this.state.openModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Agregar producto</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <ProductoForm
                                        ref={(instance) => {this.form = instance}}
                                        me={me}
                                        getProductos={this.getProductos}
                                        initCantidad={this.props.initCantidad}
                                        closeModal={this.closeModal} addProducto={this.agregarProducto}/>
                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="row col-sm-12 p-0 m-0">
                        <div className="col-sm-12 form-group np-r   mt-2 sin-borde p-0  over-h">
                            <table className="table table-striped table-sm table-responsive-sm m-0">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Lote</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Vencimiento</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-right">Cantidad</th>
                                    <th style={{width: "10%", minWidth: '160px'}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio_unitario);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top text-gris">
                                            {
                                                editando ?(
                                                    <span>{fields.get(index).fraccion.nombre_completo}</span>
                                                ) : (
                                                    <span>{fields.get(index).nombre_producto}</span>
                                                )
                                            }
                                        </td>
                                        <td className="sin-borde-top text-center text-gris ">
                                            {
                                                editando ?(
                                                    <span>{fields.get(index).lote.lote}</span>
                                                ) : (
                                                    <span>{fields.get(index).lote}</span>
                                                )
                                            }

                                        </td>
                                        <td className="sin-borde-top text-center text-gris ">

                                            {
                                                editando ? (
                                                    <span>{ dateFormatter(fields.get(index).lote.fecha_vencimiento) }</span>
                                                ) : (
                                                    <span>{ dateFormatter(fields.get(index).vencimiento) }</span>
                                                )
                                            }
                                        </td>
                                        <td className="sin-borde-top text-primary text-right">
                                            {fields.get(index).cantidad}
                                        </td>
                                        <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                            {
                                                !deshabilitado && !editando && (
                                                    <button
                                                        type="button"
                                                        className=" btn-danger btn-tabla "
                                                        onClick={() =>{
                                                            fields.remove(index)
                                                        }}
                                                    >X</button>
                                                )
                                            }

                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            {
                                !deshabilitado && !editando && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <button type="button" className="btn btn-celeste" onClick={this.openModal}>
                                                Agregar Productos
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                        </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                </div>}
            </div>
        )
    }
}

let DevolucionForm = props => {
    const { handleSubmit, ver_vale, editando, updateData, me } = props;

    let fecha = new Date();
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="row col-md-10">
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="proveedor" className="m-0">Proveedor*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                <Field
                                            name={`proveedor`}
                                            valueKey="id"
                                            disabled={editando}
                                            labelKey="nombre"
                                            label="proveedor"
                                            component={renderSearchSelect}
                                            loadOptions={getProveedores}
                                        />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha" className="m-0">Fecha:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    {
                                        !editando ? (
                                            <span className="text-gris font-weight-light">{formatAllDate(fecha)}</span>
                                        ) : (
                                            <span className="text-gris font-weight-light">{formatAllDate(updateData.fecha)}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-6 p-0">
                            <h5 className="text-uppercase text-left pl-4">Modo de reposición</h5>
                            <div className="col-md-12 m-0 p-0 d-flex justify-content-center">
                                <div className="col-md-11 row m-0 p-0 py-3 borde-gris">
                                    <div className="row col-lg-6 d-flex justify-content-center">
                                        <div className="col-auto pr-0 text-gris">
                                            Vale de crédito
                                        </div>
                                        <div className="col-md-2 col-2">
                                            <Field name="reintegro"
                                            disabled={editando}
                                            component="input" type="radio" value={'false'}/>
                                        </div>

                                    </div>
                                    <div className="row col-lg-6 d-flex justify-content-center">
                                        <div className="col-auto pr-0 text-gris">
                                            Reintegro de producto
                                        </div>
                                        <div className="col-md-2 col-2">
                                            <Field name="reintegro"
                                            disabled={editando}
                                            component="input" type="radio" value={'true'}/>
                                        </div>

                                    </div>

                                    {
                                        ver_vale && (
                                            <div className="col-md-12 row m-0 p-0">
                                                <div className="col-md-6">
                                                    <div className="col-md-12 col-sm-12">
                                                        <label htmlFor="no_vale" className="m-0">No vale:</label>
                                                    </div>
                                                    <div className="col-md-12  form-group">
                                                        <Field name="no_vale" disabled={editando} component={renderField} type="text" className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="col-md-12 col-sm-12">
                                                        <label htmlFor="monto" className="m-0">Monto cŕedito*:</label>
                                                    </div>
                                                    <div className="col-md-12  form-group">
                                                        <Field
                                                                disabled={editando}
                                                                name={`monto`}
                                                                type="number"
                                                                addClass={"text-right'"}
                                                                component={renderCurrency}
                                                                placeholder="credito"
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <h5 className="text-uppercase text-left">Observaciones de devolución</h5>
                            <Field
                                        name={`nota`}
                                        disabled={editando}
                                        component={renderTextArea} type="text" className="form-control"/>
                        </div>
                    </div>
                    <FieldArray name="detalle_movimiento"
                        bodega={0}
                        me={me}
                        editando={props.editando}
                        initCantidad={props.initCantidad}
                        component={RenderProductos} />
                    {
                        !editando && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Link className="btn btn-secondary m-1" to="/devoluciones">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </form>
    )
};
DevolucionForm = reduxForm({
    form: 'devolucion',
    validate: data => {
        const errors = {};
        if(!data.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(data.reintegro === 'false'){

            if(!data.monto){
                errors.monto = 'Campo requerido';
            }
        }
        if(!data.nota){
            errors.nota = 'Campo requerido';
        }
        return errors;
    },
    initialValues: {
        reintegro: 'false'
    }
})(DevolucionForm);
const selector = formValueSelector('devolucion');
DevolucionForm = connect(state => {
    const reintegro = selector(state, "reintegro");
    let ver_vale = false
    if(reintegro === 'false'){
        ver_vale = true;
    }
    return {
        ver_vale
    };
})(DevolucionForm);

export const DevolucionUpdateForm = reduxForm({
    form: 'devolucion',
    validate: data => {
        const errors = {};
        if(!data.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(data.reintegro === 'false'){
            if(!data.monto){
                errors.monto = 'Campo requerido';
            }
        }
        if(!data.nota){
            errors.nota = 'Campo requerido';
        }
        return errors;
    },
    asyncBlurFields: []
})(DevolucionForm);

export default DevolucionForm

import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/login';

import fcm from './modules/fcm';
import op from './modules/Compras/op';
import caja from './modules/Caja/caja';
import venta from './modules/Venta/venta';
import bodega from './modules/Bodega/bodega';
import cuenta from './modules/Cuenta/cuenta';
import gastos from './modules/Gastos/gastos';
import rcaja from './modules/Reportes/rcaja';
import compras from './modules/Compras/compras';
import usuario from './modules/Usuario/usuario';
import rcompra from './modules/Reportes/rcompra';
import aceptado from './modules/Compras/aceptado';
import clientes from './modules/Clientes/clientes';
import bitacora from './modules/Bitacora/bitacora';
import producto from './modules/Producto/producto';
import paciente from './modules/Paciente/paciente';
import rganancia from './modules/Reportes/rganancia';
import rporpagar from './modules/Reportes/rporpagar';
import categoria from './modules/Categoria/categoria';
import rinventario from './modules/Reportes/rinventario';
import estadobodega from './modules/Bodega/estadobodega';
import enfermeria from './modules/Enfermeria/enfermeria';
import puntoventa from './modules/Punto_venta/punto_venta';
import rvencimiento from './modules/Reportes/rvencimiento';
import proveedores from './modules/Proveedores/proveedores';
import cuenta_area from './modules/Cuenta_area/cuenta_area';
import devoluciones from './modules/Devoluciones/devoluciones';
import configuracion from './modules/Configuracion/configuracion';
import movimientobodega from './modules/Bodega/movimientosbodega';

export default combineReducers({
    form: formReducer,
    op,
    fcm,
    caja,
    venta,
    login,
    bodega,
    cuenta,
    gastos,
    rcaja,
    compras,
    usuario,
    rcompra,
    routing,
    bitacora,
    aceptado,
    paciente,
    producto,
    clientes,
    rganancia,
    rporpagar,
    categoria,
    enfermeria,
    puntoventa,
    proveedores,
    rinventario,
    cuenta_area,
    rvencimiento,
    estadobodega,
    devoluciones,
    configuracion,
    movimientobodega,
});

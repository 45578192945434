import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Table from 'Utils/Grid';
import { BreakLine } from '../../../Utils/tableOptions';
import { formatoMoneda, formatSelect, dateFormatter, formatNombre } from '../../../Utils/renderField/renderReadField';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import ToolbarSearch from 'Utils/Toolbar/ToolbarSearch';

function formatCategoria(cell){
    if(cell){
        return <span>{cell}</span>
    }
    return <span>Sin categoria</span>
}

export default class DetalleVenta extends Component {
    state = {
        activeTab: 'detalle'
    }
    componentWillMount() {
        this.props.getDetalleCompra(1);
    }
    toggle = (tab) => {
        if (this.state.tab !== tab) {
            this.setState({
                activeTab: tab
            })
            if (tab === 'detalle') {
                this.props.getDetalleVenta(1);
            } else {
                this.props.getCuentaVenta(1);
            }
        }
    }
    render() {
        const { cargando, data_detalle_venta, data_cuenta_venta, page } = this.props;
        return (
            <div className="mt-5">
                <ToolbarSearch 
                    titulo={" "} 
                    buscar={this.props.search} 
                    buscador={this.props.buscador} 
                    placeholder={this.props.placeholder}
                />
                <Nav tabs className="col-12  px-0 ">
                    <NavItem className="col-3 col-md-2 pl-0 pr-0">
                        <NavLink
                            className={classNames('py-2 text-center', { active: this.state.activeTab === 'detalle' })}
                            onClick={() => { this.toggle('detalle'); }}>
                            <h5>Detalle</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem className="col-3 col-md-2 pl-0 pr-0">
                        <NavLink
                            className={classNames('py-2 text-center', { active: this.state.activeTab === 'cuenta' })}
                            onClick={() => { this.toggle('cuenta'); }}>
                            <h5>Cuenta</h5>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="mt-0">
                    <TabPane tabId={"detalle"}>
                        <Table
                            onPageChange={this.props.getDetalleVenta}
                            data={data_detalle_venta}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={dateFormatter}
                                dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="orden" dataSort>Orden</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="cuenta" dataSort>Cuenta</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="subtotal" dataSort>Monto</TableHeaderColumn>

                        </Table>
                    </TabPane>
                    <TabPane tabId={"cuenta"}>
                        <Table
                            onPageChange={this.props.getCuentaVenta}
                            data={data_cuenta_venta}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="stock__producto__cuenta"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatCategoria}
                                dataField="nombre_cuenta" dataSort>Cuenta</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'center'}
                                dataFormat={formatoMoneda}
                                dataField="monto" dataSort>Monto</TableHeaderColumn>

                        </Table>
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push, go } from 'react-router-redux';
const url = 'cuenta';

const LOADER_CUENTA = 'LOADER_CUENTA';
const SET_DATA_CUENTA = 'SET_DATA_CUENTA';
const SET_DATACERRADA_CUENTA = 'SET_DATACERRADA_CUENTA';
const SET_PAGE_CUENTA = 'SET_PAGE_CUENTA';
const SET_PAGE_CUENTA_CERRADA = 'SET_PAGE_CUENTA_CERRADA';
const SET_UPDATE_DATA_CUENTA = 'SET_UPDATE_DATA_CUENTA';
const SET_BUSCADOR_CUENTA = 'SET_BUSCADOR_CUENTA';
const SET_FILTRO_CUENTA = 'SET_FILTRO_CUENTA';
const SET_TAB_CUENTA = 'SET_TAB_CUENTA';

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_CUENTA, cargando: true });
    const store = getStore();
    const search = store.cuenta.buscador;
    const filtro = store.cuenta.filtro_cuenta;
    let params = { page, search, pagado: false };

    if (filtro) {
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({ type: LOADER_CUENTA, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATA_CUENTA, data });
            dispatch({ type: SET_PAGE_CUENTA, page });
        }
        dispatch({ type: LOADER_CUENTA, cargando: false });
    })
};
const listar_cerrada = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_CUENTA, cargando: true });
    const store = getStore();
    const search = store.cuenta.buscador;
    const filtro = store.cuenta.filtro_cuenta;
    let params = { page, search, pagado: true };

    if (filtro) {
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({ type: LOADER_CUENTA, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATACERRADA_CUENTA, data_cerradas: data });
            dispatch({ type: SET_PAGE_CUENTA_CERRADA, page });
        }
        dispatch({ type: LOADER_CUENTA, cargando: false });
    })
};

const detail = id => (dispatch, getState) => {
    dispatch({ type: LOADER_CUENTA, cargando: true });
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({ type: LOADER_CUENTA, cargando: false });
        if (error.statusCode === 404) {
            dispatch(push('/reporte_cuentas'))
        }
    }).then((data) => {
        dispatch({ type: LOADER_CUENTA, cargando: false });
        if (data) {
            dispatch({ type: SET_UPDATE_DATA_CUENTA, updateData: data });
            let total_pagado = 0;
            data.movimientos.forEach(x => {
                total_pagado += x.monto;
            })
            let saldo_cuenta = data.monto - total_pagado;
            dispatch(initializeForm('pagos', {total_a_pagar: saldo_cuenta, efectivo:true}))
        }
    })
}

const actualizarUpdateData = (updateData) => (dispatch) => {
    dispatch({ type: SET_UPDATE_DATA_CUENTA, updateData });
}

const setVariablePrice = (precio, index, dato, orden, detalle) => (dispatch, getStore) => {
    let updateData = _.cloneDeep(getStore().cuenta.updateData);
    let newDeatil = []
    updateData.detalle_movimiento.forEach((item, key) => {
        if(item.id == index){
            item.new_price = precio.toString();
            item.dato = dato;
            item.orden = orden;
            item.detalle = detalle;
        }
        newDeatil.push(item);
    });

    updateData.detalle_movimiento = newDeatil;
    dispatch({ type: SET_UPDATE_DATA_CUENTA, updateData });
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.cuenta.values;
    let fecha = new Date(formData.fecha_ingreso)
    let hora = ''
    try {
        hora = formData.hora.split(":");
        fecha.setHours(Number(hora[0]), Number(hora[1]))
    } catch (error) {
        hora = new Date(formData.hora)
        fecha.setHours(hora.getHours(), hora.getMinutes())
    }

    formData.fecha_ingreso = fecha;
    dispatch({ type: LOADER_CUENTA, cargando: true })
    api.post(`${url}`, formData).then((data) => {
        dispatch({ type: LOADER_CUENTA, cargando: false })
        Swal('Éxito', 'Se ha creado la cuenta.', 'success')
            .then(() => {
                dispatch(go(-1));
            })
    }).catch((error) => {
        dispatch({ type: LOADER_CUENTA, cargando: false })
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({ type: LOADER_CUENTA, cargando: false })
    });
};
const agregar_abono = () => (dispatch, getStore) => {
    const formData = getStore().form.pagos.values;
    const store = getStore();
    const updateData = store.cuenta.updateData;
    let pagado = 0;
    updateData.movimientos.forEach(x=>{
        pagado += x.monto
    });
    // let saldo= updateData.monto - pagado;
    // if(formData.pago_efectivo){
    //     formData.pago_efectivo = saldo - Number(updateData.pago_tarjeta || 0) - Number(updateData.pago_cheque || 0)
    // }
    // let data = [];
    // data.push(formData)
    // formData.productos= data
    dispatch({ type: LOADER_CUENTA, cargando: true })
    api.post(`${url}/agregarPago/${updateData.id}`, formData).then((data) => {
        dispatch({ type: LOADER_CUENTA, cargando: false })
        Swal('Éxito', 'Se han agregado el nuevo abono.', 'success')
            .then(() => {
                dispatch(go(-1))
            })
    }).catch((error) => {
        dispatch({ type: LOADER_CUENTA, cargando: false })
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({ type: LOADER_CUENTA, cargando: false })
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({ type: LOADER_CUENTA, cargando: true });
    api.put(`${url}/${formData.id}`, formData)
        .then((data) => {
            dispatch({ type: LOADER_CUENTA, cargando: false });
            if (data) {
                Swal(
                    'Éxito',
                    'Datos editados correctamente.',
                    'success'
                ).then(() => {
                    dispatch(push('/reporte_cuentas'));
                })
            }
        })
        .catch((error) => {
            dispatch({ type: LOADER_CUENTA, cargando: false });
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            )
            if (error.statusCode === 404) {
                dispatch(push('/reporte_cuentas'));
            }
        })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({ type: LOADER_CUENTA, cargando: true });
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({ type: LOADER_CUENTA, cargando: false });
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({ type: LOADER_CUENTA, cargando: false });
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_BUSCADOR_CUENTA, buscador: search });
    dispatch(listar(1));
    dispatch(listar_cerrada());
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0) {
        filtro = null
    }
    dispatch({ type: SET_FILTRO_CUENTA, filtro_cuenta: filtro });
    dispatch(listar(1));
};

const setTab = (tab) => (dispatch, getStore) => {
    dispatch({
        type: SET_TAB_CUENTA,
        tab: tab
    })
}
const regresar = () => (dispatch, getStore) => {
    dispatch(go(-1));
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    agregar_abono,
    listar_cerrada,
    setTab,
    regresar,
    setVariablePrice,
    actualizarUpdateData,
};
export const reducer = {
    [LOADER_CUENTA]: (state, { cargando }) => {
        return { ...state, cargando }
    },
    [SET_DATA_CUENTA]: (state, { data }) => {
        return { ...state, data }
    },
    [SET_PAGE_CUENTA]: (state, { page }) => {
        return { ...state, page }
    },
    [SET_PAGE_CUENTA_CERRADA]: (state, { page }) => {
        return { ...state, page_cerrada: page }
    },
    [SET_UPDATE_DATA_CUENTA]: (state, { updateData }) => {
        return { ...state, updateData }
    },
    [SET_BUSCADOR_CUENTA]: (state, { buscador }) => {
        return { ...state, buscador }
    },
    [SET_FILTRO_CUENTA]: (state, { filtro_cuenta }) => {
        return { ...state, filtro_cuenta }
    },
    [SET_DATACERRADA_CUENTA]: (state, { data_cerradas }) => {
        return { ...state, data_cerradas }
    },
    [SET_TAB_CUENTA]: (state, { tab }) => {
        return { ...state, tab }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    page_cerrada: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_cerradas: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_cuenta: null,
    updateData: {},
    tab: 'activas'
};
export default handleActions(reducer, initialState)

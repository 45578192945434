import { iconsh } from 'icons';
import React, { Component } from 'react'
import TituloBodega from 'Utils/Header/TituloBodega';
import PagoForm from './PagoForm';
import { formatoMoneda } from '../../../Utils/renderField/renderReadField';
import ReciboMovimiento from '../../../Utils/ReciboMovimiento/reciboMovimiento';
import LoadMask from 'Utils/LoadMask/LoadMask';

function formatSubtotal(cell, row){
    let subtotal = 0;
    subtotal = row.cantidad * row.precio_descuento;
    //return formatoMoneda(subtotal)
    return formatoMoneda(subtotal)
}
export default class Pago extends Component {
    setFormulario = (data) => {
        this.props.setPagoForm(data);
    }
    render() {
        const { carrito, totales_compra, movimiento, cargando } = this.props;
        let data = [];
        return (
            <div className="col-md-12 row m-0 p-0 d-flex justify-content-between">
                {/* Lado derecho */}
                <div className="col-md-6 px-1 no-print">
                    <div className="col-md-12 p-0 container m-0 contenido-principal h85">
                        <div className="col-m-12 pt-4">
                            <TituloBodega
                                titulo={'Pago de compra'}
                                subtitulo={'Farmacia'}
                                estilo={`col-md-6`}
                                icono={iconsh.crud_caja_venta}
                                />
                        </div>
                        <div className="col-md-12 mt-5">
                            <h4 className="text-left">COMPRA</h4>
                        </div>
                        <div className="col-md-12 h35">
                            <LoadMask loading={cargando} blur_1>
                                <BootstrapTable
                                    stripped={true}
                                    data={carrito}>
                                    <TableHeaderColumn
                                        dataField="nombre_completo"
                                    >Producto/Servicio</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cantidad"
                                    >Cantidad</TableHeaderColumn>
                                    <TableHeaderColumn
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={formatSubtotal}
                                        dataField="existencias">Subtotal</TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>

                        </div>

                    </div>
                </div>
                {/* Lado derecho */}
                {/* Lado Izquierdo */}
                <div className="col-md-6 px-1 no-print">
                    <div className="col-md-12 p-0 container m-0 contenido-principal h85">
                        <div className="col-md-12 pt-4">
                            <div className="col-md-12 p-2 fondo-inverso-navbar ">
                                <h5 className="text-uppercase m-0 pr-3 text-right text-white">
                                    <img className="mr-2 pb-1" src={iconsh.crud_caja_venta2} height={35} />
                                    Pago
                                </h5>
                            </div>
                            <LoadMask loading={cargando} blur_1>
                                <PagoForm
                                    setPagoForm={this.props.setPagoForm}
                                    totales_compra={totales_compra}
                                    cancelar={this.props.regresar} onSubmit={this.props.create}/>
                            </LoadMask>
                        </div>
                    </div>
                </div>

                {/* Lado Izquierdo */}
                {
                    movimiento && (
                        <div>
                            <ReciboMovimiento movimiento={movimiento} />
                        </div>
                    )
                }
            </div>
        )
    }
}

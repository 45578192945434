import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReporte from '../../../Utils/Header/HeaderReporte';
import { iconsh, EXTRAS } from "icons";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import Table from 'Utils/Grid';
import { BreakLine } from '../../../Utils/tableOptions';
import { formatoMoneda, formatSelect, dateFormatter, formatAllDate } from '../../../Utils/renderField/renderReadField';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import ToolbarRInventario from '../../../Utils/Toolbar/ToolbarRInventario';
import Grafica from './Grafica';
//formatos par la tabla de alertas

// formatos para la tabla de movimientos
function formatMedicamento(cell, row, bla, index) {
  if (cell) {
    return <span>{cell.nombre_completo}</span>
  }
  return <span></span>
}
function fechaMovimiento(cell, row) {
    if (cell) {
        return <span>{formatAllDate(cell.fecha)}</span>
    }
    return <span></span>
}
function formatUsuario(cell, row) {
    if (cell) {
        return <span>{cell.usuario.first_name}</span>
    }
    return <span></span>
}
function formatTipo(cell, row) {
    if (cell) {
        return <div>
            <p className="m-0">{cell.tipo}</p>
            <p className="m-0">{cell.no_movimiento}</p>
        </div>
    }
    return <span></span>
}
function formatNota(cell, row) {
    if (cell) {
        let nombre = cell.nota;
        if(cell.destino) {
            nombre +=` Destino: ${cell.destino}`;
        }
        return <p className="m-0">
            {nombre}
        </p>
    }
    return <span></span>
}
export default class RInventario extends Component {
    state = {
        expandidos: [],
        inventario: null
    }
    componentWillMount() {
        this.props.listar_movimientos(this.props.page);
    }
    toggle = (tab) => {
        if (this.props.toggleTab !== tab) {
            this.props.setTab(tab)
            if (tab === 'alertas') {
            } else {
                this.props.listar_movimientos(this.props.page);
            }
        }
    }

    render() {
        const { cargando, data_movimiento, data, page, grafica_alerta, grafica_movimientos } = this.props;
        return (
            <div>
                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderReporte
                        icono={iconsh.crud_reportes}
                        titulo={'Movimientos de bodega'}
                        es_detalle
                    />
                </div>
                <div className="container m-0 contenido-principal mt-3 pt-3 col-md-12">
                    <div className="fondo-inverso-navbar " style={{ height: 40 }}></div>
                    <ToolbarRInventario
                        {...this.props}
                        buscar={this.props.search}
                        buscador={this.props.buscador}
                        // tituloBuscador={"Buscar por: Nombre, Producto"}
                        placeholder={"Buscar por: Nombre, Producto"}
                        // margen = "mt-15"
                    />

                        <Table
                                    onPageChange={this.props.listar_movimientos}
                                    data={data_movimiento}
                                    loading={cargando}
                                    page={page}>
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={fechaMovimiento}
                                        dataField="movimiento" dataSort>Fecha</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatUsuario}
                                        dataField="movimiento" dataSort>Usuario</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatTipo}
                                        dataField="movimiento" dataSort>Movimiento</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatMedicamento}
                                        dataField="fraccion" dataSort>Producto</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'right'}
                                        dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatNota}
                                        dataField="movimiento" dataSort>Motivo</TableHeaderColumn>

                                </Table>
                </div>
            </div>
        )
    }
}

import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { iconsh } from "icons";


export default class HeaderIngresoBodega extends Component {
    render() {
        const { updateData, principal}= this.props;
        let habilitado = false

        return (
            <div className="col-md-12  m-0">

                <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    {/* <button className="btn btn-primary btn-estadocuenta">
                    Agregar usuario
                </button> */}

                    {
                        this.props.texto3 && (
                            ((typeof this.props.funcion3) === "string")  ? (
                                <Link className="btn btn-fondo mt-2" to={this.props.funcion3} >
                                    {this.props.texto3}
                                </Link>

                            ):(
                                <button className="btn btn-fondo mr-2 mt-2 " onClick={e => {
                                    e.preventDefault()
                                    this.props.funcion3()
                                }}>
                                    {this.props.texto3}
                                </button>
                            )
                        )
                    }
                    {
                        (this.props.texto2 && this.props.principal) && (
                            ((typeof this.props.funcion2) === "string")  ? (
                                <Link className="btn btn-secondary mt-2 ml-md-2" to={this.props.funcion2} >
                                    {this.props.texto2}
                                </Link>

                            ):(
                                <button className="btn btn-secondary mr-2 mt-2 ml-md-2" onClick={ e => {
                                    e.preventDefault();
                                    this.props.funcion2();
                                }} >
                                    {this.props.texto2}
                                </button>
                            )
                        )
                    }
                    {
                     this.props.texto1 && (
                            ((typeof this.props.funcion1) === "string")  ? (
                                <Link className="btn btn-primary mt-2 ml-md-2" to={this.props.funcion1} >
                                    {this.props.texto1}
                                </Link>

                            ):(
                                <button className="btn btn-primary mt-2 ml-md-2" onClick={e =>{
                                    e.preventDefault()
                                    this.props.funcion1()
                                }} >
                                    {this.props.texto1}
                                </button>
                            )

                        )
                    }
                </div>
                <div className="col-md-6 mt-3" >
                    <h5 className="text-primary text-md-left text-center text-uppercase m-0 pl-md-4 ml-md-3">{this.props.titulo}</h5>
                    <h3 className="text-md-left text-uppercase text-center">
                        <img className="mr-2 pb-1" src={iconsh.crud_bodegas} height={25} />
                        {this.props.subtitulo}
                    </h3>
                </div>
            </div>

        )
    }
}

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'devolucion';

const LOADER_DEV = 'LOADER_DEV';
const SET_DATA_DEV = 'SET_DATA_DEV';
const SET_PAGE_DEV = 'SET_PAGE_DEV';
const SET_PAGE_CRED = 'SET_PAGE_CRED';
const SET_UPDATE_DATA_DEV ='SET_UPDATE_DATA_DEV';
const SET_BUSCADOR_DEV = 'SET_BUSCADOR_DEV';
const SET_FILTRO_DEV = 'SET_FILTRO_DEV';
const SET_TOTALCREDITOS_DEV = 'SET_TOTALCREDITOS_DEV';
const SET_ACTIVETAB_DEV = 'SET_ACTIVETAB_DEV';
const SET_PROVEEDORESSELECT_DEV = 'SET_PROVEEDORESSELECT_DEV';
const SET_CREDITOS_DEV = 'SET_CREDITOS_DEV';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_DEV, cargando: true});
    const store = getStore();
    const search = store.devoluciones.buscador;
    const filtro = store.devoluciones.filtro_dev;
    let params = {page, search};

    if(filtro){
        params['proveedor__id'] = filtro.id
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_DEV, data});
            dispatch({type: SET_PAGE_DEV, page});
        }
        dispatch({type: LOADER_DEV, cargando: false});
    })
};
const listarcreditos = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_DEV, cargando: true});
    const store = getStore();
    const search = store.devoluciones.buscador;
    const filtro = store.devoluciones.filtro_dev;
    let params = {page, search};

    if(filtro){
        params['id'] = filtro.id
    }
    api.get(`proveedores/getCreditos`, params).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_CREDITOS_DEV, creditos:data});
            dispatch({type: SET_PAGE_CRED, page});
        }
        dispatch({type: LOADER_DEV, cargando: false});
    })
};
const getTotalCreditos = () => (dispatch, getState) =>{
    api.get(`proveedores/getTotalCreditos`).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(data){
            dispatch({type: SET_TOTALCREDITOS_DEV, total_creditos: data.total});
        }
    })
}
const getProveedoresSelect = () => (dispatch, getState) =>{
    api.get(`proveedores/getProveedoresSelect`).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(data){
            dispatch({type: SET_PROVEEDORESSELECT_DEV, proveedores_select: data});
        }
    })
}
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_DEV, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_DEV, updateData: data});
            dispatch(initializeForm('devolucion', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.devolucion.values);

    if(formData.reintegro === 'false'){
        formData.reintegro = false;
    }else{
        formData.reintegro = true;
    }
    dispatch({type: LOADER_DEV, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_DEV, cargando: false})
        Swal('Éxito', 'Se ha creado la devolución.', 'success')
        .then(() => {
            dispatch(push('/devoluciones'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_BODEGA, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.devolucion.values);
    if(formData.reintegro === 'false'){
        formData.reintegro = false;
    }else{
        formData.reintegro = true;
    }
    dispatch({type: LOADER_DEV, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_DEV, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_DEV, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_DEV, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_DEV, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_DEV, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_DEV, buscador: search});
    const store = getStore();
    const tab = store.devoluciones.activeTab;
    if(tab == 'devoluciones'){
        dispatch(listar(1));
    }else{
        dispatch(listarcreditos());
    }
};
const filtro = (filtro) => (dispatch, getStore) => {
    const store = getStore();
    const tab = store.devoluciones.activeTab;
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_DEV, filtro_dev: filtro});
    if(tab == 'devoluciones'){
        dispatch(listar(1));
    }else{
        dispatch(listarcreditos());
    }
};
const setTab =(tab) => (dispatch, getStore) => {
    dispatch({
        type: SET_ACTIVETAB_DEV,
        activeTab: tab
    })
}

const initCantidad  = (lote, lotes) => (dispatch, getStore) => {
    const formProducto = getStore().form.productoform;
    const loteID = lote.target.value;
    let loteResult = _.find(lotes, (ob => ob.lote.id == loteID));
    if(loteResult) {
        if(loteResult.existencias >= 1) {
            dispatch(initializeForm('productoform', {
                ...formProducto.values,
                cantidad: 1,
                existencias: loteResult.existencias,
                vencimiento: loteResult.lote.fecha_vencimiento,
            }));
        }else {
            dispatch(initializeForm('productoform',
                {...formProducto.values,
                    cantidad: '',
                    existencias: loteResult.existencias,
                    vencimiento: loteResult.lote.fecha_vencimiento,
                }));
        }
    } else {
        dispatch(initializeForm('productoform', {
            ...formProducto.values,
            cantidad: '',
            existencias: 0,
        }));
    }

}

export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    listarcreditos,
    getTotalCreditos,
    getProveedoresSelect,
    initCantidad,
    setTab
};
export const reducer = {
    [LOADER_DEV]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_DEV]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_DEV]: (state, { page }) => {
        return {...state, page }
    },
    [SET_PAGE_CRED]: (state, { page }) => {
        return {...state, page_cred: page }
    },
    [SET_UPDATE_DATA_DEV]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_DEV]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_DEV]: (state, { filtro_dev }) => {
        return {...state, filtro_dev }
    },
    [SET_TOTALCREDITOS_DEV  ]: (state, { total_creditos }) => {
        return {...state, total_creditos }
    },
    [SET_ACTIVETAB_DEV]: (state, { activeTab }) => {
        return {...state, activeTab }
    },
    [SET_PROVEEDORESSELECT_DEV]: (state, { proveedores_select }) => {
        return {...state, proveedores_select }
    },
    [SET_CREDITOS_DEV]: (state, { creditos }) => {
        return {...state, creditos }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    page_cred: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    creditos: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_dev: null,
    updateData: {},
    total_creditos: 0,
    activeTab: 'devoluciones',
    proveedores_select: []
};
export default handleActions(reducer, initialState)

import React, { Component, Fragment } from 'react';
import Table from 'Utils/Grid';
import { iconsh } from 'icons';
import { Link } from 'react-router-dom';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter2 } from 'Utils/Acciones/Acciones';
import { BreakLine } from '../../../Utils/tableOptions';
import { formatUsuario } from '../../../Utils/renderField/renderReadField';


function formatPrincipal(cell) {
  if (cell) {
    return (<span>Sí</span>);
  }
  return (<span>No</span>);
}

export default class AreaGrid extends Component {
  componentWillMount() {
    this.props.listar(this.props.page);
  }

    botonModal = (id, row) => {
      const { me } = this.props;
      return (
        <Link className="btn btn-fondo" to={`/area_estado/${id}`}>
          {row.encargado ? (
            <Fragment>
              {row.encargado.id != me.id ? (
                <span>Ingresar</span>
              ) : (
                <span>Volver a mi área</span>
              )}
            </Fragment>
          ) : (
            <span>Solicitar turno</span>
          )}
        </Link>
      );
    }

    accionesFormat = (cell, row) => {
      const { me, destroy } = this.props;
      let objecto = {
        cell, row,
      };
      let opciones = {};
      if (row.tiene_inventario) {
        opciones = { adicional: this.botonModal };
      }
      if (me && me.is_superuser) {
        opciones = { ...opciones, editar: '/areas_todo/editar', eliminar: destroy };
      }
      objecto = { ...opciones, ...objecto };
      // { adicional: this.botonModal, editar: '/areas_todo/editar', eliminar: destroy }
      return activeFormatter2(objecto);
    }

    render() {

      const {
        listar,
        me,
      } = this.props;
      const {
        cargando,
        page,
        data,
      } = this.props;
      return (
        <div className="container m-0 contenido-principal row d-flex justify-content-center">
          {me.is_superuser && (
            <HeaderSimple
              texto="Agregar Área"
              ruta="/areas_todo/crear"
            />
          )}

            <ToolbarSimple
              titulo="Áreas de bodega"
              icono={iconsh.crud_areas}
              buscar={this.props.search}
              buscador={this.props.buscador}
              // tituloBuscador={"Buscar por: Nombre"}
              placeholder={"Buscar por: Nombre"}
              // margen = "mt-40"
            />
          <div className="mt-3 col-md-12">
            <Table
              onPageChange={listar}
              data={data}
              loading={cargando}
              page={page}
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                width={180}
                tdStyle={{ paddingRight: 20 }}
                thStyle={{ paddingRight: 20 }}
                dataAlign="center"
                dataFormat={this.accionesFormat}
              >
                Acciones
              </TableHeaderColumn>
              <TableHeaderColumn
                tdStyle={BreakLine}
                thStyle={BreakLine}
                dataField="nombre"
                dataSort
              >
                Nombre
              </TableHeaderColumn>
              <TableHeaderColumn
                tdStyle={BreakLine}
                thStyle={BreakLine}
                dataFormat={formatPrincipal}
                dataField="principal"
                dataSort
              >
                Principal
              </TableHeaderColumn>
              <TableHeaderColumn
                tdStyle={BreakLine}
                thStyle={BreakLine}
                dataFormat={formatUsuario}
                dataField="encargado"
                dataSort
              >
                Responsable
              </TableHeaderColumn>
            </Table>
          </div>
        </div>
      );
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency, renderTextArea } from 'Utils/renderField/renderField';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import Titulo from '../Titulo';
import { iconsh } from "icons";


class RenderProductos extends React.Component{
    state = {
        openModal: false
    }
    constructor(props){
        super(props)
    }
    openModal = () => {
        this.setState({openModal: true});
    }
    closeModal = () => {
        this.setState({openModal: false});
    }
    agregarProducto = (data) => {
        const {  fields } = this.props;
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombrecompleto,
            nuevo: true
        })
        this.closeModal();

    }
    render() {
        const { meta: { error, submitFailed } } = this.props
        const {  ciclos, fields, deshabilitado } = this.props;

        return (
            <div className="col-12 px-0">

                <div className="row col-sm-12 p-0 m-0">

                        <div className="col-sm-12 form-group np-r  no-striped mt-2 sin-borde p-0  over-h">
                            <table className="table table-sm table-responsive-sm m-0">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Cantidad</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-center">Precio unitario</th>
                                    <th style={{width: "15%"}} className="text-center"  >Sub total</th>
                                    <th style={{width: "10%"}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio_unitario);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top font-italic">
                                            {fields.get(index).nombre_producto}
                                        </td>
                                        <td className="sin-borde-top text-center text-rosado">
                                            <Field
                                                disabled={deshabilitado}
                                                name={`${producto}.cantidad`}
                                                type="number"
                                                addClass={"text-right"}
                                                component={renderField}
                                                placeholder="Representante"
                                            />
                                        </td>
                                        <td className="sin-borde-top text-center text-rosado">
                                            <Field
                                                disabled={deshabilitado}
                                                name={`${producto}.precio_unitario`}
                                                type="number"
                                                addClass={"text-right"}
                                                component={renderCurrency}
                                                placeholder="Representante"
                                            />
                                        </td>
                                        <td className="sin-borde-top text-right">
                                            <RenderCurrency value={subtotal || 0} />
                                        </td>
                                        <td className="text-center sin-borde-top" style={{width: "10%"}}>


                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                </div>}

            </div>
      )
    }

}

let OrdenPagoForm = props => {
    const { handleSubmit, deshabilitado, total } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="col-md-12">
                        <Titulo icono={iconsh.crudOc} titulo={'Orden de Pago'} />
                        <span className="text-primary h5 mr-3">Total Compra:</span>
                        <RenderCurrency value={total || 0} className={'text-verde h4'}/>
                    </div>
                    <FieldArray name="productos"
                        deshabilitado={deshabilitado}
                        component={RenderProductos} />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Descripcion" className="m-0">Descripción de actualización*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="descripcion_actualizacion"
                                    component={renderTextArea}
                                    type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="a" className="m-0">No. comprobante*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="factura" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={props.cancelar}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
OrdenPagoForm = reduxForm({
    form: 'ordenpago',
    validate: data => {
        return validate(data, {
        })
    }
})(OrdenPagoForm);
const selector = formValueSelector('ordenpago');
OrdenPagoForm = connect(state => {
    let resultado = []
    const productos = selector(state, "productos");
    if(productos){
        resultado = productos
    }
    let total = 0;
    resultado.forEach(x => {
        if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
            total += Number(x.cantidad) * Number(x.precio_unitario);
        }
    });
    return {
        total
    };
})(OrdenPagoForm);
export default OrdenPagoForm



import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';
import { iconsh, EXTRAS } from  "icons";
import {Animated} from "react-animated-css";
import { RenderCurrency } from 'Utils/renderField/renderReadField';


let Paso1Form = props => {
    const { handleSubmit, caja_nombre, monto_inicial } = props;
    return(
            <form onSubmit={handleSubmit} className="fondo-autorizacion pt-5">
                <div className="form-group grid-container m-0">
                    <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                        <div className="col-md-12 row m-0">
                            <div className="col-md-12 d-flex justify-content-center">
                                <img src={iconsh.crud_caja_venta}  height={25}/>
                            </div>
                            <div className="col-md-12">
                                <h5 className="text-center ">{caja_nombre}</h5>
                            </div>
                        </div>
                        <div className="col-md-12 row d-flex justify-content-center m-0">
                            <div className="col-md-6">
                                <h2 className="text-uppercase text-azul">Aperturar caja</h2>
                                <div className="text-center">
                                    <span className="text-primary">{props.usuario}</span>
                                </div>
                                <div className="text-center">
                                    <span className="text-gris">Total en caja:  </span>
                                    <RenderCurrency value={monto_inicial}  className={'text-primary  font-weight-bold'}/>
                                </div>
                                <div className="col-12">
                                    <div className="col-md-12 col-sm-12 text-center">
                                        <label htmlFor="password" className="m-0 text-center">Contraseña</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="password" component={renderField} type="password" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <button type="submit" className="btn btn-success-outline  m-1">Aperturar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    )
};
Paso1Form = reduxForm({
    form: 'autorizacion',// <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'password': validators.exists()('Campo requerido.'),
        })
    },
})(Paso1Form);
export default Paso1Form

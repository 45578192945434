import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import FormFraccion from './Crear/FraccionForm';
import FormEditar from './Crear/FormEditarFraccion'
import { checkFormatter, formatoMoneda } from '../../../Utils/renderField/renderReadField';

function contenidoFormatter(cell, row){
    const fraccion = (row.capacidad_maxima == 1) ? " fracción " : " fracciones ";
    const contenido = row.contenido !== undefined ? " de " + row.contenido : '';
    const descripcion = row.capacidad_maxima + fraccion + contenido;


    return(
        <React.Fragment>
            {/*<span>{cell} {row.contenido ? row.contenido + 's':''}</span>*/}
            <span>{descripcion}</span>
        </React.Fragment>
    )
}
export default class Fracciones extends Component {
    state = {
        lugar:'primero',
        posicion: 'arriba',
        primero: null,
        siguiente: null,
        ultimo: null,
        editar:false,
        initialValues: {}
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.fraccionable !== this.props.fraccionable){
            if(this.props.fraccionable && this.props.fracciones){
            }
        }
    }
  eliminar = () => {

  }
  editar = () => {

  }

  botonModal = (id, row, otro, index) => {
    const { fracciones } = this.props
    let cantidad = fracciones.length - 1
    if(index == 0 && fracciones.length == 1){
        return (<img className="action-img" title="Agregar fracción" src={require("../../../../../../assets/img/icons/mas1.png")}
        onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                        lugar: 'centro',
                        posicion:'arriba',
                        initialValues:{posicion:'arriba'},
                        primero: fracciones[0],
                        siguiente: fracciones[0],
                        ultimo: fracciones[0],
                        editar: false
                    })
                    this.props.setEditCreateFraccion(row);
                    this.props.openModal();
                 }}
                  alt="agregar fracciones" />)
    }
    else if(index == 0){
        return (<img className="action-img" title="Ingresar mayor" src={require("../../../../../../assets/img/icons/presentacion_mayor1.png")}
        onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                        lugar:'primero',
                        posicion:'arriba',
                        initialValues:{posicion:'arriba'},
                        primero: fracciones[0],
                        ultimo: fracciones[fracciones.length - 1],
                        editar:false
                    })
                    this.props.setEditCreateFraccion(row);
                    this.props.openModal();
                 }}
                  alt="agregar fracciones" />)
    }
    else if(index == cantidad){
        return (<img className="action-img" title="Ingresar menor" src={require("../../../../../../assets/img/icons/presentacion_menor1.png")}
        onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                        lugar:'ultimo',
                        posicion:'abajo',
                        initialValues:{posicion:'abajo'},
                        primero: fracciones[0],
                        ultimo: fracciones[fracciones.length - 1],
                        editar:false
                    })
                    this.props.setEditCreateFraccion(row);
                     this.props.openModal();
                 }}
                  alt="agregar fracciones" />)
    }

}
eliminar = (id, row) => {
    this.props.eliminarFraccion(id)
}
editar = (id, row) => {
    this.props.setFormFraccion(row);
    this.setState({initialValues: row, editar:true})
    this.props.openModal()
}

render() {
    const { fracciones } = this.props;
    return (
      <div>
          {
            <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                <div  style={{ Width: '100%' }}>
                    <div className="modal-header">
                        <div className="panel-body col-md-12">
                            <div className="borde-abajo pb-2">
                                <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                <span className="ml-2 text-uppercase text-titulo"><strong>Producto fraccionado</strong></span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                    {
                        this.state.editar ? (
                            <FormEditar
                                initialValues={this.state.initialValues}
                                fraccion={this.state.initialValues}
                                cerrar={this.props.closeModal}
                                onSubmit={this.props.editarFraccion}
                                setPorcentaje={this.props.setPorcentaje}
                                cambioDescuentoUnitario={this.props.cambioDescuentoFraccionEditar}
                                setPrecio_descuento={this.props.setPrecio_descuento}
                            />
                        ):(
                            <FormFraccion
                                initialValues={this.state.initialValues}
                                lugar={this.state.lugar}
                                cerrar={this.props.closeModal}
                                producto={this.props.formProducto}
                                fracciones={this.props.fracciones}
                                primero={this.state.primero}
                                ultimo={this.state.ultimo}
                                editar={this.state.editar}
                                cambioDescuentoUnitario={this.props.cambioDescuentoFraccion}
                                setPorcentaje={this.props.setPorcentaje}
                                setPrecio_descuento={this.props.setPrecio_descuento}
                                onSubmit={this.props.agregarNuevaFraccion}/>
                        )
                    }

                    </div>
                </div>
            </Modal>

          }
        <BootstrapTable data={ fracciones } options={{noDataText:'No hay registros'}}>
            <TableHeaderColumn
                isKey
                dataField='presentacion'
                dataAlign="right" width={180}
                thStyle={{paddingRight: '50px'}}
                tdStyle={{paddingRight: '50px'}}
                dataFormat={activeFormatter({ adicional2:this.botonModal,  editarCampos:this.editar, eliminar:this.eliminar})}>Acciones</TableHeaderColumn>
            <TableHeaderColumn

                dataField='presentacion'>Presentación</TableHeaderColumn>
            <TableHeaderColumn
                dataField='capacidad_maxima'
                dataFormat={contenidoFormatter}>contiene</TableHeaderColumn>

            <TableHeaderColumn
                dataFormat={formatoMoneda}
                dataAlign={'right'}
                dataField='precio'>Precio etiqueta</TableHeaderColumn>
            <TableHeaderColumn
                dataFormat={formatoMoneda}
                dataAlign={'right'}
                dataField='precio_descuento'>Precio descuento</TableHeaderColumn>
            <TableHeaderColumn
                dataFormat={checkFormatter}
                dataAlign={'center'}
                dataField='vendible'>Vendible</TableHeaderColumn>
            <TableHeaderColumn
                dataFormat={checkFormatter}
                dataAlign={'center'}
                dataField='alerta'>Alerta</TableHeaderColumn>

        </BootstrapTable>
        {
            fracciones.length == 0 && (
                <div className="col-md-12 row d-flex justify-content-center">
                    <button type="button"
                        onClick={e => {
                            e.preventDefault()
                            this.props.openModal();
                        }}
                        className="btn btn-success">
                        Agregar fracción
                    </button>
                </div>
            )
        }

      </div>
    )
  }
}

import React, { Component } from 'react'
import moment from "moment";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReporte from '../../../Utils/Header/HeaderReporte';
import { iconsh } from "icons";
import Filtro from './Filtros';
import Grafica from './Grafica';
import DetalleCaja from './DetalleCaja';
import {opcionesG1} from 'Utils/Graficas/opciones';
export default class RCaja extends Component {
    componentWillMount() {
        this.props.getGraficaCaja();
        this.props.listar(this.props.page);
    }
    render() {
        const { cargando, data_grafica } = this.props;
        const opciones = opcionesG1;
        opciones.rotateLabels = - 70;
        const ANIO_INICIAL = 2018;
        const ANIO_ACTUAL = moment().toDate().getFullYear();
        let year_list = [];
        for (let new_year = ANIO_INICIAL; new_year <= ANIO_ACTUAL; new_year++) {
            year_list.push({ value: new_year, label: new_year });
        }
        return (
            <div>
                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderReporte
                        icono={iconsh.crud_reportes}
                        titulo={'Cajas'}
                        es_detalle
                    />
                </div>
                <div className="container m-0 contenido-principal mt-3 pt-3 col-md-12">
                <div className="fondo-inverso-navbar mb-5" style={{height:40}}></div>
                    <LoadMask loading={cargando} dark blur>
                        <div className="col-md-12 row m-0 mt-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <Filtro {...this.props}  ver_rango
                                    year_list={year_list}/>
                            </div>
                            <div className="col-md-8">
                                <Grafica datum={data_grafica} opciones={opciones}/>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <DetalleCaja {...this.props} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        )
    }
}

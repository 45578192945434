import React from 'react'
import { Link } from 'react-router-dom';
import {MOVIMIENTO_BODEGA} from '../../../../utility/constants';
import { formatAllDate } from '../../../Utils/renderField/renderReadField';
export default function IngresoCard(props) {
    let ingreso = props.ingreso;
  return (
    <div className="card-ingreso m-2 col-md-3 p-0">
      <div className={`${ ingreso.tipo_id === MOVIMIENTO_BODEGA.DESPACHO ? "header-azul" : "header-verde"}`} ></div>
      <div className="col-md-12  m-0 p-3">
        <div className="col-md-12">
            <div className="text-center text-md-center">
                <span className="text-uppercase font-weight-bold text-primary">
                { ingreso.tipo_id === MOVIMIENTO_BODEGA.DESPACHO ? "Despacho desde " : "Reintegro de "}
                {ingreso.proveedor ? ingreso.proveedor.nombre : ingreso.bodega.nombre }
                </span>
            </div>

            <div className="text-gris text-center text-md-center">
                <span>{ formatAllDate(ingreso.fecha) }</span>
            </div>
        </div>
        <div className="col-md-12 mt-2 d-flex align-items-center justify-content-center">
            <Link className={`btn ${ ingreso.tipo_id === MOVIMIENTO_BODEGA.DESPACHO ? "btn-success" : "btn-primary"}`}
                to={`${ingreso.tipo_id === MOVIMIENTO_BODEGA.DESPACHO ? `/area_estado/ingreso/detalle/${ingreso.id}`:`/area_estado/ingresodevolucion/${ingreso.id}`}`} >
                Ver
            </Link>
        </div>
      </div>
    </div>
  )
}

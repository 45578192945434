import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Categoria/categoria';
import CategoriaGrid from './CategoriaGrid';

const mstp = state => {
    return {...state.categoria}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CategoriaGrid)

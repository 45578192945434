import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchCreateSelect } from 'Utils/renderField/renderField';
import { formatAllDate } from '../../../Utils/renderField/renderReadField';
import { api } from 'api/api';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import ProductoForm  from './Formularios/ProductoForm';

let proveedores = []
let productos = []

const getBodega = (search) => {
    return api.get("bodega", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                proveedores = [];
                data.results.forEach(x => {
                    proveedores.push({ value: x.id, label: x.nombre })
                })
                return proveedores
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}
class RenderProductos extends Component {
    state = {
        openModal: false
    }
    constructor(props) {
        super(props)
    }
    openModal = () => {
        this.setState({ openModal: true });
    }
    closeModal = () => {
        this.setState({ openModal: false });
    }
    getProductos = (search)  =>{
        return api.get(`inventario/getFraccionesDisponibles/${this.props.bodega.id ? this.props.bodega.id : 0}`,{search}).catch((error) => {})
            .then((data) => {
                productos = []
                if (data){
                    productos = data.results
                    return data.results
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
    }
    agregarProducto = (data, lote, existencias) => {
        const { fields } = this.props;
        fields.push({
            producto_id: data.producto.id,
            nombre_producto: data.producto.nombre_completo,
            existencia: existencias,
            cantidad: data.cantidad,
            lote: lote.lote.lote,
            lote_id: lote.lote.id,
            vencimiento: lote.lote.fecha_vencimiento,
            nuevo: true
        })
        this.closeModal();

    }
    render() {
        const { meta: { error, submitFailed } } = this.props
        const {  bodega, fields, deshabilitado } = this.props;
        return (
            <div className="col-12 px-0">
                {
                    this.state.openModal && (
                        <Modal open={this.state.openModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Agregar producto</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <ProductoForm
                                        ref={(instance) => {this.form = instance}}
                                        getProductos={this.getProductos}
                                        bodega={bodega}
                                        initCantidad={this.props.initCantidad}
                                        closeModal={this.closeModal} addProducto={this.agregarProducto}/>
                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="row col-sm-12 p-0 m-0">
                        <div className="col-sm-12 form-group np-r   mt-2 sin-borde p-0  over-h">
                            <table className="table table-striped table-sm table-responsive-sm m-0">
                                <tbody>
                                <tr className="header-tabla">
                                    <th style={{width: "40%", minWidth: '160px'}} >Producto</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-center" >Lote</th>
                                    <th style={{width: "10%", minWidth: '90px'}} className="text-right" >Existencia en bodega</th>
                                    <th style={{width: "15%" , minWidth: '160px'}} className="text-right">Cantidad </th>
                                    <th style={{width: "10%", minWidth: '160px'}}></th>
                                </tr>
                                {fields.map((producto, index) => {
                                    const todos = fields.getAll(); //Obtiene todos los productos
                                    todos[index]['index'] = index
                                    let subtotal = 0;
                                    const prod = fields.get(index); // Producto actual
                                    try {
                                        subtotal = Number(prod.cantidad) * Number(prod.precio_unitario);
                                    } catch (error) {
                                        subtotal = 0;
                                    }
                                    return (
                                        <tr key={index}>
                                        <td className="sin-borde-top text-gris">
                                            {fields.get(index).nombre_producto}
                                        </td>
                                        <td className="sin-borde-top text-center text-gris ">
                                            {fields.get(index).lote}
                                        </td>
                                        <td className="sin-borde-top text-right text-gris ">
                                            {fields.get(index).existencia}
                                        </td>
                                        <td className="sin-borde-top text-primary text-right">
                                            {fields.get(index).cantidad}
                                        </td>
                                        <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                            {
                                                !deshabilitado && (
                                                    <button
                                                        type="button"
                                                        className=" btn-danger btn-tabla "
                                                        onClick={() =>{
                                                            fields.remove(index)
                                                        }}
                                                    >X</button>
                                                )
                                            }

                                        </td>
                                    </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            {
                                !deshabilitado && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <button type="button" className="btn btn-celeste" onClick={this.openModal}>
                                                Agregar Productos
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                        </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                </div>}
            </div>
        )
    }
}


let DespachoForm = props => {
    const { handleSubmit } = props;
    let fecha = new Date()
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row col-md-12 d-flex justify-content-center">
                        <div className="row col-md-8">
                            <div className="col-md-8 p-0 px-md-2 row">
                                <div className="col-md-8 p-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="proveedor" className="m-0">Despachar a*:</label>
                                    </div>
                                    <Field
                                        name={`uuid`}
                                        type={'hidden'}
                                        component={renderField}
                                    />
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name={`bodega`}
                                            valueKey="id"
                                            labelKey="nombre"
                                            label="proveedor"
                                            component={renderSearchCreateSelect}
                                            loadOptions={props.getBodega}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 p-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="fecha" className="m-0">Fecha y hora:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <span className="font-weight-light text-gris">{formatAllDate(fecha)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FieldArray name="productos"
                        bodega={props.bodega}
                                initCantidad={props.initCantidad}
                        component={RenderProductos} />
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" onClick={e=>{
                                    e.preventDefault()
                                    props.regresar()
                                }} className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
DespachoForm = reduxForm({
    form: 'Despacho',
    validate: data => {
        const errors = {};
        if(!data.bodega){
            errors.bodega = 'Campo requerido'
        }
        if(!data.productos || data.productos.length == 0){
            errors.productos = 'Campo requerido'
        }else{
            const productosErrors = [];
            data.productos.forEach((producto, index)=> {
                const productoError = {};
                if(!producto.cantidad){
                    productoError.cantidad ='Campo requerido';
                    productosErrors[index] = productoError
                }else{
                    if(Number(producto.cantidad) <= 0){
                        productoError.cantidad = "Debe ser una cantidad mayor a 0.";
                        productosErrors[index] = productoError
                    }else{
                        if(Number(producto.cantidad) > Number(producto.existencia)){
                            productoError.cantidad = "Debe ser una cantidad menor o igual a la existencia";
                            productosErrors[index] = productoError
                        }
                    }
                }
            })
            if(productosErrors.length){
                errors.productos = productosErrors;
            }
        }
        return errors;
    }
})(DespachoForm);
export default DespachoForm

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Punto_venta/punto_venta';
import {
    listarMovimientos,
    setIdApertura
} from '../../../../../redux/modules/Caja/caja';
import PuntoVenta from './PuntoVenta';

const mstp = state => {
    let me = state.login.me;
    let caja = state.caja.apertura;
    return {...state.puntoventa, me, caja}
};

const mdtp = {
    listarMovimientos,
    setIdApertura,
    ...actions,

};

export default connect(mstp, mdtp)(PuntoVenta)

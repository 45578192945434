import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push, go } from 'react-router-redux';
const url = 'compras';

const LOADER_OP = 'LOADER_OP';
const SET_DATA_OP = 'SET_DATA_OP';
const SET_PAGE_OP = 'SET_PAGE_OP';
const SET_UPDATE_DATA_OP ='SET_UPDATE_DATA_OP';
const SET_BUSCADOR_OP = 'SET_BUSCADOR_OP';
const SET_FILTRO_OP = 'SET_FILTRO_OP';
const SET_DATASIGUIENTE_OP = 'SET_DATASIGUIENTE_OP';
const SET_MODAL_OP = 'SET_MODAL_OP';
const SET_MODALPAGOS_OP = 'SET_MODALPAGOS_OP';
const SET_PAGOS_OP = 'SET_PAGOS_OP';
const SET_PRECIOS_OP = 'SET_PRECIOS_OP';
const SET_TAB_OP = 'SET_TAB_OP';
const SET_FORMINGRESO_OP = 'SET_FORMINGRESO_OP';
const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_OP, cargando: true});
    const store = getStore();
    const search = store.op.buscador;
    const filtro = store.op.filtro_op;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_OP, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_OP, data});
            dispatch({type: SET_PAGE_OP, page});
        }
        dispatch({type: LOADER_OP, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_OP, cargando: true});
    api.get(`${url}/getInfoOP/${id}`).catch((error) => {
        dispatch({type: LOADER_OP, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/ordenpago'))
        }
    }).then((data) => {
        dispatch({type: LOADER_OP, cargando: false});
        let fecha = new Date();
        fecha = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`;
        if(data){
            dispatch({type: SET_UPDATE_DATA_OP, updateData: data});
            let instancia ={
                productos: data.productos,
                fecha: fecha
            }
            dispatch(initializeForm('OrdenPago', instancia))
        }
    })
}

const getPagos = () => (dispatch, getState) =>{
    const store = getState();
    const updateData = store.op.updateData;
    dispatch({type: LOADER_OP, cargando: true});
    api.get(`${url}/ordenespago/${updateData.id}`).catch((error) => {
        dispatch({type: LOADER_OP, cargando: false});
        if(error.statusCode  === 404){
            dispatch(go(-1))
        }
    }).then((data) => {
        dispatch({type: LOADER_OP, cargando: false});
        if(data){
            dispatch({type: SET_PAGOS_OP , pagos: data});

        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.bodega.values;
    dispatch({type: LOADER_OP, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_OP, cargando: false})
        Swal('Éxito', 'Se ha creado la orden.', 'success')
        .then(() => {
            dispatch(push('/ordenpago'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_OP, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_OP, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_OP, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_OP, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/ordenpago'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_OP, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenpago'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_OP, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_OP, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_OP, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_OP, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_OP, filtro_op: filtro});
    dispatch(listar(1));
};
const getPrecios = (productos) => (dispatch, getStore) => {
    const store = getStore();
    const updateData = store.op.updateData;
    let params = {}
    let formData = {
        productos: productos
    }
    dispatch({type: LOADER_OP, cargando: true});
    api.put(`producto/getPrecios`, formData).catch((error) => {
        dispatch({type: LOADER_OP, cargando: false});
        if(error.statusCode  === 404){
            dispatch(go(-1))
        }
    }).then((data) => {
        dispatch({type: LOADER_OP, cargando: false});
        if(data){
            dispatch({type: SET_PRECIOS_OP , precios:data});
        }
    })
}
const cambiarPrecios = () => (dispatch, getStore) => {
    const store = getStore()
    let precios = _.cloneDeep(store.op.precios);
    const formData = _.cloneDeep(getStore().form.precio.values);

    precios.forEach(x =>{
        if(x.id === formData.id){
            x.fracciones = formData.fracciones;
            if(!x.fraccionable){
                let fraccion  = formData.fracciones[0]
                x.precio = fraccion.precio
                x.precio_descuento = fraccion.precio_descuento
            }
        }

    })
    dispatch(closeModalPagos())
    dispatch({type: SET_PRECIOS_OP , precios:precios});
}


const addLote = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.ingreso.values);
    const dataOp = _.cloneDeep(getStore().op.ingresoData)
    let productos = dataOp.productos
    let producto = productos.find(x => x.id === formData.id)
    if (producto){
        producto.lotes = formData.lotes;
        producto.lotes.forEach(x => {
            try {
                x.vencimiento = x.vencimiento.format("YYYY-MM-D")
            } catch (e) {
                let fecha = new Date(x.vencimiento)
                x.vencimiento = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
            }
        });
    }
    dispatch({type: SET_DATASIGUIENTE_OP, ingresoData: dataOp})
    dispatch(closeModal())
}
const openModal = () => (dispatch, getStore) => {
    dispatch({type: SET_MODAL_OP, modal:true })
}
const closeModal = () => (dispatch, getStore) => {
    dispatch({type: SET_MODAL_OP, modal: false})
}

const openModalPagos = () => (dispatch, getStore) => {
    dispatch({type: SET_MODALPAGOS_OP, modalPagos: true})
}
const closeModalPagos = () => (dispatch, getStore) => {
    dispatch({type: SET_MODALPAGOS_OP, modalPagos: false})
}
const setTab = (tab) => (dispatch, getStore) => {
    dispatch({type: SET_TAB_OP, tab: tab});
}

const cancelar = () => (dispatch, getStore) => {
    dispatch(go(-1))
}
const setIngresoData = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.OrdenPago.values);
    const store = getStore();
    const proveedor = store.op.updateData.proveedor
    let productos = [];
    // despues del cambio
    formData.productos.forEach(x => {
        if(x.cantidadActual == "0"){
            let fecha = new Date()
            let fechaString = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
            let lotesConDatosIniciales = [{cantidadActual: x.cantidadActual, vencimiento: fechaString, lote: 'Incompletos'}]
            x.lotes = lotesConDatosIniciales
        }else{
            let lotes = [{cantidadActual: x.cantidadActual}];
            x.lotes = lotes;
        }
        productos.push(x)
    })

    try {
        formData.fecha = formData.fecha.format("YYYY-MM-D")
    } catch (e) {
        formData.fecha = formData.fecha
    }
    let data = {
        fecha: formData.fecha,
        productos: productos,
        proveedor: proveedor
    }
    dispatch(getPrecios(productos))
    dispatch({type: SET_DATASIGUIENTE_OP, ingresoData: data})
}
const saveIngreso = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().op.ingresoData);
    const id = getStore().op.updateData.id;
    let error_lotes = false;
    formData.productos.forEach(x => {
        x.lotes.forEach(item => {
            if (!item.vencimiento || !item.lote){
                error_lotes = true;
            }
        });
    })
    if (!error_lotes){
        const FormPagado = _.cloneDeep(getStore().form.FormIngresoCompleto.values)
        try {
            formData.ingreso_total = FormPagado.ingreso_completo
        } catch (error) {}
        dispatch(setTab('precio'))
        // dispatch({type: LOADER_OP, cargando: true})
        // api.put(`${url}/ingreso/${id}`, formData).then((data) => {
        //     dispatch({type: LOADER_OP, cargando: false})
        //     Swal('Éxito', 'Se ha creado la orden de pago.', 'success')
        //     .then(() => {
        //         // dispatch(push('/ordenpago'))
        //         // dispatch(go(-1));
        //         dispatch(setTab('precio'))
        //     })
        // }).catch((error) => {
        //     dispatch({type: LOADER_OP, cargando: false})
        //     Swal(
        //         'Error',
        //         (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
        //         'error'
        //     );
        // }).finally(() => {
        //     dispatch({type: LOADER_OP, cargando: false})
        // });
    }else{
        Swal(
            'Error',
            'Debe de ingresar lotes a los productos para realizar el ingreso.',
            'error'
        );
    }

};
const actualizarPrecios = () => (dispatch, getStore) => {
    const store = getStore();
    const precios = store.op.precios;
    const formData = _.cloneDeep(getStore().op.ingresoData);
    const FormPagado = _.cloneDeep(getStore().form.FormIngresoCompleto.values)
    const id = getStore().op.updateData.id;
    try {
        formData.ingreso_total = FormPagado.ingreso_completo
    } catch (error) {}
    formData.precios = precios
    dispatch({type: LOADER_OP, cargando: true});

        dispatch({type: LOADER_OP, cargando: true})
        api.put(`${url}/ingreso/${id}`, formData).then((data) => {
            dispatch({type: LOADER_OP, cargando: false})
            Swal('Éxito', 'Se ha creado la orden de pago.', 'success')
            .then(() => {
                // dispatch(push('/ordenpago'))
                dispatch(go(-1));
                // dispatch(setTab('precio'))
            })
        }).catch((error) => {
            dispatch({type: LOADER_OP, cargando: false})
            Swal(
                'Error',
                (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).finally(() => {
            dispatch({type: LOADER_OP, cargando: false})
        });
}
export const cambioDescuento = (valor, campo, index) => (dispatch, getStore) => {
    const {  values: { fracciones }, values } = _.cloneDeep(getStore().form.precio);
    const fraccion = fracciones[index]

    if(campo === 'precio_descuento'){
        if (valor){
            fraccion.porcentaje = ((valor / parseFloat(fraccion.precio)) * 100).toFixed(2);
            fraccion.porcentaje = 100 - fraccion.porcentaje;
        }
        else{
            fraccion.porcentaje = 0;
            fraccion.precio_descuento = fraccion.precio
        }
    }
    else if(campo === 'precio'){
        if(valor){
            if(fraccion.porcentaje){
                fraccion.precio_descuento = ((fraccion.porcentaje / 100) * parseFloat(fraccion.precio)).toFixed(2);
                fraccion.precio_descuento = fraccion.precio - fraccion.precio_descuento
            }else{
                fraccion.porcentaje = 0;
                fraccion.precio_descuento = fraccion.precio
            }
        }else{
            fraccion.porcentaje = 0;
            fraccion.precio_descuento = fraccion.precio
        }
    }
    else{
        if (valor){
            fraccion.precio_descuento = ((valor / 100) * parseFloat(fraccion.precio)).toFixed(2);
            fraccion.precio_descuento = fraccion.precio - fraccion.precio_descuento
        }
        else{
            fraccion.porcentaje = 0;
            fraccion.precio_descuento = fraccion.precio
        }

    }
    dispatch(initializeForm('precio', {...values}))
}
export const actions = {
    setIngresoData,
    saveIngreso,
    closeModal,
    openModal,
    cancelar,
    getPagos,
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    addLote,
    openModalPagos,
    closeModalPagos,
    cambiarPrecios,
    actualizarPrecios,
    setTab,
    cambioDescuento
};
export const reducer = {
    [LOADER_OP]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_OP]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_OP]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_OP]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_OP]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_OP]: (state, { filtro_op }) => {
        return {...state, filtro_op }
    },
    [SET_DATASIGUIENTE_OP]: (state, { ingresoData }) => {
        return {...state, ingresoData }
    },
    [SET_MODAL_OP]: (state, { modal }) => {
        return {...state, modal }
    },
    [SET_PAGOS_OP]: (state, { pagos }) => {
        return {...state, pagos }
    },
    [SET_MODALPAGOS_OP]: (state, { modalPagos }) => {
        return {...state, modalPagos }
    },
    [SET_PRECIOS_OP]: (state, { precios }) => {
        return {...state, precios }
    },
    [SET_TAB_OP]: (state, { tab }) => {
        return {...state, tab }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_op: null,
    updateData: {},
    ingresoData:{
        productos:[]
    },
    modal: false,
    modalPagos: false,
    pagos: [],
    precios: [],
    tab: 'op',

};
export default handleActions(reducer, initialState)

import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarSimple2 from 'Utils/Toolbar/ToolbarSimple2';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { dateFormatter, formatUsuario, formatSelect } from '../../../Utils/renderField/renderReadField';


function entregadaFormater(cell) {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times"/>
}
function pago_completoFormater(cell) {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times"/>
}
function rowClassNameFormat(row, rowIdx){
    return !row.ya_visto  ? 'background-noleido' : '';
}
export default class SolicitudesGrid extends Component {
componentWillMount(){
    this.props.listar(this.props.page);
    this.props.listarPendientes();
}
render() {
    const {
        listar,
        detail,
        create,
        update,
        listarPendientes,
        destroy,
    } = this.props;
    const {
        cargando,
        page,
        data,
        pendientes
    } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center pt-5">
        {
            pendientes.results.length !== 0  && (
                <div className="col-md-12 p-0 m-0">
                    <ToolbarSimple2
                    titulo="Ordenes de compra"
                    subtitulo="pendientes"
                    css="text-danger"
                    icono={iconsh.crudOc}
                    />
                    <div className="mt-3  col-md-12 mb-3">
                        <Table
                            onPageChange={listarPendientes}
                            data={pendientes}
                            loading={cargando}
                            pagination={false}
                            trClassName={rowClassNameFormat}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/confirmar'})}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="no_orden" dataSort>No orden</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatSelect}
                                dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={dateFormatter}
                                dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatUsuario}
                                dataField="usuario" dataSort>Emitida por</TableHeaderColumn>

                        </Table>
                    </div>
                </div>
            )
        }

        <ToolbarSimple2
            titulo="Ordenes de compra "
            subtitulo="Aceptados"
            css="text-verde"
            icono={iconsh.crudOc}
            buscar={this.props.search}
            buscador={this.props.buscador}
            />
        <div className="mt-3 col-md-12">
            <Table
                onPageChange={listar}
                data={data}
                loading={cargando}
                page={page}>
                <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="center"
                    dataFormat={activeFormatter({ ver: '/solicitudes/aceptado'})}>Acciones</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="no_orden" dataSort>No orden</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={dateFormatter}
                    dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatUsuario}
                    dataField="usuario" dataSort>Emitida por</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatSelect}
                    dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatUsuario}
                    dataField="aceptado_por" dataSort>Aceptado por</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={pago_completoFormater}
                    dataField="pago_completo" dataSort>Pagada</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={entregadaFormater}
                    dataField="entregado" dataSort>Entregada</TableHeaderColumn>

            </Table>
        </div>
    </div>
    )
    }
}

import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReporte from '../../../Utils/Header/HeaderReporte';
import { iconsh } from "icons";
import Filtro from './Filtros';
import Grafica from './Grafica';
import TarjetaVentas from './TarjetaVentas';

export default class RGanancia extends Component {
    componentWillMount() {
        this.props.getGraficaGanancia();
    }
    render() {
        const {
            cargando,
            data_grafica,
            total_venta,
            total_ganancia,
            total_gasto,
            data,
        } = this.props;
        return (
            <div>
                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderReporte
                        icono={iconsh.crud_reportes}
                        titulo={'Ganancias'}
                        es_detalle
                    />
                </div>
                <div className="container m-0 contenido-principal mt-3 pt-3 col-md-12">
                    <LoadMask loading={cargando} dark blur>
                        <div className="fondo-inverso-navbar " style={{height:40}}></div>
                        <div className="col-md-12 row m-0 mt-5 ">
                            <div className="col-md-4 d-flex align-items-center">
                                <Filtro {...this.props}
                                    ver_rango
                                    total_venta = {total_venta}
                                    total_ganancia = {total_ganancia}
                                    total_gasto = {total_gasto}
                                    ver_cuenta />
                            </div>
                            <div className="col-md-8">
                                <Grafica datum={data_grafica} />
                            </div>
                        </div>
                        <div className="background-secondary mt-5 py-2 pl-4" style={{height:40}}>
                            <span className="text-primary text-uppercase">Detalle Ganancias</span>
                        </div>
                        <div className="col-md-12 row p-0 d-flex justify-content-center pt-4">
                            {
                                data.map(x => {
                                    return <TarjetaVentas key={x.id} cuenta={x} />
                                })
                            }
                            {/* <DetalleCompra {...this.props} /> */}
                        </div>
                    </LoadMask>
                </div>
            </div>
        )
    }
}

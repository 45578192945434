import React, { Component, Fragment } from 'react'
import TituloBodega from 'Utils/Header/TituloBodega';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { formatAllDate, dateFormatter, formatoMoneda } from 'Utils/renderField/renderReadField';
import classNames from 'classnames';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import ReciboMovimiento from 'Utils/ReciboMovimiento/reciboMovimiento';
import AnulacionForm from './formulario/formularioanular';

function formatFraccion(cell, row) {
    if (cell.es_producto) {
        return cell.nombre_completo;
    }
    return cell.nombre
}

export default class DetalleVenta extends Component {
    componentWillMount() {
        this.props.detail(this.props.match.params.id);
    }
    anularventa = () => {
        this.props.destroy(this.props.match.params.id);
    }
    imprimir = () => {
        window.print()
    }
    render() {
        const { updateData, cargando } = this.props;
        const data = {
            count: 0,
            results: updateData.productos
        }
        let descuento = 0;
        if (updateData.monto) {
            descuento = updateData.subtotal - updateData.monto;
        }

        return (
            <Fragment>
                {
                    this.props.toggleModal && (
                        <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Venta" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Motivo de anulación</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <AnulacionForm
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.anularventa}
                                        />

                                </div>
                            </div>
                        </Modal>
                    )
                }
                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <div className="row col-md-12 no-print">
                        <TituloBodega estilo="col-md-3" titulo={'Detalle venta'} subtitulo={`${updateData.no_orden ? updateData.no_orden : ''}`} />


                    </div>
                    <div className="col-md-12 row m-0 mt-3 no-print">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 d-flex align-items-end mb-2">
                                <label htmlFor="emitido" className="m-0 mr-2">Cliente:</label>
                                <span className="text-primary">{updateData.nombre_cliente ? updateData.nombre_cliente : ''}</span>
                            </div>
                            <div className="col-md-12 d-flex align-items-end mb-2">
                                <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                                <span className="text-primary">{updateData.usuario ? updateData.usuario.first_name : ''}</span>
                            </div>
                            <div className="col-md-12 d-flex align-items-end mb-2">
                                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                <span className="text-primary">{updateData.fecha ? formatAllDate(updateData.fecha) : ''}</span>
                            </div>

                        </div>
                        <div className="col-md-6  p-0 borde-gris py-3 pr-3 row m-0">
                            <div className="col-md-4">
                                <h5>Pagos</h5>
                                {
                                    updateData.movimientos && (
                                        <Fragment>
                                            {
                                                updateData.movimientos.map(x =>{
                                                    return(
                                                        <div key={x.id} className="col-md-12">
                                                            <span className="text-gris text-uppercase">{x.tipo_movimiento}: </span>
                                                            <RenderCurrency value={x.monto} className={'text-primary font-weight-bold'} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                            <div className="col-md-8">
                                <div className="col-md-12 row p-0 m-0">
                                    <div className="col-md-6 p-0 text-right">
                                        <span className="text-primary">Subtotal</span>
                                    </div>
                                    <div className="col-md-6 p-0 text-right">
                                        <RenderCurrency value={updateData.subtotal} className={'text-gris  font-weight-bold'} />
                                    </div>
                                </div>
                                <div className="col-md-12 row p-0 m-0">
                                    <div className="col-md-6 p-0 text-right">
                                        <span className="text-gris">Descuento</span>
                                    </div>
                                    <div className="col-md-6 p-0 text-right">
                                        -<RenderCurrency value={descuento} className={'text-gris  font-weight-bold'} />
                                    </div>
                                </div>
                                <div className="col-md-12 row p-0 m-0">
                                    <div className="col-md-6 p-0 text-right">
                                        <span className="text-primary h5">TOTAL</span>
                                    </div>
                                    <div className="col-md-6 p-0 text-right">
                                        <RenderCurrency value={updateData.monto} className={'text-verde h4 font-weight-bold'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5">
                        <Table
                            data={data}
                            loading={cargando}
                            pagination={false}
                            page={1}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatFraccion}
                                dataField="stock" dataSort>Producto</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="subtotal" dataSort>Precio</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="nombre_s" dataSort></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"></TableHeaderColumn>
                        </Table>
                    </div>
                    {
                        !updateData.activo && (
                            <div className="col-md-12 mt-5 no-print">
                                <div className="col-md-10">
                                    <h5 className="text-left text-uppercase">Justificación de anulación</h5>
                                    <span className="text-gris">Anulado por: </span> <span className="text-primary">{updateData.usuario_anulacion ? updateData.usuario_anulacion.first_name : ''}</span>
                                    <textarea
                                        disabled={true}
                                        value={updateData.justificacion_anulacion}
                                        style={{ resize: "none" }} rows={3}
                                        className={classNames('form-control')} />
                                </div>
                            </div>
                        )
                    }

                    <div className="col-md-12 row mt-3">
                        <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <button type="button" onClick={e => {
                                e.preventDefault()
                                this.props.openModal();
                            }} className="btn btn-danger m-1">Anular venta</button>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            {/* <Link className="btn btn-secondary m-1" to="/solicitudes">Cancelar</Link> */}
                            <button type="button" onClick={e => {
                                e.preventDefault();
                                this.props.regresar()
                            }} className="btn btn-secondary m-1">Cancelar</button>
                            <button type="button" onClick={e => {
                                e.preventDefault();
                                this.imprimir()
                            }} className="btn btn-primary m-1">Imprimir</button>
                        </div>
                    </div>


                </div>
                {
                    updateData && (
                        <div>
                            <ReciboMovimiento movimiento={updateData} />
                        </div>
                    )
                }
            </Fragment>

        )
    }
}

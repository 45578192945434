import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import FormDos from './FormDos';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class AutorizacionDoble extends Component {
    state = {
        page: 1
    }
    componentDidMount() {
        const { encargado } = this.props;
        if(encargado){
            this.setState({page: 1})
        }else{
            this.setState({page:2})
        }
    }
    nextPage = () =>{
        this.setState({page: this.state.page + 1 })
    }
    previousPage = () => {
        this.setState({page: this.state.page - 1 })
    }
    render() {
        const { onSubmit, cargando, bodega, encargado, caja_nombre } = this.props
        const { page } = this.state
        return (
            <div >
                <Modal open={this.props.modal} onClose={this.props.closeModal} >
                    <LoadMask loading={cargando} blur_1s>
                        <FormDos
                            initialValues={{}}
                            bodega={bodega}
                            caja_nombre={caja_nombre}
                            previousPage={this.previousPage}
                            onSubmit={onSubmit}/>
                    </LoadMask>
                </Modal>
            </div>
        )
    }
}

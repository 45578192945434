import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const RenderNumber = ({ value, decimalScale, className }) => {
    return (
        <NumberFormat className={className}
            decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
            value={value} thousandSeparator={true} prefix={''} displayType={"text"}
        />
    )
};

export const RenderModifyCurrency = ({ value, disabled=false, className, simbolo = 'Q', display_type = 'text', changeValue, index, dato = null, orden = 0, detalle = 0 }) => {
    return (
            <React.Fragment>
                <NumberFormat className={className}
                    disabled={disabled}
                    decimalScale={2} fixedDecimalScale={true}
                    value={value} thousandSeparator={true} prefix={simbolo} displayType={display_type}
                    onValueChange={(values) => {
                        const value = (values.floatValue !== undefined) ? values.floatValue : 0;
                        changeValue(value, index, dato, orden, detalle);
                    }}
                />
            </React.Fragment>
    )
};

export const RenderCurrency = ({ value, className, simbolo = 'Q', display_type = 'text', change = null, dato = null }) => {
    return (
            <NumberFormat className={className}
                decimalScale={2} fixedDecimalScale={true}
                value={value} thousandSeparator={true} prefix={simbolo} displayType={display_type}
                /*
                onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    if (change !== null) {
                        change(dato, value);
                        private_value = value;
                        //change(value)
                    }
                }}
                */
            />
    )
};

export const RenderDateTime = ({ value, className }) => {
    try {
        if (value) {
            const fecha = new Date(value);
            return (
                <span className={className}>{fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}</span>
            );
        }
        return (<span className={className}>{value}</span>);
    } catch (error) {
        return (<span></span>)
    }

};
export const formatAllDate = (value) => {
    try {

        if (value) {
            const fecha = new Date(value);
            return (
                <span >{fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}</span>
            );
        }
        return (<span >{value}</span>);
    } catch (error) {
        return (<span></span>)
    }
}
export const cellTachado = (cell, row) => {
    if (row.anulado) {
        return { textDecoration: "line-through", whiteSpace: 'normal' }
    }
    return { whiteSpace: 'normal' }
}
export const dateFormatter = (cell) => {
    if (!cell) {
        return "";
    }

    try {
        let date = moment(cell).format('DD/MM/YYYY')
        return date
    }
    catch (e) {
        return cell;
    }

};
export const dateTimeFormatter = (cell) => {
    if (!cell) {
        return "";
    }

    try {
        let date = moment(cell).format('DD/MM/YYYY HH:mm:ss')
        return date
    }
    catch (e) {
        return cell;
    }

}
export const formatoMoneda = (cell, simbolo) => {
    if ((typeof simbolo) === "object") {
        simbolo = simbolo.simbolo ? simbolo.simbolo : 'Q';
    }
    if (!cell) {
        return "-- --"
    }
    try {
        return (
            <NumberFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={cell !== null ? cell : 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={simbolo ? simbolo : "Q"} />
        )
    } catch (e) {
        return cell;
    }
}
export const nombreCliente = (cell, row) => {
    if( cell ) {
        return cell;
    } else {
        if(row.paciente) {
            return row.paciente.nombre;
        }
    }
}
export const formatUsuario = (cell) => {
    let nombre = '';
    try {
        if (cell) {
            nombre = `${cell.first_name} (${cell.username})`
        }
    } catch (error) {
        nombre = '';
    }

    return nombre
}
export const formatNota = (cell, row) => {
    let nota = row.nota;
    if (row.destino && !row.origen)
        nota += ` Destino: ${row.destino.nombre}`;
    else if (!row.destino && row.origen)
        nota += ` Origen: ${row.origen}`
    return nota;
}
export const formatNombre = (cell) => {
    let nombre = '';
    try {
        if (cell) {
            nombre = cell.nombre
        }
    } catch (error) {
        nombre = ''
    }
    return nombre
}
export const formatSelect = (cell) => {
    let nombre = '';
    try {
        if (cell) {
            nombre = cell.label
        }
    } catch (error) {
        nombre = '';
    }

    return nombre
}

export const checkFormatter = (cell) => {
    return cell ? <i className="icons-oc text-verde fa fa-check" aria-hidden="true" /> :
        <i className="icons-oc text-primary fa fa-times" />
}

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
    renderDateTime: RenderDateTime,
    dateFormatter: dateFormatter,
    dateTimeFormatter: dateTimeFormatter
};

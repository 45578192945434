import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';
import renderDate from 'Utils/renderField/renderDate';
export const renderLote = ({fields, meta: {error, submitFailed }, devolucion, incompleto}) => (

    <div className="col-sm-12 form-group np-r ">

            {
                fields.map((lote, index) => (

                <div key={index} className=" mt-3">
                    <div className="float-right mt-2 mr-2">

                        <button
                                type="button"
                                className=" btn-danger btn-tabla "
                                onClick={() =>{
                                    fields.remove(index)
                                }}
                            >X</button>
                    </div>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="col-md-12 p-0 m-0 form-group mt-0">
                                <label htmlFor={`${lote}.cantidadActual`}>Cantidad a ingresar*:</label>
                            </div>
                            <div className="col-md-12 p-0 form-group m-0">
                                <Field
                                    name={`${lote}.cantidadActual`}
                                    disabled={incompleto}
                                    component={renderField}
                                    type="number"
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 m-0 p-0 form-group m-0">
                                <label htmlFor={`${lote}.vencimiento`}>Vencimiento*:</label>
                            </div>
                            <div className="col-md-12 p-0 form-group m-0">
                                <Field
                                    name={`${lote}.vencimiento`}
                                    className=""
                                    component={renderDate}
                                    placeholder="Fecha"
                                    numberOfMonths={1}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 p-0 m-0 form-group m-0">
                                <label htmlFor={`${lote}.lote`}>Lote*:</label>
                            </div>
                            <div className="col-md-12 p-0 form-group m-0">
                                <Field name={`${lote}.lote`} component={renderField} type="text" className="form-control" />
                            </div>
                        </div>

                    </div>
                </div>
            ))}
        <div className="d-flex justify-content-start mt-2">
            <button type="button" className="btn btn-success" onClick={() =>{
                fields.push({});
            } }
            disabled={incompleto}>
                    Agregar Lote
            </button>
        </div>
</div>
)


let IngresoForm = props => {
    const { handleSubmit, devolucion } = props;
    const incompleto = props && props.row && props.row.cantidadActual == "0"? true: false
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">

                    <div className="row">
                        <div className="col-md-6">
                            <span className="text-primary font-weight-bold">PRODUCTO Y PRESENTACIÓN</span>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="emitido" className="m-0 mr-2">Solicitadas:</label>
                            <span className="text-primary">{ !devolucion ? props.row.cantidadActual : props.row.cantidad}</span>
                        </div>
                    </div>
                    <FieldArray name="lotes" component={renderLote} devolucion incompleto={incompleto}/>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={props.closeModal}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
IngresoForm = reduxForm({
    form: 'ingreso',
    validate: (data, props) => {
        const errors = {}
        const lotesError = []
        if(data && data.lotes){
            data.lotes.forEach((x, index) => {
                const loteError = {}
                if(!x || !x.cantidadActual){
                    loteError.cantidadActual = 'Campo requerido';
                }
                if(!x || !x.vencimiento){
                    loteError.vencimiento = 'Campo requerido';
                }
                if(!x || !x.lote){
                    loteError.lote = 'Campo requerido';
                }
                if(loteError){
                    lotesError[index] = loteError
                }
            })
        }else{
            errors.lotes ="Campo requerido"
        }
        if(lotesError.length){
            errors.lotes = lotesError
        }
        return errors
    },
    initialValues: {
        lote:[{}]
    }
})(IngresoForm);
export default IngresoForm

import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSearchSelect } from "Utils/renderField/renderField";
import { RenderDateTime, formatAllDate } from '../../../Utils/renderField/renderReadField';

import { api } from "api/api";

let productos = [];
const getProductos = search => {
    return api
        .get("inventario/getInventarioPV", { search })
        .catch(error => {})
        .then(data => {
            if (data) {
                productos = data.results;
                return data.results;
            } else {
                return [];
            }
        })
        .catch(e => {
            return [];
        });
};


let AplicarMedicamentosForm = props => {
    const { handleSubmit, existencia, valores_paquete } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0  pb-sm-1">
                    {/* <FieldArray name="productos" component={renderProductos} /> */}
                    <div className="row">
                        <div className="col-md-5">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="medicamento" className="m-0">Medicamento*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <span className="text-primary">{valores_paquete && valores_paquete.stock ? valores_paquete.stock.nombre_completo : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="col-md-12 col-sm-12 text-center">
                                <label htmlFor="ex" className="m-0">Cantidad:</label>
                            </div>
                            <div className="col-md-12  form-group text-center">
                                <span className="text-primary">{valores_paquete ? valores_paquete.cantidad : 0}</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="col-md-12 col-sm-12 text-center">
                                <label htmlFor="ex" className="m-0">Aplicadas:</label>
                            </div>
                            <div className="col-md-12  form-group text-center">
                                <span className="text-primary">{valores_paquete ? valores_paquete.aplicados : 0 }</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cantidad_aplicar" className="m-0">Cantidad*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                            name={`cantidad_aplicar`}
                                            type="number"
                                            addClass={"text-right"}
                                            component={renderField}
                                            placeholder="Representante"
                                        />
                            </div>
                        </div>
                     </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                <button type='button'
                                    onClick={e=> {
                                        e.preventDefault();
                                        props.cerrarModal();
                                    }}
                                    className="btn btn-secondary m-1">
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Aplicar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
AplicarMedicamentosForm = reduxForm({
    form: "aplicarmedicamento",
    validate: (data, props) => {
        const { valores_paquete } = props;
        const errors = {};
        if(!data.cantidad_aplicar){
            errors.cantidad_aplicar = 'Campo obligatorio';
        }else{
            if(data.cantidad_aplicar < 1){
                errors.cantidad_aplicar = 'La cantidad debe de ser más de 0';
            }
            if(valores_paquete.cantidadActual < data.cantidad_aplicar){
                errors.cantidad_aplicar = `No puede aplicar más de ${valores_paquete.cantidadActual}`
            }
        }
        return errors
    }
})(AplicarMedicamentosForm);

const selector = formValueSelector('aplicarmedicamento');
AplicarMedicamentosForm = connect(state => {
    const producto = selector(state, "producto");
    let existencia = 0
    if(producto){
        existencia = producto.cantidad
    }
    return {
        existencia
    };
})(AplicarMedicamentosForm);
export default AplicarMedicamentosForm;

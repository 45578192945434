import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSwitch, renderSearchSelect } from 'Utils/renderField/renderField';
import { api } from 'api/api';

 let cuentas = [];
 const getCuentas = (search)  =>{
    return api.get("cuenta_area",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    cuentas = [];
                    data.results.forEach(x=>{
                        cuentas.push(x)
                    })
                    return cuentas
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

let AreaForm = props => {
    const { handleSubmit, ver_cuenta } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Nombre*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="nombre" component={renderField} type="text" className="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 d-flex justify-content-center">
                        <div className="col-md-3 d-flex align-items-center">
                            <div className="row col-md-12">
                                <div className="col-12 col-lg-6 p-0 text-center text-lg-right  text-gris">
                                   Es farmacia:
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                    <Field name="farmacia" component={renderSwitch} type="text" className="form-control" />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <div className="row col-md-12">
                                <div className="col p-0 text-lg-right text-center  text-gris">
                                   Tiene inventario:
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                    <Field name="tiene_inventario" component={renderSwitch} type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <div className="row col-md-12">
                                <div className="col p-0 text-lg-right text-center  text-gris">
                                   Tiene caja:
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                    <Field name="tiene_caja" component={renderSwitch} type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                        {
                            ver_cuenta && (
                                <div className="col-md-3">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="cuenta" className="m-0">Cuenta*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name={`cuenta`}
                                            valueKey="id"
                                            labelKey="nombre"
                                            label="nombre"
                                            component={renderSearchSelect}
                                            loadOptions={getCuentas}
                                            />
                                    </div>
                                </div>
                            )
                        }

                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/areas_todo">Cancelar</Link>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
AreaForm = reduxForm({
    form: 'area',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'cuenta': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(AreaForm);

const selector = formValueSelector('area');
AreaForm = connect(state => {
    const tiene_caja = selector(state, "tiene_caja");
    let ver_cuenta = false;
    tiene_caja ? ver_cuenta = true : ver_cuenta= false;
    return {
        ver_cuenta
    };
})(AreaForm);
export default AreaForm

import { connect } from 'react-redux'
import Menu from './Menu'
import { logOut } from '../../../redux/modules/login'
import { ROLES } from '../../utility/constants';

const mstp = state => {
    const url = window.location.href;
    const ordenes = Number(state.compras.notificacionA || 0) + Number(state.compras.notificacionR || 0);
    const solicitudes = Number(state.compras.notificacionP || 0)
    const notificacion_ingresos  = Number(state.estadobodega.notificacion || 0)
    const bodega_asignada = Number(state.login.me.bodega || 0)
    const me = state.login.me;

    let ver_ordenes = false;
    let ver_solicitudes = false;
    let ver_devoluciones = false;
    let ver_area = false;
    let ver_caja = false;
    let ver_enfermera = false;
    let ver_gasto = false;
    let ver_reportes = false;
    let ver_admin = false;

    // Auxiliares
    let menu_mi_area = false;
    let menu_areas = false;


    let menu_pacientes = false;
    let menu_cuentas_pacientes = false;

    let menu_rganancias = false;
    let menu_rvencimientos = false;
    let menu_rcaja = false;
    let menu_rcuentas = false;
    let menu_rcomprasventas = false;
    let menu_rmovbodega = false;
    let menu_rporpagar = false;
    let menu_rbitacora = false;

    let menu_ver_admin = false;
    let menu_configuracion = false;
    let menu_cuentas = false;
    let menu_categoria = false;
    let menu_clientes = false;
    let menu_proveedores = false;
    let menu_usuarios = false;
    let menu_productos = false;

    if(me.is_superuser || me.permisos && me.permisos.administrador ){
        // ver_ordenes = true;
        ver_solicitudes = true;
        ver_devoluciones = true;
        ver_area = true;
        ver_caja = true;
        ver_enfermera = true;
        ver_gasto = true;
        ver_reportes = true;
        ver_admin = true;
        menu_rganancias = true
        menu_rvencimientos = true
        menu_rcaja = true
        menu_rcuentas = true
        menu_rcomprasventas = true
        menu_rmovbodega = true
        menu_rporpagar = true
        menu_rbitacora = true

        menu_ver_admin = true;
        menu_configuracion = true;
        menu_cuentas = true;
        menu_categoria = true;
        menu_clientes = true;
        menu_proveedores = true;
        menu_usuarios = true;
        menu_productos = true;

        menu_mi_area = true;
        menu_areas = true;
        menu_pacientes = true;
        menu_cuentas_pacientes = true;
    }else{
        if(me.permisos){
            ver_ordenes = me.permisos.ordenes_compra
            ver_solicitudes = me.permisos.solicitudes
            ver_devoluciones = me.permisos.devoluciones
            ver_area = me.permisos.area
            ver_caja = me.permisos.caja
            ver_enfermera = me.permisos.enfermeria
            ver_gasto = me.permisos.gastos
            ver_reportes = me.permisos.reportes
            ver_admin = me.permisos.administrador

            if(me.permisos.ver_admin){
                menu_ver_admin = true
                menu_configuracion = me.permisos.configuracion
                menu_cuentas = me.permisos.cuentas
                menu_categoria = me.permisos.categoria
                menu_clientes = me.permisos.clientes
                menu_proveedores = me.permisos.proveedores
                menu_usuarios = me.permisos.usuarios
                menu_productos = me.permisos.productos
            }
            if(me.permisos.reportes){
                ver_reportes = true;
                menu_rganancias = me.permisos.rganancias
                menu_rvencimientos = me.permisos.rvencimientos
                menu_rcaja = me.permisos.rcaja
                menu_rcuentas = me.permisos.rcuentas
                menu_rcomprasventas = me.permisos.rcomprasventas
                menu_rmovbodega = me.permisos.rmovbodega
                menu_rporpagar = me.permisos.rporpagar
                menu_rbitacora = me.permisos.rbitacora
            }
            if(me.permisos.area){
                ver_area = true;
                menu_mi_area = me.permisos.areas
                menu_areas = me.permisos.mi_area
            }
            if(me.permisos.enfermeria){
                ver_enfermera = true;
                menu_pacientes = me.permisos.pacientes;
                menu_cuentas_pacientes = me.permisos.cuentas_pacientes;
            }
        }
    }


    return {
        me,
        url,
        ordenes,
        solicitudes,
        notificacion_ingresos,
        bodega_asignada,

        ver_ordenes,
        ver_solicitudes,
        ver_devoluciones,
        ver_area,
        ver_caja,
        ver_enfermera,
        ver_gasto,
        ver_reportes,
        ver_admin,

        menu_ver_admin,
        menu_pacientes,
        menu_areas,
        menu_rganancias,
        menu_rvencimientos,
        menu_mi_area,
        menu_cuentas_pacientes,
        menu_rcaja,
        menu_rcuentas,
        menu_rcomprasventas,
        menu_rmovbodega,
        menu_rporpagar,
        menu_rbitacora,
        menu_configuracion,
        menu_cuentas,
        menu_categoria,
        menu_clientes,
        menu_proveedores,
        menu_usuarios,
        menu_productos,
    }
}
const mdtp = {
    logOut
}

export default connect(mstp, mdtp)(Menu)

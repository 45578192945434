import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './CuentaForm';
import { iconsh } from "icons";

export default class CuentaCrear extends Component {
state = {
    editar: false,
    mensaje: 'Agregar Cuenta',
    password: false,
}
componentWillMount(){
    if(this.props.match.params.id){
        this.props.detail(this.props.match.params.id);
        this.setState({
                editar: true,
                mensaje: 'Editar Cuenta'
            });
    }
}
verPassword = () =>{
    this.setState({
        password: !this.state.password
    })
}
render() {
    const { create, update } = this.props;
    const { updateData, cargando } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12">
            <Card
                icono={iconsh.crud_paciente}
                titulo={this.state.mensaje}>
                <LoadMask loading={cargando} blur_1>
                    <Form onSubmit={create}  regresar={this.props.regresar}/>
                </LoadMask>
            </Card>
        </div>
    </div>
    )
    }
}

import React, { Component, Fragment } from 'react'
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { iconsh } from 'icons'

export default class InfoApertura extends Component {
  render() {
      const { configuracion, total } = this.props;
    return (
        <div className="mt-5">
                <div className="col-md-12 p-2 fondo-inverso-navbar ">
                    <h5 className="text-uppercase m-0 pr-3 text-right text-white">
                        <img className="mr-2 pb-1" src={iconsh.crud_caja_venta2} height={35} />
                        CAJA
                    </h5>
                </div>
                <h5 className="text-center mt-5">APERTURAR CAJA</h5>
                <div className="row col-md-12 m-0 p-0 mt-5">
                    <div className="col-md-12  py-2 d-flex justify-content-between">
                        <div className="col-md-4 text-center p-0">
                            <span className="text-uppercase text-primary">Total Apertura</span>
                        </div>
                        <div className="col-md-4 text-center p-0">
                            <span className="text-uppercase text-gris">Efectivo ingresado</span>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 p-0 ">
                    <div className="col-md-12 borde-gris py-2 d-flex justify-content-between">
                        <div className="col-md-4 text-center p-0">
                            <RenderCurrency value={configuracion.monto_inicial_caja}  className={'text-verde h4 font-weight-bold'}/>
                        </div>
                        <div className="col-md-4 text-center p-0">
                            <RenderCurrency value={total}  className={'text-primary h4 font-weight-bold'}/>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

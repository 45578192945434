//IMAGENES EN NEGRO
const caja1 = require('../../assets/img/sidebar/caja1.png');
const admin1 = require('../../assets/img/sidebar/admin1.png');
const bodega1 = require('../../assets/img/sidebar/bodega1.png');
const gastos1 = require('../../assets/img/sidebar/gastos1.png');
const ordenes1 = require('../../assets/img/sidebar/ordenes1.png');
const usuarios1 = require('../../assets/img/sidebar/usuarios1.png');
const reportes1 = require('../../assets/img/sidebar/reportes1.png');
const paciente1 = require('../../assets/img/sidebar/paciente1.png');
const productos1 = require('../../assets/img/sidebar/productos1.png');
const enfermeria1 = require('../../assets/img/sidebar/enfermeria1.png');
const solicitudes1 = require('../../assets/img/sidebar/solicitudes1.png');
const areasbodegas1 = require('../../assets/img/sidebar/areasbodegas1.png');
const devoluciones1 = require('../../assets/img/sidebar/devoluciones1.png');
const configuraciones1 = require('../../assets/img/sidebar/configuraciones1.png');

export const icons = {
    caja1,
    admin1,
    gastos1,
    bodega1,
    ordenes1,
    usuarios1,
    reportes1,
    paciente1,
    productos1,
    enfermeria1,
    devoluciones1,
    solicitudes1,
    areasbodegas1,
    configuraciones1
}


//IMAGENES EN header
const crudOc = require('../../assets/img/header/crud_OC.png');
const regresar = require('../../assets/img/icons/regresar1.png');
const crud_conf = require('../../assets/img/header/crud_conf.png');
const crud_areas = require('../../assets/img/header/crud_areas.png');
const usuario = require('../../assets/img/header/crud_usuarios.png');
const productos = require('../../assets/img/header/crud_productos.png');
const crud_bodegas = require('../../assets/img/header/crud_bodegas.png');
const crud_devoluciones = require('../../assets/img/header/crud_devoluciones.png');
const crud_caja_venta = require('../../assets/img/header/crud_caja_venta.png');
const crud_caja_venta2 = require('../../assets/img/header/crud_caja_venta2.png');
const crud_enfermeria = require('../../assets/img/header/crud_enfermeria.png');
const crud_paciente = require('../../assets/img/header/crud_paciente.png');
const crud_gastos = require('../../assets/img/header/crud_gastos.png');
const crud_cuentas = require('../../assets/img/header/crud_cuentas.png');
const crud_catgastos = require('../../assets/img/header/crud_catgastos.png');
const crud_bitacora = require('../../assets/img/header/crud_bitacora.png');
const crud_reportes = require('../../assets/img/header/crud_reportes.png');

export const iconsh = {
    crudOc,
    usuario,
    regresar,
    crud_conf,
    productos,
    crud_areas,
    crud_gastos,
    crud_bodegas,
    crud_cuentas,
    crud_catgastos,
    crud_paciente,
    crud_bitacora,
    crud_reportes,
    crud_enfermeria,
    crud_caja_venta,
    crud_caja_venta2,
    crud_devoluciones,
}

const agregar1 = require('../../assets/img/icons/agregar1.png');
const editar = require('../../assets/img/icons/editar1.png');
const mas1 = require('../../assets/img/icons/mas1.png');
const detalle = require('../../assets/img/icons/detalle1.png');
const detalle_servicio = require('../../assets/img/icons/detalle_servicio.png');
const reajustes = require('../../assets/img/icons/reajustes.png');
const eliminar1 = require('../../assets/img/icons/eliminar1.png');
const eliminar2 = require('../../assets/img/icons/eliminar2.png');
const anulacion1 = require('../../assets/img/icons/anulacion1.png');
const flecha_azul = require('../../assets/img/icons/etiqueta2.png');
const advertencia = require('../../assets/img/icons/advertencia1.png');
const vencimiento1 = require('../../assets/img/icons/vencimiento1.png');
const vencimiento2 = require('../../assets/img/icons/vencimiento2.png');
const vencimiento3 = require('../../assets/img/icons/vencimiento3.png');
const vencimiento4 = require('../../assets/img/icons/vencimiento4.png');
const vencimiento5 = require('../../assets/img/icons/vencimiento5.png');

export const EXTRAS ={
    agregar1,
    editar,
    mas1,
    detalle,
    detalle_servicio,
    reajustes,
    eliminar1,
    eliminar2,
    anulacion1,
    advertencia,
    flecha_azul,
    vencimiento1,
    vencimiento2,
    vencimiento3,
    vencimiento4,
    vencimiento5,
}

//manejo de imágenes de monedas

const b_200 = require('../../assets/img/monedas/b_200.png');
const b_100 = require('../../assets/img/monedas/b_100.png');
const b_50 = require('../../assets/img/monedas/b_50.png');
const b_20 = require('../../assets/img/monedas/b_20.png');
const b_10 = require('../../assets/img/monedas/b_10.png');
const b_5 = require('../../assets/img/monedas/b_5.png');
const b_1 = require('../../assets/img/monedas/b_1.png');
const m_1 = require('../../assets/img/monedas/m_1.png');
const m_5 = require('../../assets/img/monedas/m_5.png');
const m_10 = require('../../assets/img/monedas/m_10.png');
const m_25 = require('../../assets/img/monedas/m_25.png');
const m_50 = require('../../assets/img/monedas/m_50.png');
const m_01 = require('../../assets/img/monedas/m_01.png');

export const MONEDAS = {
    b_200,
    b_100,
    b_50,
    b_20,
    b_10,
    b_5,
    b_1,
    m_1,
    m_5,
    m_10,
    m_25,
    m_50,
    m_01
}

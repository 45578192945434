import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { iconsh } from "icons";
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';

export default class HeaderReporte extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
    render() {
        const { es_detalle } = this.props;
        return (
            <div className="col-md-12  m-0">
                
                <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    {
                        !es_detalle && (
                            <Fragment>
                                    <div className="col-md-6 row m-0 form-group">
                                        <div className="col-md-4 p-0 d-flex  justify-content-center align-items-center">
                                            <label className="m-0">Fecha inicio</label>
                                        </div>
                                        <div className="col-md-8 p-0">
                                            <SingleDatePicker
                                                placeholder={"Fecha Inicio"}
                                                date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                                                focused={this.state.focusedStart}
                                                isOutsideRange={() => false}
                                                onDateChange={(value) => {
                                                this.setState({dateStart:value})
                                                this.props.setDateStart(value)
                                                }}
                                                onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                numberOfMonths={1}
                                                id={"dateStart"}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 row m-0  form-group">
                                        <div className="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                            <label className="m-0">Fecha fin</label>
                                        </div>
                                        <div className="col-md-8 p-0">
                                            <SingleDatePicker
                                                placeholder={"Fecha Inicio"}
                                                date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                                                focused={this.state.focusedEnd}
                                                isOutsideRange={() => false}
                                                onDateChange={(value) => {
                                                this.setState({dateEnd:value})
                                                this.props.setDateEnd(value);
                                                }}
                                                onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                                                numberOfMonths={1}
                                                id={"dateEnd"}
                                            />
                                        </div>
                                    </div>
                            </Fragment>
                        )
                    }

                </div>
                <div className="col-md-6 mt-3" >
                    <h5 className="text-primary text-md-left text-center text-uppercase m-0 pl-md-3 ml-md-3">{this.props.titulo}</h5>
                    <h3 className="text-md-left text-uppercase text-center">
                        <img className="mr-2 pb-1" src={this.props.icono} height={30} />
                        Reportes
                    </h3>
                </div>
            </div>

        )
    }
}

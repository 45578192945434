import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import moment from 'moment';
import { dateFormatter } from '../../../common/components/Utils/renderField/renderReadField'

const url = 'reporte';

const LOADER_RPAGAR = 'LOADER_RPAGAR';
const SET_DATA_RPAGAR = 'SET_DATA_RPAGAR';
const SET_PAGE_RPAGAR = 'SET_PAGE_RPAGAR';
const SET_UPDATE_DATA_RPAGAR ='SET_UPDATE_DATA_RPAGAR';
const SET_BUSCADOR_RPAGAR = 'SET_BUSCADOR_RPAGAR';
const SET_FILTRO_RPAGAR = 'SET_FILTRO_RPAGAR';

const SET_TOTALDEUDA_RPAGAR = 'SET_TOTALDEUDA_RPAGAR';
const SET_DATAGRAFICA_RPAGAR = 'SET_DATAGRAFICA_RPAGAR';

const SET_STARTDATE_RPAGAR  = 'SET_STARTDATE_RPAGAR';
const SET_ENDDATE_RPAGAR  = 'SET_ENDDATE_RPAGAR';
const SET_FILTROPROVEEDOR_RPAGAR = 'SET_FILTROPROVEEDOR_RPAGAR';


function obtenerFechas(dateStart, dateEnd){
    let params = {};
    if (dateStart) {
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    return params;
}
const getGraficaProveedores = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RPAGAR, cargando: true });
    const store = getStore();
    const search = store.rporpagar.buscador;
    const f_proveedores = store.rporpagar.f_proveedores;

    let dateStart = store.rporpagar.dateStart;
    let dateEnd = store.rporpagar.dateEnd

    let params = { page, search };

    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/grafica_porpagar`, params).catch((error) => {
        dispatch({ type: LOADER_RPAGAR, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATAGRAFICA_RPAGAR, data_grafica: data.grafica });
            dispatch({type: SET_TOTALDEUDA_RPAGAR, total_deuda: data.total_deuda})
        }
        dispatch({ type: LOADER_RPAGAR, cargando: false });
    })
};

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RPAGAR, cargando: true });
    const store = getStore();
    const search = store.rporpagar.buscador;
    const f_proveedores = store.rporpagar.f_proveedores;

    let dateStart = store.rporpagar.dateStart;
    let dateEnd = store.rporpagar.dateEnd

    let params = { page, search };

    if(f_proveedores){
        params['proveedor__id'] = f_proveedores.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart, dateEnd)
    }

    api.get(`${url}/detalle_porpagar`, params).catch((error) => {
        dispatch({ type: LOADER_RPAGAR, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATA_RPAGAR, data: data });
            dispatch({type: SET_PAGE_RPAGAR, page: page})
        }
        dispatch({ type: LOADER_RPAGAR, cargando: false });
    })
};
const setProveedor = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTROPROVEEDOR_RPAGAR, f_proveedores: filtro});
    dispatch(getGraficaProveedores());
    dispatch(listar());
}
const setDateStart = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_STARTDATE_RPAGAR, dateStart: date });
    dispatch(getGraficaProveedores());
    dispatch(listar());
}
const setDateEnd = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_ENDDATE_RPAGAR, dateEnd: date });
    dispatch(getGraficaProveedores());
    dispatch(listar());
}
const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_RPAGAR, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_RPAGAR, filtro_: filtro});
    dispatch(getGraficaProveedores());
    dispatch(listar());
};
export const actions = {
    listar,
    search,
    filtro,
    getGraficaProveedores,
    setDateStart,
    setDateEnd,
    setProveedor
};
export const reducer = {
    [LOADER_RPAGAR]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_RPAGAR]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_RPAGAR]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_RPAGAR]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_RPAGAR]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_RPAGAR]: (state, { filtro_ }) => {
        return {...state, filtro_ }
    },
    [SET_STARTDATE_RPAGAR]: (state, { dateStart }) => {
        return {...state, dateStart }
    },
    [SET_ENDDATE_RPAGAR]: (state, { dateEnd }) => {
        return {...state, dateEnd }
    },
    [SET_FILTROPROVEEDOR_RPAGAR]: (state, { f_proveedores }) => {
        return {...state, f_proveedores }
    },
    [SET_TOTALDEUDA_RPAGAR]: (state, { total_deuda }) => {
        return {...state, total_deuda }
    },
    [SET_DATAGRAFICA_RPAGAR]: (state, { data_grafica }) => {
        return {...state, data_grafica }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_: null,
    updateData: {},
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
    f_proveedores: null,
    total_deuda: 0,
    data_grafica: []
};
export default handleActions(reducer, initialState)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Punto_venta/punto_venta';
import {
    actions as actionsCuenta
} from '../../../../../redux/modules/Cuenta/cuenta';
import CuentaDetalle from './CuentaDetalle';

const mstp = state => {
    let me = state.login.me;
    let caja = state.caja.apertura;
    let cuenta = state.cuenta.updateData;
    return {...state.puntoventa, me, caja, cuenta}
};

const mdtp = {
    ...actions, ...actionsCuenta
};

export default connect(mstp, mdtp)(CuentaDetalle)

import React, { Component } from 'react';
import "./dashboardPrivadoEstilos.css";
import { productosPorVencerFarmacia } from '../../../redux/modules/login';

class Privado extends Component {
    
    componentWillMount = () => {
        const {
            productosPorVencerFarmacia, 
            productosPorVencerHospital,
            cuentasPorPagar,
            gastosIngresos,
            cuentasPorCobrar,
            ventasPorArea,
        } = this.props;
    
        productosPorVencerFarmacia();
        productosPorVencerHospital();
        cuentasPorPagar();
        gastosIngresos();
        cuentasPorCobrar();
        ventasPorArea();
    }
    
    render() {
        const {me} = this.props;
        console.log(me);
        return (
            <div className="fondo-dashboard1 d-flex align-items-center justify-content-center">
                <div className="text-center col-md-5 dashboard-blank ">
                    <img  title="Logo" className="img-fluid" src={require("assets/img/logo01.png")} alt="Génesis" />
                </div>
            </div>
        );
    }
}

export default Privado;
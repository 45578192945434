import React, { Component } from 'react';
import Card from 'Utils/Cards/cardGrande';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { iconsh } from "icons";
import { renderTextArea } from '../../../Utils/renderField/renderField';
import { dateFormatter, formatUsuario, formatoMoneda, RenderCurrency } from 'Utils/renderField/renderReadField';
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import RechazoForm from './formulariosmodal/rechazar';


function formatProveedor(cell){
    let nombre = ''
    try {
        nombre = cell.label
    } catch (error) {
        nombre =''
    }
    return <span>{nombre}</span>
}

export default class ConfirmarCrear extends Component {
state = {
    editar: false,
    mensaje: 'Agregar ',
    password: false,
}
componentWillMount(){
    if(this.props.match.params.id){
        this.props.detail(this.props.match.params.id);

    }
}
render() {
    const { create, update } = this.props;
    const { updateData, cargando, page } = this.props;
    let data = {
        count: 0,
        results: updateData.productos
    }
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        {
                    this.props.toggleModal && (
                        <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Observaciones orden de compra</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <RechazoForm
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.rechazarOrden}
                                        />

                                </div>
                            </div>
                        </Modal>
                    )
                }
        <div className="col-md-12">
            <Card
                icono={iconsh.crudOc}
                titulo={'Orden de compra'}>
                <LoadMask loading={cargando} blur_1>
                    <div className="col-md-12 row">
                        <div className="col-md-3 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Orden No:</label>
                                <span className="text-primary">{updateData.no_orden}</span>
                        </div>
                        <div className="col-md-3 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                                <span className="text-primary">{formatUsuario(updateData.usuario)}</span>
                        </div>
                        <div className="col-md-3 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                <span className="text-primary">{dateFormatter(updateData.fecha)}</span>
                        </div>
                        <div className="col-md-3 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Descripción:</label>
                                <span className="text-primary">{updateData.descripcion}</span>
                        </div>
                    </div>
                    <div className="col-md-12 row">
                        <div className="col-md-3 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Proveedor:</label>
                                <span className="text-primary">{formatProveedor(updateData.proveedor)}</span>
                        </div>
                        <div className="col-md-3 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Cuenta:</label>
                                <span className="text-primary">{(updateData.cuenta) ? updateData.cuenta.nombre: '' }</span>
                        </div>
                    </div>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            <span className="text-primary h5 mr-3">Total Compra:</span>
                            <RenderCurrency value={updateData.monto || 0} className={'text-verde h4'}/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Table
                            data={data}
                            loading={cargando}
                            pagination={false}
                            page={page}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre_producto" dataSort>Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatoMoneda}
                                dataField="precio_unitario" dataSort>Precio unitario</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatoMoneda}
                                dataField="subtotal" dataSort>Subtotal</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"></TableHeaderColumn>
                        </Table>
                    </div>
                    {
                        updateData.anulaciones.length > 0 && (
                            <div className="row col-sm-12 p-0 m-0 mt-5 pt-2">
                                <div className="col-md-12">
                                    <h5 className="font-weight-bold text-uppercase">Observaciones de orden de compra</h5>
                                </div>
                                    <div className="col-sm-12 form-group np-r tabla-gris no-striped mt-2 sin-borde p-0 over-h">
                                        <table className="table table-sm table-responsive-sm  m-0">
                                            <tbody>
                                            {updateData.anulaciones.map((anulacion, index) => (
                                                <div key={index} className="col-md-12 row my-3">
                                                    <div className="col-md-5 ver-movil p-0">
                                                        <div className="col-md-12 p-0">
                                                            <div className="col-md-12 col-sm-12 p-0">
                                                                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                                                <span className="text-primary">{dateFormatter(anulacion.creado)}</span>
                                                            </div>
                                                            <div className="col-md-12 col-sm-12 p-0">
                                                                <label htmlFor="emitido" className="m-0 mr-2">Rechazada por:</label>
                                                                <span className="text-primary">{anulacion.usuario.first_name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 ">
                                                        <textarea
                                                            disabled={true} value={anulacion.justificacion} style={{ resize: "none" }} rows={ 3}
                                                            className={'form-control'} />
                                                    </div>
                                                    <div className="col-md-5 ver-escritorio">
                                                        <div className="col-md-12 p-0">
                                                            <div className="col-md-12 col-sm-12 p-0">
                                                                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                                                <span className="text-primary">{dateFormatter(anulacion.creado)}</span>
                                                            </div>
                                                            <div className="col-md-12 col-sm-12 p-0">
                                                                <label htmlFor="emitido" className="m-0 mr-2">Rechazada por:</label>
                                                                <span className="text-primary">{anulacion.usuario.first_name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            </tbody>
                                        </table>
                                </div>
                        </div>
                        )
                    }

                </LoadMask>
                <div className="col-md-12 row mt-3">
                     <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" onClick={this.props.openModal} className="btn btn-danger m-1">Rechazar orden</button>
                     </div>
                     <div className="col-md-6 d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Link className="btn btn-secondary m-1" to="/solicitudes">Cancelar</Link>
                        <button type="button" onClick={ e=>{
                            e.preventDefault();
                            this.props.aceptarOrden(this.props.match.params.id)
                        }} className="btn btn-primary m-1">Confirmar orden</button>
                     </div>
                </div>

            </Card>
        </div>
    </div>
    )
    }
}

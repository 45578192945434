import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import { api } from 'api/api';

let cuentas = [];

const getCuentas = (search)  =>{
    return api.get("cuenta",{search,pagado:false}).catch((error) => {})
            .then((data) => {
                if (data){
                    cuentas = [];
                    data.results.forEach(x=>{
                        cuentas.push({value: x.id, label: x.paciente.nombre})
                    })
                    return cuentas
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}
let CuentasForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="paciente" className="m-0">Paciente:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`paciente`}
                                    valueKey="value"
                                    labelKey="label"
                                    label="label"
                                    component={renderSearchSelect}
                                    loadOptions={getCuentas}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={e=>{
                                        e.preventDefault();
                                        props.cerrar();
                                    }}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Abrir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
CuentasForm = reduxForm({
    form: 'cuentas',
    validate: data => {
        return validate(data, {
            'paciente': validators.exists()('Campo requerido.'),
        })
    }
})(CuentasForm);
export default CuentasForm

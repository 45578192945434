import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Bodega/estadobodega';
import EstadoGrid from './EstadoGrid';

const mstp = state => {
    let nombreBodega = '';
    let encargado = null;
    if(state.estadobodega.updateData){
        let bodega = state.estadobodega.updateData
        nombreBodega = bodega.nombre ? bodega.nombre : '';
        let usuario = state.login;
        if(usuario.me) {
            encargado = usuario.me.nombreCompleto;
        }
        /*if(bodega.encargado){
            encargado = bodega.encargado.nombreCompleto ? bodega.encargado.nombreCompleto : null;
        }*/
    }
    return {
        ...state.estadobodega,
        ...state.login,
        nombreBodega,
        encargado
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(EstadoGrid)

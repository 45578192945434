import { api } from 'api/api';
import Select from 'react-select';
import classNames from 'classnames';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';

let bodegas = [];
const getBodegas = (search) => {
  return api.get('bodega', { search }).catch((error) => { })
    .then((data) => {
      if (data) {
        bodegas = [];
        data.results.forEach((x) => {
          bodegas.push(x);
        });
        return bodegas;
      }

      return [];
    })
    .catch((e) => {
      return [];
    });
};
export default class Filtro extends Component {
  render() {
    const { ver_vencimiento } = this.props;
    return (
      <div className="col-md-12 row m-0 d-flex justify-content-center ">
        {ver_vencimiento
            && (
            <div className="col-lg-8 form-group">
              <label className="m-0">Vencimiento:</label>
              <Select
                name="filtro"
                onChange={this.props.setFiltroVencimiento}
                value={this.props.vencimiento}
                searchPromptText="Escriba para buscar"
                placeholder="Ninguno"
                options={[
                  { value: 0, label: 'Todos' },
                  { value: 1, label: 'Vencidos' },
                  { value: 2, label: '1 Mes' },
                  { value: 3, label: '2 Meses' },
                  { value: 4, label: '3 Meses' },
                ]}
              />
            </div>
            )}
        <div className="col-lg-8 form-group">
          <label className="m-0">Área:</label>
          <AsyncSelect
            value={this.props.filtro_area}
            onChange={this.props.setFiltroArea}
            placeholder="todos"
            options={getBodegas}
            className={classNames('form-control p-0 ')}
            isClearable
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: '1em',
                background: 'white',
                colors: {
                  ...theme.colors,
                  text: '#415362',
                  primary25: '#ebebeb',
                  primary: '#a8ba3a',
                },
              };
            }}
            classNamePrefix="react-select"
            cache={false}
            defaultOptions
            searchPromptText="Escriba para buscar"
            getOptionValue={option => (option.id)}
            getOptionLabel={option => (option.nombre)}
            loadOptions={getBodegas}
          />
        </div>
      </div>
    );
  }
}

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'paciente';

const LOADER_PACIENTE = 'LOADER_PACIENTE';
const SET_DATA_PACIENTE = 'SET_DATA_PACIENTE';
const SET_PAGE_PACIENTE = 'SET_PAGE_PACIENTE';
const SET_UPDATE_DATA_PACIENTE ='SET_UPDATE_DATA_PACIENTE';
const SET_BUSCADOR_PACIENTE = 'SET_BUSCADOR_PACIENTE';
const SET_FILTRO_PACIENTE = 'SET_FILTRO_PACIENTE';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PACIENTE, cargando: true});
    const store = getStore();
    const search = store.paciente.buscador;
    const filtro = store.paciente.filtro_paciente;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PACIENTE, data});
            dispatch({type: SET_PAGE_PACIENTE, page});
        }
        dispatch({type: LOADER_PACIENTE, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_PACIENTE, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/enfermeria_pacientes'))
        }
    }).then((data) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_PACIENTE, updateData: data});
            dispatch(initializeForm('paciente', data))
        }
    })
}

const create = (validar_duplicado=true) => (dispatch, getStore) => {
    const formData = getStore().form.paciente.values;
    dispatch({type: LOADER_PACIENTE, cargando: true})
    try {
        let fecha = new Date(formData.fecha_nacimiento)
        formData.fecha_nacimiento = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
    } catch (error) {

    }
    if(validar_duplicado === false) {
        formData['validar_duplicado'] = false;
    }
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_PACIENTE, cargando: false})
        Swal('Éxito', 'Se ha creado el paciente.', 'success')
        .then(() => {
            dispatch(push('/enfermeria_pacientes'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        if (error.status == 409) {
            Swal.fire({
                title: 'Paciente duplicado',
                html: error.detail,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, agregar'
            }).then((result) => {
                if (result.value) {
                    dispatch(create(false));
                }
            });
        } else {
            Swal.fire({
                'title': 'Error',
                'html': (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'type': 'error',
            }).then((result) => {
                    if (result) {
                        dispatch(push('/enfermeria_pacientes'))
                    }
                }
            );
        }
    }).finally(() => {
        dispatch({type: LOADER_PACIENTE, cargando: false})
    });
};

const update = (validar_duplicado=true) => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.paciente.values);
    if(validar_duplicado === false) {
        formData['validar_duplicado'] = false;
    }
    dispatch({type: LOADER_PACIENTE, cargando: true});
    try {
        let fecha = new Date(formData.fecha_nacimiento)
        formData.fecha_nacimiento = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
    } catch (error) {

    }
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/enfermeria_pacientes'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_PACIENTE, cargando: false});
        if (error.status == 409) {
            Swal.fire({
                title: 'Paciente duplicado',
                html: error.detail,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, agregar'
            }).then((result) => {
                if (result.value) {
                    dispatch(update(false));
                }
            });
        } else {
            Swal.fire({
                'title': 'Error',
                'html': error.detail || 'Ha ocurrido un error, verifique los datos.',
                'type': 'error',
            }).then((result) => {
                    if (result) {
                        dispatch(push('/enfermeria_pacientes'))
                    }
                }
            );
        }
        if(error.statusCode === 404){
            dispatch(push('/enfermeria_pacientess'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PACIENTE, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_PACIENTE, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PACIENTE, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PACIENTE, filtro_paciente: filtro});
    dispatch(listar(1));
};
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro
};
export const reducer = {
    [LOADER_PACIENTE]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PACIENTE]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PACIENTE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PACIENTE]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PACIENTE]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PACIENTE]: (state, { filtro_paciente }) => {
        return {...state, filtro_paciente }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_paciente: null,
    updateData: {},
};
export default handleActions(reducer, initialState)

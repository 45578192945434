import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import renderDatePicker  from 'Utils/renderField/renderDatePicker';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { opcionesBanco, TIPOSPAGO, BANCOS } from '../../../../../utility/constants';
import { renderField, renderCurrency, renderNoAsyncSelectField, renderFieldCheck } from 'Utils/renderField/renderField';
import { formatoMoneda} from '../../../../Utils/renderField/renderReadField';

let PagosForm = props => {
    const { handleSubmit, mensaje, ver, data, vale, total } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha" className="m-0">Fecha*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha"
                                    component={renderDatePicker}
                                    placeholder="fecha"
                                    type="fecha"
                                    className=""
                                    numberOfMonths={1}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="tipo" className="m-0">Tipo pago*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name={`tipo_pago`}
                                    valueKey="value"
                                    labelKey="label"
                                    label="Tipopago"
                                    component={renderNoAsyncSelectField}
                                    options={opcionesBanco}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ver && (
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="documento_pago" className="m-0">{mensaje}*:</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="documento_pago" component={renderField} type="text" className="form-control"/>
                                </div>
                            </div>
                        )}
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="documento_pago" className="m-0">No. Factura*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="factura" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="s" className="m-0">Pago*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                        disabled={true}
                                        name={`monto`}
                                        type="number"
                                        input={{value:total}}
                                        addClass={"text-right"}
                                        component={renderCurrency}
                                        placeholder="Monto"
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            ver && (
                                <div className="col-md-6">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="tipo" className="m-0">Banco*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name={`banco`}
                                            valueKey="value"
                                            labelKey="label"
                                            label="Tipopago"
                                            component={renderNoAsyncSelectField}
                                            options={BANCOS}
                                            />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="row">
                        <div className="row col-md-6 m-0 p-0 pl-2 ml-2">
                            <div className="col-auto m-0 ml-3 form-group label">
                                <Field name="vale" component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                    placeholder=""/>
                            </div>
                            <div className="p-0 ml-3  ml-sm-0  col-lg-9 m-0 col-md-9 col-sm-12  pt-1">
                                <span className="text-primary font-weight-bold text-uppercase">Pagar con crédito</span>
                            </div>
                        </div>
                        {
                            vale && (
                                <div className="col-md-4">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="valor" className="m-0">Crédito acumulado:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <span className="text-primary">{ formatoMoneda(data.proveedor.credito)}</span>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    {
                        vale && (
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="vale" className="m-0">No. vale:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="no_vale" component={renderField} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="monto_vale" className="m-0">Monto vale*:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                                name={`monto_vale`}
                                                type="number"
                                                addClass={"text-right"}
                                                component={renderCurrency}
                                                placeholder="monto"
                                            />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={props.closeModal}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
PagosForm = reduxForm({
    form: 'pagos',
    validate: (data, props) => {
        const errors = {};
        if(!data.fecha){
            errors.fecha='Campo requerido'
        }
        if(!data.banco){
            errors.banco = 'Campo Requerido'
        }
        if(!data.tipo_pago){
            errors.tipo_pago = 'Campo requerido';
        }
        if(!data.documento_pago){
            errors.documento_pago = 'Campo requerido';
        }
        if(data.vale){
            if(!data.monto_vale){
                errors.monto_value = 'Campo requerido';
            }else{
                if(data.monto < data.monto_vale){
                    errors.monto_value = 'El monto del crédito no puede ser mayor al monto del pago'
                }
                if(props.data.proveedor.credito < data.monto_vale){
                    errors.monto_value = 'El monto no puede superar el crédito que se tiene con el proveedor'
                }
                if(props.data.proveedor.credito == 0){
                    errors.monto_value = 'No tienen créditos para canjear'
                }
            }
        }
        return errors;
    }

})(PagosForm);

const selector = formValueSelector('pagos');
PagosForm = connect((state, props) => {
    const tipo = selector(state, "tipo_pago");
    const vale = selector(state, "vale");
    const monto_vale = selector(state, "monto_vale");
    const monto = selector(state, "monto");
    let total = props.pago.monto;
    if(vale){
        if(monto_vale){
            total = monto - monto_vale
        }
    }
    let mensaje = 'No. cheque';
    let ver = true;
    if(tipo){
        let opcion = opcionesBanco.find(x => x.value === tipo);
        mensaje = opcion.documento;
        if(tipo === TIPOSPAGO.EFECTIVO){
            mensaje = 'No. comprobante';
            ver = false
        }
    }

    return {
        mensaje,
        ver,
        vale,
        total
    };
})(PagosForm);
export default PagosForm

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Caja/caja';
import DetalleCaja from './DetalleCaja';

const mstp = state => {
    return {...state.caja}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleCaja)

import React from 'react'

export default function Titulo(props) {
  return (
    <div className="col-md-12 row">
        <div className="titulo d-flex align-items-center p-0">
            <img width={35} className="" src={props.icono} alt="Usuario" />
            <span className="ml-2 text-uppercase text-titulo"><strong>{props.titulo}</strong></span>
        </div>
    </div>
  )
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Punto_venta/punto_venta';
import ProductosVenta from './ProductosVenta';

const mstp = state => {
    return {...state.puntoventa}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ProductosVenta)

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm, reset } from 'redux-form'
import { push } from 'react-router-redux';
import { PROCESO } from '../../../common/utility/constants';
import uuid1 from 'uuid/v1';
const url = 'compras';

const LOADER_COMPRAS = 'LOADER_COMPRAS';
const SET_DATA_COMPRAS = 'SET_DATA_COMPRAS';
const SET_PAGE_COMPRAS = 'SET_PAGE_COMPRAS';
const SET_PAGE_COMPRAS_ACEPTADAS = 'SET_PAGE_COMPRAS_ACEPTADAS';
const SET_UPDATE_DATA_COMPRAS ='SET_UPDATE_DATA_COMPRAS';
const SET_BUSCADOR_COMPRAS = 'SET_BUSCADOR_COMPRAS';
const SET_FILTRO_COMPRAS = 'SET_FILTRO_COMPRAS';
const SET_DATATODO_COMPRAS = 'SET_DATATODO_COMPRAS';
const SET_DATAACEPTADOS_COMPRAS = 'SET_DATAACEPTADOS_COMPRAS';
const SET_NOTRECHA_COMPRAS = 'SET_NOTRECHA_COMPRAS';
const SET_NOTACEPT_COMPRAS = 'SET_NOTACEPT_COMPRAS';
const SET_NOTPENDIENTES_COMPRAS = 'SET_NOTPENDIENTES_COMPRAS';
const SET_PENDIENTES_COMPRAS = 'SET_PENDIENTES_COMPRAS';
const SET_MODAL_COMPRAS = 'SET_MODAL_COMPRAS';


const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_COMPRAS, cargando: true});
    const store = getStore();
    const search = store.compras.buscador;
    const filtro = store.compras.filtro_compras;
    let params = {page, search};

    params['proceso'] = PROCESO.ACEPTADO;

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_COMPRAS, data});
            dispatch({type: SET_PAGE_COMPRAS, page});
            dispatch(getNotificacionA())
            dispatch(getNotificacionR())
            dispatch(getNotificacionP())
        }
        dispatch({type: LOADER_COMPRAS, cargando: false});
    })
};
const listarPendientes = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_COMPRAS, cargando: true});
    const store = getStore();
    let params = {page};
    api.get(`${url}/getPendientes`, params).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            let result = {
                count: 0,
                results: data
            }
            dispatch({type: SET_PENDIENTES_COMPRAS, pendientes:result});
            dispatch(getNotificacionP())
        }
        dispatch({type: LOADER_COMPRAS, cargando: false});
    })
};
const listarTodo = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_COMPRAS, cargando: true});
    const store = getStore();
    const search = store.compras.buscador;
    const filtro = store.compras.filtro_compras;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}/getMovimientos`, params).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATATODO_COMPRAS, dataTodo: data});
            dispatch({type: SET_PAGE_COMPRAS, page});
            dispatch(getNotificacionA())
            dispatch(getNotificacionR())
            dispatch(getNotificacionP())
        }
        dispatch({type: LOADER_COMPRAS, cargando: false});
    })
};

const listarAceptados = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_COMPRAS, cargando: true});
    const store = getStore();
    const search = store.compras.buscador;
    const filtro = store.compras.filtro_compras;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    params['aceptado'] = true;
    api.get(`${url}/getMovimientos`, params).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATAACEPTADOS_COMPRAS, dataAceptados:data});
            dispatch({type: SET_PAGE_COMPRAS_ACEPTADAS, pageAceptadas: page});
            dispatch(getNotificacionA())
            dispatch(getNotificacionR())
            dispatch(getNotificacionP())
        }
        dispatch({type: LOADER_COMPRAS, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_COMPRAS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/ordenes'))
        }
    }).then((data) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_COMPRAS, updateData: data});
            dispatch(initializeForm('orden', data));
            dispatch(marcarVisto(id))

        }
    })
}

const create = (data='a', borrador=true) => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.orden.values);
    let params = {}
    if(borrador === true){
        params['borrador'] = borrador;
    }
    try {
        formData.fecha = formData.fecha.format("YYYY-MM-D")
    } catch (error) {
        formData.fecha = formData.fecha
    }
    formData.cuenta = formData.cuenta.id;
    dispatch({type: LOADER_COMPRAS, cargando: true})
    api.post(`${url}`, formData, params).then((data) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        dispatch(reset('orden'));
        Swal('Éxito', 'Se ha creado la orden de compra.', 'success')
        .then(() => {
            dispatch(push('/ordenes'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_COMPRAS, cargando: false})
    });
};

const update = (data='a', borrador=true) => (dispatch, getStore) => {
    let params = {};
    if(borrador === true){
        params['borrador'] = borrador;
    }
    const formData = _.cloneDeep(getStore().form.orden.values);
    const orden = getStore().compras.updateData;
    formData.cuenta = formData.cuenta.id;
    let borrados = orden.productos.filter((el) => {
        !formData.productos.includes(el.id)
        if(formData.productos.find(x => x.id === el.id)){
            return
        }
        return el
    });
    formData.productosBorrados = borrados;
    dispatch({type: LOADER_COMPRAS, cargando: true});
    api.put(`${url}/${formData.id}`, formData, params)
    .then((data) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        dispatch(reset('orden'));
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/ordenes'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/compras'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_COMPRAS, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_COMPRAS, buscador: search});
    dispatch(listar(1));
    dispatch(listarTodo());
    dispatch(listarAceptados());
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_COMPRAS, filtro_compras: filtro});
    dispatch(listar(1));
    dispatch(listarTodo());
    dispatch(listarAceptados());
};

export const marcarVisto = (id) => (dispatch, getStore) => {
    let formData = {s: 's'}
    api.put(`${url}/marcarVisto/${id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_COMPRAS, cargando: false});
        if(data){
            dispatch(getNotificacionA())
            dispatch(getNotificacionR())
            dispatch(getNotificacionP())
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_COMPRAS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/compras'));
        }
    })
}
const aceptarOrden = (id) => (dispatch, getStore) => {
    let formData = {s:'s'};
    dispatch({type: LOADER_COMPRAS, cargando: true});
    api.put(`${url}/aceptarorden/${id}`, formData)
        .then((data) => {
            dispatch({type: LOADER_COMPRAS, cargando: false});
            dispatch(push('/solicitudes'));
        })
        .catch((error) => {
            dispatch({type: LOADER_COMPRAS, cargando: false});
            Swal(
                'Error',
                 error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            )
            if(error.statusCode === 404){
                dispatch(push('/solicitudes'));
            }
        })
}
const rechazarOrden = () => (dispatch, getStore) => {
    let formData = _.cloneDeep(getStore().form.rechazo.values)
    const store = getStore();
    const updateData = store.compras.updateData;
    let id = updateData.id;
    dispatch({type: LOADER_COMPRAS, cargando: true});
    api.put(`${url}/rechazarorden/${id}`, formData)
        .then((data) => {
            dispatch({type: LOADER_COMPRAS, cargando: false});
            dispatch(closeModal());
            dispatch(push('/solicitudes'));
        })
        .catch((error) => {
            dispatch({type: LOADER_COMPRAS, cargando: false});
            dispatch(closeModal());
            Swal(
                'Error',
                 error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            )
            if(error.statusCode === 404){
                dispatch(push('/solicitudes'));
            }
        })
}
const getNotificacionA = () =>  (dispatch, getStore) => {
    const store = getStore();
    let params = {aceptado: ''};
    api.get(`${url}/getNotificacion`, params).catch((error) => {
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_NOTACEPT_COMPRAS, notificacionA:data.detail});
        }
    })
};
const getNotificacionR = () =>  (dispatch, getStore) => {
    const store = getStore();
    api.get(`${url}/getNotificacion`).catch((error) => {
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_NOTRECHA_COMPRAS, notificacionR:data.detail});
        }
    })
};
const getNotificacionP = () =>  (dispatch, getStore) => {
    const store = getStore();
    let params = {pendientes: ''};
    api.get(`${url}/getNotificacion`, params).catch((error) => {
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_NOTPENDIENTES_COMPRAS, notificacionP:data.detail});
        }
    })
};
const closeModal = () => (dispatch, getStore) => {
    dispatch({ type: SET_MODAL_COMPRAS, toggleModal: false});
}
const openModal = () => (dispatch, getStore) => {
    dispatch({ type: SET_MODAL_COMPRAS, toggleModal:true});
}

const uuidInitial = () => (dispatch) => {
    // Inicializamos el uuid cada vez que entra en el formulario
    dispatch(initializeForm('orden', {
        uuid: uuid1()
    }));
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    listarTodo,
    listarAceptados,
    getNotificacionA,
    getNotificacionR,
    listarPendientes,
    getNotificacionP,
    aceptarOrden,
    rechazarOrden,
    closeModal,
    openModal,
    uuidInitial
};
export const reducer = {
    [LOADER_COMPRAS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_COMPRAS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_COMPRAS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_PAGE_COMPRAS_ACEPTADAS]: (state, { pageAceptadas }) => {
        return {...state, pageAceptadas }
    },
    [SET_UPDATE_DATA_COMPRAS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_COMPRAS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_COMPRAS]: (state, { filtro_compras }) => {
        return {...state, filtro_compras }
    },
    [SET_DATATODO_COMPRAS]: (state, { dataTodo }) => {
        return {...state, dataTodo }
    },
    [SET_DATAACEPTADOS_COMPRAS]: (state, { dataAceptados }) => {
        return {...state, dataAceptados }
    },
    [SET_NOTRECHA_COMPRAS]: (state, { notificacionR }) => {
        return {...state, notificacionR }
    },
    [SET_NOTACEPT_COMPRAS]: (state, { notificacionA }) => {
        return {...state, notificacionA }
    },
    [SET_PENDIENTES_COMPRAS]: (state, { pendientes }) => {
        return {...state, pendientes }
    },
    [SET_NOTPENDIENTES_COMPRAS]: (state, { notificacionP }) => {
        return {...state, notificacionP }
    },
    [SET_MODAL_COMPRAS]: (state, {toggleModal}) => {
        return { ...state, toggleModal}
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    pageAceptadas: 1,
    pendientes: {
        count: 0,
        results: []
    },
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    dataTodo: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    dataAceptados: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_compras: null,
    updateData: {
        anulaciones: []
    },
    notificacionR: 0,
    notificacionA: 0,
    notificacionP: 0,
    toggleModal: false
};
export default handleActions(reducer, initialState)

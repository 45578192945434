import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter }  from 'Utils/Acciones/Acciones'

export default class CuentaAreaGrid extends Component {
componentWillMount(){
    this.props.listar(this.props.page);
}
render() {
    const {
        listar,
        detail,
        create,
        update,
        destroy,
    } = this.props;
    const {
        cargando,
        page,
        data
    } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <HeaderSimple
            texto={"Agregar Cuenta"}
            ruta={"/admin_cuenta_area"}
        />
        <ToolbarSimple
            titulo="Cuenta"
            icono={iconsh.crud_cuentas}
            buscar={this.props.search}
            buscador={this.props.buscador}
            />
        <div className="mt-3 col-md-12">
            <Table
                onPageChange={listar}
                data={data}
                loading={cargando}
                page={page}>
                <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="center"
                    dataFormat={activeFormatter({ editar: '/admin_cuenta_area', eliminar: destroy })}>Acciones</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="nombre" dataSort>Nombre</TableHeaderColumn>

            </Table>
        </div>
    </div>
    )
    }
}

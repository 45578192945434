import React, { Component } from 'react'
import MonedaForm from './Formularios/monedasform';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import {iconsh} from 'icons';
export default class DetalleBilletes extends Component {
  render() {
      const { updateData, total, apertura, configuracion } = this.props;
      let total_encaja = 0;
      if(apertura){
        total_encaja = apertura.total_apertura + apertura.total_efectivo;
      }
    return (
      <div className="col-md-12 py-3 ">
        <div className="col-md-12 p-2 fondo-inverso-navbar mb-2">
            <h5 className="text-uppercase m-0 pr-3 text-right text-white">
                <img className="mr-2 pb-1" src={iconsh.crud_caja_venta2} height={35} />
                Caja
            </h5>
        </div>
        <div className="col-md-12 row p-0  d-flex  justify-content-center" style={{height: '100%'}}>
            <div className="col-md-12 d-flex  justify-content-center row m-0 p-0">
                {
                    updateData && updateData.cierre && (
                        <div className="col-md-12  m-0 d-flex justify-content-center mt-3 mb-3">
                            <div className="col-md-10 row m-0 ">
                                <div className="col-md-6 text-center">
                                    <h5 className="m-0 text-center">Total en caja</h5>
                                    <RenderCurrency value={total_encaja}  className={'text-verde h5 font-weight-bold'}/>
                                </div>
                                <div className="col-md-6 text-center">
                                    <h5 className="m-0 text-center text-gris">Efectivo ingresado</h5>
                                    <RenderCurrency value={total}  className={'text-primary h5 font-weight-bold'}/>
                                </div>
                            </div>
                        </div>
                    )
                }
                <MonedaForm
                    caja={updateData}
                    onSubmit={this.props.verficarMonedas}
                    total={total}
                    cancelar={this.props.regresar}/>
            </div>
        </div>
      </div>
    )
  }
}

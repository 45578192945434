import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Compras/compras';
import ConfirmarCrear from './ConfirmarCrear';

const mstp = state => {
    return {...state.compras}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ConfirmarCrear)

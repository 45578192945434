import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack, go } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { reset, formValueSelector } from 'redux-form';
import { push } from 'react-router-redux';
import { actions as actionsCaja } from '../Caja/caja';
import { actions as actionsLogin } from '../login';
import swal from 'sweetalert2';
import { actions as actions_cuenta } from '../Cuenta/cuenta';
import _ from 'lodash';

const url = 'venta';
const url_cuenta = 'cuenta';

const LOADER_PV = 'LOADER_PV';
const SET_DATA_PV = 'SET_DATA_PV';
const SET_PAGE_PV = 'SET_PAGE_PV';
const SET_UPDATE_DATA_PV ='SET_UPDATE_DATA_PV';
const SET_BUSCADOR_PV = 'SET_BUSCADOR_PV';
const SET_FILTRO_PV = 'SET_FILTRO_PV';
const SET_INVENTARIO_PV = 'SET_INVENTARIO_PV';
const SET_CARGANDO_PRODUCTOS_PV = 'SET_CARGANDO_PRODUCTOS_PV';
const SET_CARRITO_PV = 'SET_CARRITO_PV';
const SET_TOTALCOMPRA_PV = 'SET_TOTALCOMPRA_PV';
const SET_MOVIMIENTO_PV = 'SET_MOVIMIENTO_PV';
const LOADER_PV_CUENTA = 'LOADER_PV_CUENTA';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PV, cargando: true});
    const store = getStore();
    const search = store.puntoventa.buscador;
    const filtro = store.puntoventa.filtro_pv;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PV, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PV, data});
            dispatch({type: SET_PAGE_PV, page});
        }
        dispatch({type: LOADER_PV, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_PV, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PV, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_PV, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_PV, updateData: data});

        }
    })
}

const getInventario = (page=1) => (dispatch, getStore) =>{
    dispatch({type: SET_CARGANDO_PRODUCTOS_PV, cargando_productos: true});
    const store = getStore();
    const search = store.puntoventa.buscador;
    dispatch({type: SET_CARGANDO_PRODUCTOS_PV, cargando_productos : true});
    api.get(`inventario/getInventarioPV`,{search, page}).catch((error) => {
        dispatch({type: SET_CARGANDO_PRODUCTOS_PV, cargando_productos: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: SET_CARGANDO_PRODUCTOS_PV, cargando_productos: false});
        if(data){
            dispatch({type: SET_INVENTARIO_PV, inventario: data});
            dispatch({type: SET_PAGE_PV, page: page})
        }
    })
}

const create = () => (dispatch, getStore) => {
    const store = getStore();
    const carrito = store.puntoventa.carrito || [];
    if(carrito.length == 0){
        swal('Error', 'Debe seleccionar productos para vender.', 'error')
    }else{
        const formData = _.cloneDeep(getStore().form.pago.values);
        if(formData.tarjeta){
            formData.tarjeta = true;
        }
        if(formData.efectivo){
            formData.efectivo = true;
        }
        formData.productos = carrito;
        dispatch({type: LOADER_PV, cargando: true})
        api.post(`${url}`, formData).then((data) => {
            dispatch({type: LOADER_PV, cargando: false})
            dispatch({type: SET_MOVIMIENTO_PV, movimiento: data})
            Swal('Éxito', 'Se ha realizado la venta.', 'success')
            .then(() => {
                dispatch({
                    type: SET_CARRITO_PV,
                    carrito: []
                })
                setTimeout(() => {
                    window.print();
                    dispatch(push('/caja/punto_venta'))
                }, 500);
            })
        }).catch((error) => {
            dispatch({type: LOADER_PV, cargando: false})
            Swal(
                'Error',
                (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).finally(() => {
            dispatch({type: LOADER_PV, cargando: false})
        });
    }

};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_PV, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PV, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_PV, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PV, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PV, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_PV, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PV, buscador: search});
    dispatch(getInventario(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PV, filtro_pv: filtro});
    dispatch(listar(1));
};

const getInfoCaja = () => (dispatch, getStore) => {
    const store = getStore();
    const me = store.login.me;
    dispatch(actionsCaja.detailApertura(me.apertura))

}

const abrir_cuenta = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.cuentas.values)
    dispatch(push(`/caja/punto_venta/cuenta/${formData.paciente.value}`))
}

//const cambioPrecioServicio = (dato, value, orden, detalle) => (dispatch, getStore) => {
const cambioPrecioServicio = (index) => (dispatch, getStore) => {
    let updateData = _.cloneDeep(getStore().cuenta.updateData);
    let rowProducto = _.find(updateData.detalle_movimiento, { 'id': index});
    let indexProducto = _.findIndex(updateData.detalle_movimiento, { 'id': index});
    const deshabilitar = rowProducto.disabled === undefined || (rowProducto.disabled !== undefined && rowProducto.disabled) ? true : false;

    if(deshabilitar){
        rowProducto.disabled = false;
        updateData[indexProducto] = rowProducto;
        dispatch(actions_cuenta.actualizarUpdateData(updateData));
    }else{
            const value = rowProducto.new_price;
            const dato = rowProducto.subtotal;
            const orden = rowProducto.orden;
            const detalle = rowProducto.detalle;
            if(value !== undefined && dato !== undefined && value !== dato){
                dispatch({type: LOADER_PV_CUENTA, cargando_cuenta: true});
                const cantidad_a_restar = parseFloat((parseFloat(value) - parseFloat(dato)).toFixed(2));
                api.post(`${url_cuenta}/cambiarPrecioServicio/${orden}`, {cantidad_a_restar, detalle}).then((data) => {
                    dispatch(actions_cuenta.detail(orden));
                }).catch((error) => {
                    console.log("error al cambiar el precio: ", error);
                }).finally(() => {
                    dispatch({type: LOADER_PV_CUENTA, cargando_cuenta: false});
                });
            }else{
                rowProducto.disabled = true;
                updateData[indexProducto] = rowProducto;
                dispatch(actions_cuenta.actualizarUpdateData(updateData));
            }
    }
}

/*
const cambioPrecioServicio = (producto=null, value=null) => (dispatch, getStore) => {
    console.log("Cambiando precio servicio");
    const store = getStore();
    const _carrito = store.form.venta.values ? _.cloneDeep(store.form.venta.values.productos) : [];
    const index = _carrito.findIndex(x => x.id === producto.id);
    //Si el producto existe se suma la cantidad
    if (producto && (index !== -1)){
        _carrito[index].precio = value;
        _carrito[index].precio_descuento = value;
    }

    dispatch({
        type: SET_CARRITO_PV,
        carrito: _carrito
    })
}
*/

/**Agrega los productos al  listado de compra */
const agregar_productos_carrito = (nuevo_producto) => (dispatch, getStore) => {
    const store = getStore();
    const _carrito = store.form.venta.values ? _.cloneDeep(store.form.venta.values.productos) : [];
    const producto = _carrito.find(x => x.id === nuevo_producto.id);
    const index = _carrito.findIndex(x => x.id === nuevo_producto.id);
    //Si el producto existe se suma la cantidad
    if (nuevo_producto.es_producto){
        if (producto && (index !== -1)){
            _carrito[index].cantidad += parseInt(nuevo_producto.cantidad_agregar)
            const lote = _carrito[index].lotes_ids.find(lote => nuevo_producto.lote_id===lote.id)
            if(lote){
                lote.cantidad += parseInt(nuevo_producto.cantidad_agregar)
            }
            else{
                _carrito[index].lotes_ids = [..._carrito[index].lotes_ids, {id: nuevo_producto.lote_id, cantidad: nuevo_producto.cantidad_agregar}]
            }
        }else{
            //Agrega el nuevo producto al carrito
            _carrito.push({
                ...nuevo_producto,
                cantidad: parseInt(nuevo_producto.cantidad_agregar),
                fraccion: nuevo_producto.id,
                lotes_ids: [{id: nuevo_producto.lote_id, cantidad: nuevo_producto.cantidad_agregar}]
            });
        }
    }
    else{
        if (producto && (index !== -1)){
            _carrito[index].cantidad += 1
        }else{
            //Agrega el nuevo producto al carrito
            _carrito.push({
                ...nuevo_producto,
                cantidad: 1,
                fraccion: nuevo_producto.id
            });
        }
    }
    dispatch({
        type: SET_CARRITO_PV,
        carrito: _carrito
    })
    // dispatch(initializeForm('venta', {productos: _carrito}))
}
const borrar_carrito = () => (dispatch, getStore) => {
    // dispatch(reset('venta'))
    dispatch({
        type: SET_CARRITO_PV,
        carrito: []
    })
}
const ir_pagos = () => (dispatch, getStore) => {
    const store = getStore();
    const _carrito = store.form.venta.values ? _.cloneDeep(store.form.venta.values.productos) : [];

    if(_carrito.length == 0){
        swal('Error', 'Debe de seleccionar productos para realizar un pago.', 'error');
    }else{
        dispatch({
            type: SET_CARRITO_PV,
            carrito: _carrito
        });
        let subtotal = 0;
        let precio_final = 0;
        _carrito.forEach(prod => {
            try {
                subtotal += Number(prod.cantidad) * Number(prod.precio);
                precio_final += Number(prod.cantidad) * Number(prod.precio_descuento);
            } catch (error) {
                subtotal = subtotal;
                precio_final = precio_final;
            }
        });
        let objecto = {
            subtotal, precio_final
        }
        dispatch({
            type: SET_TOTALCOMPRA_PV,
            totales_compra: objecto

        });
        dispatch(push('/caja/punto_venta/pago'))
    }

}
const regresar = () => (dispatch, getStore) => {
    dispatch(go(-1));
}
const initCantidad  = (lote, lotes) => (dispatch, getStore) => {
    const formProducto = getStore().form.productoform2;
    const loteID = lote.target.value;
    let loteResult = _.find(lotes, (ob => ob.lote.id == loteID));
    if(loteResult) {
        if(loteResult.existencias >= 1) {
            dispatch(initializeForm('productoform2', {
                ...formProducto.values,
                cantidad: 1,
                existencias: loteResult.existencias,
                vencimiento: loteResult.lote.fecha_vencimiento,
            }));
        }else {
            dispatch(initializeForm('productoform2',
                {...formProducto.values,
                    cantidad: '',
                    existencias: loteResult.existencias,
                    vencimiento: loteResult.lote.fecha_vencimiento,
                }));
        }
    } else {
        dispatch(initializeForm('productoform2', {
            ...formProducto.values,
            cantidad: '',
            existencias: 0,
        }));
    }

}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    ir_pagos,
    regresar,
    getInfoCaja,
    abrir_cuenta,
    getInventario,
    borrar_carrito,
    agregar_productos_carrito,
    cambioPrecioServicio,
    initCantidad
};
export const reducer = {
    [LOADER_PV_CUENTA]: (state, { cargando_cuenta }) => {
        return {...state, cargando_cuenta }
    },
    [LOADER_PV]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PV]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PV]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PV]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PV]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PV]: (state, { filtro_pv }) => {
        return {...state, filtro_pv }
    },
    [SET_INVENTARIO_PV]: (state, { inventario }) => {
        return {...state, inventario }
    },
    [SET_CARGANDO_PRODUCTOS_PV]: (state, { cargando_productos }) => {
        return {...state, cargando_productos }
    },
    [SET_CARRITO_PV]: (state, { carrito }) => {
        return {...state, carrito }
    },
    [SET_TOTALCOMPRA_PV]: (state, { totales_compra }) => {
        return {...state, totales_compra }
    },
    [SET_MOVIMIENTO_PV]: (state, { movimiento }) => {
        return {...state, movimiento }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_pv: null,
    updateData: {},
    inventario : {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    cargando_productos: false,
    carrito:[],
    totales_compra: {},
    movimiento: null,
    cargando_cuenta : false,
    valores_precio: [{}]
};
export default handleActions(reducer, initialState)


import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reportes/rcaja';
import RCaja from './RCaja';

const mstp = state => {
    return {...state.rcaja}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(RCaja)

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack, go } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import { getMe } from '../login';
import swal from 'sweetalert2';
//import { actions as printActions } from "../../common/components/Utils/Print";
import { actions as printActions } from "../../../common/components/Utils/Print";
const url = 'caja';

const LOADER_CAJA = 'LOADER_CAJA';
const SET_DATA_CAJA = 'SET_DATA_CAJA';
const SET_PAGE_CAJA = 'SET_PAGE_CAJA';
const SET_UPDATE_DATA_CAJA ='SET_UPDATE_DATA_CAJA';
const SET_BUSCADOR_CAJA = 'SET_BUSCADOR_CAJA';
const SET_BUSCARDORMOV_CAJA = 'SET_BUSCARDORMOV_CAJA';
const SET_FILTRO_CAJA = 'SET_FILTRO_CAJA';
const SET_CONFIGURACION_CAJA = 'SET_CONFIGURACION_CAJA';
const SET_MODALAUTORIZACION_CAJA = 'SET_MODALAUTORIZACION_CAJA';
const SET_CARGANDOMODAL_CAJA = 'SET_CARGANDOMODAL_CAJA';
const SET_APERTURA_CAJA = 'SET_APERTURA_CAJA';
const SET_DATAMOVIMIENTOS_CAJA = 'SET_DATAMOVIMIENTOS_CAJA';
const SET_IDAPERTURA_CAJA = 'SET_IDAPERTURA_CAJA';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CAJA, cargando: true});
    const store = getStore();
    const search = store.caja.buscador;
    const filtro = store.caja.filtro_caja;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_CAJA, data});
            dispatch({type: SET_PAGE_CAJA, page});
        }
        dispatch({type: LOADER_CAJA, cargando: false});
    })
};
export const setIdApertura = (apertura) => (dispatch, getStore) => {
    dispatch({
        type: SET_IDAPERTURA_CAJA,
        id_apertura: apertura
    });
}
export const listarMovimientos = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CAJA, cargando: true});
    const store = getStore();
    const id_apertura = store.caja.id_apertura;
    const search = store.caja.buscador_movimientos;
    let params = { search, page};

    if(id_apertura){
        params['id_apertura'] = id_apertura
    }

    api.get(`venta/getVentasDia`, params).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATAMOVIMIENTOS_CAJA, data_movimientos: data});
            dispatch({type: SET_PAGE_CAJA, page});
        }
        dispatch({type: LOADER_CAJA, cargando: false});
    })
};
const getConfiguracion = () => (dispatch, getState) =>{
    // dispatch({type: LOADER_CAJA, cargando: true});
    api.get(`configuracion/getConfiguracion`).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(data){
            dispatch({type: SET_CONFIGURACION_CAJA, configuracion: data});
        }
    })
}
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_CAJA, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(data){
            dispatch(getConfiguracion());
            dispatch({type: SET_UPDATE_DATA_CAJA, updateData: data});

        }
    })
}
const detailApertura = id => (dispatch, getState) =>{
    dispatch({type: LOADER_CAJA, cargando: true});
    api.get(`apertura_cierre_caja/${id}`).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(data){
            dispatch(getConfiguracion());
            dispatch({type: SET_APERTURA_CAJA, apertura: data});

        }
    })
}
const verficarMonedas = (cierre=true) => (dispatch, getStore) => {
    const store = getStore();
    const valor_inicial = store.caja.configuracion.monto_inicial_caja;
    const formData = getStore().form.monedas.values;
    const updateData = store.caja.updateData;
    const apertura = store.caja.apertura;


    let data = formData ? formData : {};
    let total = 0;

    total = data.b200 ? total + (parseInt(data.b200) * 200) : total;
    total = data.b100 ? total + (parseInt(data.b100) * 100) : total;
    total = data.b50 ? total + (parseInt(data.b50) * 50) : total;
    total = data.b20 ? total + (parseInt(data.b20) * 20) : total;
    total = data.b10 ? total + (parseInt(data.b10) * 10) : total;
    total = data.b5 ? total + (parseInt(data.b5) * 5) : total;
    total = data.b1 ? total + (parseInt(data.b1)) : total;
    total = data.m2000 ? total + (parseInt(data.m2000) * 20) : total;
    total = data.m1000 ? total + (parseInt(data.m1000) * 10) : total;
    total = data.m500 ? total + (parseInt(data.m500) * 5) : total;
    total = data.m200 ? total + (parseInt(data.m200) * 2) : total;
    total = data.m100 ? total + (parseInt(data.m100)) : total;
    total = data.m50 ? total + (parseInt(data.m50) * 0.50) : total;
    total = data.m25 ? total + (parseInt(data.m25) * 0.25) : total;
    total = data.m20 ? total + (parseInt(data.m20) * 0.20) : total;
    total = data.m10 ? total + (parseInt(data.m10) * 0.10) : total;
    total = data.m5 ? total + (parseInt(data.m5) * 0.05) : total;
    total = data.m1 ? total + (parseInt(data.m1)) : total;
    total = data.m01 ? total + (parseInt(data.m01)* 0.01) : total;
    total = parseFloat(total).toFixed(2);


    let efectivo_apertura = Number(apertura.total_apertura) + Number(apertura.total_efectivo);
    efectivo_apertura = parseFloat(parseFloat(efectivo_apertura).toFixed(2));
    //Verifica que el total cuadra con el valor inicial de la caja
    if(!updateData.encargado_actual &&  parseFloat(total).toFixed(2) === parseFloat(valor_inicial).toFixed(2)){
        dispatch(openModal());
    }
    else if(updateData.encargado_actual && parseFloat(total).toFixed(2) === parseFloat(efectivo_apertura).toFixed(2)){
        dispatch(openModal());
    }
    else{
        let valor_mensaje = !updateData.encargado_actual && total === valor_inicial ? parseFloat(valor_inicial) : efectivo_apertura;

        //add ricky
        if(Number.isNaN(valor_mensaje)){
            valor_mensaje = Number(valor_inicial).toFixed(2);
        }


        let mensaje = `El conteo de billetes y monedas debe de sumar Q${valor_mensaje}`;
        swal('Error', mensaje, 'error');
    }
}
const aperturarCaja = () => (dispatch, getStore) => {
    const store = getStore();
    const formData = getStore().form.monedas.values;
    const updateData = store.caja.updateData
    dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: true})

    api.post(`apertura_cierre_caja`, {
        caja: updateData.id
    }).then((data) => {
        dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: false})
        Swal('Éxito', 'Se ha aperturado la caja.', 'success')
        .then(() => {
            dispatch(closeModal());
            dispatch(getMe());
            dispatch(push('/caja/punto_venta'))
        })
    }).catch((error) => {
        dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: false})
        dispatch(closeModal());
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch(closeModal());
        dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: false})
    });


};

const cierreCaja = () => (dispatch, getStore) => {
    let formData = _.cloneDeep(getStore().form.monedas.values);
    const autorizacion = _.cloneDeep(getStore().form.autorizacion.values);


    let data = formData ? formData : {};
    let total = 0;

    total = data.b200 ? total + (parseInt(data.b200) * 200) : total;
    total = data.b100 ? total + (parseInt(data.b100) * 100) : total;
    total = data.b50 ? total + (parseInt(data.b50) * 50) : total;
    total = data.b20 ? total + (parseInt(data.b20) * 20) : total;
    total = data.b10 ? total + (parseInt(data.b10) * 10) : total;
    total = data.b5 ? total + (parseInt(data.b5) * 5) : total;
    total = data.b1 ? total + (parseInt(data.b1)) : total;
    total = data.m2000 ? total + (parseInt(data.m2000) * 20) : total;
    total = data.m1000 ? total + (parseInt(data.m1000) * 10) : total;
    total = data.m500 ? total + (parseInt(data.m500) * 5) : total;
    total = data.m200 ? total + (parseInt(data.m200) * 2) : total;
    total = data.m100 ? total + (parseInt(data.m100)) : total;
    total = data.m50 ? total + (parseInt(data.m50) * 0.50) : total;
    total = data.m25 ? total + (parseInt(data.m25) * 0.25) : total;
    total = data.m20 ? total + (parseInt(data.m20) * 0.20) : total;
    total = data.m10 ? total + (parseInt(data.m10) * 0.10) : total;
    total = data.m5 ? total + (parseInt(data.m5) * 0.05) : total;
    total = data.m1 ? total + (parseInt(data.m1) ) : total;
    total = data.m01 ? total + (parseInt(data.m01)* 0.01) : total;
    total = parseFloat(total).toFixed(2);
    formData.total_cierre = total;
    formData = {
        ...formData,
        ...autorizacion
    }
    dispatch({type: SET_CARGANDOMODAL_CAJA, cargancargando_modaldo: true})
    api.post(`apertura_cierre_caja/cierre`, formData).then((data) => {
        dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: false})
        Swal('Éxito', 'Se ha cerrado la caja.', 'success')
        .then(() => {

            /*
            setTimeout(() => {
                printActions.print('detalle_cierre');
              }, 200);
            */


            //setTimeout(() => {
                dispatch({
                    type: SET_APERTURA_CAJA,
                    apertura: {}
                })

                dispatch(closeModal());
                dispatch(push('/cajas'))
              //}, 300);
        })
    }).catch((error) => {
        dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: false})
        dispatch(closeModal());
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch(closeModal());
        dispatch({type: SET_CARGANDOMODAL_CAJA, cargando_modal: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_CAJA, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_CAJA, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_CAJA, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_CAJA, buscador: search});
    dispatch(listar(1));
};
const setBuscarMov = (search) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_BUSCARDORMOV_CAJA, buscador_movimientos: search});
    dispatch(listarMovimientos(1))
}
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_CAJA, filtro_caja: filtro});
    dispatch(listar(1));
};

const closeModal = () => (dispatch, getStore) => {
    dispatch({
        type: SET_MODALAUTORIZACION_CAJA,
        modal_autorizacion: false
    })
}
const openModal = () => (dispatch, getStore) => {
    dispatch({
        type: SET_MODALAUTORIZACION_CAJA,
        modal_autorizacion: true
    });
}
const regresar = () => (dispatch, getStore) => {
    dispatch(go(-1));
}

export const actions = {
    listar,
    detail,
    aperturarCaja,
    cierreCaja,
    update,
    destroy,
    search,
    filtro,
    regresar,
    setIdApertura,
    closeModal,
    openModal,
    verficarMonedas,
    detailApertura,
    setBuscarMov,
    listarMovimientos
};
export const reducer = {
    [LOADER_CAJA]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_CAJA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_CAJA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_CAJA]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_CAJA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_CAJA]: (state, { filtro_caja }) => {
        return {...state, filtro_caja }
    },
    [SET_CONFIGURACION_CAJA]: (state, { configuracion }) => {
        return {...state, configuracion }
    },
    [SET_MODALAUTORIZACION_CAJA]: (state, { modal_autorizacion }) => {
        return {...state, modal_autorizacion }
    },
    [SET_CARGANDOMODAL_CAJA]: (state, { cargando_modal }) => {
        return {...state, cargando_modal }
    },
    [SET_APERTURA_CAJA]: (state, { apertura }) => {
        return {...state, apertura }
    },
    [SET_DATAMOVIMIENTOS_CAJA]: (state, { data_movimientos }) => {
        return {...state, data_movimientos }
    },
    [SET_BUSCARDORMOV_CAJA]: (state, { buscador_movimientos }) => {
        return {...state, buscador_movimientos }
    },
    [SET_IDAPERTURA_CAJA]: (state, { id_apertura }) => {
        return {...state, id_apertura }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    data_movimientos: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    configuracion: {monto_inicial_caja: 0},
    buscador: '',
    buscador_movimientos: '',
    filtro_caja: null,
    updateData: {},
    modal_autorizacion: false,
    cargando_modal: false,
    apertura: {},
    id_apertura: null
};
export default handleActions(reducer, initialState)

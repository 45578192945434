import React, { Component, Fragment } from 'react'
import { iconsh, EXTRAS } from "icons";
import Table from 'Utils/Grid';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import { formatAllDate, formatNombre, formatoMoneda } from '../../../Utils/renderField/renderReadField';
import { opcionesBanco } from '../../../../utility/constants';
import Modal from 'react-responsive-modal';
import AnulacionForm from '../../Punto_venta/Detalle_venta/formulario/formularioanular';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

function formatDescripcion(cell, row) {
    return <div>
        <span className="font-weight-bold">Factura/recibo: </span><span className="font-weight-normal">{row.factura? row.factura: '-- --'}</span>
        <p className="m-0 p-0">
            {cell}
        </p>
    </div>
}
function formatIngreso(cell, row) {
    if (cell) {
        return <span>{cell.nombre}</span>
    }
    if (row.cuenta_ingreso) {
        return <span>{row.cuenta_ingreso.nombre}</span>
    }
    return <span>-- --</span>
}
function cellTachado(cell, row) {
    if(!row.activo){
        return {textDecoration: "line-through", whiteSpace: 'normal'}
    }
    return {whiteSpace: 'normal'}
}

export default class GastosGrid extends Component {
    state = {
        id_gasto: 0,
        popoverOpen: false
    }
    componentWillMount() {
        this.props.listar(this.props.page);
    }
    togglePop = () => {
        this.setState({
            popoverOpen: false
        })
    }
    botonModal = (id, row) => {
        if(row.activo){
            return (
                <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({id_gasto: id});
                    this.props.openModal();
                }}
                    className="action-img"
                    title="Anular gastos" src={EXTRAS.eliminar2} alt="Anular gasto" />

            );
        }

    }
    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;
        const {
            cargando,
            page,
            data
        } = this.props;
        return (
            <div className="container m-0 contenido-principal row d-flex justify-content-center">
                <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                    <div style={{ Width: '100%' }}>
                        <div className="modal-header">
                            <div className="panel-body col-md-12">
                                <div className="borde-abajo pb-2">
                                    <img width={35} className="" src={iconsh.crud_gastos} alt="Gasto" />
                                    <span className="ml-2 text-uppercase text-titulo"><strong>Anular gasto</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <AnulacionForm
                                initialValues={{id: this.state.id_gasto}}
                                closeModal={this.props.closeModal}
                                onSubmit={this.props.destroy}/>
                        </div>
                    </div>
                </Modal>
                <HeaderSimple
                    texto={"Agregar Gasto"}
                    ruta={"/gasto"}
                />
                <ToolbarSimple
                    titulo="Gastos Hospital"
                    icono={iconsh.crud_gastos}
                    buscar={this.props.search}
                    buscador={this.props.buscador}
                    // tituloBuscador={"Buscar por: Categoria de Gastos"}
                    placeholder={"Buscar por: Categoría de Gastos"}
                    // margen = "mt-40"
                />
                <div className="mt-3 col-md-12">
                    <Table
                        onPageChange={listar}
                        data={data}
                        loading={cargando}
                        page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            isKey={true}
                            dataAlign="center"
                            dataFormat={activeFormatter({ adicional: this.botonModal, popover:true })}>Acciones</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={BreakLine}
                            dataFormat={formatFechaGasto}
                            dataField="fecha"
                            dataSort
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={BreakLine}
                            dataFormat={formatNombre}
                            dataField="categoria_gasto" dataSort>Categoría de gasto</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={BreakLine}
                            dataFormat={formatNombre}
                            dataField="cuenta" dataSort>Salió</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={BreakLine}
                            dataFormat={formatIngreso}
                            dataField="proveedor" dataSort>Ingreso</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={BreakLine}
                            dataFormat={formatoMoneda}
                            dataAlign={'right'}
                            dataField="monto" dataSort>Monto</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={cellTachado}
                            thStyle={BreakLine}
                            dataFormat={formatDescripcion}
                            dataField="descripcion" dataSort>Descripción</TableHeaderColumn>

                    </Table>
                </div>
            </div>
        )
    }
}

export function formatFechaGasto(value, row) {
    try {
        if (value) {
            let fecha = new Date(value);
            // para verificar si los datos registrados despues de octube 2021
            // ya tienen el dato de fecha de pago que se utiliza como fehca de facturacion
            if(row && row.fecha_pagado){
                fecha = new Date(row.fecha_pagado);
            }

            return (
                <span>{fecha.toLocaleDateString()} {row && row.fecha_pagado? "":fecha.toLocaleTimeString()}</span>
            );
        }
        return (<span>{value}</span>);
    } catch (error) {
        return (<span></span>)
    }
}

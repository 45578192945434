import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderCurrency, RenderDateTime } from "../renderField/renderReadField";
export default class Recibo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { movimiento } = this.props;
        let descuento = 0;
        if(movimiento.monto){
            descuento = movimiento.subtotal - movimiento.monto;
        }
        return (
            <div className={`print-shadow vista-recibo print-only`} width={{width: "85mm"}}>
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    {/* <img  style={{maxWidth: "100%"}} src={require("../../../../../assets/img/blanco_y_negro.png")} alt="Ver"/> */}
                </div>
                <div className="mt mb d-flex flex-row justify-content-center fnt-14">
                    <b>TICKET DE VENTA</b>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                  <b className="mr-2">No Transacción:</b><span>{movimiento.no_orden}</span>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                  <b className="mr-2">Nit:</b><span>{movimiento.proveedor ? movimiento.proveedor.nit : ''}</span>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                        <b className="mr-2">Cliente: </b>
                        {(movimiento.nombre_cliente && movimiento.nombre_cliente !== "") &&(
                            <span>{movimiento.nombre_cliente}</span>
                        )}
                        {(!movimiento.nombre_cliente && movimiento.proveedor) &&(
                            <span>{movimiento.proveedor.label}</span>
                        )}
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-row">
                        <b>Fecha y hora:</b> &nbsp;&nbsp;&nbsp;
                        <RenderDateTime time value={movimiento.creado}/>
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-row">
                        <b>Operador:</b>&nbsp;&nbsp;&nbsp;
                        <span className="text-capitalize">
                            { movimiento.usuario ? movimiento.usuario.first_name : ''}
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-row fnt-12 mb05">
                  <b className="mr-2">Transacción:</b><span>{movimiento.tipo_orden}</span>
                </div>



                <div className="d-flex flex-row justify-content-between fnt-12" style={{borderTop: "dotted 1px", borderBottom:"dotted 1px" , paddingTop:"10px"}}>
                    <b>DESCRIPCIÓN</b>
                </div>
                <div className="col-md-12 mb fnt-11">
                    {
                        movimiento.productos && (
                            movimiento.productos.map( x => (
                                <div className="pt-1 d-flex flex-row justify-content-between fnt-11" >
                                    <span>{x.cantidad}&nbsp;&nbsp;</span>
                                    <span>{x.nombre_producto} </span>
                                    <span><RenderCurrency value={ x.subtotal } className={"ml-auto"}/></span>
                                </div>
                            ))
                        )
                    }
                </div>

                <div className="pt-1 d-flex flex-row justify-content-between fnt-12" style={{borderTop: "dotted 1px"}}>
                    <b>Subtotal</b>
                    <span><RenderCurrency value={ movimiento.subtotal } className={"ml-auto"}/></span>
                </div>
                <div className=" d-flex flex-row justify-content-between fnt-12">
                    <b>Descuento</b>
                    <span>-<RenderCurrency value={ descuento } className={"ml-auto fnt-12"}/></span>
                </div>
                <div className="d-flex flex-row justify-content-between fnt-14">
                    <b>Total</b>
                    <b><RenderCurrency value={ movimiento.monto } className={"ml-auto"}/></b>
                </div>
                {
                    !movimiento.activo && (
                        <div className="mt mb d-flex flex-row justify-content-center fnt-12">
                            <span>Venta anulada</span>
                        </div>
                    )
                }

                <div className="mt mb d-flex flex-row justify-content-center fnt-14">
                    <span>Este documento no es una factura</span>
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import './accordion.css';
import './menu.css';
import { icons } from "icons";
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router';


class Menu extends Component {
    static propTypes = {
        logOut: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
    }
    componentWillMount(){

    }
    toggleMenu = (e) => {
        try {
            this.props.toggleMenu(e);
        } catch (error) {

        }

    }

    render() {
        const { logOut, url, isOpen, me} = this.props;
        const {
            bodega_asignada,
            ver,
            ver_ordenes,
            ver_solicitudes,
            ver_devoluciones,
            ver_area,
            ver_caja,
            ver_enfermera,
            ver_gasto,
            ver_reportes,
            ver_admin,
            notificacion_ingresos,
            menu_ver_admin,
            menu_pacientes,
            menu_areas,
            menu_rganancias,
            menu_rvencimientos,
            menu_mi_area,
            menu_cuentas_pacientes,
            menu_rcaja,
            menu_rcuentas,
            menu_rcomprasventas,
            menu_rmovbodega,
            menu_rporpagar,
            menu_rbitacora,
            menu_configuracion,
            menu_cuentas,
            menu_categoria,
            menu_clientes,
            menu_proveedores,
            menu_usuarios,
            menu_productos,
        } = this.props;
        const username = me ? me.nombreCompleto: 'Perfil';
        const proyecto = me ? me.proyecto : false;
        return (
            <div style={{ position: "relative" }}>
                <div className="v-menu v-menu-front">

                    <Accordion className={`text-center mt-4 menu-item `}>
                        {
                            me && me.is_superuser && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#/dashboard" className={`blue-hover `} onClick={this.toggleMenu} >
                                            <div className={`col-md-12 text-center d-flex align-items-center py-1 ${url.includes("/dashboard") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.reportes1} alt="" />
                                                <img className="img-activo" src={icons.reportes1} alt="" />
                                                <div className="text-center font-weight-bold text-uppercase no-border-sidebar">
                                                    DASHBOARD
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_ordenes && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#/ordenes" className={`blue-hover `} onClick={this.toggleMenu} >
                                            <div className={`col-md-12 text-center d-flex align-items-center py-1 ${url.includes("/orden") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.ordenes1} alt="" />
                                                <img className="img-activo" src={icons.ordenes1} alt="" />
                                                {
                                                    this.props.ordenes > 0 && (
                                                        <span className={" num-alert "}>{this.props.ordenes}</span>
                                                    )
                                                }
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    ORDENES
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_solicitudes && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#/solicitudes" className={`blue-hover `} onClick={this.toggleMenu} >
                                            <div className={`col-md-12 text-center d-flex align-items-center py-1 ${url.includes("/solicitudes") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.solicitudes1} alt="" />
                                                <img className="img-activo" src={icons.solicitudes1} alt="" />
                                                {
                                                    this.props.solicitudes > 0 && (
                                                        <span className={" num-alert "}>{this.props.solicitudes}</span>
                                                    )
                                                }
                                                <div className="text-center font-weight-bold text-uppercase no-border-sidebar">
                                                    SOLICITUDES
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_devoluciones && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#/devoluciones" className={`blue-hover `} onClick={this.toggleMenu} >
                                            <div className={`col-md-12 text-center d-flex align-items-center py-1 ${url.includes("/devoluciones") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.devoluciones1} alt="" />
                                                <img className="img-activo" src={icons.devoluciones1} alt="" />
                                                <div className="ml-2 text-center font-weight-bold text-uppercase no-border-sidebar">
                                                    DEVOLUCIONES
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_area && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#" className={`blue-hover `}
                                            onClick={(e) => { e.preventDefault() }}>
                                            <div className={`col-md-12 d-flex align-items-center py-1 ${url.includes("/area") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.areasbodegas1} alt="" />
                                                <img className="img-activo" src={icons.areasbodegas1} alt="" />
                                                {
                                                    this.props.notificacion_ingresos > 0 && (
                                                        <span className={" num-alert "}>{this.props.notificacion_ingresos}</span>
                                                    )
                                                }
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    ÁREA
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {
                                            bodega_asignada > 0 && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/area_estado") ? "activo-subitem" : ""}`} to={`/area_estado/${bodega_asignada}`}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Mi área</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_areas && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/areas_todo") ? "activo-subitem" : ""}`} to={"/areas_todo"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Áreas</div>
                                                </Link>
                                            )
                                        }

                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_caja && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#/cajas" className={`blue-hover `} onClick={this.toggleMenu} >
                                            <div className={`col-md-12 d-flex align-items-center py-1 ${url.includes("/caja") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.caja1} alt="" />
                                                <img className="img-activo" src={icons.caja1} alt="" />
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    Caja
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_enfermera && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#" className={`blue-hover `}
                                            onClick={(e) => { e.preventDefault() }}>
                                            <div className={`col-md-12 d-flex align-items-center py-1 ${url.includes("/enfermeria") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.enfermeria1} alt="" />
                                                <img className="img-activo" src={icons.enfermeria1} alt="" />
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    Enfermería
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {
                                            menu_pacientes && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/enfermeria_paciente") ? "activo-subitem" : ""}`} to={`/enfermeria_pacientes`}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Pacientes</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_cuentas_pacientes && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/enfermeria_cuenta") ? "activo-subitem" : ""}`} to={"/enfermeria_cuentas"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Cuentas</div>
                                                </Link>
                                            )
                                        }
                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_gasto && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#/gastos" className={`blue-hover `}>
                                            <div className={`col-md-12 d-flex align-items-center py-1 ${url.includes("/gasto") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.gastos1} alt="" />
                                                <img className="img-activo" src={icons.gastos1} alt="" />
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    Gastos
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                </AccordionItem>
                            )
                        }
                        {
                            ver_reportes && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#" className={`blue-hover `}
                                            onClick={(e) => { e.preventDefault() }}>
                                            <div className={`col-md-12 d-flex align-items-center py-1 ${url.includes("/reporte") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.reportes1} alt="" />
                                                <img className="img-activo" src={icons.reportes1} alt="" />
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    Reportes
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {
                                            menu_rcuentas && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_cuenta") ? "activo-subitem" : ""}`} to={"/reporte_cuentas"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Cuentas</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rcomprasventas && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_compra_ventas") ? "activo-subitem" : ""}`} to={"/reporte_compra_ventas"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Com. y Vts.</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rganancias && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_ganacia") ? "activo-subitem" : ""}`} to={"/reporte_ganacia"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Ganancias</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rcaja && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_cajas") ? "activo-subitem" : ""}`} to={"/reporte_cajas"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Caja</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rmovbodega && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_inventario") ? "activo-subitem" : ""}`} to={"/reporte_inventario"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Mov bodega</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rvencimientos && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_vencimiento") ? "activo-subitem" : ""}`} to={"/reporte_vencimiento"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Vencimientos</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rporpagar && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_porpagar") ? "activo-subitem" : ""}`} to={"/reporte_porpagar"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase" style={{whiteSpace:"nowrap"}}>Ctas por pagar</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_rbitacora && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/reporte_bitacora") ? "activo-subitem" : ""}`} to={"/reporte_bitacoras"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Bitacora</div>
                                                </Link>
                                            )
                                        }




                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                        }
                        {
                            menu_ver_admin && (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <a href="#" className={`blue-hover `}
                                            onClick={(e) => { e.preventDefault() }}>
                                            <div className={`col-md-12 d-flex align-items-center py-1 ${url.includes("/admin") ? "activo no-border-sidebar" : ""}`}>
                                                <img className="img-inactivo" src={icons.admin1} alt="" />
                                                <img className="img-activo" src={icons.admin1} alt="" />
                                                <div className="ml-2 font-weight-bold text-uppercase no-border-sidebar">
                                                    Admin
                                                </div>
                                            </div>
                                        </a>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {
                                            menu_productos && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_producto") ? "activo-subitem" : ""}`} to={"/admin_productos"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Productos</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_usuarios && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_usuario") ? "activo-subitem" : ""}`} to={"/admin_usuarios"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Usuarios</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_proveedores && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_proveedor") ? "activo-subitem" : ""}`} to={"/admin_proveedores"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Proveedores</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_clientes && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_cliente") ? "activo-subitem" : ""}`} to={"/admin_clientes"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Clientes</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_categoria && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_categoria") ? "activo-subitem" : ""}`} to={"/admin_categorias"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Categoría</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_cuentas && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_cuenta_area") ? "activo-subitem" : ""}`} to={"/admin_cuenta_areas"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Cuentas</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            menu_configuracion && (
                                                <Link onClick={this.toggleMenu}  className={`sidebar-subitem menu-item ${url.includes("/admin_configuracion") ? "activo-subitem" : ""}`} to={"/admin_configuracion"}>
                                                    <div className="col-md-12 text-center font-weight-bold text-uppercase">Configuración</div>
                                                </Link>
                                            )
                                        }


                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                        }
                    </Accordion>

                    <a className="sidebar-item menu-item display-grid " href="#" onClick={logOut}>
                        {/* <img className="img-inactivo" src={icons.usuarios1} alt="" /> */}
                        <div className="pl-3">Salir</div>
                    </a>
                </div>
            </div>
        );
    }
}

export default Menu;


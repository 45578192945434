import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Bodega/bodega';
import AreaGrid from './AreaGrid';

const mstp = state => {
    let me = state.login.me
    return {...state.bodega, me}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AreaGrid)

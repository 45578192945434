import React, { Component } from 'react';
import Card from 'Utils/Cards/cardGrande';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ProductoForm';
import { ProductoUpdateForm } from './ProductoForm';
import { iconsh } from "icons";

export default class ProductoCrear extends Component {
state = {
    editar: false,
    mensaje: 'Agregar Producto',
    password: false,
}
componentWillMount(){
    if(this.props.match.params.id){
        this.props.detail(this.props.match.params.id);
        this.setState({
                editar: true,
                mensaje: 'Editar Producto'
            });
    }else{
        this.props.resetFracciones();
    }
}
verPassword = () =>{
    this.setState({
        password: !this.state.password
    })
}
render() {
    const { create, update } = this.props;
    const { updateData, cargando } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12">
            <Card
                icono={iconsh.productos}
                titulo={this.state.mensaje}>
                <LoadMask loading={cargando} blur_1>
                    {
                        this.state.editar ?
                            <ProductoUpdateForm
                                onSubmit={update}
                                crear={update}
                                updateData={updateData}
                                {...this.props}
                                getProveedores={this.props.getProveedores}
                                proveedores={this.props.proveedores}
                                editando={true} />
                        :
                            <Form
                                {...this.props}
                                onSubmit={create}
                                editando={false}
                                crear={create}
                                proveedores={this.props.proveedores}
                                getProveedores={this.props.getProveedores}/>
                    }
                </LoadMask>
            </Card>
        </div>
    </div>
    )
    }
}

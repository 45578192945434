import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Usuario/usuario';
import UsuarioCrear from './UsuarioCrear';

const mstp = state => {
    return {...state.usuario}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(UsuarioCrear)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Producto/producto';
import ProductoGrid from './ProductoGrid';

const mstp = state => {
    return {...state.producto}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ProductoGrid)

import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import { marcarVisto } from './compras'
const url = 'compras';

const LOADER_ACEPTADO = 'LOADER_ACEPTADO';
const SET_DATA_ACEPTADO = 'SET_DATA_ACEPTADO';
const SET_PAGE_ACEPTADO = 'SET_PAGE_ACEPTADO';
const SET_ACTUALIZADO_DATA ='SET_ACTUALIZADO_DATA';
const SET_BUSCADOR_ACEPTADO = 'SET_BUSCADOR_ACEPTADO';
const SET_FILTRO_ACEPTADO = 'SET_FILTRO_ACEPTADO';
const SET_OC_ACPETADO = 'SET_OC_ACPETADO';
const SET_TOGGLEMODAL_ACEPTADO = 'SET_TOGGLEMODAL_ACEPTADO';
const SET_MODALINGRESO_ACEPTADO = 'SET_MODALINGRESO_ACEPTADO';
const SET_PAGOS_ACEPTADO = 'SET_PAGOS_ACEPTADO';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    const store = getStore();
    const search = store.aceptado.buscador;
    const filtro = store.aceptado.filtro_aceptado;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ACEPTADO, data});
            dispatch({type: SET_PAGE_ACEPTADO, page});
        }
        dispatch({type: LOADER_ACEPTADO, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/ordenes'))
        }
    }).then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            dispatch(marcarVisto(data.id));
            dispatch({type: SET_ACTUALIZADO_DATA, dataActualizada: data});
            dispatch(initializeForm('pagado', {
                pago_completo: data.pago_completo
            }))
            dispatch(initializeForm('ordenpago',{
                factura: data.factura,
                productos: data.productos,
                descripcion_actualizacion: data.descripcion_actualizacion
            }))
        }
    })
}
const detailOc = id => (dispatch, getState) =>{
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/ordenes'))
        }
    }).then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            dispatch({type: SET_OC_ACPETADO, oc: data});
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.bodega.values;
    dispatch({type: LOADER_ACEPTADO, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false})
        Swal('Éxito', 'Se ha creado la bodega.', 'success')
        .then(() => {
            dispatch(push('/ordenes'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_BODEGA, cargando: false})
    });
};
const getPagos = () => (dispatch, getState) =>{
    const store = getState();
    const updateData = store.aceptado.dataActualizada;
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.get(`${url}/ordenespago/${updateData.id}`).catch((error) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(error.statusCode  === 404){
            dispatch(go(-1))
        }
    }).then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            dispatch({type: SET_PAGOS_ACEPTADO , pagos: data});

        }
    })
}

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodega.values);
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/ordenes'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenes'));
        }
    })
}
const registrarPago = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.pagos.values);
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.put(`${url}/pagar/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Se ha realizado el pago.',
                'success'
            ).then(() => {
                // dispatch(push('/ordenes'));
                dispatch(getPagos())
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenes'));
        }
    })
}
const pagoCompleto = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.pagado.values);
    const store = getStore();
    const actualizado = store.aceptado.dataActualizada
    let params = {pago:true}
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.put(`${url}/actualizar/${actualizado.id}`, formData, params)
    .then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(detail(actualizado.id))
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenes'));
        }
    })
}
const actualizarOrden = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.ordenpago.values);
    const store = getStore();
    const actualizado = store.aceptado.dataActualizada
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.put(`${url}/actualizar/${actualizado.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(detail(actualizado.id))
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenes'));
        }
    })
}
const agregarPago = () => (dispatch, getStore) => {
    let formData = _.cloneDeep(getStore().form.pagos.values);
    const store = getStore();
    const actualizado = store.aceptado.dataActualizada;
    try {
        formData.fecha = formData.fecha.format("YYYY-MM-D")
    } catch (error) {
        formData.fecha = formData.fecha
    }
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.put(`${url}/realizarPago/${actualizado.id}`, formData)
    .then((data) => {
        dispatch(detail(actualizado.id))
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(getPagos())
                dispatch(closeModal())
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenes'));
        }
    })
}
const maracarPagoComoPagado = (data) => (dispatch, getStore) => {
    let formData = data;
    try {
        formData.fecha = formData.fecha.format("YYYY-MM-D")
    } catch (error) {
        formData.fecha = formData.fecha
    }
    formData.tipo_pago = null // movimiento de efectivo que sera de 0 quetzales
    const store = getStore();
    const actualizado = store.aceptado.dataActualizada;
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.put(`${url}/realizarPago/${actualizado.id}`, formData)
    .then((data) => {
        dispatch(detail(actualizado.id))
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(getPagos())
                dispatch(closeModal())
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/ordenes'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_ACEPTADO, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_ACEPTADO, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_ACEPTADO, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_ACEPTADO, filtro_aceptado: filtro});
    dispatch(listar(1));
};

// acciones para controlar la modal
const closeModal = () => (dispatch, getStore) => {
    dispatch({type: SET_TOGGLEMODAL_ACEPTADO, toggleModal: false});
}
const openModal = () => (dispatch, getStore) => {
    dispatch({type: SET_TOGGLEMODAL_ACEPTADO, toggleModal: true});
}
const closeModalIngreso = () => (dispatch, getStore) => {
    dispatch({type: SET_MODALINGRESO_ACEPTADO, modalIngreso: false});
}
const openModalIngreso = () => (dispatch, getStore) => {
    dispatch({type: SET_MODALINGRESO_ACEPTADO, modalIngreso: true});
}
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    detailOc,
    closeModal,
    openModal,
    agregarPago,
    closeModalIngreso,
    openModalIngreso,
    pagoCompleto,
    actualizarOrden,
    getPagos,
    registrarPago,
    maracarPagoComoPagado,
};
export const reducer = {
    [LOADER_ACEPTADO]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_ACEPTADO]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_ACEPTADO]: (state, { page }) => {
        return {...state, page }
    },
    [SET_ACTUALIZADO_DATA]: (state, { dataActualizada }) => {
        return {...state, dataActualizada }
    },
    [SET_BUSCADOR_ACEPTADO]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_ACEPTADO]: (state, { filtro_aceptado }) => {
        return {...state, filtro_aceptado }
    },
    [SET_OC_ACPETADO]: (state, { oc }) => {
        return {...state, oc }
    },
    [SET_TOGGLEMODAL_ACEPTADO]: (state, { toggleModal }) => {
        return {...state,  toggleModal}
    },
    [SET_MODALINGRESO_ACEPTADO]: (state, { modalIngreso }) => {
        return {...state, modalIngreso }
    },
    [SET_PAGOS_ACEPTADO]: (state, { pagos }) => {
        return {...state, pagos }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_aceptado: null,
    dataActualizada: {
        usuario: {first_name:''},
        id: 0,
        no_orden: 10,
        fecha: null,
        movimientos:[],
        proveedor: {label: ''}
    },
    oc: {
        usuario: {first_name:''},
        id: 0,
        no_orden: 10,
        fecha: null,
        proveedor: {label: ''}
    },
    toggleModal: false,
    modalIngreso: false,
    pagos:[]
};
export default handleActions(reducer, initialState)

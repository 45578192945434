import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Caja/caja';
import CajaGrid from './CajaGrid';

const mstp = state => {
    return {...state.caja, ...state.login}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CajaGrid)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Bodega/estadobodega';
import {
    actions as actionsMov
} from '../../../../../redux/modules/Bodega/movimientosbodega';
import IngresarDevolucion  from './IngresarDevolucion';

const mstp = state => {
    let nombreBodega = '';
    if(state.estadobodega.updateData){
        let bodega = state.estadobodega.updateData
        nombreBodega = bodega.nombre ? bodega.nombre : '';
    }
    return {
        ...state.estadobodega,
        ...state.movimientobodega,
        nombreBodega
    }
};

const mdtp = {
    ...actions,
    ...actionsMov
};

export default connect(mstp, mdtp)(IngresarDevolucion)

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderFieldCheck } from 'Utils/renderField/renderField';

let FormIngresoCompletoForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="row col-md-12 m-0 p-0">
                            <div className="col-auto m-0 ml-3 form-group label">
                                <Field name="ingreso_completo" component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                    placeholder="Correo"/>
                            </div>
                            <div className="p-0 ml-3  ml-sm-0  col-lg-9 m-0 col-md-9 col-sm-12  pt-1">
                                <span className="text-primary font-weight-bold text-uppercase">Ingreso Completo</span>
                            </div>
                        </div>
                        <div className="col-12 p-0">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <button type="button"
                                onClick={e => {
                                    e.preventDefault()
                                    props.atras('op')
                                }}
                                className="btn btn-secondary m-1">Atras</button>
                            <button type="submit"
                                className="btn btn-primary m-1">Siguiente</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
FormIngresoCompletoForm = reduxForm({
    form: 'FormIngresoCompleto',
    validate: data => {
        return validate(data, {
        })
    }
})(FormIngresoCompletoForm);
export default FormIngresoCompletoForm

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchCreateSelect, renderCurrency, renderFieldCheck } from 'Utils/renderField/renderField';
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { formatAllDate } from '../../../Utils/renderField/renderReadField';

let PagosForm = props => {
    const {
        handleSubmit,
        cancelar,
        ver_efectivo,
        ver_tarjeta,
        ver_cheque,
        total_cuenta,
        vuelto,
        nombre_cliente,
        total_pagado,
        descuento,
        cuenta
    } = props;
    let saldo_cuenta = total_cuenta - total_pagado;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row col-md-12 m-0 ">
                        <div className="col-md-12 row m-0 mt-1">
                                <div className="col-md-6 p-0 ">
                                    <div className="col-md-12 row p-0 m-0">
                                        <div className="col-md-6 p-0 text-right">
                                            <span className="text-gris">SUBTOTAL</span>
                                        </div>
                                        <div className="col-md-6 p-0 text-right">
                                            <RenderCurrency value={cuenta.subtotal}  className={'text-azul  font-weight-bold'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 row p-0 m-0">
                                        <div className="col-md-6 p-0 text-right">
                                            <span className="text-gris">DESCUENTO</span>
                                        </div>
                                        <div className="col-md-6 p-0 text-right">
                                            <RenderCurrency value={descuento}  className={'text-azul  font-weight-bold'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 row p-0 m-0">
                                        <div className="col-md-6 p-0 text-right">
                                            <span className="text-primary">TOTAL CUENTA</span>
                                        </div>
                                        <div className="col-md-6 p-0 text-right">
                                            <RenderCurrency value={total_cuenta}  className={'text-primary  font-weight-bold'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  p-0">

                                    <div className="col-md-12 row p-0 m-0">
                                        <div className="col-md-6 p-0 text-right">
                                            <span className="text-gris">Pagado</span>
                                        </div>
                                        <div className="col-md-6 p-0 text-right">
                                            <RenderCurrency value={total_pagado}  className={'text-azul  font-weight-bold'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 row p-0 m-0">
                                        <div className="col-md-6 p-0 text-right">
                                            <span className="text-primary h5">SALDO CUENTA</span>
                                        </div>
                                        <div className="col-md-6 p-0 text-right">
                                            <RenderCurrency value={saldo_cuenta}  className={'text-verde h4 font-weight-bold'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {
                            saldo_cuenta > 0 && (
                                <div className="col-md-12 borde-gris py-4">

                                    <div className=" col-md-12">
                                        <div className="col-md-12">
                                            <h5>CARGAR PAGO</h5>
                                        </div>
                                        <div className="col-md-12 row m-0 d-flex justify-content-between">
                                            <div className="row col-md-4">
                                                <div className="col-8 text-gris">
                                                    TARJETA
                                                </div>
                                                <div className="col-2">
                                                <Field name="tarjeta"
                                                        component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="efectivo"/>
                                                </div>
                                            </div>
                                            <div className="row col-md-4">
                                                <div className="col-8 text-gris">
                                                    CHEQUE
                                                </div>
                                                <div className="col-2">
                                                <Field name="cheque"
                                                        component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="efectivo"/>
                                                </div>
                                            </div>
                                            <div className="row col-md-4">
                                                <div className="col-8 text-gris">
                                                    EFECTIVO
                                                </div>
                                                <div className=" col-2">
                                                <Field name="efectivo"
                                                        component={renderFieldCheck}
                                                        type="checkbox"
                                                        className="form-control"
                                                        placeholder="efectivo"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-3 d-flex justify-content-center">
                                            <div className="col-md-11">
                                                <div className="col-md-12 p-0">
                                                    <div className="col-md-6 p-0">
                                                        <div className="col-md-12 col-sm-12">
                                                            <label htmlFor="pago_tarjeta" className="m-0">TOTAL A PAGAR / ABONO</label>
                                                        </div>
                                                        <div className="col-md-12  form-group">
                                                            <Field
                                                                    name={`total_a_pagar`}
                                                                    type="number"
                                                                    addClass={"text-right"}
                                                                    component={renderCurrency}
                                                                    placeholder=""
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    ver_tarjeta && (
                                                        <div className="col-md-12 p-0">
                                                            <div className="col-md-6 p-0">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label htmlFor="pago_tarjeta" className="m-0">TARJETA</label>
                                                                </div>
                                                                <div className="col-md-12  form-group">
                                                                    <Field
                                                                            name={`pago_tarjeta`}
                                                                            type="number"
                                                                            addClass={"text-right"}
                                                                            component={renderCurrency}
                                                                            placeholder=""
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ver_cheque && (
                                                        <div className="col-md-12 p-0 row m-0">
                                                            <div className="col-md-4 p-0">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label htmlFor="pago_cheque" className="m-0">CHEQUE:</label>
                                                                </div>
                                                                <div className="col-md-12  form-group">
                                                                    <Field
                                                                            name={`pago_cheque`}
                                                                            type="number"
                                                                            addClass={"text-right"}
                                                                            component={renderCurrency}
                                                                            placeholder=""
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 p-0">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label htmlFor="nocheque" className="m-0">NO. CHEQUE:</label>
                                                                </div>
                                                                <div className="col-md-12  form-group">
                                                                    <Field name="no_cheque" component={renderField} type="number" className="form-control"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 p-0">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label htmlFor="banco" className="m-0">Banco:</label>
                                                                </div>
                                                                <div className="col-md-12  form-group">
                                                                    <Field name="banco" component={renderField} type="text" className="form-control"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ver_efectivo && (
                                                        <div className="col-md-12 row m-0 p-0">
                                                            <div className="col-6 p-0">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label htmlFor="pago_efectivo" className="m-0">EFECTIVO</label>
                                                                </div>
                                                                <div className="col-md-12  form-group">
                                                                    <Field
                                                                            name={`pago_efectivo`}
                                                                            type="number"
                                                                            addClass={"text-right'"}
                                                                            component={renderCurrency}
                                                                            placeholder="pago en efectivo"
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 p-0">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <label htmlFor="vuelto" className="m-0">VUELTO:</label>
                                                                </div>
                                                                <div className="col-md-12  form-group">
                                                                    <RenderCurrency value={vuelto || 0}  className={'text-primary font-weight-light'}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    {
                        saldo_cuenta > 0 && (
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Link className="btn btn-secondary m-1" to="/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Pagar</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </form>
    )
};
PagosForm = reduxForm({
    form: 'pagos',
    validate: (data, props) => {
        const { totales_compra } = props;
        const { total_cuenta, total_pagado} = props;
        let saldo_cuenta = total_cuenta - total_pagado;
        const errors = {};
        if(!data.nit){
            errors.nit = 'Campo requerido';
        }
        if(!data.nombre){
            errors.nombre = 'Campo requerido';
        }
        if(!data.total_a_pagar){
            errors.total_a_pagar = 'Campo requerido';
        }else{
            if(Number(data.total_a_pagar || 0) <= 0){
                errors.total_a_pagar = 'Debe de ser un valor mayor a 0'
            }
            if( Number(data.total_a_pagar || 0 ) > Number(saldo_cuenta || 0)){
                errors.total_a_pagar = 'Debe ser menor al saldo por pagar';
            }

        }
        if(!data.efectivo && !data.tarjeta && !data.cheque){
            errors.efectivo = 'Debe de seleccionar una opción'
        }else{
            //VAlidación de pago solo en efectivo
            let valor_efectivo = Number(data.pago_efectivo || 0);
            let valor_cheque = Number(data.pago_cheque || 0);
            let valor_tarjeta = Number(data.pago_tarjeta || 0);
            let total = Number(data.total_a_pagar || 0);

            // if(!data.pago_cheque){
            //     errors.pago_cheque = 'Campo requerido';
            // }
            // if(!data.pago_tarjeta){
            //     errors.pago_tarjeta = 'Campo requerido';
            // }
            if(!data.pago_efectivo && !data.pago_tarjeta && !data.pago_cheque){
                errors.pago_efectivo = 'Campo requerido';
                errors.pago_tarjeta = 'Campo requerido';
                errors.pago_cheque = 'Campo requerido';
            }

            if(!data.banco){
                errors.banco = 'Campo requerido';
            }
            if(!data.no_cheque){
                errors.no_cheque = 'Campo requerido';
            }

            // Si solo es tarjeta entonces se verifica
                // que la cantidad del monto de pago por tarjeta
                // debe de ser igual al monto a pagar
            if(valor_efectivo <= 0 && valor_tarjeta > 0 && valor_cheque <= 0 ){
                if(valor_tarjeta != total){
                    errors.pago_tarjeta = 'El monto de tarjeta debe de coincidir con el total';
                }
            }
            else if(valor_efectivo <= 0 && valor_tarjeta > 0 && valor_cheque > 0){
                if((valor_tarjeta + valor_cheque) != total){
                    errors.pago_tarjeta = 'La suma del monto de cheque y tarjeta debe de ser igual al total.'
                    errors.pago_cheque = 'La suma del monto de cheque y tarjeta debe de ser igual al total.'
                }
            }
            else if(valor_efectivo <= 0 && valor_tarjeta <= 0 && valor_cheque > 0){
                if(valor_cheque != total){
                    errors.pago_cheque = 'El monto de cheque debe de coincidir el total del pago.'
                }
            }
            else if(valor_efectivo > 0 && valor_tarjeta <= 0 && valor_cheque <= 0){
                if(valor_efectivo < total){
                    errors.pago_efectivo = 'El monto de efectivo debe de ser igual o mayor al total';
                }
            }
            else if(valor_efectivo > 0 && (valor_tarjeta > 0 || valor_cheque > 0)){
                if((valor_cheque + valor_tarjeta) >= total){
                    errors.pago_cheque = 'La suma de cheque y tarjeta debe de ser menor al total.';
                }
                else if((valor_cheque + valor_tarjeta + valor_efectivo) < total){
                    errors.pago_cheque = 'La suma debe ser igual o mayor al total'
                    errors.pago_tarjeta = 'La suma debe ser igual o mayor al total'
                }
            }
            // Si solo es tarjeta entonces se verifica
                // que la cantidad del monto de pago por tarjeta
                // debe de ser igual al monto a pagar

            // if (valor_efectivo > 0){
            //     if((valor_cheque + valor_tarjeta) != total){
            //         if(valor_cheque > 0 && valor_tarjeta <= 0){
            //         }
            //     }
            // }else{
            // }
        }
        return errors;
    }
})(PagosForm);

const selector = formValueSelector('pagos');
PagosForm = connect((state, props) => {
    const { total_cuenta, total_pagado} = props;
    let saldo_cuenta = total_cuenta - total_pagado;
    const efectivo = selector(state, "efectivo");
    const tarjeta = selector(state, "tarjeta");
    const cheque = selector(state, "cheque");
    const pago_efectivo = selector(state, "pago_efectivo");
    const pago_tarjeta = selector(state, "pago_tarjeta");
    const pago_cheque = selector(state, "pago_cheque");
    const total_a_pagar = selector(state, "total_a_pagar");
    const nit = selector(state, 'nit');



    let nombre_cliente = null;
    if(nit && nit.cliente){
        nombre_cliente = nit.cliente;
    }
    const { totales_compra } = props;
    let ver_efectivo = false;
    let ver_tarjeta = false;
    let ver_cheque = false;
    let vuelto = 0;

    if(efectivo == true){
        ver_efectivo = true;
        let pagar_en_efectivo = Number(total_a_pagar || 0) - Number( pago_tarjeta ||0) - Number(pago_cheque || 0);
        if(pago_efectivo >= pagar_en_efectivo){
            vuelto = pago_efectivo - pagar_en_efectivo ;

        }
    }
    tarjeta ? ver_tarjeta=true : ver_tarjeta=false;
    cheque ? ver_cheque= true: ver_cheque=false;
    return {
        ver_efectivo,
        ver_tarjeta,
        ver_cheque,
        vuelto,
        nombre_cliente
    };
})(PagosForm);
export default PagosForm


import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Devoluciones/devoluciones';
import DevolucionGrid from './DevolucionGrid';

const mstp = state => {
    return {...state.devoluciones}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DevolucionGrid)

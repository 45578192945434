import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"
import { formatAllDate, RenderDateTime, } from 'Utils/renderField/renderReadField';
import { RenderCurrency } from 'Utils/renderField/renderReadField'

export default class ToolbarEnfermeria extends Component {
  render() {
    return (
            <React.Fragment>
            <div className="col-12 row d-flex justify-content-between">
                    <div className="col-md-6 row">
                            <div className="col-md-6">
                                <span className="text-gris">Paciente: </span>
                                <span className="text-primary text-uppercase">{this.props.paciente}</span>
                            </div>
                            <div className="col-md-6">
                                <span className="text-gris">Ingreso: </span>
                                <RenderDateTime value={this.props.ingreso} className="text-primary text-uppercase"/>
                            </div>
                    </div>
                    <div className="col-lg-4 col-md-4 p-0 text-right search">
                        {(this.props.buscar !== undefined) && (
                        <Search buscar={this.props.buscar} buscador={this.props.buscador}/>
                        )}
                    </div>
            </div>
            <div className="col-12 row d-flex justify-content-between">
                    <div className="col-md-6 row">
                            <div className="col-md-6">
                                <span className="text-gris">Total de productos: </span>
                                <span className="text-primary text-uppercase">{this.props.total_productos}</span>
                            </div>
                            <div className="col-md-6">
                                <span className="text-gris">Total de servicios: </span>
                                <span className="text-primary text-uppercase">{this.props.total_servicios}</span>
                            </div>
                            <div className="col-md-12">
                                <span className="text-uppercase h5 text-azul">Total en quetzales:</span>
                                &nbsp;<RenderCurrency value={this.props.monto} className={'text-primary h5 font-weight-bold'} />
                            </div>
                    </div>
            </div>
            </React.Fragment>
    )
  }
}

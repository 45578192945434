import React, { Component, Fragment } from 'react'
import "./toolbar.css";
import Search from "./Search"
import classNames from 'classnames';
import { api } from 'api/api';
import AsyncSelect from 'react-select/lib/Async';
import { SingleDatePicker } from 'react-dates'
import Select from 'react-select';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';

let bodegas = [];
const getBodegas = (search) => {
    return api.get("bodega", { search }).catch((error) => { })
        .then((data) => {
            if (data) {
                bodegas = [];
                data.results.forEach(x => {
                    bodegas.push(x)
                })
                return bodegas
            }
            else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}

export default class ToolbarRInventario extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    }
    render() {
        const { es_detalle } = this.props;
        return (
            <div className="col-12 row d-flex justify-content-between mt-5">
                <div className="col-md-8 row d-flex justify-content-md-end justify-content-center">
                    <div className="col-md-8 row m-0">
                        {
                            !es_detalle && (
                                <Fragment>
                                        <div className="col-md-6  m-0 form-group">
                                            <label className="m-0">Fecha inicio:</label>
                                                <SingleDatePicker
                                                    placeholder={"Fecha Inicio"}
                                                    date={this.props.dateStart ? moment(this.props.dateStart ) : null}
                                                    focused={this.state.focusedStart}
                                                    isOutsideRange={() => false}
                                                    onDateChange={(value) => {
                                                    this.setState({dateStart:value})
                                                    this.props.setDateStart(value)
                                                    }}
                                                    onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                    numberOfMonths={1}
                                                    id={"dateStart"}
                                                />
                                        </div>
                                        <div className="col-md-6 row m-0  form-group">
                                                <label className="m-0">Fecha fin:</label>
                                                <SingleDatePicker
                                                    placeholder={"Fecha Inicio"}
                                                    date={this.props.dateEnd ? moment(this.props.dateEnd ) : null}
                                                    focused={this.state.focusedEnd}
                                                    isOutsideRange={() => false}
                                                    onDateChange={(value) => {
                                                    this.setState({dateEnd:value})
                                                    this.props.setDateEnd(value);
                                                    }}
                                                    onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                                                    numberOfMonths={1}
                                                    id={"dateEnd"}
                                                />
                                        </div>
                                </Fragment>
                            )
                        }
                    </div>
                    <div className="col-md-8 row m-0">
                        <div className="col-md-6 form-group">
                            <label className="m-0">Área:</label>
                            <AsyncSelect
                                value={this.props.filtro_area}
                                onChange={this.props.setFiltroArea}
                                placeholder="todos"
                                options={getBodegas}
                                className={classNames('form-control p-0 ')}
                                placeholder="todos"
                                isClearable
                                theme={(theme) => {
                                    return {
                                        ...theme,
                                        borderRadius: '1em',
                                        background: 'white',
                                        colors: {
                                            ...theme.colors,
                                            text: '#415362',
                                            primary25: '#ebebeb',
                                            primary: '#a8ba3a',
                                        },
                                    }
                                }}
                                classNamePrefix="react-select"
                                cache={false} placeholder="Escriba para buscar"
                                defaultOptions={true}
                                searchPromptText="Escriba para buscar"
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["nombre"])}
                                loadOptions={getBodegas}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="m-0">Movimientos:</label>
                            <Select
                                value={this.props.filtro_movimiento}
                                onChange={this.props.setFiltroMovimientos}
                                placeholder="todos"
                                isClearable
                                className={'form-control p-0'}
                                classNamePrefix="react-select"
                                theme={(theme) => {
                                    return {
                                        ...theme,
                                        borderRadius: '1em',
                                        background: 'white',
                                        colors: {
                                            ...theme.colors,
                                            text: '#415362',
                                            primary25: '#ebebeb',
                                            primary: '#a8ba3a',
                                        },
                                    }
                                }}
                                options={[
                                    { value: 10, label: 'Ingreso' },
                                    { value: 20, label: 'Despacho' },
                                    { value: 40, label: 'Baja' },
                                    { value: 30, label: 'Reajuste' },
                                    { value: 50, label: 'Devolución' },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-lg-4 col-md-4 p-0 search form-group pt-18 ${this.props.margen}`}>
                    {(this.props.tituloBuscador &&
                        <p className="ml-2 text-primary mt-5 mt-md-0 mt-lg-0"><strong>{this.props.tituloBuscador}</strong></p>
                    )}
                    {(this.props.buscar !== undefined) && (
                        <Search buscar={this.props.buscar} buscador={this.props.buscador} placeholder={this.props.placeholder}/>
                    )}
                </div>
            </div>
        )
    }
}

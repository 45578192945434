import React from 'react';
import './login.css';

export const Layout = (props) => {
    return (
        <div  className="login w-100 h-100 d-flex align-items-center justify-content-center ">

                <div className="row login-wrapper col-md-11 " style={{justifyContent: "center"}}>

                    <div className="login-container row col-md-12 d-flex justify-content-center justify-content-xl-end">

                        <div className= {`login-form d-md-flex align-items-center justify-content-md-center ${props.expanded ? "expand" : 'invisible'} `} >
                                <img  title="Logo" src={require("assets/img/logo01.png")} alt="Génesis" />
                        </div>
                        <div  className="login_formulario text-center d-flex align-items-center">
                                <div className="col-md-12 ">
                                    <div className="col-md-12 p-0 d-flex justify-content-center mb-4 ">
                                        <img height={50} className="ver_isotipo" title="logo1" src={require("assets/img/logo02.png")} alt="Genesis" />
                                    </div>
                                        {props.children}
                                </div>
                         </div>
                    </div>
                </div>
            </div>
    )
}

import { iconsh } from 'icons';
import React, { Component } from 'react'
import TituloBodega from 'Utils/Header/TituloBodega';
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import { formatAllDate, formatoMoneda } from '../../../Utils/renderField/renderReadField';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PagoForm from './PagosForm';
import CargarForm from './CargarForm';
import { RenderModifyCurrency } from 'Utils/renderField/renderReadField'
import './cuenta';

function formatMedicamento(cell, row){
    if(cell){
        return <span>{cell.nombre_completo}</span>
    }
    return <span></span>
}

export default class CuentaDetalle extends Component {
    componentDidMount() {
        const { me  } = this.props;
        this.props.detail(this.props.match.params.id);
        if (me.apertura) {
            this.props.getInfoCaja();
        }
    }

    changePriceFormatter = (cell, row, enumObject, index) => {
        const deshabilitar = row.disabled === undefined || (row.disabled !== undefined && row.disabled) ? true : false;
        const clase = deshabilitar ? ' btn-secondary ' : ' btn-primary '
        return(
            <React.Fragment>
                <div className="d-flex flex-row w-100 align-items-center">
                <RenderModifyCurrency
                    disabled={deshabilitar}
                    value={row.new_price || cell}
                    display_type={row.stock.modificable_venta ? 'input' : 'text'}
                    className={(row.stock.modificable_venta) ? "w-100 text-right form-control": "w-100 text-right form-control disabled" }
                    dato={cell}
                    orden = {row.orden}
                    detalle = {row.id}
                    changeValue={this.props.setVariablePrice}
                    index={row.id}
                />
                {row.stock && row.stock.modificable_venta &&
                    <button className={"btn" + clase + " boton-pequenio ml-2 w-25"} onClick={() => this.props.cambioPrecioServicio(row.id)}>{deshabilitar ? "modificar" : "guardar"}</button>
                }
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { caja, cuenta, cargando, } = this.props;
        let descuento = 0;
        if(cuenta.monto){
           descuento = cuenta.subtotal - cuenta.monto ;
        }

        //Agregado por ricky
        let detalles_movimiento = [];
        if(cuenta.detalle_movimiento){
            cuenta.detalle_movimiento.forEach((element) => {
                if(element.activo === true){
                    detalles_movimiento.push(element)
                }
            });
        }

        let consumidos = {
            results: cuenta.detalle_movimiento ? detalles_movimiento : [],
            count: 0
        }
        //...

        /*
        let consumidos = {
            results: cuenta.detalle_movimiento ? cuenta.detalle_movimiento : [],
            count: 0
        }
        */

        let pagos = {
            count: 0,
            results: cuenta.movimientos ? cuenta.movimientos : []
        }
        let total_pagado = 0;
        pagos.results.forEach(x => {
            total_pagado += x.monto;
        })

        let saldo_cuenta = cuenta.monto - total_pagado;

        return (
            <div className="col-md-12 row m-0 p-0 d-flex justify-content-between">
                {/* Inicio sección derecha */}
                <div className="col-md-6 px-1">
                    <div className="col-md-12 p-0 container m-0 contenido-principal h85">
                        <div className="col-md-12 p-0 row m-0 pt-4">
                            <TituloBodega
                                titulo={'Caja y ventas'}
                                subtitulo={`${caja.caja ? caja.caja.nombre : ''}`}
                                estilo={`col-md-6`}
                                icono={iconsh.crud_caja_venta}
                            />

                        </div>
                        <div className="row m-0 mt-5">
                            <div className="col-md-3">
                                <h4>CUENTA</h4>
                            </div>
                            <div className="col-md-9">
                                <span className="text-gris">Paciente: </span>
                                <span className="text-primary text-uppercase">{cuenta.paciente ? cuenta.paciente.nombre : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-12 h35">

                        <LoadMask loading={this.props.cargando_cuenta} blur_1>

                        <Table
                            data={consumidos}
                            pagination
                            loading={cargando}
                            page={0}>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatMedicamento}
                                dataField="stock" dataSort>Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>

                            <TableHeaderColumn
                                width={'40%'}
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={this.changePriceFormatter}
                                dataAlign={'right'}
                                dataField="subtotal" dataSort>Sub total</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                isKey={true}
                                hidden={true}
                                dataField="stock" dataSort>Fecha Ingreso</TableHeaderColumn>
                        </Table>

                        </LoadMask>

                        </div>

                        <div className="col-md-12 mt-3">
                                <h5>PAGOS DE CUENTA</h5>
                                <Table
                                    data={pagos}
                                    pagination
                                    loading={cargando}
                                    page={0}>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatAllDate}
                                        dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'center'}
                                        dataField="tipo_movimiento" dataSort>Forma pago</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'right'}
                                        dataFormat={formatoMoneda}
                                        dataField="monto" dataSort>Pagado</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        isKey={true}
                                        hidden={true}
                                        dataField="stock" dataSort>Fecha Ingreso</TableHeaderColumn>
                                </Table>
                            </div>
                    </div>
                </div>
                {/* Fin sección derecha */}
                {/* inicio sección izquierda */}
                <div className="col-md-6  px-1 ">
                    <div className="col-md-12 container m-0 contenido-principal row h85">
                        <div className="col-md-12">
                            <div className="col-md-12 p-2 fondo-inverso-navbar ">
                                <h5 className="text-uppercase m-0 pr-3 text-right text-white">
                                    <img className="mr-2 pb-1" src={iconsh.crud_caja_venta2} height={35} />
                                    Pago
                                </h5>
                            </div>

                            <LoadMask loading={cargando} blur_1>
                                <PagoForm
                                    // initialValues={{total_a_pagar: saldo_cuenta, efectivo:true }}
                                    cuenta={cuenta}
                                    descuento={descuento}
                                    total_pagado={total_pagado}
                                    total_cuenta={cuenta.monto}
                                    cancelar={this.props.regresar} onSubmit={this.props.agregar_abono}/>
                            </LoadMask>
                        </div>

                    </div>
                </div>
                {/* fin sección izquierda */}
            </div>
        )
    }
}

import React, { Component, Fragment} from 'react'
import Table from 'Utils/Grid';
import { iconsh, EXTRAS } from "icons";
import HeaderReporte from 'Utils/Header/HeaderReporte';
import { BreakLine } from '../../../Utils/tableOptions';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import { formatoMoneda, formatSelect, dateFormatter, formatAllDate } from '../../../Utils/renderField/renderReadField';
import Grafica from './Grafica';
import Filtro from './Filtro';
import ToolbarSearch from 'Utils/Toolbar/ToolbarSearch';
import moment from "moment";
import _ from 'lodash';

function formatNombre(cell, row) {
    return <span className={`${row.ya_vencidos > 0 ? 'text-danger' : ''}`}>{cell}</span>
}
function formatAlertaVencimiento(cell, row) {
    let _existencias = _.sumBy(row.inventario, (inven) => inven.existencias);
    if (Number(row.alerta_vencimiento) > 0) {
        return <div className="col-md-12 row m-0 p-0">
            <div className="col-md-6 p-0">
                <img src={EXTRAS.vencimiento1} height={'30px'} alt="Ya vencido" />
            </div>
            <div className={`${row.alerta_minima ? 'background-danger' : ''} col-md-6 p-0 pr-2 pt-2 text-primary{`}  >
                <span className="font-weight-bold text-primary">{_existencias}</span>
            </div>
        </div>
    } else {
        return <div className="col-md-12 row m-0 p-0">
            <div className="col-md-6"></div>
            <div className={`${row.alerta_minima ? 'background-danger' : ''} col-md-6 p-0 pr-2 pt-2 text-primary{`} >
                <span className="font-weight-bold text-primary">{_existencias}</span>
            </div>
        </div>
    }
}

function cantidadesVencidas(props) {

    return (cell, row) => {
        let fecha_inicial;
        let fecha_fin;
        let todos_vencidos = true;

        if(props.vencimiento) {
            // se hace el filtro del mes actual
            if(props.vencimiento.value === 2) {
                let fecha_actual = moment().format('YYYY-MM-DD');
                fecha_actual = fecha_actual.split('-');
                // Se saca la fecha inicial y final
                fecha_inicial = fecha_actual[0]+'-'+fecha_actual[1]+ '-01';
                // Sacamos el ultimo dia dependiendo del mes
                fecha_fin = moment(fecha_actual[0]+'-'+fecha_actual[1]+'-01').endOf('month').format('YYYY-MM-DD');
                todos_vencidos = false;
            } else if (props.vencimiento.value === 3) {
                let fecha_actual = moment().add(1, 'M').format('YYYY-MM-DD'); // Se suman 1 mes
                fecha_actual = fecha_actual.split('-');
                fecha_inicial = fecha_actual[0]+'-'+fecha_actual[1]+ '-01';
                fecha_fin = moment(fecha_actual[0]+'-'+fecha_actual[1]+'-01').endOf('month').format('YYYY-MM-DD');
                todos_vencidos = false;
            } else if (props.vencimiento.value === 4) {
                let fecha_actual = moment().add(2, 'M').format('YYYY-MM-DD'); // Se suman 2 meses
                fecha_actual = fecha_actual.split('-');
                fecha_inicial = fecha_actual[0]+'-'+fecha_actual[1]+ '-01';
                fecha_fin = moment(fecha_actual[0]+'-'+fecha_actual[1]+'-01').endOf('month').format('YYYY-MM-DD');
                todos_vencidos = false;
            } else {
                let fecha_actual = moment().format('YYYY-MM-DD');
                fecha_actual = fecha_actual.split('-');
                // Se saca la fecha inicial y final
                fecha_inicial = fecha_actual[0]+'-'+fecha_actual[1]+ '-'+fecha_actual[2];
                todos_vencidos = true;
            }
        } else {
            let fecha_actual = moment().format('YYYY-MM-DD');
                fecha_actual = fecha_actual.split('-');
                // Se saca la fecha inicial y final
                fecha_inicial = fecha_actual[0]+'-'+fecha_actual[1]+'-'+fecha_actual[2];
                todos_vencidos = true;
        }
        // Debemos de convertir las fechas con moment
        let _existencias;
        if (!todos_vencidos) {
            _existencias = _.sumBy(row.inventario, (inven) => {
                if(moment(inven.lote.fecha_vencimiento) >= moment(fecha_inicial) && moment(inven.lote.fecha_vencimiento) <= moment(fecha_fin)) {
                    return inven.existencias
                }
            });
        } else {
            _existencias = _.sumBy(row.inventario, (inven) => {
                if(moment(inven.lote.fecha_vencimiento) <= moment(fecha_inicial)) {
                    return inven.existencias
                }
            });
        }
        if (Number(row.alerta_vencimiento) > 0) {
            return <div className="col-md-12 row m-0 p-0">
                <div className="col-md-6 p-0">
                    <img src={EXTRAS.vencimiento1} height={'30px'} alt="Ya vencido" />
                </div>
                <div className={`${row.alerta_minima ? 'background-danger' : ''} col-md-6 p-0 pr-2 pt-2 text-primary{`}  >
                    <span className="font-weight-bold text-primary">{(_existencias)? _existencias: 0}</span>
                </div>
            </div>
        } else {
            return <div className="col-md-12 row m-0 p-0">
                <div className="col-md-6"></div>
                <div className={`${row.alerta_minima ? 'background-danger' : ''} col-md-6 p-0 pr-2 pt-2 text-primary{`} >
                    <span className="font-weight-bold text-primary">{(_existencias)? _existencias: 0}</span>
                </div>
            </div>
        }
    }
}
function formatLote(cell, row) {
    if (cell) {
        return <span className={`${Number(row.meses_vencimiento) == 1 ? 'text-danger' : ''}`}>{cell.lote}</span>
    }
}
function formatExistencias(cell, row) {
    return <span className={`${Number(row.meses_vencimiento) == 1 ? 'text-danger' : ''}`}>{cell}</span>
}
function formatVencimiento(cell, row) {
    if (cell) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                {
                    row.meses_vencimiento && Number(row.meses_vencimiento) < 5 && (
                        <Fragment>
                            {
                                Number(row.meses_vencimiento) == 1 && (
                                    <img src={EXTRAS.vencimiento5} height={'30px'} alt="Ya vencido" />
                                )
                            }
                            {
                                row.meses_vencimiento === 2 && (
                                    <img src={EXTRAS.vencimiento4} height={'30px'} alt="vencimiento un mes" />
                                )
                            }
                            {
                                row.meses_vencimiento === 3 && (
                                    <img src={EXTRAS.vencimiento3} height={'30px'} alt="vencimiento dos meses" />
                                )
                            }
                            {
                                row.meses_vencimiento === 4 && (
                                    <img src={EXTRAS.vencimiento2} height={'30px'} alt="vencimiento tres meses" />
                                )
                            }


                        </Fragment>
                    )
                }
                <span className={`pl-2 ${Number(row.meses_vencimiento) == 1 ? 'text-danger' : ''}`}>{dateFormatter(cell.fecha_vencimiento)}</span>
            </div>
        )
    }
}
const isExpandableRow = () => {
    return true;
}

export default class Rvencimiento extends Component {
    state = {
        expandidos: [],
        inventario: null
    }
    expandComponent = (row) => {
        let data = row && row.inventario ? row.inventario : [];
        let inventario = _.cloneDeep(data);
        // const movimientos = []
        // const { me } = this.props
        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    stripped={false}
                    data={inventario}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        hidden={true}
                        dataAlign="center"
                        dataFormat={activeFormatter({  })}>Acciones</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="lote"
                        dataAlign="center"
                        dataFormat={formatLote}
                        width={'90px'}>Lote</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre_bodega"
                        width={'90px'}>Nombre bodega</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="lote"
                        dataFormat={formatVencimiento}
                        dataAlign="center"
                    >Vencimiento</TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataFormat={formatExistencias}
                        dataField="existencias">Existencias</TableHeaderColumn>


                </BootstrapTable>
            </div>
        )
    }
    handleExpand = (rowKey, isExpand) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        if (isExpand) {
            expandidos.push(rowKey)
        } else {
            let index = expandidos.findIndex(x => x === rowKey)
            expandidos.pop(index)
        }
        this.setState({ expandidos: expandidos })
    }
    rowClassNameFormat = (row, rowIdx) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        let registro = expandidos.find(x => x === row.id)
        return registro === undefined ? '' : 'background-row-selected';
    }
    componentWillMount(){
        this.props.listar_alertas();
        this.props.getGraficaAlerta();
    }
    render() {
        const { data, cargando, page, grafica_alerta } = this.props;
        return (
            <div>
                <div className="container m-0 contenido-principal row d-flex justify-content-center">
                    <HeaderReporte
                        icono={iconsh.crud_reportes}
                        titulo={'Alertas vencimiento'}
                        es_detalle
                    />
                </div>
                <div className="container m-0 contenido-principal mt-3 pt-3 col-md-12">
                    <div className="fondo-inverso-navbar " style={{ height: 40 }}></div>
                    <div className="col-md-12 row m-0 mt-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <Filtro {...this.props} ver_vencimiento />
                            </div>
                            <div className="col-md-8">
                                <Grafica datum={grafica_alerta} />
                            </div>
                        </div>
                    <div className="con-hijos">
                        <div className="mb-2 ">
                            <ToolbarSearch 
                                // titulo={" "<div className="mb-2"></div>} 
                                buscar={this.props.search} 
                                buscador={this.props.buscador}  
                                placeholder={"Buscar por: Producto"}
                            />
                        </div>
                        <Table
                            onPageChange={this.props.listar_alertas}
                            data={data}
                            loading={cargando}
                            expandableRow={isExpandableRow}
                            expandComponent={this.expandComponent}
                            onExpand={this.handleExpand}
                            trClassName={this.rowClassNameFormat}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                hidden={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ editar: '/' })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="nombre_completo" dataSort>Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal', width: 90 }}
                                thStyle={{ whiteSpace: 'normal', width: 90 }}
                                width={'90px'}
                                dataAlign={'right'}
                                className='tamanio-cuadrado'
                                dataFormat={formatAlertaVencimiento}
                                dataField="cantidad" dataSort>Existencias</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={{ whiteSpace: 'normal', width: 90 }}
                                thStyle={{ whiteSpace: 'normal', width: 90 }}
                                width={'90px'}
                                dataAlign={'right'}
                                className='tamanio-cuadrado'
                                dataFormat={cantidadesVencidas(this.props)}
                                dataField="cantidad" dataSort>Existencias (Vencidas)</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'center'}
                                dataFormat={formatoMoneda}
                                dataField="precio" dataSort>Precio</TableHeaderColumn>

                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import Login from './common/components/Login/LoginContainer';
import Home from './common/components/Home/Home';

import Privado from './common/components/Privado/';
import Dashboard from './common/components/Dashboard/';
import ProtectedRoute from './ProtectedRoute'

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

import {
    Listar as AreaGrid,
    Crear as AreaCrear,
} from './common/components/App/Areas';
import {
    Listar as EstadoBodegaGrid,
    Ingresos as IngresosBodega,
    Crear as CrearIngreso,
    Despacho as CrearDespacho,
    Detalle as DetalleMovimiento,
    DetalleDespacho,
    VerMovimiento
}from './common/components/App/EstadoBodega'

import {
    Editar as Configuracion
} from './common/components/App/Configuracion';

import {
    Listar as UsuarioGrid,
    Crear as UsuarioCrear
} from './common/components/App/Usuarios';
import {
    Listar as ProductoGrid,
    Crear as ProductoCrear
} from './common/components/App/Producto';
import {
    Listar as ProveedorGrid,
    Crear as ProveedorCrear
} from './common/components/App/Proveedores'
import {
    Listar as ClientesGrid,
    Crear as ClientesCrear
} from './common/components/App/Clientes';
import {
    Listar as BitacoraGrid,
    Detalle as BitacoraDetalle
} from './common/components/App/Bitacora';
import {
    Listar as OrdenesGrid,
    Crear as OrdenesCrear
} from './common/components/App/Ordenes';
import {
    Listar as SolicitudesGrid,
    Confirmar as ConfirmarVer,
    Aceptado as AceptadoVer,
    OrdenPago as OrdenPago
} from './common/components/App/Solicitudes';

import {
    Listar as DevolucionesGrid,
    Crear as DevolucionesCrear,
    IngresarDevolucion
} from './common/components/App/Devoluciones';
import {
    DetalleCuenta as PVDetalleCuenta,
    PuntoVenta,
    PagoVenta,
    DetalleVenta
} from './common/components/App/Punto_venta';
import {
    Listar as ListarCajas,
    Apertura_Cierre
} from './common/components/App/Caja';

import {
    Listar as PacienteGrid,
    Crear as PacienteCrear
} from './common/components/App/Paciente';

import {
    Listar as CuentasGrid,
    Crear as CuentaCrear,
    Detalle as DetalleCuenta,
} from './common/components/App/Cuentas';

import {
    Listar as EnfermeriaGrid,
    Detalle as DetalleEnfermeria
} from './common/components/App/Enfermeria';
import {
    Crear as CategoriaCrear,
    Listar as CategoriaListar
} from './common/components/App/Categoria';
import {
    Listar as CuentaAreaGrid,
    Crear as CuentaAreaCrear
} from './common/components/App/Cuenta_area';

import {
    Listar as GastosGrid,
    Crear as GastoCrear
} from './common/components/App/Gastos';
import {
    RCompraVenta,
    RGanancia,
    RCaja,
    RInventario,
    RDetalleCaja,
    Rporpagar,
    RVencieminto
} from './common/components/App/Reportes'
import DetalleVentaCaja from './common/components/App/Reportes/DetalleCaja/DetalleVenta';

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}
module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <ProtectedRoute path="/page" component={Privado} />
                <ProtectedRoute path="/dashboard" component={Dashboard} />

                {/* compras */}
                <ProtectedRoute path="/ordenes/aceptado/pago/:id" component={OrdenPago} />
                <ProtectedRoute path="/ordenes/aceptado/:id" component={AceptadoVer} />
                <ProtectedRoute path="/ordenes" component={OrdenesGrid} />
                <ProtectedRoute path="/orden/:id" component={OrdenesCrear} />
                <ProtectedRoute path="/orden" component={OrdenesCrear} />


                {/* Solicitudes */}
                <ProtectedRoute path="/confirmar/:id" component={ConfirmarVer} />
                <ProtectedRoute path="/solicitudes/aceptado/pago/:id" component={OrdenPago} />
                <ProtectedRoute path="/solicitudes/aceptado/:id" component={AceptadoVer} />
                <ProtectedRoute path="/solicitudes" component={SolicitudesGrid} />

                {/* Devoluciones */}
                <ProtectedRoute path="/devolucion/:id" component={DevolucionesCrear} />
                <ProtectedRoute path="/devolucion" component={DevolucionesCrear} />
                <ProtectedRoute path="/devoluciones" component={DevolucionesGrid} />

                {/* area */}
                <ProtectedRoute path="/area_estado/ingreso/detalle/:id" component={DetalleMovimiento} />
                <ProtectedRoute path="/area_estado/ingreso/vermovimiento/:id" component={VerMovimiento} />
                <ProtectedRoute path="/area_estado/ingreso/detalledespacho/:id" component={DetalleDespacho} />
                <ProtectedRoute path="/area_estado/ingresodevolucion/:id" component={IngresarDevolucion} />
                <ProtectedRoute path="/area_estado/ingreso/:id" component={IngresosBodega} />
                <ProtectedRoute path="/area_estado/crearingreso/:id" component={CrearIngreso} />
                <ProtectedRoute path="/area_estado/creardespacho/:id" component={CrearDespacho} />
                <ProtectedRoute path="/area_estado/:id" component={EstadoBodegaGrid} />

                {/* mi area */}
                <ProtectedRoute path="/area_asignada/:id" component={EstadoBodegaGrid} />

                <ProtectedRoute path="/areas_todo/editar/:id" component={AreaCrear} />
                <ProtectedRoute path="/areas_todo/crear" component={AreaCrear} />
                <ProtectedRoute path="/areas_todo" component={AreaGrid} />

                {/* Gastos */}
                <ProtectedRoute path="/gasto" component={GastoCrear} />
                <ProtectedRoute path="/gastos" component={GastosGrid} />


                {/* usuarios */}
                <ProtectedRoute path="/admin_usuario/:id" component={UsuarioCrear} />
                <ProtectedRoute path="/admin_usuarios" component={UsuarioGrid} />
                <ProtectedRoute path="/admin_usuario" component={UsuarioCrear} />

                {/* configuracion */}
                <ProtectedRoute path="/admin_configuracion" component={Configuracion} />



                {/* cuenta area */}
                <ProtectedRoute path="/admin_cuenta_area/:id" component={CuentaAreaCrear}/>
                <ProtectedRoute path="/admin_cuenta_area" component={CuentaAreaCrear}/>
                <ProtectedRoute path="/admin_cuenta_areas" component={CuentaAreaGrid}/>

                {/* proveedores */}
                <ProtectedRoute path="/admin_proveedores" component={ProveedorGrid} />
                <ProtectedRoute path="/admin_proveedor/:id" component={ProveedorCrear} />
                <ProtectedRoute path="/admin_proveedor" component={ProveedorCrear} />

                {/* clientes */}
                <ProtectedRoute path="/admin_clientes" component={ClientesGrid}/>
                <ProtectedRoute path="/admin_cliente/:id" component={ClientesCrear}/>
                <ProtectedRoute path="/admin_cliente" component={ClientesCrear}/>

                {/* producto */}
                <ProtectedRoute path="/admin_producto/:id" component={ProductoCrear}/>
                <ProtectedRoute path="/admin_producto" component={ProductoCrear}/>
                <ProtectedRoute path="/admin_productos" component={ProductoGrid}/>
                {/* categoria */}
                <ProtectedRoute path="/admin_categoria/:id" component={CategoriaCrear}/>
                <ProtectedRoute path="/admin_categoria" component={CategoriaCrear}/>
                <ProtectedRoute path="/admin_categorias" component={CategoriaListar}/>


                {/* paciente */}
                <ProtectedRoute path="/enfermeria_pacientedetalle/:id" component={PacienteCrear}/>
                <ProtectedRoute path="/enfermeria_pacientecrear" component={PacienteCrear}/>
                <ProtectedRoute path="/enfermeria_pacientes" component={PacienteGrid}/>
                {/* enfermeria */}
                <ProtectedRoute path="/enfermeria_cuenta/:id" component={DetalleEnfermeria}/>
                <ProtectedRoute path="/enfermeria_cuenta" component={CuentaCrear}/>
                <ProtectedRoute path="/enfermeria_cuentas" component={EnfermeriaGrid}/>


                {/* Bitacora */}
                <ProtectedRoute path="/reporte_bitacora_detalle/:id" component={BitacoraDetalle}/>
                <ProtectedRoute path="/reporte_bitacoras" component={BitacoraGrid}/>

                {/* Cuentas */}
                <ProtectedRoute path="/reporte_cuenta/:id" component={DetalleCuenta}/>
                <ProtectedRoute path="/reporte_cuenta" component={CuentaCrear}/>
                <ProtectedRoute path="/reporte_cuentas" component={CuentasGrid}/>

                {/* Reporte de Compras y ventas */}
                <ProtectedRoute path="/reporte_compra_ventas" component={RCompraVenta}/>

                <ProtectedRoute path="/reporte_ganacia" component={RGanancia}/>
                <ProtectedRoute path="/reporte_caja/:id" component={RDetalleCaja}/>
                <ProtectedRoute path="/reporte_cajas" component={RCaja}/>
                <ProtectedRoute path="/cierre_caja/detalle_venta/:id" component={DetalleVentaCaja}/>
                <ProtectedRoute path="/reporte_inventario" component={RInventario}/>
                <ProtectedRoute path="/reporte_vencimiento" component={RVencieminto}/>
                <ProtectedRoute path="/reporte_porpagar" component={Rporpagar}/>


                {/* punto de venta */}
                <ProtectedRoute path="/caja/punto_venta/cuenta/:id" component={PVDetalleCuenta} />
                <ProtectedRoute path="/caja/punto_venta/pago" component={PagoVenta} />
                <ProtectedRoute path="/caja/punto_venta/detalle/:id" component={DetalleVenta} />
                <ProtectedRoute path="/caja/punto_venta" component={PuntoVenta} />

                {/* Manejo de apertura y cierre de cajas */}
                <ProtectedRoute path="/caja/apertura_cierre/:id" component={Apertura_Cierre} />

                {/* caja */}
                <ProtectedRoute path="/cajas" component={ListarCajas} />
                <Route exact path="*" component={Login} />
            </Switch>
        </div>
    </div>
);


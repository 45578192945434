import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Enfermeria/enfermeria';
import DetallePaciente from './DetallePaciente';

const mstp = state => {
    let me = state.login.me;
    let nombre_bodega = me.nombre_bodega ? me.nombre_bodega : '';
    return {...state.enfermeria, nombre_bodega}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetallePaciente)

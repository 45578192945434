import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';

const url = 'proveedores';

const LOADER_PROVEEDORES = 'LOADER_PROVEEDORES';
const SET_DATA_PROVEEDORES = 'SET_DATA_PROVEEDORES';
const SET_PAGE_PROVEEDORES = 'SET_PAGE_PROVEEDORES';
const SET_UPDATE_DATA_PROVEEDORES ='SET_UPDATE_DATA_PROVEEDORES';
const SET_BUSCADOR_PROVEEDORES = 'SET_BUSCADOR_PROVEEDORES';
const SET_FILTRO_PROVEEDORES = 'SET_FILTRO_PROVEEDORES';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PROVEEDORES, cargando: true});
    const store = getStore();
    const search = store.proveedores.buscador;
    const filtro = store.proveedores.filtro_proveedores;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PROVEEDORES, data});
            dispatch({type: SET_PAGE_PROVEEDORES, page});
        }
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
    })
};
const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_PROVEEDORES, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/admin_proveedores'))
        }
    }).then((data) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_PROVEEDORES, updateData: data});
            dispatch(initializeForm('proveedor', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.proveedor.values;
    dispatch({type: LOADER_PROVEEDORES, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false})
        Swal('Éxito', 'Se ha creado el proveedor.', 'success')
        .then(() => {
            dispatch(push('/admin_proveedores'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false})
        Swal(
            'Error',
             (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.proveedor.values);
    dispatch({type: LOADER_PROVEEDORES, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/admin_proveedores'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/admin_proveedores'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PROVEEDORES, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_PROVEEDORES, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PROVEEDORES, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PROVEEDORES, filtro_proveedores: filtro});
    dispatch(listar(1));
};
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro
};
export const reducer = {
    [LOADER_PROVEEDORES]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PROVEEDORES]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PROVEEDORES]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PROVEEDORES]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PROVEEDORES]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PROVEEDORES]: (state, { filtro_proveedores }) => {
        return {...state, filtro_proveedores }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_proveedores: null,
    updateData: {},
};
export default handleActions(reducer, initialState)

import { iconsh } from 'icons';
import React, { Component } from 'react'
import { RenderCurrency } from 'Utils/renderField/renderReadField'
import { Link } from 'react-router-dom';

export default class Caja extends Component {
    render() {
        const { apertura, me } = this.props;
        let total = 0;
        if(apertura.total_apertura){
            total = apertura.total_apertura + apertura.total_efectivo;
        }
        let cuentas = apertura.cuentas ? apertura.cuentas : [];
        let cuentas_group = _(cuentas).groupBy('cuenta_nombre').map((objs, key) =>({
            'cuenta_nombre': key,
            'total': _.sumBy(objs, 'total')
        })).value()
        return (
            <div className="mt-5">
                <div className="col-md-12 p-2 fondo-inverso-navbar ">
                    <h5 className="text-uppercase m-0 pr-3 text-right text-white">
                        <img className="mr-2 pb-1" src={iconsh.crud_caja_venta2} height={35} />
                        CAJA
                    </h5>
                </div>
                <div className="row m-0 mt-3 p-0 col-md-12">
                    <div className="col-md-12 row m-0 mt-3">
                        <div className="col-md-4 p-0 d-flex align-items-end">
                        </div>
                        <div className="col-md-8  p-0">
                            <div className="col-md-12 row p-0 m-0">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-gris">APERTURA</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    <RenderCurrency value={apertura.total_apertura ? apertura.total_apertura : 0}  className={'text-gris  font-weight-bold'}/>
                                </div>
                            </div>
                            <div className="col-md-12 row p-0 m-0">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-primary">TOTAL INGRESADO</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    <RenderCurrency value={apertura.total_efectivo ? apertura.total_efectivo : 0}  className={'text-primary h4 font-weight-bold'}/>
                                </div>
                            </div>
                            <div className="col-md-12 row p-0 m-0 mb-3">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-gris ">INGRESO TARJETA</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    <RenderCurrency value={apertura.total_tarjeta ? apertura.total_tarjeta : 0}  className={'text-gris font-weight-bold'}/>
                                </div>
                            </div>
                            {
                                cuentas_group.map(x => {
                                    return(
                                        <div className="col-md-12 row p-0 m-0">
                                            <div className="col-md-6 p-0 text-right">
                                                <span className="text-gris text-uppercase">{x.cuenta_nombre}</span>
                                            </div>
                                            <div className="col-md-6 p-0 text-right">
                                                <RenderCurrency value={x.total}  className={'text-gris font-weight-bold'}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 p-0 mt-5">
                    <div className="col-md-12 borde-gris py-2 d-flex justify-content-end">
                        <div className="col-md-8 p-0">
                            <div className="col-md-12 row p-0 m-0">
                                <div className="col-md-6 p-0 text-right">
                                    <span className="text-primary h5">TOTAL EN CAJA</span>
                                </div>
                                <div className="col-md-6 p-0 text-right">
                                    <RenderCurrency value={total}  className={'text-verde h4 font-weight-bold'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3 d-flex justify-content-md-end justify-content-center">
                    <Link className="btn btn-primary m-1" to={`/caja/apertura_cierre/${me.caja}`}>Cerrar caja</Link>

                </div>
            </div>
        )
    }
}

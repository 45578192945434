import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderFieldCheck } from 'Utils/renderField/renderField';

let PagadoForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row col-md-12">
                        <div className="row col-md-12 m-0 p-0">
                            <div className="col-auto m-0 ml-3 form-group label">
                                <Field name="pago_completo" component={renderFieldCheck}
                                    type="checkbox"
                                    disabled={true}
                                    className="form-control"
                                    placeholder="Correo"/>
                            </div>
                            <div className="p-0 ml-3  ml-sm-0  col-lg-9 m-0 col-md-9 col-sm-12  pt-1">
                                <span className="text-primary font-weight-bold text-uppercase">Pago Completo</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
PagadoForm = reduxForm({
    form: 'pagado',
    validate: data => {
        return validate(data, {
        })
    }

})(PagadoForm);
export default PagadoForm

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency } from 'Utils/renderField/renderField';

let ConfiguracionForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="limite" className="m-0">Monto inicial caja*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="monto_inicial_caja" component={renderCurrency} type="" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={ e => {
                                        e.preventDefault()
                                        props.cancelar()
                                    }}
                                    className="btn btn-secondary m-1">CAncelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
ConfiguracionForm = reduxForm({
    form: 'configuracion',
    validate: data => {
        return validate(data, {
        })
    }
})(ConfiguracionForm);
export default ConfiguracionForm

import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ClienteForm';
import { ClienteUpdateForm } from './ClienteForm';
import { iconsh } from "icons";

export default class ClienteCrear extends Component {
state = {
    editar: false,
    mensaje: 'Agregar cliente',
    password: false,
}
componentWillMount(){
    if(this.props.match.params.id){
        this.props.detail(this.props.match.params.id);
        this.setState({
                editar: true,
                mensaje: 'Editar cliente'
            });
    }
}

render() {
    const { create, update } = this.props;
    const { updateData, cargando } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12">
            <Card
                icono={iconsh.usuario}
                titulo={this.state.mensaje}>
                <LoadMask loading={cargando} blur_1>
                    {
                        this.state.editar ?
                            <ClienteUpdateForm
                                onSubmit={update}
                                updateData={updateData}
                                editando={true} />
                        :
                            <Form onSubmit={create}  />
                    }
                </LoadMask>
            </Card>
        </div>
    </div>
    )
    }
}

import React, { Component, Fragment } from 'react'
import Card from 'Utils/Cards/cardGrande';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { iconsh, EXTRAS } from "icons";
import { formatAllDate, RenderDateTime } from '../../../Utils/renderField/renderReadField';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import MedicamentosForm from './MedicamentosForm';
import AplicacionMedicamentoForm from './FormAplicarMedicamento';
import ToolbarEnfermeria from 'Utils/Toolbar/ToolbarEnfermeria';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import Modal from 'react-responsive-modal';
import { activeFormatter, activeFormatter2 }  from 'Utils/Acciones/Acciones';
import AnulacionForm from '../../Punto_venta/Detalle_venta/formulario/formularioanular';
import AplicarMedicamentosForm from './FormAplicarMedicamento';

function numeracionFormat(cell, row, enumObject, index){
  return (
      <span>{index + 1}</span>
  );
}

function formatMedicamento(cell, row){
    if(cell){
        return <span>{cell.nombre_completo}</span>
    }

}
function cellTachado(cell, row) {
    if(!row.activo){
        return {textDecoration: "line-through", whiteSpace: 'normal'}
    }
    return {whiteSpace: 'normal'}
}
function formatAplicadas(cell, row){
    if(cell){
        return <span>{row.cantidad - row.cantidadActual}</span>
    }
    return <span>0</span>
}

const isExpandableRow = (row) => {
    // if(!row.stock.es_producto){
    //     return true
    // }
    return true;
}
const isExpandableRowAplicaciones = () => {
    return true;
}
export default class DetallePaciente extends Component {
    state = {
        activeTab: 'medicamentos',
        form: 1,
        form_aplicar:{},
        id_cargo:0,
        expandidos: [],
        inventario: null,
        aplicacion: false
    }
    handleExpand = (rowKey, isExpand) => {
        let expandidos = _.cloneDeep(this.state.expandidos);
        if (isExpand) {
            expandidos.push(rowKey)
        } else {
            let index = expandidos.findIndex(x => x === rowKey)
            expandidos.pop(index)
        }
        this.setState({ expandidos: expandidos })
    }
    componentDidMount() {
        this.props.detail(this.props.match.params.id);
    }
    botonVerMas = (id, row) => {
        if(row.activo){
            return (
                <img onClick={(e) => {
                    e.preventDefault();
                }}
                    className="action-img"
                    title="Ver más" src={EXTRAS.detalle_servicio} alt="Anular gasto" />

            );
        }

    }
    accionesFormat = (cell, row) => {
        if(cell){
            if(!row.stock.es_producto && row.paquete_servicio.length > 0 ){
                return activeFormatter2({ adicional: this.botonVerMas, cell, row})
            }
        }
    }
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
            if (tab === 'activas') {
                // this.props.listar(1);
            } else {
                // this.props.listar_cerrada(1);
                this.props.listar_detalle(1);
            }
        }
    }
    openModal = (form=1) => {
        this.setState({form: form})
        this.props.openModal()
    }
    botonModal = (id, row) => {
        return (
            <img onClick={e => {
                e.preventDefault();
                this.setState({form_aplicar: {
                    id: row.id,
                    aplicados: row.cantidad - row.cantidadActual,
                    cantidad_aplicar: 1,
                    ...row
                }});
                this.openModal(3);
            }}
                className="action-img"
                title="Editar"
                src={EXTRAS.mas1} alt="reajuste o baja" />

        )
    }
    accionesFormatAplicar = (cell, row) => {
        if(cell){
            if((row.cantidadActual > 0) ){
                return activeFormatter2({ adicional: this.botonModal, cell, row})
            }
        }

    }
    expandAplicadas = (row) => {
        let data = row && row.aplicaciones ? row.aplicaciones : [];
        let historia = _.cloneDeep(data);

        // const movimientos = []
        // const { me } = this.props
        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    stripped={false}
                    data={historia}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        hidden={true}
                        dataAlign="center"
                        dataFormat={activeFormatter({ adicional: this.botonModal })}>Acciones</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="creado"
                        dataFormat={formatAllDate}
                        dataAlign="center"
                        width={'90px'}>Fecha aplicación</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataAlign="center"
                    >Cantidad</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="usuario_que_agrego"
                        dataAlign="center"
                    >Agregado por</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="bodega_origen"
                        dataAlign="center"
                    >Bodega</TableHeaderColumn>

                </BootstrapTable>
            </div>
        )
    }
    expandComponent = (row) => {
        let data = row && row.paquete_servicio ? row.paquete_servicio : [];
        let kit = _.cloneDeep(data);
        // const movimientos = []
        // const { me } = this.props
        if(kit.length > 0){
            return (
                <div className=" tabla-adentro con-hijos">
                    <BootstrapTable
                        stripped={true}
                        expandableRow={isExpandableRowAplicaciones}
                        expandComponent={this.expandAplicadas}
                        data={kit}>
                        {row.activo ?
                        <TableHeaderColumn
                            dataField="id"
                            isKey={true}
                            dataAlign="center"
                            dataFormat={this.accionesFormatAplicar}></TableHeaderColumn>
                            :
                            <TableHeaderColumn
                            dataField="id"
                            isKey={true}
                            dataAlign="center"
                            ></TableHeaderColumn>
                        }
                        <TableHeaderColumn
                            dataField="stock"
                            dataAlign="center"
                            dataFormat={formatMedicamento}
                            width={'90px'}>Medicamento</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataAlign="center"
                        >Cantidad</TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataFormat={formatAplicadas}
                            dataField="cantidad">Aplicadas</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            )
        }
    }
    render() {
        const my_style={
            background: "red",
        }

        const { cargando, updateData, setExistencia, page, listar_detalle, detalle, nombre_bodega, quitarMedicamento } = this.props;
        let data = {
            results: detalle,
            count:0
        }

        let total_productos = 0;
        let total_servicios = 0;

        data.results.forEach(element => {
            if(element.stock.es_producto && element.activo){
                if(element.activo){
                    total_productos++;
                }
            }else{
                if(element.activo){
                    total_servicios++;
                }
            }
        });


        return (
            <div className="container m-0 contenido-principal row pt-4">

                        <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        {
                                            this.state.form == 1 && (
                                                <div className="borde-abajo pb-2">
                                                    <img width={35} className="" src={iconsh.crud_paciente} alt="Paciente" />
                                                    <span className="ml-2 text-uppercase text-titulo"><strong>Agregar Medicamento o servicio</strong></span>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.form == 2 && (
                                                <div className="borde-abajo pb-2">
                                                    <img width={35} className="" src={iconsh.crud_gastos} alt="Paciente" />
                                                    <span className="ml-2 text-uppercase text-titulo"><strong>Anular cargo</strong></span>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.form == 3 && (
                                                <div className="borde-abajo pb-2">
                                                    <img width={35} className="" src={iconsh.crud_paciente} alt="Paciente" />
                                                    <span className="ml-2 text-uppercase text-titulo"><strong>Agregar Medicamento Extra</strong></span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="modal-body pt-0">
                                    {
                                        this.state.form == 1 && (
                                            <Fragment>
                                                <div className="text-md-right text-center mb-3">
                                                    <span className="text-gris">Medicamentos en área: </span><span className="text-primary">{nombre_bodega}</span>
                                                </div>
                                                <MedicamentosForm setExistencia={setExistencia} cerrarModal={this.props.closeModal} onSubmit={this.props.create}/>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        this.state.form == 2 && (
                                            <AnulacionForm
                                                initialValues={{id: this.state.id_cargo}}
                                                closeModal={this.props.closeModal}
                                                onSubmit={this.props.anular_cargo}/>
                                        )
                                    }
                                    {
                                        this.state.form == 3 && (
                                            <Fragment>
                                                <div className="text-md-right text-center mb-3">
                                                    <span className="text-gris">Medicamentos en área: </span><span className="text-primary">{nombre_bodega}</span>
                                                </div>
                                                <AplicarMedicamentosForm
                                                    initialValues={this.state.form_aplicar}
                                                    valores_paquete={this.state.form_aplicar}
                                                    cerrarModal={this.props.closeModal}
                                                    onSubmit={this.props.aplicarMedicamento} />
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </Modal>
                <HeaderSimple
                    titulo="Paciente"
                    icono={iconsh.crud_paciente}
                    texto={"Agregar medicamentos o servicios"}
                    ruta={this.openModal}
                />
                <ToolbarEnfermeria
                            paciente={`${updateData.paciente ? updateData.paciente.nombre : ''}`}
                            ingreso={updateData.fecha_ingreso}
                            buscar={this.props.set_search_detalle}
                            buscador={this.props.buscador_detalle}
                            total_productos={total_productos}
                            total_servicios={total_servicios}
                            monto={updateData? updateData.monto: 0}
                            />
                <div className="mt-3 col-md-12 con-hijos ">
                <Table
                    onPageChange={listar_detalle}
                    data={data}
                    loading={cargando}
                    pagination
                    expandableRow={isExpandableRow}
                    expandComponent={this.expandComponent}
                    onExpand={this.handleExpand}
                    page={page}>
                            {/*
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({eliminar: quit})}>Acciones</TableHeaderColumn>
                            */}

                            <TableHeaderColumn
                                tdStyle={cellTachado}
                                thStyle={BreakLine}
                                width={'3%'}
                                dataAlign={'center'}
                                dataFormat={numeracionFormat}
                                dataField="numero" dataSort>#</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ quitar:  quitarMedicamento })}>Acciones</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={cellTachado}
                                thStyle={BreakLine}
                                dataField="bodega" dataSort>Bodega</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={cellTachado}
                                thStyle={BreakLine}
                                dataField="usuario_que_agrego" dataSort>Agregado por</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={cellTachado}
                                thStyle={BreakLine}
                                dataFormat={formatMedicamento}
                                dataField="stock" dataSort>Producto/Servicio</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={cellTachado}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={cellTachado}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatAllDate}
                                dataField="creado" dataSort>Registrado</TableHeaderColumn>

                        </Table>
                </div>
            </div>
        )
    }
}

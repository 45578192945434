import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarDevoluciones from 'Utils/Toolbar/ToolbarDevoluciones';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import {formatUsuario, formatNombre, dateFormatter, formatoMoneda} from '../../../Utils/renderField/renderReadField';

function formatCredito(cell, row){
    if(cell){
        return formatoMoneda(row.monto);
    }
    return <span>Reintegro</span>
}
export default class DevolucionGrid extends Component {
    state = {
        activeTab: 'devoluciones'
    }
componentWillMount(){
    this.props.getProveedoresSelect();
    this.props.listar(this.props.page);
    this.props.getTotalCreditos();
}
toggle = (tab) => {
    if(this.props.activeTab !== tab){
        this.props.setTab(tab)
        // this.setState({activeTab: tab})
        if(tab === 'devoluciones'){
            this.props.listar(this.props.page);
        }else{
            this.props.listarcreditos(this.props.page_cred);
        }
    }
}
render() {
    const {
        listar,
        detail,
        create,
        update,
        destroy,
    } = this.props;
    const {
        cargando,
        page,
        data,
        total_creditos,
        filtro_dev,
        proveedores_select,
        creditos,
        listarcreditos
    } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <HeaderSimple
            icono={iconsh.crud_devoluciones}
            titulo={'Devoluciones y créditos'}
            texto={"Crear devolución "}
            ruta={"/devolucion"}
        />
        <ToolbarDevoluciones
            titulo=""
            total={total_creditos}
            icono={iconsh.usuario}
            valor_filtro={filtro_dev}
            valores_filtro={proveedores_select}
            cambiar_filtro={this.props.filtro}
            buscar={this.props.search}
            buscador={this.props.buscador}
            // tituloBuscador={"Buscar por: Proveedor, Emitida por"}
            placeholder={"Buscar por: Proveedor, Emitida por"}
            />

        <div className="mt-3 col-md-12">
        <Nav tabs className="col-12  px-0 ">
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        style={{wordBreak: 'break-all'}}
                        className={classNames('py-2 text-center',{ active: this.props.activeTab === 'devoluciones' })}
                        onClick={() => { this.toggle('devoluciones'); }}>
                        <h5>Devoluciones</h5>
                    </NavLink>
                </NavItem>
                <NavItem  className="col-3 col-md-2 pl-0 pr-0">
                    <NavLink
                        className={classNames('py-2 text-center',{ active: this.props.activeTab === 'creditos' })}
                        onClick={() => { this.toggle('creditos'); }}>
                        <h5>Créditos</h5>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.props.activeTab} className="mt-0">
                <TabPane  tabId={"devoluciones"}>
                    <LoadMask loading={cargando} dark blur>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver:'/devolucion'})}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatNombre}
                                dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={dateFormatter}
                                dataField="fecha" dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatUsuario}
                                dataField="usuario" dataSort>Emitida por</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatCredito}
                                dataField="vale" dataSort>Crédito</TableHeaderColumn>

                        </Table>
                    </LoadMask>
                </TabPane>
            <TabPane  tabId={"creditos"}>
                    <LoadMask loading={cargando} dark blur>
                        <Table
                            onPageChange={listarcreditos}
                            data={creditos}
                            loading={cargando}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ editar: '/producto', eliminar: destroy })}>Acciones</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre" dataSort>Proveedor</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="credito" dataSort>Crédito</TableHeaderColumn>
                                <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="cre3dito" dataSort></TableHeaderColumn>

                        </Table>
                    </LoadMask>
                </TabPane>
            </TabContent>
        </div>
    </div>
    )
    }
}

import React, { Component } from 'react'
import { iconsh } from  "icons";
import Table from 'Utils/Grid';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { formatAllDate, formatNombre } from '../../../Utils/renderField/renderReadField';

function formatEdad(cell){
    if(cell){
        return <span>{cell.edad}</span>
    }
    return '---'
}

export default class EnfermeriaGrid extends Component {
componentWillMount(){
    this.props.listar(this.props.page);
}
render() {
    const {
        listar,
        detail,
        create,
        update,
        destroy,
        eliminarCuentaUsuario,
    } = this.props;
    const {
        cargando,
        page,
        data
    } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center ">
        <HeaderSimple
                    texto={"Crear cuenta"}
                    ruta={"/enfermeria_cuenta"}
                />
        <ToolbarSimple
            titulo="Enfermería"
            icono={iconsh.crud_enfermeria}
            buscar={this.props.search}
            buscador={this.props.buscador}
            // tituloBuscador={"Buscar por: Nombre"}
            placeholder={"Buscar por: Nombre"}
            // margen = "mt-40"
            />
        <div className="mt-3 col-md-12">
            <Table
                onPageChange={listar}
                data={data}
                loading={cargando}
                page={page}>
                <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="center"
                    dataFormat={activeFormatter({ ver: '/enfermeria_cuenta', quitar: eliminarCuentaUsuario })}>Acciones</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatNombre}
                    dataField="paciente" dataSort>Nombre</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatEdad}
                    dataField="paciente" dataSort>Edad</TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatAllDate}
                    dataField="fecha_ingreso" dataSort>Fecha ingreso</TableHeaderColumn>

            </Table>
        </div>
    </div>
    )
    }
}

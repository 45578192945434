import React, { Component } from 'react'
import { iconsh } from 'icons';
import CajaCard from './CajaCard';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class CajaGrid extends Component {
    componentWillMount(){
        this.props.listar();
    }
    render() {
        const { data, cargando, me } = this.props;
        let cajas = data.results;
        return (
            <div className="container m-0 contenido-principal row ">
                <h5 className="text-uppercase m-0 pr-3 text-left mt-5">
                    <img className="mr-2 pb-1" src={iconsh.crud_caja_venta} height={35} />
                    CAJA
                </h5>
                <div className="col-md-12 background-secondary" style={{ height: 30 }}>

                </div>
                <div className="col-md-12">
                    <LoadMask loading={cargando} blur_1>
                        <div className="col-md-12 row m-0 pt-4 d-flex justify-content-center">
                                {
                                    cajas.map( (x, key) => (
                                        <CajaCard usuario_logueado={me} caja={x} key={key}/>
                                    ))
                                }
                        </div>
                    </LoadMask>
                </div>

            </div>
        )
    }
}

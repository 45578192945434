import React, { Component } from 'react'
import Card from 'Utils/Cards/cardGrande';
import HeaderIngresoBodega from 'Utils/Header/HeaderIngreso';
import DespachoForm from './DespachoForm';
import { api } from 'api/api';

let proveedores = []
export default class DespachoCrear extends Component {
    componentWillMount() {
        this.props.uuidInitial();
    }

    getBodega = (search) => {
        return api.get("bodega", { search }).catch((error) => { })
            .then((data) => {
                if (data) {
                    proveedores = [];
                    data.results.forEach(x => {
                        if(x.id != this.props.match.params.id){
                            proveedores.push({ value: x.id, label: x.nombre })
                        }
                    })
                    return proveedores
                }
                else {
                    return []
                }
            })
            .catch(e => {
                return []
            })
    }
    render() {
        const { nombreBodega, updateData, crearDespacho, regresar,initCantidad } = this.props;
        return (
            <div className="container m-0 contenido-principal row d-flex justify-content-center">
                <div className="col-md-12">
                <HeaderIngresoBodega
                        principal={updateData.principal ? updateData.principal : false}
                        titulo={`${nombreBodega}`}
                        subtitulo={`Despacho`}
                    />
                <div className="col-md-12">
                    <DespachoForm
                        bodega={updateData}
                        getBodega={this.getBodega}
                        regresar={regresar}
                        onSubmit={crearDespacho}
                        initCantidad={initCantidad}/>
                </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import FormUno from './FormUno';
import FormDos from './FormDos';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class AutorizacionDoble extends Component {
    state = {
        page: 1
    }
    componentDidMount() {
        const { encargado } = this.props;
        if(encargado){
            this.setState({page: 1})
        }else{
            this.setState({page:2})
        }
    }
    nextPage = () =>{
        // this.setState({page: this.state.page + 1 })
        // se lanza la funcion para verifica contrasenia
        this.props.verificarUsuario();
    }
    previousPage = () => {
        this.setState({page: this.state.page - 1 })
    }
    render() {
        const { onSubmit, cargando, bodega, encargado } = this.props
        const { page } = this.state
        return (
            <div >
                <Modal open={this.props.modal} onClose={this.props.closeModal} >
                    <LoadMask loading={cargando} blur_1>
                        {this.props.verificacion_usuario === false && <FormUno bodega={bodega} initialValues={{}} usuario={encargado} onSubmit={this.nextPage} />}
                        {
                            this.props.verificacion_usuario === true && (
                                <FormDos
                                    initialValues={{}}
                                    bodega={bodega}
                                    previousPage={this.previousPage}
                                    onSubmit={onSubmit}/>
                            )
                        }
                    </LoadMask>
                </Modal>
            </div>
        )
    }
}

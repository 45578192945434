import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderTextArea } from 'Utils/renderField/renderField';
import { RenderCurrency, dateFormatter } from 'Utils/renderField/renderReadField'

let ReajusteForm = props => {
    const { handleSubmit, ver_reajuste, inventario } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8 row justify-content-center">
                            <div className="row col-md-10">
                                <div className="row col-md-6">
                                    <div className="col-8 text-gris text-right">
                                        REAJUSTE
                                    </div>
                                    <div className="col-md-2 col-2">
                                        <Field name="reajuste"  disabled={false}
                                        component="input" type="radio" value={'true'}/>
                                    </div>

                                </div>
                                <div className="row col-md-6">
                                    <div className="col-8 text-gris text-right">
                                        BAJA
                                    </div>
                                    <div className="col-md-2 col-2">
                                        <Field name="reajuste"  disabled={false}
                                        component="input" type="radio" value={'false'}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="lote" className="m-0">Lote:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <span className="text-gris font-weight-light">{inventario ? inventario.lote.lote : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="lote" className="m-0">Vencimiento:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <span className="text-gris font-weight-light">{inventario ? dateFormatter(inventario.lote.fecha_vencimiento) : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12 text-center">
                                <label htmlFor="actual" className="m-0">Cantidad Actual:</label>
                            </div>
                            <div className="col-md-12  form-group text-center">
                                <span className="text-primary font-weight-bold">{inventario ? inventario.existencias : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cantidad" className="m-0">Cantidad*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="cantidad" component={renderField} type="number" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="Nota" className="m-0">Nota*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nota" component={renderTextArea} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button" className="btn btn-secondary m-1"
                                    onClick={e => {
                                        e.preventDefault();
                                        props.cancelar();
                                    }}
                                    >Cancelar</button>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
ReajusteForm = reduxForm({
    form: 'reajuste',
    validate: data => {
        return validate(data, {
            'nota': validators.exists()('Campo requerido.'),
            'cantidad': validators.exists()('Campo requerido.'),
        })
    },
    initialValues:{
        reajuste: 'true'
    }
})(ReajusteForm);

const selector = formValueSelector('reajuste');
ReajusteForm = connect(state => {
    const reajuste = selector(state, "reajuste");
    let ver_reajuste = false;
    if(reajuste == 'true'){
        ver_reajuste = true;
    }
    return {
        ver_reajuste
    };
})(ReajusteForm);
export default ReajusteForm

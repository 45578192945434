import Swal from 'sweetalert2';
import { api } from '../../../api/api';
import { createActions, handleActions } from 'redux-actions';
import { MESES } from '../../../common/utility/constants';
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import _ from 'lodash';

import moment from 'moment';
import { dateFormatter } from '../../../common/components/Utils/renderField/renderReadField'

const url = 'reporte';

const LOADER_RCAJA = 'LOADER_RCAJA';
const SET_DATA_RCAJA = 'SET_DATA_RCAJA';
const SET_DATAGRAFICA_RCAJA = 'SET_DATAGRAFICA_RCAJA';
const SET_PAGE_RCAJA = 'SET_PAGE_RCAJA';
const SET_UPDATE_DATA_RCAJA ='SET_UPDATE_DATA_RCAJA';
const SET_BUSCADOR_RCAJA = 'SET_BUSCADOR_RCAJA';
const SET_FILTRO_RCAJA = 'SET_FILTRO_RCAJA';
const SET_TOTALRECAUDADO_RCAJA = 'SET_TOTALRECAUDADO_RCAJA';

const SET_STARTDATE_RCAJA = 'SET_STARTDATE_RCAJA';
const SET_ENDDATE_RCAJA = 'SET_ENDDATE_RCAJA';

function obtenerFechas(dateStart, dateEnd){
    let params = {};
    const inicio_mes = moment(String(dateStart)+'-'+String(dateEnd)+'-'+'01').startOf('month').format('YYYY-MM-DD');
    const fin_mes   = moment(String(dateStart)+'-'+String(dateEnd)+'-'+'01').endOf('month').format('YYYY-MM-DD');
    if (dateStart) {
        try {
            dateStart = inicio_mes;
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if (dateEnd) {
        try {
            dateEnd = fin_mes;
        } catch (error) {
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }
    return params;
}

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCAJA, cargando: true });
    const store = getStore();
    const search = store.rcaja.buscador;
    const filtro_caja = store.rcaja.filtro_rcaja;

    let dateStart = store.rcaja.dateStart;
    let dateEnd = store.rcaja.dateEnd

    let params = { page, search };

    if(filtro_caja){
        params['caja__id'] = filtro_caja.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart.value, dateEnd.value)
    }

    api.get(`${url}/detalle_caja`, params).catch((error) => {
        dispatch({ type: LOADER_RCAJA, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATA_RCAJA, data: data });
            dispatch({type: SET_PAGE_RCAJA,page: page});
        }
        dispatch({ type: LOADER_RCAJA, cargando: false });
    })
};
const getGraficaCaja = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_RCAJA, cargando: true });
    const store = getStore();
    const search = store.rcaja.buscador;
    const filtro_caja = store.rcaja.filtro_rcaja;

    let dateStart = store.rcaja.dateStart;
    let dateEnd = store.rcaja.dateEnd

    let params = { page, search };

    if(filtro_caja){
        params['caja__id'] = filtro_caja.id;
    }

    params = {
        ...params,
        ...obtenerFechas(dateStart.value, dateEnd.value)
    }
    api.get(`${url}/grafica_caja`, params).catch((error) => {
        dispatch({ type: LOADER_RCAJA, cargando: false });
        Swal(
            'Error',
            (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({ type: SET_DATAGRAFICA_RCAJA, data_grafica: data.grafica });
            dispatch({type: SET_TOTALRECAUDADO_RCAJA, total_recaudado: data.total_recaudado});
        }
        dispatch({ type: LOADER_RCAJA, cargando: false });
    })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_RCAJA, buscador: search});
    dispatch(listar(1));
};
const setFiltro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_RCAJA, filtro_rcaja: filtro});
    dispatch(getGraficaCaja());
    dispatch(listar(1));
};
const setDateStart = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_STARTDATE_RCAJA, dateStart: date });
    dispatch(getGraficaCaja());
    dispatch(listar(1));
}
const setDateEnd = (date) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({ type: SET_ENDDATE_RCAJA, dateEnd: date });
    dispatch(getGraficaCaja());
    dispatch(listar(1));
}

export const actions = {
    listar,
    getGraficaCaja,
    search,
    setFiltro,
    setDateStart,
    setDateEnd
};
export const reducer = {
    [LOADER_RCAJA]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_RCAJA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_RCAJA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_RCAJA]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_RCAJA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_RCAJA]: (state, { filtro_rcaja }) => {
        return {...state, filtro_rcaja }
    },
    [SET_DATAGRAFICA_RCAJA]: (state, { data_grafica }) => {
        return {...state, data_grafica }
    },
    [SET_STARTDATE_RCAJA]: (state, { dateStart }) => {
        return {...state, dateStart }
    },
    [SET_ENDDATE_RCAJA]: (state, { dateEnd }) => {
        return {...state, dateEnd }
    },
    [SET_TOTALRECAUDADO_RCAJA]: (state, { total_recaudado }) => {
        return {...state, total_recaudado }
    }
}
// Se obtiene el mes actual
let mes_actual = moment().format('YYYY-MM-DD');
mes_actual = mes_actual.split('-');
// Se hace la busqueda para setear el mes actual
let mes_busqueda = _.find(MESES, { value: mes_actual[1] });

export const initialState = {
    cargando: false,
    page: 1,
    data_grafica:[],
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    total_recaudado: 0,
    filtro_rcaja: null,
    updateData: {},
    dateStart: {value: moment().year(), label: moment().year()},
    dateEnd: {value: mes_actual[1], label: mes_busqueda.label}
};
export default handleActions(reducer, initialState)

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reportes/rporpagar';
import Rporpagar from './Rporpagar';

const mstp = state => {
    return {...state.rporpagar}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Rporpagar)

import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderFieldCheck, renderSwitch, renderCurrency, renderPercentage } from 'Utils/renderField/renderField';

class RenderPrecio extends React.Component{

    render(){
        const {cambioDescuentoUnitario} = this.props;
        return (
            <Fragment>
                <div className="col-md-3">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="precioetiqueta" className="m-0">Precio etiqueta*:</label>
                    </div>
                    <div className="col-md-12  form-group">
                        <Field
                                name={`precio`}
                                type="number"
                                addClass={"text-right"}
                                component={renderCurrency}
                                placeholder="precio descuento"
                                _onChange={(e) => cambioDescuentoUnitario(e, 'precio')}
                            />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="porcentaje" className="m-0">(%) de descuento:</label>
                    </div>
                    <div className="col-md-12  text-center text-gris ">
                        <Field
                                name={`porcentaje`}
                                type="number"
                                addClass={"text-right"}
                                component={renderPercentage}
                                placeholder="Porcentaje"
                                _onChange={(e) => cambioDescuentoUnitario(e, 'porcentaje')}
                            />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="preciodescuento" className="m-0">Precio descuento*:</label>
                    </div>
                    <div className="col-md-12  form-group">
                        <Field
                                name={`precio_descuento`}
                                type="number"
                                addClass={"text-right"}
                                component={renderCurrency}
                                placeholder="Precio decuento"
                                _onChange={(e) => cambioDescuentoUnitario(e, 'precio_descuento')}
                            />
                    </div>
                </div>

            </Fragment>
        )
    }
}

let FraccionForm= props => {
    const { handleSubmit } = props;
    const { producto, fracciones, lugar, posicion, primero,
        ultimo, siguiente, editar,
        vendible,
        alerta,
        cambioDescuentoUnitario,
        porcentaje
    } = props;
    let nombre_primero = primero ? primero.presentacion : '';
    let nombre_ultimo = ultimo ? ultimo.presentacion : '';
    let nombre_siguiente = siguiente ? siguiente.presentacion: '';
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    {
                        !editar && fracciones && fracciones.length <= 1 &&(
                            <h3 className="text-primary font-weight-bold text-center">
                                {producto.nombre}  {lugar && lugar === 'primero' ? nombre_primero : nombre_ultimo}
                            </h3>
                        )
                    }
                    {
                         !editar && fracciones && fracciones.length > 1 && (
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-12 row d-flex justify-content-center ">
                                     <div className="col-md-5">
                                        <h3 className="text-primary font-weight-bold text-center">
                                            {producto.nombre}  {lugar && lugar === 'primero' ? nombre_primero : nombre_ultimo}
                                        </h3>
                                    </div>
                                    {
                                        lugar && lugar === 'primero' ? (
                                            <div className="row col-md-5">
                                                <div className="col-md-2 col-2">
                                                    <Field name="posicion"  disabled={false}
                                                    component="input" type="radio" value={'arriba'}/>
                                                </div>
                                                <div className="col-10 text-gris">
                                                    PRESENTACIÓN MAYOR
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row col-md-5">
                                                <div className="col-md-2 col-2">
                                                    <Field name="posicion"  disabled={false}
                                                    component="input" type="radio" value={'abajo'}/>
                                                </div>
                                                <div className="col-10 text-gris">
                                                    PRESENTACIÓN MENOR
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        !editar && fracciones && fracciones.length == 1 && (
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-12 row d-flex justify-content-center ">

                                            <div className="row col-md-5">
                                                <div className="col-md-2 col-2">
                                                    <Field name="posicion"  disabled={false}
                                                    component="input" type="radio" value={'arriba'}/>
                                                </div>
                                                <div className="col-10 text-gris">
                                                    PRESENTACIÓN MAYOR
                                                </div>
                                            </div>
                                            <div className="row col-md-5">
                                                <div className="col-md-2 col-2">
                                                    <Field name="posicion"  disabled={false}
                                                    component="input" type="radio" value={'abajo'}/>
                                                </div>
                                                <div className="col-10 text-gris">
                                                    PRESENTACIÓN MENOR
                                                </div>
                                            </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="row d-flex justify-content-left">
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="presentacion" className="m-0">Presentación*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="presentacion" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="costo" className="m-0">Costo*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                        name={`costo`}
                                        type="number"
                                        addClass={"text-right"}
                                        component={renderCurrency}
                                        placeholder="costo"
                                    />
                            </div>
                        </div>
                        {
                            posicion === 'arriba' && fracciones.length > 0  && (
                                <div className="col-md-4">
                                    <div className="col-md-12 text-left text-md-center col-sm-12">
                                        <label htmlFor="presentacion" className="m-0">Fracción contenida:</label>
                                    </div>
                                    <div className="col-md-12  form-group text-center">
                                        <span className="text-primary">{nombre_primero}</span>
                                    </div>
                                </div>
                            )
                        }

                        {
                        fracciones && fracciones.length > 0 && (

                                posicion === 'arriba' ? (
                                    <div className="col-md-4">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="capacidad_maxima_abajo" className="m-0">No. fracciones:</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="capacidad_maxima_arriba" component={renderField} type="number" className="form-control"/>
                                        </div>
                                    </div>
                                ):(
                                    <div className="col-md-4">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="capacidad_maxima_abajo" className="m-0">No. fracciones:</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="capacidad_maxima_abajo" component={renderField} type="number" className="form-control"/>
                                        </div>
                                    </div>
                                )


                        )}

                    </div>
                    <div className="row">
                        <div className="row col-md-3 m-0 p-0 pl-2">
                            <div className="col-auto m-0 ml-3 form-group label ">
                                <Field name="vendible" component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                    placeholder="vendible"/>
                            </div>
                            <div className="p-0 ml-3  ml-sm-0  col  pt-1">
                                <span className="text-primary font-weight-bold text-uppercase">Vendible</span>
                            </div>
                        </div>
                        {
                            vendible && (
                                <RenderPrecio {...props} />
                            )
                        }

                    </div>
                    <div className="row mt-2 pt-2">
                        <div className="row col-md-4 m-0 p-0">
                            <div className="col-md-8 text-gris">
                                Alerta de existencias mínimas:
                            </div>
                            <div className="col-md-4">
                                <Field name="alerta" component={renderSwitch} type="text" className="form-control" />
                            </div>

                        </div>
                        {
                            alerta && (
                                <Fragment>
                                    <div className="col-md-3">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="minimo" className="m-0">Mínimo en bodega*:</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="minimo_existencias" component={renderField} type="number" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="minimo_farmacia" className="m-0">Mínimo en farmacia*:</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="minimo_existencias_farmacia" component={renderField} type="number" className="form-control"/>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }

                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    onClick={e => {
                                        e.preventDefault();
                                        props.cerrar();
                                    }}
                                    className="btn btn-secondary m-1">Cancelar</button>
                                <button type="submit"  className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
FraccionForm = reduxForm({
    form: 'fraccion',
    validate: data => {
        return validate(data, {
            'presentacion': validators.exists()("Campo requerido."),
            'capacidad_maxima': validators.exists()("Campo requerido."),
            'capacidad_maxima_abajo': validators.exists()("Campo requerido."),
            'capacidad_maxima_arriba': validators.exists()("Campo requerido."),
            'precio': validators.exists()("Campo requerido."),
            'precio_descuento': validators.exists()("Campo requerido."),
            'minimo_existencias': validators.exists()("Campo requerido."),
            'minimo_existencias_farmacia': validators.exists()("Campo requerido."),
        })
    },
    asyncBlurFields:[]
})(FraccionForm);
const selector = formValueSelector('fraccion');
FraccionForm = connect(state => {
    const posicion = selector(state, "posicion");
    const vendible = selector(state, "vendible");
    const alerta = selector(state, "alerta");

    const precio = selector(state, "precio")
    const precio_descuento = selector(state, "precio_descuento")
    let valores_formulario;
    let porcentaje = 0;
    if( precio && precio_descuento){
        porcentaje = Number((100 * Number(precio_descuento) )/ Number(precio));
        porcentaje = 100 - porcentaje;
        porcentaje = porcentaje.toFixed(1);
    }
    if(state.form.fraccion){
        if(state.form.fraccion.values){
            valores_formulario = state.form.fraccion.values;
        }
    }
    return {
        posicion,
        vendible,
        alerta,
        porcentaje,
        valores_formulario
    };
})(FraccionForm);
export default FraccionForm



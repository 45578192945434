import { iconsh } from 'icons';
import classNames from 'classnames';
import DetalleVenta from '../../Ventas';
import React, { Component } from 'react';
import Productos from '../../Ventas/Productos';
import TituloBodega from 'Utils/Header/TituloBodega';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Caja from '../../Ventas/Caja';
import HistorialVentas from '../../Ventas/HistoriaVentas';
export default class PuntoVenta extends Component {
    state = {
        activeTab:'venta'
    }
    componentDidMount(){
        const { me } = this.props;
        if(me.apertura){
            this.props.getInfoCaja();
        }
        this.props.getInventario();
    }
    componentDidUpdate(prevProps){
        const { me } = this.props;
        if(this.props.me !== prevProps.me){
            this.props.getInfoCaja();
        }
    }
    toggle = (tab) => {
        if(this.state.activeTab !== tab){
            // this.props.setTab(tab)
            this.setState({activeTab: tab})
            if(tab === 'venta'){
                // this.props.listar(1);
            }else{
                this.props.setIdApertura(null);
                this.props.getInfoCaja();
                this.props.getInventario();
                this.props.listarMovimientos();
                // this.props.listarcreditos(1);
            }
        }
    }
  render() {
    const { caja } = this.props;
    return (
      <div className="col-md-12 row m-0 p-0 d-flex justify-content-between">
          {/* Inicio sección derecha */}
          <div className="col-lg-6 px-1">
            <div className="col-md-12 p-0 container m-0 contenido-principal h85">
                <div className="col-md-12 p-0 row m-0 pt-4">
                    <TituloBodega
                        titulo={'Caja y ventas'}
                        subtitulo={`${caja.caja ? caja.caja.nombre:''}`}
                        estilo={`col-md-6`}
                        icono={iconsh.crud_caja_venta}
                        />
                    <Nav tabs className="col-md-6  px-0" style={{height: 40}}>
                        <NavItem  className="col-6 col-md-6 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center',{ active: this.state.activeTab === 'venta' })}
                                onClick={() => { this.toggle('venta'); }}>
                                <h5>Venta</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem  className="col-6 col-md-6 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center',{ active: this.state.activeTab === 'caja' })}
                                onClick={() => { this.toggle('caja'); }}>
                                <h5>Caja</h5>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="col-md-12">
                    <TabContent activeTab={this.state.activeTab} className="mt-2">
                        <TabPane  tabId={"venta"}>
                            <Productos />
                        </TabPane>
                        <TabPane  tabId={"caja"}>
                            <Caja />
                        </TabPane>
                    </TabContent>
                </div>

            </div>
          </div>
          {/* Fin sección derecha */}
          {/* inicio sección izquierda */}
          <div className="col-lg-6  px-1 mt-lg-0 mt-2">
                <div className="col-md-12 container m-0 contenido-principal row h85">
                    <div className="col-md-12">
                        <TabContent activeTab={this.state.activeTab} className="mt-0">
                            <TabPane  tabId={"venta"}>
                                <DetalleVenta />
                            </TabPane>
                            <TabPane  tabId={"caja"}>
                                <HistorialVentas />
                            </TabPane>
                        </TabContent>
                    </div>

                </div>
          </div>
          {/* fin sección izquierda */}
      </div>
    )
  }
}


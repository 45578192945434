import React, { Component } from 'react'
import { iconsh } from "icons";
import Table from 'Utils/Grid';
import ToolbarSimple from 'Utils/Toolbar/ToolbarSimple';
import { BreakLine } from '../../../Utils/tableOptions';
import HeaderSimple from 'Utils/Header/HeaderSimple';
import { activeFormatter } from 'Utils/Acciones/Acciones'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { formatoMoneda, checkFormatter, formatAllDate, formatNombre } from '../../../Utils/renderField/renderReadField';


export default class CuentaGrid extends Component {
    state = {
        activeTab: 'activas'
    }
    componentWillMount() {
        if (this.props.tab === 'activas') {
            this.props.listar(this.props.page);
        } else {
            this.props.listar_cerrada(this.props.page_cerrada);
        }
    }
    toggle = (tab) => {
        if (this.props.tab !== tab) {
            this.props.setTab(tab)
            if (tab === 'activas') {
                this.props.listar(this.props.page);
            } else {
                this.props.listar_cerrada(this.props.page_cerrada);
            }
        }
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;
        const {
            cargando,
            page,
            page_cerrada,
            data,
            data_cerradas
        } = this.props;
        return (
            <div className="container m-0 contenido-principal row d-flex justify-content-center">
                <HeaderSimple
                    texto={"Crear cuenta"}
                    ruta={"/reporte_cuenta"}
                />
                <ToolbarSimple
                    titulo="Cuentas pacientes"
                    icono={iconsh.crud_paciente}
                    buscar={this.props.search}
                    buscador={this.props.buscador}
                    // tituloBuscador={"Buscar por: Nombre, Cuenta"}
                    placeholder={"Buscar por: Nombre, Cuenta"}
                    // margen = "mt-40"
                />
                <div className="mt-3 col-md-12">
                    <Nav tabs className="col-12  px-0 ">
                        <NavItem className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center', { active: this.props.tab === 'activas' })}
                                onClick={() => { this.toggle('activas'); }}>
                                <h5>Activas</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem className="col-3 col-md-2 pl-0 pr-0">
                            <NavLink
                                className={classNames('py-2 text-center', { active: this.props.tab === 'cerradas' })}
                                onClick={() => { this.toggle('cerradas'); }}>
                                <h5>Cerradas</h5>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.props.tab} className="mt-0">
                        <TabPane tabId={"activas"}>
                            <LoadMask loading={cargando} dark blur>
                                <Table
                                    onPageChange={listar}
                                    data={data}
                                    loading={cargando}
                                    page={page}>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="no_orden" dataSort>Cuenta</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatNombre}
                                        dataField="paciente" dataSort>Nombre</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatAllDate}
                                        dataField="fecha_ingreso" dataSort>Fecha Ingreso</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'center'}
                                        dataFormat={formatoMoneda}
                                        dataField="monto" dataSort>Monto</TableHeaderColumn>

                                </Table>
                            </LoadMask>
                        </TabPane>
                        <TabPane tabId={"cerradas"} className="">
                            <LoadMask loading={cargando} dark blur>
                                <Table
                                    onPageChange={this.props.listar_cerrada}
                                    data={data_cerradas}
                                    loading={cargando}
                                    page={page_cerrada}>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={activeFormatter({ ver: '/reporte_cuenta' })}>Acciones</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="no_orden" dataSort>Cuenta</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatNombre}
                                        dataField="paciente" dataSort>Nombre</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={formatAllDate}
                                        dataAlign={'right'}
                                        dataField="fecha_ingreso" dataSort>Fecha Ingreso</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign={'center'}
                                        dataFormat={formatoMoneda}
                                        dataField="monto" dataSort>Monto</TableHeaderColumn>

                                </Table>
                            </LoadMask>
                        </TabPane>
                    </TabContent>

                </div>
            </div>
        )
    }
}

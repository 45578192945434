import React from 'react'
import { iconsh } from "icons";

export default function TituloBodega(props) {
  return (
    <div className={`${props.estilo}`} >
        <h5 className="text-primary text-md-left text-center text-uppercase m-0 pl-md-4 ml-md-3">
            {props.titulo}
        </h5>
        <h3 className="text-md-left text-uppercase text-center m-0">
            <img className="mr-2 pb-1" src={ props.icono ? props.icono : iconsh.crud_bodegas} height={25} />
            {props.subtitulo}
        </h3>
    </div>
  )
}

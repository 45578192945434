import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"

export default class ToolbarSearch extends Component {
  render() {
      const { half } = this.props;
    return (
      <div className={`col-12 row ${half ? 'p-0 m-0':''} d-flex justify-content-md-between justify-content-center align-items-center`}>
            {
              this.props.titulo && (
                    <div className="col-md-4 text-center text-md-left">
                        <h5 className="text-uppercase text-center text-md-left m-0">{this.props.titulo}</h5>
                        <span className="text-gris ">{this.props.subtitulo}</span>
                    </div>
                )
            }
            <div className={`${half ? 'col-lg-6 col-md-6':'col-lg-4 col-md-4'} p-0 search`} >
              {(this.props.tituloBuscador &&
                <span className="ml-2 text-primary mt-5 mt-md-0 mt-lg-0"><strong>{this.props.tituloBuscador}</strong></span>
              )}
                {(this.props.buscar) && (
                <Search 
                  buscar={this.props.buscar} 
                  buscador={this.props.buscador} 
                  placeholder={this.props.placeholder}
                />
                )}
            </div>
      </div>
    )
  }
}

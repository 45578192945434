import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSwitch, renderNumber } from 'Utils/renderField/renderField';
import  renderDate from 'Utils/renderField/renderDate';

let PacienteForm = props => {
    const { handleSubmit, crear } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">Nombre*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nombre" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha nacimiento" className="m-0">Fecha nacimiento*:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                        name={`fecha_nacimiento`}
                                        className=""
                                        component={renderDate}
                                        placeholder="Fecha"
                                        numberOfMonths={1}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="telefono" className="m-0">Teléfono:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="telefono" numberFormat="#### ####" component={renderNumber}
                                       type="number" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="direccion" className="m-0">Dirección:</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="direccion" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    {
                        crear && (
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="col-auto text-gris">
                                        Abrir cuenta:
                                    </div>
                                    <div className="col-md-4">
                                        <Field name="abrir_cuenta" component={renderSwitch} type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link className="btn btn-secondary m-1" to="/enfermeria_pacientes">Cancelar</Link>
                                <button type="submit" className="btn btn-primary m-1">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
PacienteForm = reduxForm({
    form: 'paciente',
    validate: data => {
        return validate(data, {
        })
    },
    initialValues: {
        abrir_cuenta: true
    }
})(PacienteForm);
export default PacienteForm

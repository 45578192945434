import { createAction, handleActions } from 'redux-actions';
import { api } from '../../api/api';
import { actions as actionsSolicitudes} from './Compras/compras';
import { actions as actionsEB } from './Bodega/estadobodega';
import { rejects } from 'assert';
import { ToastsStore } from 'react-toasts';
import Swal from 'sweetalert2';

const INITIAL = 'INITIAL';
const SUBMIT = 'SUBMIT';
const LOGIN = 'LOGIN';
const NAME_ERROR = 'NAME_ERROR';
const PASS_ERROR = 'PASS_ERROR';
const LOGIN_LOADER = 'LOGIN_LOADER';
const SUBMIT_ERROR = 'SUBMIT_ERROR';
const DIFERENTE = 'DIFERENTE';
const MENSAJE_LOGIN = 'MENSAJE_LOGIN';
const ME = 'ME';

const SET_PRODUCTOS_POR_VENCER_FARMACIA = 'SET_PRODUCTOS_POR_VENCER_FARMACIA';
const SET_PRODUCTOS_POR_VENCER_HOSPITAL = 'SET_PRODUCTOS_POR_VENCER_HOSPITAL';
const SET_CUENTAS_POR_PAGAR = "SET_CUENTAS_POR_PAGAR";
const SET_GASTOS_INGRESOS = "SET_GASTOS_INGRESOS";
const SET_CUENTAS_POR_COBRAR = "SET_CUENTAS_POR_COBRAR";
const SET_VENTAS_POR_AREA = "SET_VENTAS_POR_AREA"

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Actions
// ------------------------------------
/* Funcion para simular el login, ELIMINAR */
function logInMock(dispatch) {
    sessionStorage.setItem('token', '123');
    dispatch({ type: LOGIN_LOADER, loader: false });
    dispatch({ type: SUBMIT, autenticado: true });
}

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    let canLog = true;
    dispatch({ type: LOGIN_LOADER, loader: true });
    dispatch({ type: SUBMIT_ERROR, submitError: false });
    if (data.username === "" || data.username === undefined) {
        canLog = false;
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
    if (data.password === "" || data.password === undefined) {
        canLog = false;
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
    if (canLog) {
        // Esto debiera hacer una peticion a un API
        api.post("users/token", data)
            .catch((error) => {
                dispatch({ type: LOGIN_LOADER, loader: false });
                dispatch({ type: SUBMIT_ERROR, submitError: true });
                dispatch({ type: MENSAJE_LOGIN, mensajeLogin: error.detail });
                // ToastsStore.error('Verifique que ha ingresado bien su nombre de usuario y contraseña.');
                Swal(
                    'Error',
                    (error && error.detail) ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            })
            .then(resp => {
                if (resp) {
                    sessionStorage.setItem("token", resp.token);
                    dispatch({ type: SUBMIT, autenticado: true });
                    dispatch({ type: LOGIN_LOADER, loader: false });
                }
            });
    } else {
        dispatch({ type: LOGIN_LOADER, loader: false });
        dispatch({ type: SUBMIT, autenticado: false });
    }
    // dispatch({ type: LOGIN_LOADER, loader: true });
    // dispatch({ type: SUBMIT_ERROR, submitError: false });

    // if (canLog) {
    //     // Esto debiera hacer una peticion a un API
    //     setTimeout(logInMock, 2000, dispatch);
    // } else {
    //     dispatch({ type: LOGIN_LOADER, loader: false });
    //     dispatch({ type: SUBMIT, autenticado: false });
    // }
};




export const getMe = () => (dispatch, getStore) => {

    api.get(`users/me`).catch((error) => {
        sessionStorage.removeItem("token");
        dispatch({ type: SUBMIT, autenticado: false });

        window.location.assign("/");
    }).then((data) => {
        dispatch(actionsSolicitudes.getNotificacionA());
        dispatch(actionsSolicitudes.getNotificacionR());
        dispatch(actionsSolicitudes.getNotificacionP());
        dispatch(actionsEB.getNotificacion())
        dispatch({type: LOGIN_LOADER, loader: false});
        dispatch({type: ME, me: data})
    });
};

export const cambioTurno = (data) => (dispatch, getStore) => {
    sessionStorage.setItem("token", data.token);
    dispatch({type: ME, me: data.me})
    dispatch(actionsEB.getNotificacion());
}
export const irLogin = login => ({
    type: LOGIN,
    login,
});

export const autenticar = autenticado => ({
    type: SUBMIT,
    autenticado,
});


export const logOut = () => (dispatch) => {
    sessionStorage.removeItem('token');
    dispatch({ type: SUBMIT, autenticado: false });
    window.location.assign("/");
};

export const initialLoad = createAction(INITIAL);
export const hasNameError = nameError => ({
    type: NAME_ERROR,
    nameError,
});
export const hasPassError = passError => ({
    type: PASS_ERROR,
    passError,
});
export const diferentPass = diferente => ({
    type: DIFERENTE,
    diferente,
});

export const productosPorVencerFarmacia = () => (dispatch, getStore) => {
    api.get(`dashboard/getProductosVencer`, {farmacia:true}).catch((error) => {
        console.log("erros: ", error);        
    }).then((data) => {
        const productos_por_vencer_farmacia = {
            dos_meses: data[0].values[3].value,
            un_mes: data[0].values[2].value,
            vencidos: data[0].values[1].value,
        }
        dispatch({type: SET_PRODUCTOS_POR_VENCER_FARMACIA, productos_por_vencer_farmacia});
        //console.log("Productos farmacia: ", productos_por_vencer_farmacia);        
    });    
}

export const productosPorVencerHospital = () => (dispatch, getStore) => {
    api.get(`dashboard/getProductosVencer`, {farmacia:false}).catch((error) => {
        console.log("erros: ", error);        
    }).then((data) => {
        const productos_por_vencer_hospital = {
            dos_meses: data[0].values[3].value,
            un_mes: data[0].values[2].value,
            vencidos: data[0].values[1].value,
        }
        dispatch({type: SET_PRODUCTOS_POR_VENCER_HOSPITAL, productos_por_vencer_hospital});
        console.log("Productos hospital: ", productos_por_vencer_hospital);        
    });    
}

export const cuentasPorPagar = () => (dispatch, getStore) => {
    api.get(`dashboard/cuentasPorPagar`).catch((error) => {
        console.log("erros: ", error);        
    }).then((data) => {
        const cuentas_por_pagar = {
            cantidad: data.cantidad_total,
            total: data.suma_total.toFixed(2),
        }

        dispatch({type: SET_CUENTAS_POR_PAGAR, cuentas_por_pagar});             
    });  
}

export const gastosIngresos = () => (dispatch, getStore) => {
    api.get(`dashboard/gastosIngresos`).catch((error) => {
        console.log("erros: ", error);        
    }).then((data) => {
        const gastos_ingresos = {
            gastos: data.gastos.toFixed(2),
            ingresos: data.ingresos.toFixed(2),
        }
        
        dispatch({type: SET_GASTOS_INGRESOS, gastos_ingresos});        
    });  
}

export const cuentasPorCobrar = () => (dispatch, getStore) => {
    api.get(`dashboard/cuentasPorCobrar`).catch((error) => {
        console.log("erros: ", error);        
    }).then((data) => {
        console.log("cuentas por cobrar: ", data)
        const cuentas_por_cobrar = {
            total: data.total.toFixed(2),
            cantidad: data.cantidad,
        }
        
        dispatch({type: SET_CUENTAS_POR_COBRAR, cuentas_por_cobrar});        
    });  
}

export const ventasPorArea = () => (dispatch, getStore) => {
    api.get(`dashboard/ventasPorArea`).catch((error) => {
        console.log("erros: ", error);        
    }).then((data) => {
        console.log("ventas por área: ", data)
        
        const ventas_por_area = {
            total_farmacia: data[0].total,
            total_hospital: data[1].total,
        }
        
        dispatch({type: SET_VENTAS_POR_AREA, ventas_por_area});        
    });  
}


export const actions = {
    initialLoad,
    hasNameError,
    hasPassError,
    onSubmit,
    logOut,
    cambioTurno,
    productosPorVencerFarmacia,
    productosPorVencerHospital,
    cuentasPorPagar,
    gastosIngresos,
    cuentasPorCobrar,
    ventasPorArea,
};

export const reducers = {
    [INITIAL]: (state) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            return {
                ...state,
                redirect: true,
            };
        }
        return {
            ...state,
            redirect: false,
        };
    },
    [SUBMIT]: (state, { autenticado }) => {
        return {
            ...state,
            autenticado,
        };
    },
    [NAME_ERROR]: (state, { nameError }) => {
        return {
            ...state,
            nameError,
        };
    },
    [PASS_ERROR]: (state, { passError }) => {
        return {
            ...state,
            passError,
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUBMIT_ERROR]: (state, { submitError }) => {
        return {
            ...state,
            submitError,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
    [MENSAJE_LOGIN]: (state, { mensajeLogin }) => {
        return {...state, mensajeLogin }
    },
    [SET_PRODUCTOS_POR_VENCER_FARMACIA]: (state, { productos_por_vencer_farmacia }) => {
        return {...state, productos_por_vencer_farmacia }
    },
    [SET_PRODUCTOS_POR_VENCER_HOSPITAL]: (state, { productos_por_vencer_hospital }) => {
        return {...state, productos_por_vencer_hospital }
    },
    [SET_CUENTAS_POR_PAGAR]: (state, {cuentas_por_pagar}) => {
        return {...state, cuentas_por_pagar}
    },
    [SET_GASTOS_INGRESOS]: (state, {gastos_ingresos}) => {
        return {...state, gastos_ingresos}
    },     
    [SET_CUENTAS_POR_COBRAR]: (state, {cuentas_por_cobrar}) => {
        return {...state, cuentas_por_cobrar}
    },
    [SET_VENTAS_POR_AREA]: (state, {ventas_por_area}) => {
        return {...state, ventas_por_area}
    },
};

export const initialState = {
    submitError: false,
    passError: false,
    nameError: false,
    autenticado: false,
    loader: false,
    mensajeLogin: '',
    bodega: 1,
    apertura: null,
    caja: 1,
    me:{
        cargo:"Supervisor de proyecto",
        email:"aa@gamil.com",
        first_name: '',
        accesos: {
            administrador: true,
            backoffice: false,
            supervisor: false,
            vendedor: false,
            bodeguero: false,
            compras: false,
            sin_acceso: false
        }
    },
    productos_por_vencer_farmacia:{
        dos_meses: 0,
        un_mes:0,
        vencidos:0
    },
    productos_por_vencer_hospital:{
        dos_meses: 0,
        un_mes:0,
        vencidos:0
    },
    cuentas_por_pagar:{
        cantidad: 0,
        total: 0
    },
    gastos_ingresos:{
        gastos: 0,
        ingresos: 0,
    },
    cuentas_por_cobrar:{
        total: 0,
        cantidad: 0
    },
    ventas_por_area:{
        total_farmacia: 0,
        total_hospital: 0,
    },
};

export default handleActions(reducers, initialState);

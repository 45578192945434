import React from 'react';

function CardGrandeForm(props) {
    return (
        <div className="row d-flex justify-content-center">
            <div className="card col-11 ">
                <div className="card-header d-flex justify-content-center text-left">
                    <div className="col-md-9 d-flex flex-row mt-2 align-items-center">
                        <img width={35} height={35} className="" src={props.icono} alt="Usuario" />
                        <span className="ml-2 text-uppercase text-titulo"><strong>{props.titulo}</strong></span>
                    </div>
                </div>

                    <div className="card-body d-flex justify-content-center">
                        <div className="col-md-12 p-0 p-md-2">
                            {props.children}
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default CardGrandeForm;

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';
import { MONEDAS } from 'icons';
import './monedas.css';
import { actions as printActions } from "../../../../Utils/Print";

let MonedasForm = props => {
    const { handleSubmit, caja, total } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div id="monedas" className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1 d-flex justify-content-center ">
                    <div className="col-md-10">
                        <div className="borde-gris py-3">
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.b_200}
                                            className={'billete'}
                                            alt="billetes 200" />
                                    </div>
                                    <div className="col-8 form-group m-0 d-flex align-items-center">
                                        <Field name="b200" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img className={'billete'} src={MONEDAS.b_100} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="b100" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img className={'billete'} src={MONEDAS.b_50} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="b50" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img className={'billete'} src={MONEDAS.b_20} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="b20" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img className={'billete'} src={MONEDAS.b_10} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="b10" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img className={'billete'} src={MONEDAS.b_5} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="b5" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img className={'billete'} src={MONEDAS.b_1} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="b1" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.m_1} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="m1" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.m_50} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="m50" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.m_25} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="m25" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.m_10} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="m10" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.m_5} alt="billetes 200" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="m5" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6 row m-0">
                                    <div className="col-4">
                                        <img src={MONEDAS.m_01} alt="un centavo" />
                                    </div>
                                    <div className="col-8  form-group m-0 d-flex align-items-center">
                                        <Field name="m01" component={renderField} type="number" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <button type="button" onClick={e => {
                                        e.preventDefault();
                                        props.cancelar();
                                    }} className="btn btn-secondary m-1">
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary m-1">
                                    {
                                        caja && caja.cierre ? (
                                            <span>Hacer cierre</span>
                                            ):(
                                                <span>Hacer apertura</span>
                                        )
                                    }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
MonedasForm = reduxForm({
    form: 'monedas',
    validate: data => {
        return validate(data, {
            // 'b200': validators.exists()('Campo requerido.'),
            // 'b100': validators.exists()('Campo requerido.'),
            // 'b50': validators.exists()('Campo requerido.'),
            // 'b20': validators.exists()('Campo requerido.'),
            // 'b10': validators.exists()('Campo requerido.'),
            // 'b5': validators.exists()('Campo requerido.'),
            // 'b1': validators.exists()('Campo requerido.'),
            // 'm1': validators.exists()('Campo requerido.'),
            // 'm50': validators.exists()('Campo requerido.'),
            // 'm25': validators.exists()('Campo requerido.'),
            // 'm10': validators.exists()('Campo requerido.'),
            // 'm5': validators.exists()('Campo requerido.'),
        });
    }
})(MonedasForm);
export default MonedasForm;

import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { formatSelect, formatoMoneda, dateFormatter, formatUsuario } from 'Utils/renderField/renderReadField';
import classNames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { iconsh } from "icons";
import { Link } from 'react-router-dom';


// importaciones de componentes auxiliares
import OrdenCompra from './OrdenCompra';
import OrdenPago from './OrdenPago';
import Ingreso from './Ingreso';
import Pagos from './pagos';

export default class AceptadoContainer extends Component {
    state = {
        activeTab: 'oc'
    }
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.detail(this.props.match.params.id);
            this.props.detailOc(this.props.match.params.id);

        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id != this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.props.detailOc(this.props.match.params.id);
        }
    }
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            if (tab === 'oc') {
                // this.props.listarTodo(1);
                this.props.detailOc(this.props.match.params.id);
            }
            else if (tab === 'op') {
                const { dataActualizada } = this.props;
                let data = {
                    productos: dataActualizada.productos
                }
                // this.props.setFormularioIngreso(data);
            }
            else if (tab === 'ingreso') {
                this.props.getPagos();
            }
            else if (tab === 'pagos') {
                this.props.getPagos();
            }
            else {
                // this.props.listarAceptados(1);
            }
        }
    }
    render() {
        const { create, update, url } = this.props;
        const { dataActualizada, cargando } = this.props;
        return (
            <div className="container m-0 contenido-principal row d-flex justify-content-center">
                <div className="col-md-12">
                    <LoadMask loading={cargando} blur_1>
                        <div className="col-md-12 row m-0 borde-extremo-y">
                            <div className="col-md-1 d-flex  align-items-center">
                                <Link to={`${url.includes("/solicitudes") ? '/solicitudes' : '/ordenes'}`} >
                                    <img className="cursor-pointer" src={iconsh.regresar} width={30} height={30} />
                                </Link>
                            </div>
                            <div className="col-md-3 m-0 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Proveedor:</label>
                                <span className="text-primary">{formatSelect(dataActualizada.proveedor)}</span>
                            </div>
                            <div className="col-md-2 m-0 form-group">
                                <div className="col-md-12 p-0">
                                    <label htmlFor="emitido" className="m-0 mr-2">Orden no:</label>
                                    <span className="text-primary">{dataActualizada.no_orden}</span>
                                </div>
                                <div className="col-md-12 p-0">
                                    <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                                    <span className="text-primary">{dateFormatter(dataActualizada.fecha)}</span>
                                </div>
                            </div>
                            <div className="col-md-3 m-0 form-group">
                                <div className="col-md-12 p-0">
                                    <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                                    <span className="text-primary">{formatUsuario(dataActualizada.usuario)}</span>
                                </div>
                                <div className="col-md-12 p-0">
                                    <label htmlFor="emitido" className="m-0 mr-2">Cuenta:</label>
                                    <span className="text-primary">{dataActualizada.cuenta ? dataActualizada.cuenta.nombre : '-- --'}</span>
                                </div>
                            </div>
                            <div className="col-md-3 m-0 form-group">
                                <label htmlFor="emitido" className="m-0 mr-2">Descripción:</label>
                                <span className="text-primary">{dataActualizada.descripcion}</span>
                            </div>

                        </div>
                    </LoadMask>
                    {/* manejo de tabs */}
                    <div className="col-md-12 mt-4 p-0">
                        <Nav tabs className="col-12  px-0 ">
                            <NavItem className="col-3 col-md-2 pl-0 pr-0">
                                <NavLink
                                    className={classNames('py-2 text-center', { active: this.state.activeTab === 'oc' })}
                                    onClick={() => { this.toggle('oc'); }}>
                                    <h5>Orden de compra</h5>
                                </NavLink>
                            </NavItem>
                            <NavItem className="col-3 col-md-2 pl-0 pr-0">
                                <NavLink
                                    className={classNames('py-2 text-center', { active: this.state.activeTab === 'ingreso' })}
                                    onClick={() => { this.toggle('ingreso'); }}>
                                    <h5>Ingresos</h5>
                                </NavLink>
                            </NavItem>
                            <NavItem className="col-3 col-md-2 pl-0 pr-0">
                                <NavLink
                                    className={classNames('py-2 text-center', { active: this.state.activeTab === 'pagos' })}
                                    onClick={() => { this.toggle('pagos'); }}>
                                    <h5>Pagos</h5>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab} className="mt-0">
                            <TabPane tabId={"oc"}>
                                <LoadMask loading={cargando} dark blur>
                                    <OrdenCompra {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tabId={"ingreso"}>
                                <LoadMask loading={cargando} dark blur>
                                    <Ingreso {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tabId={"pagos"}>
                                <LoadMask loading={cargando} dark blur>
                                    <Pagos {...this.props} />
                                </LoadMask>
                            </TabPane>
                        </TabContent>
                    </div>

                    {/* fin de manejo de tabs */}
                </div>
            </div>
        )
    }
}

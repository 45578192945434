import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,FieldArray } from 'redux-form';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';
import { iconsh, EXTRAS } from  "icons";
import {Animated} from "react-animated-css";


let Paso1Form = props => {
    const { handleSubmit } = props;
    return(
            <form onSubmit={handleSubmit} className="fondo-autorizacion pt-5">
                <div className="form-group grid-container m-0">
                    <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                        <div className="col-md-12 row m-0">
                            <div className="col-md-12 d-flex justify-content-center">
                                <img src={iconsh.crud_bodegas}  height={25}/>
                            </div>
                            <div className="col-md-12">
                                <h3 className="text-center ">{props.bodega}</h3>
                            </div>
                        </div>
                        <div className="col-md-12 row d-flex justify-content-center m-0">
                            <div className="col-md-6">
                                <h2 className="text-uppercase text-azul">Cerrar turno</h2>
                                <div className="text-center">
                                    Hola: <span className="text-primary">{props.usuario}</span>
                                    <p>
                                        Para poder cerrar tu turno debes cerrar caja y luego ingresar tu contraseña.
                                    </p>
                                </div>
                                <div className="col-12">
                                    <div className="col-md-12 col-sm-12 text-center">
                                        <label htmlFor="password" className="m-0 text-center">Contraseña:</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="password1" component={renderField} type="password" className="form-control"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                    <button type="submit" className="btn btn-white btn-with-all">
                                        <img src={EXTRAS.flecha_azul}/>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

    )
};
Paso1Form = reduxForm({
    form: 'autorizacion',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'password1': validators.exists()('Campo requerido.'),
        })
    },
})(Paso1Form);
export default Paso1Form

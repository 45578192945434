import React, { Component } from 'react'
import OrdenesGrid from './OrdenesGrid';
export default class OrdenesContainer extends Component {
  render() {
      const { me } = this.props;
    return (
      <div>
          <OrdenesGrid {...this.props} />
          {/* <OrdenesAdminGrid {...this.props} /> */}
      </div>
    )
  }
}

import React, { Component } from 'react'
import TituloBodega from '../../../Utils/Header/TituloBodega';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { iconsh } from "icons";
import Table from 'Utils/Grid';
import { BreakLine } from 'Utils/tableOptions';
import FormRechazo from './formulario/formulariodespacho';
import { formatAllDate, dateFormatter } from '../../../Utils/renderField/renderReadField';
import {MOVIMIENTO_BODEGA} from '../../../../utility/constants';


function formatFraccion(cell){
    if(cell){
        return cell.nombre_completo;
    }
    return ''
}
function formatLote(cell){
    if(cell){
        return cell.lote;
    }
    return ''
}
function formatVencimiento(cell){
    if(cell){
        return dateFormatter(cell.fecha_vencimiento);
    }
    return ''
}
export default class DetalleMovimiento extends Component {
    componentWillMount(){
        this.props.detail_movimiento(this.props.match.params.id)
    }
  render() {
        const {cargando, page, update_movimiento} = this.props;
        let data = {
            results: update_movimiento.detalle_movimiento,
            count: 0
        }
      return (
      <div className="container m-0 contenido-principal row d-flex justify-content-center">
      {
                    this.props.toggleModal && (
                        <Modal open={this.props.toggleModal} onClose={this.props.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body col-md-12">
                                        <div className="borde-abajo pb-2">
                                            <img width={35} className="" src={iconsh.crudOc} alt="Usuario" />
                                            <span className="ml-2 text-uppercase text-titulo"><strong>Observaciones rechazo</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <FormRechazo
                                        closeModal={this.props.closeModal}
                                        onSubmit={this.props.createRechazar}
                                        />

                                </div>
                            </div>
                        </Modal>
                    )
                }
        <div className="row col-md-12">
            <TituloBodega estilo="col-md-3"
                titulo={update_movimiento.destino ? update_movimiento.destino.nombre:''}
                subtitulo={`${update_movimiento.tipo_id === MOVIMIENTO_BODEGA.DESPACHO ? 'Despacho': 'Devolución'}`} />
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Envio de:</label>
                {
                    update_movimiento.tipo_id === MOVIMIENTO_BODEGA.DESPACHO ? (
                        <span className="text-primary">{update_movimiento.bodega ? update_movimiento.bodega.nombre:''}</span>

                    ) :(
                        <span className="text-primary">{update_movimiento.proveedor ? update_movimiento.proveedor.nombre:''}</span>
                    )
                }
            </div>
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Emitida por:</label>
                <span className="text-primary">{update_movimiento.usuario ? update_movimiento.usuario.nombreCompleto:''}</span>
            </div>
            <div className="col-md-3 d-flex align-items-end">
                <label htmlFor="emitido" className="m-0 mr-2">Fecha:</label>
                <span className="text-primary">{update_movimiento.fecha ? formatAllDate(update_movimiento.fecha) : '' }</span>
            </div>
        </div>
        <div className="col-md-12 mt-5">
        <Table
                            data={data}
                            loading={cargando}
                            pagination={false}
                            page={1}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatFraccion}
                                dataField="fraccion" dataSort>Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatLote}
                                dataField="lote" dataSort>lote</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatVencimiento}
                                dataField="lote" dataSort>Vencimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                                <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataAlign={'right'}
                                dataField="nombre_s" dataSort></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                hidden={true}
                                isKey={true}
                                dataAlign="center"></TableHeaderColumn>
                        </Table>
        </div>

        <div className="col-md-12 row mt-3">
             <div className="col-md-6 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {
                    update_movimiento.tipo_id === MOVIMIENTO_BODEGA.DESPACHO && (
                        <button type="button" onClick={e => {
                            e.preventDefault()
                            this.props.openModal()
                            }} className="btn btn-danger m-1">Rechazar Despacho</button>
                    )
                }
             </div>
             <div className="col-md-6 d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {/* <Link className="btn btn-secondary m-1" to="/solicitudes">Cancelar</Link> */}
                <button type="button" onClick={ e=>{
                    e.preventDefault();
                    this.props.regresar()
                }} className="btn btn-secondary m-1">Cancelar</button>
                <button type="button" onClick={ e=>{
                    e.preventDefault();
                    this.props.ingresar_bodega()
                }} className="btn btn-primary m-1">Confirmar</button>
             </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './CategoriaForm';
import CategoriaUpdateForm  from './CategoriaForm';
import { iconsh } from "icons";

export default class CategoriaCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar Categoría',
        password: false,
    }
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar Categoría'
            });
        }
    }

    render() {
        const { create, update } = this.props;
        const { updateData, cargando } = this.props;
        return (
            <div className="container m-0 contenido-principal row d-flex justify-content-center">
                <div className="col-md-12">
                    <Card
                        icono={iconsh.crud_catgastos}
                        titulo={this.state.mensaje}>
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <CategoriaUpdateForm
                                        onSubmit={update}
                                        updateData={updateData}
                                        editando={true} />
                                    :
                                    <Form onSubmit={create} />
                            }
                        </LoadMask>
                    </Card>
                </div>
            </div>
        )
    }
}

import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Bodega/estadobodega';
import IngresoCrear from './IngresoCrear';

const mstp = state => {
    return {...state.estadobodega}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(IngresoCrear)

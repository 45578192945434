import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './DevolucionForm';
import { DevolucionUpdateForm } from './DevolucionForm';
import { iconsh } from "icons";
import TituloBodega from '../../../Utils/Header/TituloBodega';


export default class DevolucionesCrear extends Component {
state = {
    editar: false,
    mensaje: 'Agregar ',
    password: false,
}
componentWillMount(){
    if(this.props.match.params.id){
        this.props.detail(this.props.match.params.id);
        this.setState({
                editar: true,
                mensaje: 'Editar '
            });
    }
}

render() {
    const { create, update, initCantidad } = this.props;
    const { updateData, cargando, me } = this.props;
    return (
    <div className="container m-0 contenido-principal row d-flex justify-content-center">
        <div className="col-md-12 p-0">
           <TituloBodega
                titulo={'Bodega central'}
                subtitulo={'Devoluciones'}
                estilo={`col-md-12`}
                />
                <div className="mt-5"></div>
                <LoadMask loading={cargando} blur_1>
                    {
                        this.state.editar ?
                            <DevolucionUpdateForm
                                updateData={updateData}
                                onSubmit={update}
                                me={me}
                                initCantidad={initCantidad}
                                editando={true} />
                        :
                            <Form onSubmit={create}  me={me} initCantidad={initCantidad}/>
                    }
                </LoadMask>
        </div>
    </div>
    )
    }
}
